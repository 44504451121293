"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { TableHeading } from "@/v2/styles/table.styles";
export const TableHeadingWithTooltip = ({
  tooltipText,
  columnId,
  maxWidth,
  minWidth,
  style,
  stickyPosition,
  externalTdAndThPadding,
  children
}) => {
  const internalRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);
  useEffect(() => {
    const checkTruncation = () => {
      if (internalRef.current) {
        const child = internalRef.current.querySelector("div span");
        if (child) {
          const { scrollWidth, clientWidth } = child;
          setIsTruncated(scrollWidth > clientWidth);
        }
      }
    };
    checkTruncation();
    window.addEventListener("resize", checkTruncation);
    return () => {
      window.removeEventListener("resize", checkTruncation);
    };
  }, [children]);
  return /* @__PURE__ */ jsx(StyledTooltip, { title: isTruncated ? tooltipText : "", children: /* @__PURE__ */ jsx(
    TableHeading,
    {
      ref: internalRef,
      "data-column-id": columnId,
      maxWidth,
      minWidth,
      style,
      className: "tableHeading",
      stickyPosition,
      externalTdAndThPadding,
      children
    }
  ) });
};
