"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { keyBy } from "lodash";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { useUserIdParam } from "@/hooks/userid-param.hook";
import { ReactComponent as NotUploadedDocIcon } from "@/images/documents/MissingDoc.svg";
import { ReactComponent as UploadedDocIcon } from "@/images/documents/UploadedDoc.svg";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { nestErrorMessage } from "@/lib/errors";
import { USER_ONBOARDING_SUMMARY_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DateLabelComponent } from "@/v2/components/forms/date-label.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { IconTextCell } from "@/v2/components/table/icon-text-cell.component";
import { sortDate, sortString } from "@/v2/components/table/table-sorting.util";
import { DocumentNewAPI } from "@/v2/feature/document-new/api-client/document-new.api";
import { DocumentTypeEndpoints } from "@/v2/feature/document-new/api-client/document-type.api";
import { NewDocumentFormModal } from "@/v2/feature/document-new/components/new-document-form-modal.component";
import { FormTypeEnum } from "@/v2/feature/document-new/interfaces/document-new.interface";
import { CloseButton } from "@/v2/feature/user-onboarding/onboarding-by-user/components/close-button.component";
import { filterUserOnboardingDocuments } from "@/v2/feature/user-onboarding/user-onboarding.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { ReachType } from "@/v2/interfaces/general.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const OnboardingDocumentsPage = () => {
  const { polyglot } = usePolyglot();
  const userId = useUserIdParam();
  const [documents, setDocuments] = useState();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [documentToUpload, setDocumentToUpload] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { data: fetchedDocumentTypes } = useApiClient(DocumentTypeEndpoints.getTypeCompanyId(), {
    suspense: false
  });
  const fetchDocuments = useCallback(() => __async(void 0, null, function* () {
    try {
      const userDocuments = yield DocumentNewAPI.getAllDocumentsByUserId(userId);
      const onboardingDocuments = filterUserOnboardingDocuments(userDocuments).sort(
        (a, b) => a.name.localeCompare(b.name, void 0, { sensitivity: "base" })
      );
      setDocuments({
        items: onboardingDocuments
      });
    } catch (e) {
      showMessage(
        polyglot.t("OnboardingByUser.errors.documentRetrieveFailed", { errorMessage: nestErrorMessage(e) }),
        "error"
      );
      setDocuments(void 0);
    }
  }), [showMessage, userId, polyglot]);
  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);
  const documentTypesLookup = useMemo(() => keyBy(fetchedDocumentTypes != null ? fetchedDocumentTypes : [], "id"), [fetchedDocumentTypes]);
  const columnData = useMemo(() => {
    const getDocumentUploadDate = (doc) => doc.createdAt;
    return [
      {
        id: "name",
        header: () => polyglot.t("OnboardingByUser.name"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: (c) => {
          const doc = c.getValue();
          const hasUploadedFile = !!doc.fileUuid;
          return /* @__PURE__ */ jsx(
            IconTextCell,
            {
              icon: hasUploadedFile ? /* @__PURE__ */ jsx(UploadedDocIcon, { width: 24, height: 30 }) : /* @__PURE__ */ jsx(NotUploadedDocIcon, { width: 24, height: 30 }),
              text: doc.name,
              textSx: hasUploadedFile ? themeFonts.caption : themeFonts.title4
            }
          );
        }
      },
      {
        id: "type",
        header: () => polyglot.t("OnboardingByUser.type"),
        accessorFn: (row) => row,
        enableSorting: true,
        cell: ({ row: { original } }) => {
          return documentTypesLookup && original.typeId && documentTypesLookup[original.typeId] ? /* @__PURE__ */ jsx("div", { children: documentTypesLookup[original.typeId].label }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: "upload",
        header: () => polyglot.t("General.upload"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => getDocumentUploadDate(item)),
        cell: (c) => {
          const documentDate = getDocumentUploadDate(c.getValue());
          return documentDate ? /* @__PURE__ */ jsx(DateLabelComponent, { date: documentDate }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: "action",
        header: () => "",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: (c) => {
          const doc = c.getValue();
          const hasUploadedFile = !!(doc == null ? void 0 : doc.fileUuid);
          return hasUploadedFile ? /* @__PURE__ */ jsx(
            IconButton,
            {
              onClick: () => {
                setDocumentToUpload(doc);
                setIsOpen(true);
              },
              disabled: !!documentToUpload,
              title: polyglot.t("General.edit"),
              sx: __spreadProps(__spreadValues({}, tableIconButtonSx), { display: "flex", ml: "auto" }),
              children: /* @__PURE__ */ jsx(EditIcon, {})
            }
          ) : /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              onClick: () => {
                setDocumentToUpload(doc);
                setIsOpen(true);
              },
              disabled: !!documentToUpload,
              sizeVariant: "small",
              colorVariant: "primary",
              style: { marginLeft: "auto" },
              children: polyglot.t("General.upload")
            }
          );
        }
      }
    ];
  }, [polyglot, documentToUpload, documentTypesLookup]);
  const returnToOnboardingSummary = useCallback(() => {
    routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }));
  }, [routerHistory, userId]);
  const allDocumentsUploaded = !!(documents == null ? void 0 : documents.items.every((d) => !!d.fileUuid));
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1 }, children: [
      /* @__PURE__ */ jsxs(Stack, { sx: { flex: 0, mx: "auto", width: "800px", maxWidth: "90vw", mt: spacing.mt40 }, children: [
        /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.title2), {
              color: themeColors.DarkGrey
            }),
            children: polyglot.t("OnboardingByUser.uploadDocuments")
          }
        ),
        /* @__PURE__ */ jsx(CloseButton, { onClick: () => returnToOnboardingSummary() })
      ] }),
      /* @__PURE__ */ jsx(Stack, { sx: { flex: 1, overflowY: "auto", mt: spacing.mt30 }, children: /* @__PURE__ */ jsxs(Stack, { sx: { mx: "auto", width: "800px", maxWidth: "90vw" }, children: [
        documents && /* @__PURE__ */ jsx(BasicTable, { rowData: documents.items, columnData, hidePagination: true }),
        allDocumentsUploaded && /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            onClick: () => returnToOnboardingSummary(),
            style: { marginTop: "40px" },
            children: polyglot.t("General.complete")
          }
        )
      ] }) })
    ] }),
    /* @__PURE__ */ jsx(
      NewDocumentFormModal,
      {
        isOpen,
        setIsOpen,
        onClose: () => {
          setIsOpen(false);
          setTimeout(() => {
            setDocumentToUpload(null);
          }, 300);
        },
        documentTypes: fetchedDocumentTypes != null ? fetchedDocumentTypes : [],
        refresh: fetchDocuments,
        document: documentToUpload != null ? documentToUpload : void 0,
        formType: FormTypeEnum.upload,
        reach: ReachType.Me
      }
    )
  ] });
};
