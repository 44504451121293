"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Autocomplete, Paper, Typography } from "@mui/material";
import { pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import { ReactComponent as ArrowDownACIcon } from "@/images/side-bar-icons/ArrowDownAC.svg";
import { ReactComponent as ChoseIcon } from "@/images/side-bar-icons/Chose.svg";
import { ClearIcon } from "@/v2/components/theme-components/clear-icon.component";
import { COUNTRY_CODE_TO_NAME_MAPPING, COUNTRY_ISO_CODE_MAPPING } from "@/v2/infrastructure/country/country.interface";
import { getCountriesForNationality } from "@/v2/infrastructure/country/country.util";
import { StyledAuto, StyledAutoTextfield } from "@/v2/styles/autocomplete.styles";
import { StyledMenuItem } from "@/v2/styles/menu.styles";
const iconSize = { width: "14px", height: "14px" };
const countryOptions = pipe(
  getCountriesForNationality(),
  RA.map(
    (r) => {
      var _a;
      return pipe(
        (_a = r.options) != null ? _a : [],
        RA.map((x) => __spreadProps(__spreadValues({}, x), { group: r.label }))
      );
    }
  ),
  RA.flatten
);
export const CountrySelect = (_a) => {
  var _b = _a, {
    label = "Country",
    name,
    onChange,
    error,
    helperText,
    value,
    valueType = "country-name"
  } = _b, autoCompleteProps = __objRest(_b, [
    "label",
    "name",
    "onChange",
    "error",
    "helperText",
    "value",
    "valueType"
  ]);
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const autoCompleteOptions = useMemo(
    () => ({
      "country-name": () => countryOptions,
      // only return countries where we have an ISO code set
      "iso-code": () => countryOptions.filter((c) => c.value in COUNTRY_ISO_CODE_MAPPING)
    })[valueType](),
    [valueType]
  );
  useEffect(() => {
    var _a2;
    const countryName = valueType === "country-name" ? value : value && COUNTRY_CODE_TO_NAME_MAPPING[value];
    const matchedOption = (_a2 = autoCompleteOptions.find((option) => option.value === countryName)) != null ? _a2 : null;
    setAutoCompleteValue(matchedOption);
  }, [value, valueType, autoCompleteOptions]);
  return /* @__PURE__ */ jsx(
    Autocomplete,
    __spreadProps(__spreadValues({}, autoCompleteProps), {
      options: autoCompleteOptions,
      getOptionLabel: (option) => option.label,
      value: autoCompleteValue != null ? autoCompleteValue : void 0,
      renderOption: (props, option) => {
        return option.value === value ? /* @__PURE__ */ jsx(StyledMenuItem, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsxs(Typography, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }, children: [
          option.label,
          /* @__PURE__ */ jsx(ChoseIcon, __spreadValues({}, iconSize))
        ] }) })) : /* @__PURE__ */ jsx(StyledMenuItem, __spreadProps(__spreadValues({}, props), { children: option.label }));
      },
      groupBy: (option) => option.group,
      onChange: (_, x) => {
        var _a2;
        return onChange((_a2 = x == null ? void 0 : x.value) != null ? _a2 : null, COUNTRY_ISO_CODE_MAPPING[x == null ? void 0 : x.value]);
      },
      renderInput: (params) => /* @__PURE__ */ jsx(
        StyledAutoTextfield,
        __spreadProps(__spreadValues({}, params), {
          variant: "standard",
          size: "small",
          InputLabelProps: { shrink: true },
          label,
          name,
          error,
          helperText
        })
      ),
      popupIcon: /* @__PURE__ */ jsx(ArrowDownACIcon, {}),
      clearIcon: /* @__PURE__ */ jsx(ClearIcon, {}),
      PaperComponent: ({ children }) => /* @__PURE__ */ jsx(Paper, { sx: StyledAuto, children })
    })
  );
};
