"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { DeviceTransitDrawer } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/device-transit-action-item.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { secondaryCTABtn } from "@v2/styles/buttons.styles";
import { SiteEndpoints } from "@/api-client/site.api";
import { IconContentActionCard } from "@/v2/components/theme-components/icon-content-action-card.component";
import { getModelImage, getTransitStatus } from "@/v2/feature/device/device.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const MyDevicesReturns = ({
  deviceTransits,
  refreshDevicesData
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDeviceTransitId, setSelectedDeviceTransitId] = useState(null);
  const { data: sites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title2, children: "Return" }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: deviceTransits.length > 0 ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: deviceTransits.map((d, idx) => {
      var _a, _b, _c, _d, _e, _f, _g, _h, _i;
      return /* @__PURE__ */ jsx(
        IconContentActionCard,
        {
          iconMedia: d && getModelImage((_a = d == null ? void 0 : d.device) == null ? void 0 : _a.type, (_b = d == null ? void 0 : d.device) == null ? void 0 : _b.modelName, {
            width: "150px",
            height: "auto"
          }),
          title: (_g = (_e = (_c = d.device) == null ? void 0 : _c.deviceName) != null ? _e : (_d = d.device) == null ? void 0 : _d.modelName) != null ? _g : (_f = d.device) == null ? void 0 : _f.type,
          content: /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m5, display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
            ((_h = d.device) == null ? void 0 : _h.modelName) && /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: (_i = d.device) == null ? void 0 : _i.modelName }),
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: getTransitStatus(d.status) })
          ] }),
          action: /* @__PURE__ */ jsx(
            Button,
            {
              sx: secondaryCTABtn,
              onClick: () => {
                setSelectedDeviceTransitId(d.id);
                setIsDrawerOpen(true);
              },
              children: "Details"
            }
          ),
          showDivider: idx < deviceTransits.length - 1
        }
      );
    }) }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: "There are no active returns or handovers at the moment" }) }),
    isDrawerOpen && selectedDeviceTransitId && /* @__PURE__ */ jsx(
      DeviceTransitDrawer,
      {
        deviceTransitId: selectedDeviceTransitId,
        refresh: refreshDevicesData,
        afterClose: () => setIsDrawerOpen(false),
        isOpen: isDrawerOpen,
        setIsOpen: setIsDrawerOpen,
        sites: sites != null ? sites : []
      }
    )
  ] });
};
