"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Settings } from "@/images/fields/Settings.svg";
import { ReactComponent as Apps } from "@/images/side-bar-icons/Apps.svg";
import { ReactComponent as Payment } from "@/images/side-bar-icons/Bill.svg";
import { ReactComponent as Clock } from "@/images/side-bar-icons/Clock.svg";
import { ReactComponent as Device } from "@/images/side-bar-icons/Device.svg";
import { ReactComponent as Document } from "@/images/side-bar-icons/Document.svg";
import { ReactComponent as NoAccess } from "@/images/side-bar-icons/NoAccess.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as PartialAccess } from "@/images/side-bar-icons/PartialAccess.svg";
import { ReactComponent as PersonIcon } from "@/images/side-bar-icons/Person.svg";
import { ReactComponent as Report } from "@/images/side-bar-icons/Report.svg";
import { ReactComponent as Reviews } from "@/images/side-bar-icons/Reviews.svg";
import { ReactComponent as Star } from "@/images/side-bar-icons/Star.svg";
import { ReactComponent as Task } from "@/images/side-bar-icons/Task.svg";
import { ReactComponent as TimeAway } from "@/images/side-bar-icons/TimeAway.svg";
import { ReactComponent as Wallet } from "@/images/side-bar-icons/Wallet.svg";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { translatePermissionCategory } from "@/v2/infrastructure/i18n/translate.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
export const PERMISSION_CATEGORY_ICON_MAP = {
  "Personal profile": /* @__PURE__ */ jsx(PersonIcon, __spreadValues({}, iconSize)),
  "Compensation profile": /* @__PURE__ */ jsx(PersonIcon, __spreadValues({}, iconSize)),
  "Work profile": /* @__PURE__ */ jsx(PersonIcon, __spreadValues({}, iconSize)),
  "Contact profile": /* @__PURE__ */ jsx(PersonIcon, __spreadValues({}, iconSize)),
  Apps: /* @__PURE__ */ jsx(Apps, __spreadValues({}, iconSize)),
  Administration: /* @__PURE__ */ jsx(Settings, __spreadValues({}, iconSize)),
  Devices: /* @__PURE__ */ jsx(Device, __spreadValues({}, iconSize)),
  Payroll: /* @__PURE__ */ jsx(Wallet, __spreadValues({}, iconSize)),
  Benefits: /* @__PURE__ */ jsx(Star, __spreadValues({}, iconSize)),
  Payments: /* @__PURE__ */ jsx(Payment, __spreadValues({}, iconSize)),
  Expenses: /* @__PURE__ */ jsx(Payment, __spreadValues({}, iconSize)),
  Invoices: /* @__PURE__ */ jsx(Payment, __spreadValues({}, iconSize)),
  "Time and Calendar": /* @__PURE__ */ jsx(TimeAway, __spreadValues({}, iconSize)),
  Documents: /* @__PURE__ */ jsx(Document, __spreadValues({}, iconSize)),
  Tasks: /* @__PURE__ */ jsx(Task, __spreadValues({}, iconSize)),
  Reviews: /* @__PURE__ */ jsx(Reviews, __spreadValues({}, iconSize)),
  Reports: /* @__PURE__ */ jsx(Report, __spreadValues({}, iconSize)),
  Attendance: /* @__PURE__ */ jsx(Clock, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.black } }))
};
const PERMISSION_ACCESS_ICON_MAP = (polyglot) => {
  return {
    [polyglot.t("PermissionCategoryListingTable.partial")]: /* @__PURE__ */ jsx(PartialAccess, __spreadValues({}, iconSize)),
    [polyglot.t("PermissionCategoryListingTable.noAccess")]: /* @__PURE__ */ jsx(NoAccess, __spreadValues({}, iconSize)),
    [polyglot.t("PermissionCategoryListingTable.fullAccess")]: /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } }))
  };
};
export const PermissionCategoryListingTable = ({
  permissionCategories,
  loading,
  openDrawerInEditMode,
  openDrawerInViewMode,
  permissionGroupName
}) => {
  const { polyglot } = usePolyglot();
  const isAdmin = permissionGroupName === "Admin";
  const [enabledState, setEnabledState] = useState({});
  const getEnabledPermissionsState = useCallback(
    (category) => {
      var _a, _b, _c, _d;
      const totalPermissions = (_b = (_a = category.subcategories[0]) == null ? void 0 : _a.permissions) == null ? void 0 : _b.length;
      const enabledPermissions = (_d = (_c = category.subcategories[0]) == null ? void 0 : _c.permissions) == null ? void 0 : _d.filter(
        (p) => {
          var _a2;
          return (_a2 = p.permission_group_scope) == null ? void 0 : _a2.enabled;
        }
      ).length;
      return enabledPermissions === totalPermissions ? polyglot.t("PermissionCategoryListingTable.fullAccess") : enabledPermissions > 0 && enabledPermissions < totalPermissions ? polyglot.t("PermissionCategoryListingTable.partial") : enabledPermissions === 0 ? polyglot.t("PermissionCategoryListingTable.noAccess") : "";
    },
    [polyglot]
  );
  useEffect(() => {
    const enabledState2 = {};
    permissionCategories.forEach((category) => {
      enabledState2[category.name] = getEnabledPermissionsState(category);
    });
    setEnabledState(enabledState2);
  }, [getEnabledPermissionsState, permissionCategories, polyglot]);
  const tableColumns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("PermissionCategoryListingTable.domain"),
        accessorFn: (row) => translatePermissionCategory(row.name, polyglot),
        id: "name",
        maxSize: 350,
        minSize: 250,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return original && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [
            PERMISSION_CATEGORY_ICON_MAP[original.name],
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { ml: spacing.m5, color: themeColors.DarkGrey }), children: translatePermissionCategory(original.name, polyglot) })
          ] });
        }
      },
      {
        header: () => polyglot.t("PermissionCategoryListingTable.access"),
        accessorFn: (row) => row,
        id: "access",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return original && enabledState[original.name] ? /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                alignItems: "center",
                color: enabledState[original.name] !== "Full access" ? themeColors.Grey : themeColors.DarkGrey
              },
              children: [
                PERMISSION_ACCESS_ICON_MAP(polyglot)[enabledState[original.name]],
                /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { ml: spacing.m5, color: themeColors.DarkGrey }), children: enabledState[original.name] })
              ]
            }
          ) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      }
    ];
  }, [enabledState, polyglot]);
  const handleRowClick = useCallback(
    (row) => {
      !isAdmin ? openDrawerInEditMode(row.original) : openDrawerInViewMode(row);
    },
    [openDrawerInViewMode, openDrawerInEditMode, isAdmin]
  );
  return /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m20 }, children: /* @__PURE__ */ jsx(
    BasicTable,
    {
      rowData: permissionCategories,
      columnData: tableColumns,
      loading,
      hidePagination: true,
      rowClick: handleRowClick
    }
  ) });
};
