"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box, IconButton } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as DeleteIcon } from "@/images/side-bar-icons/Trash.svg";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortString } from "@/v2/components/table/table-sorting.util";
import { tableIconButtonBoxSx, tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/table.styles";
export const SiteListingTable = ({
  sites,
  loading,
  openFormDrawer,
  openDeleteDrawer
}) => {
  const { polyglot } = usePolyglot();
  const tableColumns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("SiteListingTable.name"),
        accessorFn: (row) => row.name,
        id: "displayName",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => {
          return original && /* @__PURE__ */ jsx("div", { children: polyglot.t(original.name) });
        }
      },
      {
        header: () => polyglot.t("SiteListingTable.address"),
        accessorFn: (row) => row.address,
        id: "address",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return original && /* @__PURE__ */ jsx("div", { children: original.address });
        }
      },
      {
        header: () => "",
        id: "action",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsxs(Box, { sx: tableIconButtonBoxSx, children: [
          /* @__PURE__ */ jsx(
            IconButton,
            {
              title: polyglot.t("General.delete"),
              sx: tableIconButtonSx,
              onClick: () => {
                openDeleteDrawer(original);
              },
              children: /* @__PURE__ */ jsx(DeleteIcon, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              title: polyglot.t("General.edit"),
              sx: tableIconButtonSx,
              onClick: () => openFormDrawer(original),
              children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
            }
          )
        ] })
      }
    ];
  }, [polyglot, openFormDrawer, openDeleteDrawer]);
  const handleRowClick = useCallback(
    (row) => {
      openFormDrawer(row.original);
    },
    [openFormDrawer]
  );
  return /* @__PURE__ */ jsx(
    BasicTable,
    {
      rowData: sites,
      columnData: tableColumns,
      loading,
      initialSort: [{ id: "displayName", desc: true }],
      rowClick: handleRowClick
    }
  );
};
