"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { sortDate } from "@/v2/components/table/table-sorting.util";
import { Typography } from "@/v2/components/typography/typography.component";
import { convertMinutesToHHMM } from "@/v2/feature/absence/absence.util";
import { ScheduleTrackingType } from "@/v2/feature/attendance/attendance.interface";
import { getAttendanceStatusIcon } from "@/v2/feature/attendance/attendance.util";
import { AttendanceEmptyState } from "@/v2/feature/attendance/company/components/empty-state/attendance-empty-state.component";
import { TrackTimeDrawer } from "@/v2/feature/attendance/company/components/track-time-drawer.component";
import { SubmitWeekRequestsButtonComponent } from "@/v2/feature/attendance/components/submit-week-requests-button.component";
import { AttendanceWeeklyHeaderLoader } from "@/v2/feature/attendance/me/components/attendance-weekly-header-loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const AttendanceMeWeeklyEntries = ({
  weeklyEntries,
  userLoading,
  refresh,
  loading,
  weekList,
  refreshWeekList,
  userId
}) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(void 0);
  const generateDynamicColumns = (data) => {
    const uniqueNames = /* @__PURE__ */ new Set();
    data.forEach((userData) => {
      Object.keys(userData.loggedHoursByType).forEach((key) => {
        uniqueNames.add(key);
      });
    });
    return Array.from(uniqueNames).map((name) => ({
      id: name,
      header: () => name,
      accessorFn: (row) => row,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const value = original.loggedHoursByType[name];
        return value !== void 0 ? /* @__PURE__ */ jsx("div", { style: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: /* @__PURE__ */ jsx("div", { children: convertMinutesToHHMM(value) }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
      }
    }));
  };
  const columns = useMemo(() => {
    const dateColumns = [
      {
        id: "logDate",
        header: () => polyglot.t("AttendanceDomain.date"),
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.logDate),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.logDate ? getDateString(original.logDate) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => polyglot.t("AttendanceDomain.status"),
        accessorFn: (row) => row,
        id: "status",
        enableSorting: false,
        cell: ({ row: { original } }) => original.status ? /* @__PURE__ */ jsx("div", { children: getAttendanceStatusIcon(original.status, false, polyglot) }) : /* @__PURE__ */ jsx(EmptyCell, {})
      }
    ];
    return weeklyEntries ? [...dateColumns, ...generateDynamicColumns(weeklyEntries == null ? void 0 : weeklyEntries.data)] : [...dateColumns];
  }, [polyglot, weeklyEntries]);
  const handleRowClick = useCallback(({ original }) => {
    setSelectedRow(original);
    setIsOpen(true);
  }, []);
  const weekStatuses = useMemo(() => {
    return weeklyEntries && weeklyEntries.data && weeklyEntries.data.map((d) => d.status).filter((a) => a !== null);
  }, [weeklyEntries]);
  return /* @__PURE__ */ jsxs(Box, { sx: spacing.mt20, children: [
    !loading && weekList && weekList.length === 0 ? /* @__PURE__ */ jsx(AttendanceEmptyState, {}) : /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Box, { sx: spacing.pl20, children: /* @__PURE__ */ jsx(
        AttendanceWeeklyHeader,
        {
          headerData: weeklyEntries == null ? void 0 : weeklyEntries.header,
          userId,
          weekStatuses,
          loading: userLoading,
          refreshWeekList
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: spacing.pl20, children: /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: (weeklyEntries == null ? void 0 : weeklyEntries.data) ? [...weeklyEntries == null ? void 0 : weeklyEntries.data] : [],
          columnData: columns,
          hidePagination: true,
          loading: userLoading,
          rowClick: handleRowClick
        }
      ) }) })
    ] }),
    selectedRow && // this needs to be defined so the userId and the logDate is defined on open
    /* @__PURE__ */ jsx(
      TrackTimeDrawer,
      {
        isOpen,
        setIsOpen,
        userId: selectedRow.userId,
        refresh: () => __async(void 0, null, function* () {
          yield refresh(`${weeklyEntries == null ? void 0 : weeklyEntries.header.weekNo}/${weeklyEntries == null ? void 0 : weeklyEntries.header.year}`);
        }),
        onClose: () => {
          setIsOpen(false);
        },
        afterClose: () => {
          setSelectedRow(void 0);
        },
        mode: "view",
        view: "user",
        logDate: selectedRow.logDate,
        weekAttendances: []
      }
    )
  ] });
};
const AttendanceWeeklyHeader = ({
  headerData,
  userId,
  weekStatuses,
  loading,
  refreshWeekList
}) => {
  const { polyglot } = usePolyglot();
  return loading ? /* @__PURE__ */ jsx(AttendanceWeeklyHeaderLoader, {}) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: [
    headerData && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g20 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("UserAttendancePage.weekNum", { weekNum: headerData.weekNo }) }),
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: headerData.name })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(
        SubmitWeekRequestsButtonComponent,
        {
          userId,
          year: headerData.year,
          weekNo: headerData.weekNo,
          weekStatuses: weekStatuses != null ? weekStatuses : [],
          refreshRequests: () => __async(void 0, null, function* () {
            return yield refreshWeekList();
          }),
          isClockInSchedule: Boolean(
            headerData && headerData.trackingType === ScheduleTrackingType.ClockInClockOut
          ),
          trackingType: headerData == null ? void 0 : headerData.trackingType
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gap: spacing.g5,
          width: "100%"
        },
        children: headerData && headerData.scheduleAndAbsenceData.map((s, i) => /* @__PURE__ */ jsx(ScheduleTitle, { scheduleDetail: s }, i))
      }
    )
  ] });
};
const ScheduleTitle = ({ scheduleDetail }) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        height: "95px",
        background: scheduleDetail.blockedByAbsence ? (_c = (_b = (_a = scheduleDetail == null ? void 0 : scheduleDetail.absence[0]) == null ? void 0 : _a.policy) == null ? void 0 : _b.color) != null ? _c : themeColors.Background : themeColors.Background,
        borderRadius: radius.br8,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      },
      children: /* @__PURE__ */ jsxs("div", { style: { padding: "15px 10px 15px 10px" }, children: [
        /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "title4",
            sx: {
              color: scheduleDetail.isWorking || scheduleDetail.blockedByAbsence ? themeColors.DarkGrey : themeColors.Grey,
              textAlign: "center"
            },
            children: new LocalDate(scheduleDetail.currentDate).toLocaleDateString(void 0, {
              day: "numeric",
              weekday: "short"
            })
          }
        ),
        scheduleDetail.blockedByAbsence ? /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "caption",
              sx: {
                color: themeColors.DarkGrey,
                textAlign: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginTop: spacing.m5
              },
              children: scheduleDetail.absence.length > 1 ? polyglot.t("AttendanceDomain.holiday") : (_f = (_e = (_d = scheduleDetail.absence[0]) == null ? void 0 : _d.policy) == null ? void 0 : _e.name) != null ? _f : polyglot.t("AttendanceDomain.away")
            }
          ),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { color: themeColors.DarkGrey, textAlign: "center" }, children: scheduleDetail.absence.length > 1 ? polyglot.t("AttendanceDomain.allDay") : scheduleDetail.absence[0].afternoonOnly ? polyglot.t("AttendanceDomain.pm") : scheduleDetail.absence[0].morningOnly ? polyglot.t("AttendanceDomain.am") : polyglot.t("AttendanceDomain.allDay") })
        ] }) : scheduleDetail.isWorking ? /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(
          Typography,
          {
            variant: "caption",
            sx: {
              color: scheduleDetail.isWorking ? themeColors.DarkGrey : themeColors.Grey,
              textAlign: "center",
              marginTop: spacing.m5
            },
            children: [
              scheduleDetail.from,
              " - ",
              scheduleDetail.to
            ]
          }
        ) }) : !scheduleDetail.isWorking ? /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "caption",
            sx: {
              color: scheduleDetail.isWorking ? themeColors.DarkGrey : themeColors.Grey,
              textAlign: "center",
              marginTop: spacing.m5
            },
            children: polyglot.t("AttendanceDomain.notWorking")
          }
        ) : /* @__PURE__ */ jsx(Fragment, {})
      ] })
    }
  );
};
