"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { keyBy, merge, values } from "lodash";
import { generatePath, Redirect, Switch, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import {
  SETTINGS_EDIT_PERMISSION_GROUP_MEMBERS_ROUTE,
  SETTINGS_EDIT_PERMISSION_GROUP_PERMISSIONS_ROUTE,
  SETTINGS_EDIT_PERMISSION_GROUP_TARGETS_ROUTE,
  SETTINGS_PERMISSION_GROUP_GENERAL_ROUTE,
  SETTINGS_PERMISSION_GROUP_ROUTE,
  SETTINGS_PERMISSION_GROUPS_ROUTE
} from "@/lib/routes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { PayrollAPI } from "@/v2/feature/payroll/payroll.api";
import { EditPermissionGroupGeneralPage } from "@/v2/feature/permission-group/pages/edit-permission-group-general.page";
import { EditPermissionGroupMembersPage } from "@/v2/feature/permission-group/pages/edit-permission-group-members.page";
import { EditPermissionGroupPermissionsPage } from "@/v2/feature/permission-group/pages/edit-permission-group-permissions.page";
import { EditPermissionGroupTargetsPage } from "@/v2/feature/permission-group/pages/edit-permission-group-targets.page";
import { PermissionGroupAPI } from "@/v2/feature/permission-group/permission-group.api";
import {
  DEFAULT_GROUPS,
  getAdditionalScopesFromCategories,
  getScopeCategories
} from "@/v2/feature/permission-group/permission-group.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const getPageConfig = (permissionGroupId, polyglot, isDefault, isAdmin, permissionGroup) => {
  var _a;
  const navItems = [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t("PermissionGroups.router.general"),
          stub: "general",
          path: generatePath(SETTINGS_PERMISSION_GROUP_GENERAL_ROUTE, { permissionGroupId })
        },
        {
          title: polyglot.t("PermissionGroups.router.permissions"),
          stub: "permissions",
          path: generatePath(SETTINGS_EDIT_PERMISSION_GROUP_PERMISSIONS_ROUTE, { permissionGroupId })
        },
        {
          title: polyglot.t("PermissionGroups.router.members"),
          stub: "members",
          path: generatePath(SETTINGS_EDIT_PERMISSION_GROUP_MEMBERS_ROUTE, { permissionGroupId })
        }
      ]
    }
  ];
  if (permissionGroup && !isDefault && !isAdmin) {
    (_a = navItems[0].subItems) == null ? void 0 : _a.push({
      title: polyglot.t("PermissionGroups.router.targets"),
      stub: "targets",
      path: generatePath(SETTINGS_EDIT_PERMISSION_GROUP_TARGETS_ROUTE, { permissionGroupId })
    });
  }
  return navItems;
};
export function PermissionGroupEditRouter() {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const params = useParams();
  const permissionGroupId = Number(params.permissionGroupId);
  const [loading, setLoading] = useState(true);
  const [permissionGroup, setPermissionGroup] = useState(null);
  const [additionalScopes, setAdditionalScopes] = useState({ Administration: [] });
  const [permissionCategories, setPermissionCategories] = useState([]);
  const isDefault = permissionGroup ? DEFAULT_GROUPS.includes(permissionGroup == null ? void 0 : permissionGroup.name) : false;
  const isAdmin = (permissionGroup == null ? void 0 : permissionGroup.name) === "Admin";
  const [entitiesForPermission, setEntitiesForPermission] = useState(null);
  const loadPermissionGroup = useCallback(
    (id) => __async(this, null, function* () {
      setLoading(true);
      try {
        const permissionGroup2 = yield PermissionGroupAPI.getPermissionGroup(id);
        setPermissionGroup(permissionGroup2);
        let permissions = permissionGroup2.permissions;
        if (!permissionGroup2.isDefault) {
          let allPermissions = yield PermissionGroupAPI.listPermissions();
          allPermissions = allPermissions.filter((permission) => !permission.scope.includes(":manager"));
          permissions = values(merge(keyBy(allPermissions, "scope"), keyBy(permissionGroup2.permissions, "scope")));
        }
        const categoryWithPermissions = getScopeCategories(permissions);
        setPermissionCategories(categoryWithPermissions);
        const additionalScopes2 = getAdditionalScopesFromCategories(permissions);
        setAdditionalScopes(additionalScopes2);
        const entities = {};
        const companyPayrolls = yield PayrollAPI.getCompanyPayrolls();
        const payrolls = companyPayrolls.items.map(({ id: id2, displayName }) => ({
          id: `${id2}`,
          label: displayName
        }));
        entities["payroll"] = payrolls;
        setEntitiesForPermission(entities);
        setLoading(false);
      } catch (error) {
        showMessage("Permission group info could not be loaded.", "error");
      } finally {
        setLoading(false);
      }
    }),
    [showMessage]
  );
  useEffect(() => {
    if (!permissionGroup) loadPermissionGroup(permissionGroupId);
  }, [permissionGroup]);
  const refresh = useCallback(() => __async(this, null, function* () {
    loadPermissionGroup(permissionGroupId);
  }), []);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: (_a = permissionGroup == null ? void 0 : permissionGroup.name) != null ? _a : "",
        subtitle: polyglot.t("PermissionGroupListingTable.permissionGroup"),
        pageConfig: getPageConfig(permissionGroupId, polyglot, isDefault, isAdmin, permissionGroup),
        backPath: SETTINGS_PERMISSION_GROUPS_ROUTE,
        showBack: true,
        type: "Custom"
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, children: permissionGroup && /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["company.settings:all"], path: SETTINGS_PERMISSION_GROUP_GENERAL_ROUTE, exact: true, children: /* @__PURE__ */ jsx(
        EditPermissionGroupGeneralPage,
        {
          permissionGroup,
          refresh,
          additionalScopes,
          permissionCategories,
          loading,
          setLoading
        }
      ) }),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: ["company.settings:all"],
          path: SETTINGS_EDIT_PERMISSION_GROUP_PERMISSIONS_ROUTE,
          exact: true,
          children: /* @__PURE__ */ jsx(
            EditPermissionGroupPermissionsPage,
            {
              permissionGroup,
              refresh,
              additionalScopes,
              permissionCategories,
              loading,
              setLoading,
              entitiesForPermission
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: ["company.settings:all"],
          path: SETTINGS_EDIT_PERMISSION_GROUP_MEMBERS_ROUTE,
          exact: true,
          children: /* @__PURE__ */ jsx(
            EditPermissionGroupMembersPage,
            {
              permissionGroup,
              refresh,
              additionalScopes,
              permissionCategories,
              loading,
              setLoading
            }
          )
        }
      ),
      permissionGroup && !isDefault && !isAdmin && /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: ["company.settings:all"],
          path: SETTINGS_EDIT_PERMISSION_GROUP_TARGETS_ROUTE,
          exact: true,
          children: /* @__PURE__ */ jsx(
            EditPermissionGroupTargetsPage,
            {
              permissionGroup,
              refresh,
              additionalScopes,
              permissionCategories,
              loading,
              setLoading
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_PERMISSION_GROUP_ROUTE, exact: true })
    ] }) })
  ] });
}
