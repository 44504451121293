"use strict";
export var ReminderFrequencyValue = /* @__PURE__ */ ((ReminderFrequencyValue2) => {
  ReminderFrequencyValue2["Daily"] = "daily";
  ReminderFrequencyValue2["EveryMonday"] = "everyMonday";
  ReminderFrequencyValue2["None"] = "none";
  return ReminderFrequencyValue2;
})(ReminderFrequencyValue || {});
export var ReviewerTypes = /* @__PURE__ */ ((ReviewerTypes2) => {
  ReviewerTypes2["Peer"] = "Peer";
  ReviewerTypes2["Self"] = "Self";
  ReviewerTypes2["Manager"] = "Manager";
  ReviewerTypes2["Upward"] = "Upward";
  return ReviewerTypes2;
})(ReviewerTypes || {});
export const NonSelfReviewerTypes = ["Peer" /* Peer */, "Manager" /* Manager */, "Upward" /* Upward */];
export var ReminderFrequencyLabel = /* @__PURE__ */ ((ReminderFrequencyLabel2) => {
  ReminderFrequencyLabel2["Daily"] = "Daily";
  ReminderFrequencyLabel2["EveryMonday"] = "Weekly on Monday";
  ReminderFrequencyLabel2["None"] = "None";
  return ReminderFrequencyLabel2;
})(ReminderFrequencyLabel || {});
export var ReminderType = /* @__PURE__ */ ((ReminderType2) => {
  ReminderType2["All"] = "all";
  ReminderType2["Overdue"] = "overdue";
  return ReminderType2;
})(ReminderType || {});
export const ReminderFrequencyOptions = [
  {
    value: "daily" /* Daily */,
    label: "Daily" /* Daily */
  },
  {
    value: "everyMonday" /* EveryMonday */,
    label: "Weekly on Monday" /* EveryMonday */
  },
  {
    value: "none" /* None */,
    label: "None" /* None */
  }
];
export const ReminderFrequencyDict = {
  ["daily" /* Daily */]: "Daily" /* Daily */,
  ["everyMonday" /* EveryMonday */]: "Weekly on Monday" /* EveryMonday */,
  ["none" /* None */]: "None" /* None */
};
export const InviteMessageOptions = [
  {
    value: "default",
    label: "Use default message"
  }
];
