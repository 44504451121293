"use strict";
import { jsx } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from "react";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { TableData } from "@/v2/styles/table.styles";
export const TableDataWithTooltip = React.forwardRef(
  ({ tooltipText, columnId, maxWidth, minWidth, meta, stickyPosition, cellStyle, externalTdAndThPadding, children }, forwardedRef) => {
    const internalRef = useRef(
      null
    );
    const [isTruncated, setIsTruncated] = useState(false);
    const setRefs = (node) => {
      internalRef.current = node;
      if (typeof forwardedRef === "function") {
        forwardedRef(node);
      } else if (forwardedRef) {
        forwardedRef.current = node;
      }
    };
    useEffect(() => {
      const checkTruncation = () => {
        if (internalRef.current) {
          const childrenToCheck = internalRef.current.querySelectorAll("div, p");
          let isAnyChildTruncated = false;
          childrenToCheck.forEach((child) => {
            const { scrollWidth, clientWidth } = child;
            if (scrollWidth > clientWidth) {
              isAnyChildTruncated = true;
            }
          });
          setIsTruncated(isAnyChildTruncated);
        }
      };
      checkTruncation();
      window.addEventListener("resize", checkTruncation);
      return () => {
        window.removeEventListener("resize", checkTruncation);
      };
    }, [children]);
    return /* @__PURE__ */ jsx(StyledTooltip, { title: isTruncated ? tooltipText : "", children: /* @__PURE__ */ jsx(
      TableData,
      {
        ref: setRefs,
        "data-column-id": columnId,
        maxWidth,
        minWidth,
        cellStyle,
        meta,
        stickyPosition,
        externalTdAndThPadding,
        children
      }
    ) });
  }
);
