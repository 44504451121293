"use strict";
import { jsx } from "react/jsx-runtime";
import { useContext } from "react";
import { Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { REPORT_COMPANY_REPORTS_ROUTE } from "@/lib/routes";
import { ReportsRouter } from "@/v2/feature/reports/reports.router";
export const HiddenContentRouter = () => {
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext(user);
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(
    RouteScopesControl,
    {
      scopes: ["reports:all"],
      context: scopesContext,
      path: [REPORT_COMPANY_REPORTS_ROUTE],
      component: ReportsRouter
    }
  ) });
};
