"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Alert, Box, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { Typography } from "@v2/components/typography/typography.component";
import { FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { nestErrorMessage } from "@/lib/errors";
import { LOGIN_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { AuthLayout } from "@/v2/feature/auth/components/auth-layout.component";
import { borders } from "@/v2/styles/borders.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const states = {
  FORGOT: 0,
  SENT: 1,
  CHOOSE: 2
};
const validationSchema = yup.object({
  email: yup.string().email("Enter a valid email address").required("Email is required")
});
export const AuthForgotPasswordPage = () => {
  const routerHistory = useHistory();
  const [pageState, setPageState] = useState(states.FORGOT);
  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(-1);
  const [sentAddress, setSentAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const initialEmail = useMemo(() => {
    var _a;
    return ((_a = routerHistory.location.state) == null ? void 0 : _a.email) || "";
  }, [routerHistory]);
  const requestPasswordResetLink = useCallback((email, selectedEmailIdx, sentAddress2) => __async(void 0, null, function* () {
    var _a;
    const payload = {
      email,
      emailIndex: selectedEmailIdx
    };
    const emails2 = (_a = yield AuthAPI.forgotPassword(payload)) == null ? void 0 : _a.emails;
    if ((emails2 == null ? void 0 : emails2.length) > 1) {
      setEmails(emails2);
      setPageState(states.CHOOSE);
    } else {
      setSentAddress(sentAddress2);
      setPageState(states.SENT);
    }
  }), []);
  const formik = useFormik({
    initialValues: {
      email: initialEmail
    },
    validationSchema,
    onSubmit: (formData) => __async(void 0, null, function* () {
      setIsLoading(true);
      setErrorMessage("");
      try {
        yield requestPasswordResetLink(formData.email, selectedEmail, emails[selectedEmail] || formData.email);
      } catch (error) {
        setErrorMessage(`The password reset link could not be sent. ${nestErrorMessage(error)}`);
      }
      setIsLoading(false);
    })
  });
  const { title, description } = {
    [states.FORGOT]: {
      title: "Password recovery",
      description: "Enter your work email to receive a password reset link"
    },
    [states.CHOOSE]: {
      title: "Select email address",
      description: "We found multiple email addresses. Where would you like to receive the password reset link?"
    },
    [states.SENT]: {
      title: "Password recovery",
      description: `If you have an account in Zelt, we will send the reset instructions to ${sentAddress} shortly`
    }
  }[pageState];
  const returnToLogin = useCallback(() => {
    routerHistory.replace(LOGIN_ROUTE);
  }, [routerHistory]);
  return /* @__PURE__ */ jsxs(AuthLayout, { title, description, submit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
      pageState === states.FORGOT && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g30 }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: "Work email",
            name: "email",
            value: formik.values.email,
            onChange: formik.handleChange,
            error: formik.touched.email && !!formik.errors.email,
            helperText: formik.touched.email && formik.errors.email,
            type: "email",
            autoFocus: true,
            clearText: () => formik.setFieldValue("email", "")
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: { width: { xs: "50%", sm: "100%", md: "100%", lg: "100%" } }, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Send the password reset link",
            type: "submit",
            loading: isLoading,
            fullWidth: true,
            sizeVariant: "medium",
            colorVariant: "primary"
          }
        ) })
      ] }),
      pageState === states.SENT && /* @__PURE__ */ jsx(Box, { sx: { width: { xs: "50%", sm: "100%", md: "100%", lg: "100%" } }, children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          type: "button",
          sizeVariant: "medium",
          colorVariant: "primary",
          fullWidth: true,
          style: { marginTop: spacing.m10 },
          onClick: () => returnToLogin(),
          children: "OK"
        }
      ) }),
      pageState === states.CHOOSE && emails.length > 0 && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g30 }, children: [
        /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
          RadioGroup,
          {
            "aria-labelledby": "demo-radio-buttons-group-label",
            name: "radio-buttons-group",
            onChange: (event) => {
              setSelectedEmail(parseInt(event.target.value));
            },
            // the order in which emails are displayed is important - the indexes are directly
            // tied to the order that the backend uses.
            children: emails.map((email, idx) => /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "start",
                value: idx,
                checked: selectedEmail === idx,
                control: /* @__PURE__ */ jsx(StyledRadio, {}),
                label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: email }),
                sx: {
                  justifyContent: "space-between",
                  borderBottom: borders.middle,
                  py: spacing.p10
                }
              },
              email
            ))
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: { width: { xs: "50%", sm: "100%", md: "100%", lg: "100%" } }, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Send the password reset link",
            type: "submit",
            fullWidth: true,
            loading: isLoading,
            disabled: selectedEmail < 0,
            sizeVariant: "medium",
            colorVariant: "primary"
          }
        ) })
      ] })
    ] }),
    errorMessage && !isLoading && /* @__PURE__ */ jsx(Alert, { severity: "error", sx: { my: 2 }, children: errorMessage })
  ] });
};
