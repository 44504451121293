"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { GooglePlacesWrapper } from "./google-maps-autocomplete.component";
import { SiteAPI } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
const SiteFormSchema = (polyglot) => Yup.object().shape({
  name: Yup.string().required(polyglot.t("validation.requiredField")),
  address: Yup.string().nullable().notRequired(),
  longitude: Yup.number().nullable().typeError(polyglot.t("validation.enterValid")).notRequired(),
  latitude: Yup.number().nullable().typeError(polyglot.t("validation.enterValid")).notRequired()
});
export const SiteFormDrawer = ({ isOpen, setIsOpen, selectedSite, refreshSites, onClose }) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(SiteFormDrawerContent, { selectedSite, refreshSites, onClose }) });
const SiteFormDrawerContent = ({
  selectedSite,
  refreshSites,
  onClose
}) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputManually, setInputManually] = useState(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const formik = useFormik({
    initialValues: {
      name: (_a = selectedSite == null ? void 0 : selectedSite.name) != null ? _a : "",
      address: (_b = selectedSite == null ? void 0 : selectedSite.address) != null ? _b : "",
      longitude: (_d = (_c = selectedSite == null ? void 0 : selectedSite.coordinates) == null ? void 0 : _c.longitude) != null ? _d : null,
      latitude: (_f = (_e = selectedSite == null ? void 0 : selectedSite.coordinates) == null ? void 0 : _e.latitude) != null ? _f : null
    },
    validationSchema: SiteFormSchema(polyglot),
    onSubmit: (values2) => __async(void 0, null, function* () {
      var _a2, _b2;
      setIsSubmitting(true);
      const action = selectedSite && selectedSite.id ? "update" : "add";
      try {
        if (selectedSite && selectedSite.id) {
          yield SiteAPI.updateSite(selectedSite.id, {
            name: values2.name,
            address: (_a2 = values2.address) != null ? _a2 : null,
            latitude: values2.latitude ? Number(values2.latitude) : null,
            longitude: values2.longitude ? Number(values2.longitude) : null
          });
        } else {
          yield SiteAPI.saveNewSite({
            name: values2.name,
            address: (_b2 = values2.address) != null ? _b2 : null,
            latitude: values2.latitude ? Number(values2.latitude) : null,
            longitude: values2.longitude ? Number(values2.longitude) : null
          });
        }
        showMessage(polyglot.t(`SiteFormDrawer.successMessages.${action}`), "success");
        yield refreshSites();
        onClose();
      } catch (error) {
        showMessage(
          polyglot.t(`SiteFormDrawer.errorMessages.${action}`, { nestError: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setIsSubmitting(false);
      }
    })
  });
  const { handleChange, setFieldValue, values, touched, errors } = formik;
  const renderAddressField = () => {
    var _a2;
    return /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "address",
        label: polyglot.t("SiteFormDrawer.address"),
        value: values.address,
        type: "text",
        onChange: handleChange,
        error: touched.address && !!errors.address,
        helperText: (_a2 = touched.address && errors.address) != null ? _a2 : " ",
        clearText: () => setFieldValue("address", "")
      }
    );
  };
  const renderCoordinatesFields = () => {
    var _a2, _b2;
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "longitude",
          label: polyglot.t("SiteFormDrawer.longitude"),
          value: values.longitude,
          onChange: handleChange,
          error: touched.longitude && !!errors.longitude,
          helperText: (_a2 = touched.longitude && errors.longitude) != null ? _a2 : " "
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "latitude",
          label: polyglot.t("SiteFormDrawer.latitude"),
          value: values.latitude,
          onChange: handleChange,
          error: touched.latitude && !!errors.latitude,
          helperText: (_b2 = touched.latitude && errors.latitude) != null ? _b2 : " "
        }
      )
    ] });
  };
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: selectedSite ? polyglot.t("SiteFormDrawer.edit") : polyglot.t("SiteFormDrawer.new") }) }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("SiteFormDrawer.siteName"),
        value: values.name,
        type: "text",
        onChange: (e) => setFieldValue("name", e.currentTarget.value),
        error: touched.name && !!errors.name,
        helperText: (_g = touched.name && errors.name) != null ? _g : " ",
        clearText: () => setFieldValue("name", "")
      }
    ),
    !inputManually && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        GooglePlacesWrapper,
        {
          label: polyglot.t("SiteFormDrawer.address"),
          value: values.address,
          onChange: (address, latitude, longitude) => {
            setFieldValue("longitude", longitude != null ? longitude : null);
            setFieldValue("latitude", latitude != null ? latitude : null);
            setFieldValue("address", address);
          },
          fullWidth: true
        }
      ),
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "link", colorVariant: "text", onClick: () => setInputManually(true), children: polyglot.t("SiteFormDrawer.enterLocation") })
    ] }),
    inputManually && /* @__PURE__ */ jsxs(Fragment, { children: [
      renderAddressField(),
      renderCoordinatesFields(),
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "link", colorVariant: "text", onClick: () => setInputManually(false), children: polyglot.t("SiteFormDrawer.searchMap") })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        fullWidth: true,
        type: "submit",
        loading: isSubmitting,
        colorVariant: "primary",
        sizeVariant: "medium",
        name: polyglot.t("General.save")
      }
    ) })
  ] }) });
};
