"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ScopesControl } from "@/component/widgets/Scopes";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { DateLabelComponent } from "@/v2/components/forms/date-label.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { isSameCountryCode } from "@/v2/infrastructure/country/country.util";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
const iconSize = { width: 14, height: 14 };
const identity = (row) => row;
const PAYROLL_ALL_SCOPE = ["payroll:all"];
export const useTaxRemoteTable = ({
  onEditRowClick,
  payroll,
  userId,
  countryCode
}) => {
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const filteredEntries = useMemo(() => {
    if (!countryCode) return payroll == null ? void 0 : payroll.entries;
    return payroll == null ? void 0 : payroll.entries.filter((e) => e.countryCode === countryCode);
  }, [countryCode, payroll == null ? void 0 : payroll.entries]);
  const generateTableColumnsFromEntries = useCallback(
    (entries) => {
      const columns = /* @__PURE__ */ new Map();
      for (const entry of entries) {
        if (!entry.remotePayrollValues) continue;
        if (countryCode && !isSameCountryCode(entry.countryCode, countryCode)) continue;
        for (const key in entry.remotePayrollValues) {
          if (columns.has(key)) continue;
          if (!/string|number|boolean/.test(typeof entry.remotePayrollValues[key])) continue;
          columns.set(key, {
            header: () => key,
            accessorFn: identity,
            id: key,
            enableSorting: false,
            cell: ({ row: { original } }) => {
              var _a, _b, _c, _d;
              return /* @__PURE__ */ jsx(
                Typography,
                {
                  title: (_b = (_a = original.remotePayrollValues) == null ? void 0 : _a[key]) == null ? void 0 : _b.toString(),
                  sx: __spreadProps(__spreadValues({}, themeFonts.caption), { whiteSpace: "nowrap" }),
                  children: (_d = (_c = original.remotePayrollValues) == null ? void 0 : _c[key]) == null ? void 0 : _d.toString()
                }
              );
            }
          });
        }
      }
      return columns.values();
    },
    [countryCode]
  );
  const columnData = useMemo(
    () => [
      {
        header: () => "Payroll ID",
        accessorFn: identity,
        id: "payroll-id",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.payrollId })
      },
      ...filteredEntries ? generateTableColumnsFromEntries(filteredEntries) : [],
      {
        header: () => "Last edited by",
        accessorFn: identity,
        id: "last-edit-user",
        enableSorting: false,
        cell: ({ row: { original } }) => original.updatedBy ? /* @__PURE__ */ jsx(UserAvatar, { userId: original.updatedBy, size: "xxsmall" }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "Last edited on",
        accessorFn: identity,
        id: "last-edit-date",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(DateLabelComponent, { date: original.updatedAt })
      },
      {
        header: () => "Reason",
        accessorFn: identity,
        id: "change-reason",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(Typography, { title: (_a = original.changeReason) != null ? _a : "", sx: __spreadProps(__spreadValues({}, themeFonts.caption), { whiteSpace: "nowrap" }), children: original.changeReason });
        }
      },
      {
        header: () => "Effective date",
        accessorFn: identity,
        id: "effective-date",
        enableSorting: false,
        cell: ({
          row: {
            original: { effectiveDate }
          }
        }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: 0.5 }, children: effectiveDate ? /* @__PURE__ */ jsx(DateLabelComponent, { date: effectiveDate }) : "-" })
      },
      {
        header: () => "",
        accessorFn: identity,
        id: "action",
        enableSorting: false,
        size: 100,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(ScopesControl, { scopes: PAYROLL_ALL_SCOPE, context: scopesContext, children: /* @__PURE__ */ jsx("div", { style: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            title: "edit",
            onClick: () => {
              onEditRowClick(original.record);
            },
            sx: tableIconButtonSx,
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) }) })
      }
    ],
    [filteredEntries, generateTableColumnsFromEntries, onEditRowClick, scopesContext]
  );
  const tableRowData = useMemo(
    () => (filteredEntries != null ? filteredEntries : []).reduce((result, entry) => {
      const { remotePayrollValues, changeReason, effectiveDate, payrollId, updatedAt, updatedBy } = entry;
      if (remotePayrollValues) {
        result.push({
          record: entry,
          remotePayrollValues,
          changeReason,
          effectiveDate,
          payrollId,
          updatedAt,
          updatedBy
        });
      }
      return result;
    }, []),
    [filteredEntries]
  );
  if (!payroll) return [void 0, void 0];
  return [tableRowData, columnData];
};
