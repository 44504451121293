"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { keyBy } from "lodash";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { CountrySelect } from "@/v2/components/country-select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UserCustomField } from "@/v2/feature/user/components/user-custom-field.component";
import { useUserFieldsContext } from "@/v2/feature/user/context/user-fields.context";
import {
  UserAddressAPI,
  UserBankAccountEndpoints
} from "@/v2/feature/user/features/user-forms/user-address/user-address.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { dateFieldTest } from "@/v2/infrastructure/date/date-format.util";
import { spacing } from "@/v2/styles/spacing.styles";
import { todaysDateShortISOString } from "@/v2/util/date-format.util";
const UserAddressSchema = (defaultFieldsMap, polyglot) => Yup.object().shape({
  effectiveDate: defaultFieldsMap["address.effectiveDate"] && defaultFieldsMap["address.effectiveDate"].isRequired ? Yup.string().test(dateFieldTest).required(
    polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.effectiveDate") })
  ) : Yup.string().notRequired(),
  addressLine1: defaultFieldsMap["address.addressLine1"] && defaultFieldsMap["address.addressLine1"].isRequired ? Yup.string().max(
    30,
    polyglot.t("AddressCard.schema.maxLength", {
      field: polyglot.t("AddressCard.addressLine1"),
      maxLength: 30
    })
  ).nullable().required(polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.addressLine1") })) : Yup.string().notRequired(),
  addressLine2: defaultFieldsMap["address.addressLine2"] && defaultFieldsMap["address.addressLine2"].isRequired ? Yup.string().max(
    30,
    polyglot.t("AddressCard.schema.maxLength", {
      field: polyglot.t("AddressCard.addressLine2"),
      maxLength: 30
    })
  ).nullable().required() : Yup.string().max(
    30,
    polyglot.t("AddressCard.schema.maxLength", {
      field: polyglot.t("AddressCard.addressLine2"),
      maxLength: 30
    })
  ).nullable().notRequired(),
  postcode: defaultFieldsMap["address.postcode"] && defaultFieldsMap["address.postcode"].isRequired ? Yup.string().max(
    20,
    polyglot.t("AddressCard.schema.maxLength", { field: polyglot.t("AddressCard.postcode"), maxLength: 20 })
  ).nullable().required(polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.postcode") })) : Yup.string().notRequired(),
  city: defaultFieldsMap["address.city"] && defaultFieldsMap["address.city"].isRequired ? Yup.string().max(
    30,
    polyglot.t("AddressCard.schema.maxLength", { field: polyglot.t("AddressCard.city"), maxLength: 30 })
  ).nullable().required(polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.city") })) : Yup.string().notRequired(),
  country: defaultFieldsMap["address.country"] && defaultFieldsMap["address.country"].isRequired ? Yup.string().max(
    50,
    polyglot.t("AddressCard.schema.maxLength", { field: polyglot.t("AddressCard.country"), maxLength: 50 })
  ).nullable().required(polyglot.t("AddressCard.schema.requiredField", { field: polyglot.t("AddressCard.country") })) : Yup.string().notRequired()
});
export const UserAddressSection = ({ onNext, sx, userId }) => {
  var _a;
  const { data: addressInfo } = useApiClient(UserBankAccountEndpoints.findCurrentByUserId(userId), {
    suspense: false
  });
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const { getCachedDefaultFieldsByFormName, getCachedCustomFieldsByFormName } = useUserFieldsContext();
  const defaultFields = getCachedDefaultFieldsByFormName(CustomProfileFormType.Address);
  const customFields = getCachedCustomFieldsByFormName(CustomProfileFormType.Address);
  const defaultFieldsMap = useMemo(() => {
    return keyBy(defaultFields, "fieldStub");
  }, [defaultFields]);
  const customFieldIds = useMemo(() => new Set((customFields != null ? customFields : []).map((field) => field.fieldId)), [customFields]);
  const validationSchema = useMemo(() => UserAddressSchema(defaultFieldsMap, polyglot), [polyglot, defaultFieldsMap]);
  const initialValues = useMemo(() => {
    var _a2, _b, _c, _d, _e, _f, _g;
    const values = {};
    if (defaultFieldsMap["address.addressLine1"]) values.addressLine1 = (_a2 = addressInfo == null ? void 0 : addressInfo.addressLine1) != null ? _a2 : "";
    if (defaultFieldsMap["address.city"]) values.city = (_b = addressInfo == null ? void 0 : addressInfo.city) != null ? _b : "";
    if (defaultFieldsMap["address.postcode"]) values.postcode = (_c = addressInfo == null ? void 0 : addressInfo.postcode) != null ? _c : "";
    if (defaultFieldsMap["address.country"]) values.country = (_d = addressInfo == null ? void 0 : addressInfo.country) != null ? _d : "United Kingdom";
    if (defaultFieldsMap["address.effectiveDate"])
      values.effectiveDate = (_e = addressInfo == null ? void 0 : addressInfo.effectiveDate) != null ? _e : todaysDateShortISOString();
    values.customUpdates = (_g = (_f = addressInfo == null ? void 0 : addressInfo.customUpdates) == null ? void 0 : _f.filter((update) => customFieldIds.has(update.fieldId))) != null ? _g : [];
    return values;
  }, [defaultFieldsMap, addressInfo, customFieldIds]);
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        if ("userAddressId" in values) {
          yield UserAddressAPI.update(userId, values);
        } else {
          yield UserAddressAPI.create(userId, values);
        }
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.addressNotSaved", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("AddressForm.homeAddress") }),
    defaultFieldsMap["address.addressLine1"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "addressLine1",
        label: polyglot.t("AddressForm.addressLine1"),
        value: formik.values.addressLine1,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("addressLine1", ""),
        helperText: hasSubmitted && formik.errors.addressLine1,
        error: hasSubmitted && !!formik.errors.addressLine1,
        maxLength: 30,
        autoFocus: true
      }
    ),
    defaultFieldsMap["address.addressLine2"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "addressLine2",
        label: polyglot.t("AddressForm.addressLine2"),
        value: formik.values.addressLine2,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("addressLine2", ""),
        helperText: hasSubmitted && formik.errors.addressLine2,
        error: hasSubmitted && !!formik.errors.addressLine2,
        maxLength: 30
      }
    ),
    defaultFieldsMap["address.city"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "city",
        label: polyglot.t("AddressForm.city"),
        value: formik.values.city,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("city", ""),
        helperText: hasSubmitted && formik.errors.city,
        error: hasSubmitted && !!formik.errors.city,
        maxLength: 30
      }
    ),
    defaultFieldsMap["address.postcode"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "postcode",
        label: polyglot.t("AddressForm.postCode"),
        value: formik.values.postcode,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("postcode", ""),
        helperText: hasSubmitted && formik.errors.postcode,
        error: hasSubmitted && !!formik.errors.postcode,
        maxLength: 20
      }
    ),
    defaultFieldsMap["address.country"] && /* @__PURE__ */ jsx(
      CountrySelect,
      {
        name: "country",
        label: polyglot.t("AddressForm.country"),
        value: formik.values.country,
        onChange: (value) => formik.setFieldValue("country", value),
        disabled: saving,
        error: hasSubmitted && !!formik.errors.country,
        helperText: hasSubmitted && formik.errors.country
      }
    ),
    /* @__PURE__ */ jsx(
      UserCustomField,
      {
        values: (_a = formik.values.customUpdates) != null ? _a : [],
        formFields: customFields != null ? customFields : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        sizeVariant: "large",
        colorVariant: "primary",
        loading: saving,
        fullWidth: true
      }
    )
  ] }) }) });
};
