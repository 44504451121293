"use strict";
import { useEffect } from "react";
export const useDynamicShadowEffect = (tableContainerRef, stickyColumns, enabled) => {
  useEffect(() => {
    if (!enabled) return;
    const handleScroll = () => {
      if (!tableContainerRef.current) return;
      const target = tableContainerRef.current;
      const leftShadow = target.scrollLeft > 0;
      const rightShadow = target.scrollWidth - target.scrollLeft - target.clientWidth > 0;
      stickyColumns.forEach((col) => {
        const columnCells = target.querySelectorAll(`[data-column-id="${col.id}"]`);
        columnCells.forEach((cell) => {
          if (!cell) return;
          if (col.isLastLeftPinned) {
            cell.classList.toggle("left-shadow", leftShadow);
          }
          if (col.isFirstRightPinned) {
            cell.classList.toggle("right-shadow", rightShadow);
          }
        });
      });
    };
    const container = tableContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleScroll);
      handleScroll();
    }
    return () => {
      container == null ? void 0 : container.removeEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleScroll);
    };
  }, [enabled, tableContainerRef, stickyColumns]);
};
