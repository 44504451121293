"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { Box, Dialog } from "@mui/material";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const NewDocumentPreview = ({ docData, onClose, isOpen, error, title }) => {
  const fileURLRef = useRef(null);
  const { polyglot } = usePolyglot();
  useEffect(() => {
    return () => {
      if (fileURLRef.current) {
        URL.revokeObjectURL(fileURLRef.current);
        fileURLRef.current = null;
      }
    };
  }, []);
  const renderPreview = () => {
    if (!docData) return null;
    const { uri, fileType } = docData;
    if (fileType === "application/pdf") {
      return /* @__PURE__ */ jsx("iframe", { src: uri, width: "100%", height: "600px", title: "PDF Preview", style: { border: "none" } });
    }
    if (fileType === "text/plain") {
      return /* @__PURE__ */ jsx("iframe", { src: uri, width: "100%", height: "600px", title: "Text Preview", style: { border: "none" } });
    }
    if (fileType && fileType.startsWith("image/")) {
      return /* @__PURE__ */ jsx("img", { src: uri, alt: "Document Preview", style: { maxWidth: "100%", maxHeight: "600px" } });
    }
    return /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("NewDocumentViewModal.errorMessages.unsupportedType") });
  };
  return /* @__PURE__ */ jsx(
    Dialog,
    {
      open: isOpen,
      onClose,
      maxWidth: "lg",
      fullWidth: true,
      sx: {
        boxSizing: "border-box",
        border: "none",
        outline: "none"
      },
      children: /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            position: "relative",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            gap: spacing.s3,
            boxSizing: "border-box",
            border: "none",
            outline: "none",
            padding: spacing.s2
          },
          children: [
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "title3", children: title }),
              /* @__PURE__ */ jsx(
                IconButton,
                {
                  sizeVariant: "small",
                  colorVariant: "secondary",
                  style: { position: "absolute", top: 8, right: 8, zIndex: 2 },
                  onClick: onClose,
                  children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey, stroke: themeColors.DarkGrey }))
                }
              )
            ] }),
            /* @__PURE__ */ jsx(
              Box,
              {
                sx: {
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  boxSizing: "border-box",
                  border: "none",
                  outline: "none"
                },
                children: error ? /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: error }) : renderPreview()
              }
            )
          ]
        }
      )
    }
  );
};
