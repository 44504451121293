"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { identity } from "fp-ts/lib/function";
import { UserBankAccountFormSchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import { ScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Hazard } from "@/images/side-bar-icons/Hazard.svg";
import { DateLabelComponent } from "@/v2/components/forms/date-label.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { sortCustomFields } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { useProfileFormUpdate } from "@/v2/feature/user/context/user-profile.context";
import { UserBankAccountAPI } from "@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api";
import {
  CardStructure,
  FieldStructure
} from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import {
  calculateSkeletonHeight,
  cardSx,
  definitionListSx,
  hazardSize
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { BankForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-bank-information-form.component";
import { buildColumnsForCustomFields } from "@/v2/feature/user/features/user-profile/details/user-profile.util";
import { UserAPI } from "@/v2/feature/user/user.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { hazardMissingField, tableIconButtonSx } from "@/v2/styles/icon-button.styles";
const SCOPE = "user.bankAccount";
const iconSize = { width: 14, height: 14 };
const skeletonHeight = (polyglot) => calculateSkeletonHeight(UserBankAccountFormSchema(polyglot));
export const UserBankInfoForm = ({
  userId,
  handleSubmit = () => {
  },
  missingFields
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [defaultName, setDefaultName] = useState("");
  const setFormCurrentlyEditing = useProfileFormUpdate();
  const [modalInitialValues, setModalInitialValues] = useState(null);
  const getUserBankAccounts = useCallback(() => __async(void 0, null, function* () {
    var _a, _b;
    if (!userId) return;
    setLoading(true);
    try {
      const [userBankAccounts, userBasic] = yield Promise.all([
        UserBankAccountAPI.findByUserId(userId),
        UserAPI.getUserBasicInfo(userId)
      ]);
      setDefaultName((_a = userBasic == null ? void 0 : userBasic.name) != null ? _a : "");
      setData(userBankAccounts);
      if (!((_b = userBankAccounts == null ? void 0 : userBankAccounts.entries) == null ? void 0 : _b.length)) setShowDetails(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showMessage(`${polyglot.t("UserBankInfoForm.errorMessages.load")}: ${error}`, "error");
    }
  }), [polyglot, showMessage, userId]);
  useEffect(() => {
    getUserBankAccounts();
  }, [getUserBankAccounts]);
  const editBankRow = useCallback((rowData) => {
    setModalInitialValues(rowData);
    setIsModalOpen(true);
  }, []);
  const addBankRow = () => {
    setModalInitialValues(null);
    setIsModalOpen(true);
  };
  const columnData = useMemo(
    () => [
      {
        header: () => polyglot.t("UserBankInfoForm.accountName"),
        accessorFn: identity,
        id: "accountName",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.accountName })
      },
      {
        header: () => polyglot.t("UserBankInfoForm.accountNumber"),
        accessorFn: identity,
        id: "accountNumber",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.accountNumber ? original.accountNumber : "" })
      },
      {
        header: () => polyglot.t("UserBankInfoForm.sortCode"),
        accessorFn: identity,
        id: "sortCode",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.sortCode ? original.sortCode : "" })
      },
      {
        header: () => polyglot.t("UserBankInfoForm.bank"),
        accessorFn: identity,
        id: "bankName",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.bankName ? original.bankName : "" })
      },
      {
        header: () => polyglot.t("UserBankInfoForm.country"),
        accessorFn: identity,
        id: "country",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.country ? original.country : "" })
      },
      {
        header: () => polyglot.t("UserBankInfoForm.currency"),
        accessorFn: identity,
        id: "currency",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original.currency) != null ? _a : "" });
        }
      },
      ...buildColumnsForCustomFields(data == null ? void 0 : data.entries, { size: 120 }),
      {
        header: () => polyglot.t("UserBankInfoForm.effectiveDate"),
        accessorFn: identity,
        id: "effectiveDate",
        enableSorting: false,
        cell: ({
          row: {
            original: { effectiveDate }
          }
        }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: 0.5 }, children: effectiveDate ? /* @__PURE__ */ jsx(DateLabelComponent, { date: effectiveDate }) : "-" })
      },
      {
        header: () => "",
        accessorFn: identity,
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { style: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            title: polyglot.t("UserBankInfoForm.edit"),
            onClick: () => {
              editBankRow(original);
            },
            sx: tableIconButtonSx,
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) }) })
      }
    ],
    [polyglot, data == null ? void 0 : data.entries, scopesContext, editBankRow]
  );
  const effectiveRecord = data == null ? void 0 : data.effectiveRecord;
  return !data ? /* @__PURE__ */ jsx(
    SkeletonLoader,
    {
      variant: "rectangular",
      height: skeletonHeight(polyglot),
      sx: [cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]
    }
  ) : /* @__PURE__ */ jsx(
    CardStructure,
    {
      cardTitle: polyglot.t("UserBankInfoForm.bankAccount"),
      cardScope: [SCOPE],
      showDetails,
      setShowDetails,
      userId,
      addAction: addBankRow,
      showMissingInfo: !effectiveRecord,
      cardFieldCustomUpdates: effectiveRecord == null ? void 0 : effectiveRecord.customUpdates,
      cardFieldStubs: [
        "bankAccount.accountName",
        "bankAccount.accountNumber",
        "bankAccount.bankName",
        "bankAccount.sortCode",
        "bankAccount.country",
        "bankAccount.currency"
      ],
      cardFieldDetails: effectiveRecord && /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("UserBankInfoForm.accountName"),
            fieldValue: effectiveRecord.accountName,
            fieldStub: "bankAccount.accountName"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("UserBankInfoForm.accountNumber"),
            fieldValue: effectiveRecord.accountNumber,
            fieldStub: "bankAccount.accountNumber"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("UserBankInfoForm.bankName"),
            fieldValue: effectiveRecord.bankName,
            fieldStub: "bankAccount.bankName"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("UserBankInfoForm.sCode"),
            fieldValue: effectiveRecord.sortCode,
            fieldStub: "bankAccount.sortCode"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("UserBankInfoForm.country"),
            fieldValue: effectiveRecord.country,
            fieldStub: "bankAccount.country"
          }
        ),
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("UserBankInfoForm.currency"),
            fieldValue: effectiveRecord.currency,
            fieldStub: "bankAccount.currency"
          }
        ),
        sortCustomFields(effectiveRecord.customUpdates).map(
          (f) => !f.field.isHidden && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: f.field.fieldName, fieldValue: f.value }, f.field.fieldId)
        )
      ] }),
      drawerDetails: /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(DrawerModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, children: /* @__PURE__ */ jsx(
        BankForm,
        {
          initialValues: modalInitialValues,
          userId,
          defaultName,
          getUserBankAccounts,
          rowModalMode: modalInitialValues ? "edit" : "add",
          handleSubmit,
          setFormCurrentlyEditing,
          setIsModalOpen,
          setLoading,
          loading,
          onClose: () => setIsModalOpen(false)
        }
      ) }) }),
      addButtonTitle: polyglot.t("UserBankInfoForm.new"),
      tableRowData: [...data.entries],
      tableColumn: columnData,
      missingInfoIcon: /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user:all"], context: scopesContext, children: (missingFields == null ? void 0 : missingFields.find((rec) => rec.cardId === CustomProfileFormType.BankAccount)) && /* @__PURE__ */ jsx(Box, { sx: hazardMissingField, children: /* @__PURE__ */ jsx(Hazard, __spreadValues({}, hazardSize)) }) }),
      editAction: effectiveRecord && (() => {
        editBankRow(effectiveRecord);
      })
    }
  );
};
