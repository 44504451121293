"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { groupBy } from "lodash";
import { ReactComponent as Plus } from "@/images/fields/Plus.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { EmptyStateJobLevel } from "@/v2/feature/job-level/components/empty-state-job-level.component";
import { LevelFormDrawer } from "@/v2/feature/job-level/components/level-form-drawer.component";
import { TrackFormDrawer } from "@/v2/feature/job-level/components/track-form-drawer.component";
import { JobLevelEndpoints } from "@/v2/feature/job-level/job-level.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { DARK_CONTRAST_COLOR, SECONDARY_CHART_COLOR, themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const JobLevelSettingsPage = () => {
  const { polyglot } = usePolyglot();
  const {
    data: jobLevels,
    isLoading: loadingJobLevels,
    mutate: refreshJobLevels
  } = useApiClient(JobLevelEndpoints.listJobLevel(), { suspense: false });
  const [isTrackOpen, setIsTrackOpen] = useState(false);
  const [isLevelOpen, setIsLevelOpen] = useState(false);
  const [trackName, setTrackName] = useState();
  const [selectedLevel, setSelectedLevel] = useState();
  const jobsLookup = useMemo(() => {
    return groupBy(jobLevels, "trackName");
  }, [jobLevels]);
  const handleLevelSelect = useCallback((track, level) => {
    setTrackName(track);
    setSelectedLevel(level);
    setIsLevelOpen(true);
  }, []);
  const handleAddLevelClick = useCallback((track) => {
    setTrackName(track);
    setIsLevelOpen(true);
  }, []);
  const closeLevelForm = useCallback(() => {
    setTrackName(void 0);
    setIsLevelOpen(false);
    setTimeout(() => setSelectedLevel(void 0), 500);
  }, []);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("JobLevelSettingsPage.jobLevels") }),
        showAction: Boolean(jobLevels && (jobLevels == null ? void 0 : jobLevels.length) > 0),
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsTrackOpen(true), children: polyglot.t("JobLevelSettingsPage.newTrack") }),
        showBack: true
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: loadingJobLevels, children: [
      jobLevels && (jobLevels == null ? void 0 : jobLevels.length) > 0 && /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            alignItems: "flex-start",
            gap: spacing.g20,
            overflowX: "auto",
            width: "100%",
            height: "100%",
            flexWrap: "nowrap",
            "&::-webkit-scrollbar": {
              display: "none"
            },
            "-ms-overflow-style": "none",
            scrollbarWidth: "none"
          },
          children: Object.keys(jobsLookup).map((trackName2, idx) => {
            return /* @__PURE__ */ jsx(
              TrackAndLevelComponent,
              {
                idx,
                trackName: trackName2,
                handleLevelSelect,
                handleAddLevelClick,
                jobsLookup,
                polyglot
              },
              trackName2
            );
          })
        }
      ),
      jobLevels && (jobLevels == null ? void 0 : jobLevels.length) === 0 && /* @__PURE__ */ jsx(EmptyStateJobLevel, { addNewTrack: () => setIsTrackOpen(true) }),
      /* @__PURE__ */ jsx(
        TrackFormDrawer,
        {
          isOpen: isTrackOpen,
          setIsOpen: setIsTrackOpen,
          refresh: refreshJobLevels,
          onClose: () => {
            setIsTrackOpen(false);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        LevelFormDrawer,
        {
          isOpen: isLevelOpen,
          setIsOpen: setIsLevelOpen,
          refresh: refreshJobLevels,
          trackName: trackName || "",
          selectedLevel,
          onClose: closeLevelForm
        }
      )
    ] })
  ] });
};
const TrackAndLevelComponent = ({
  idx,
  trackName,
  handleAddLevelClick,
  handleLevelSelect,
  jobsLookup,
  polyglot
}) => {
  function firstLetters(str) {
    return str.split(" ").map((word) => word[0]).join("");
  }
  return /* @__PURE__ */ jsx(Box, { sx: { width: "18rem", flexShrink: 0 }, children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: __spreadValues({
          paddingX: spacing.p15,
          paddingY: spacing.p10,
          boxSizing: "border-box",
          borderRadius: radius.br10,
          background: SECONDARY_CHART_COLOR[idx]
        }, spacing.mb20),
        children: [
          /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "title3",
              sx: {
                color: DARK_CONTRAST_COLOR.includes(SECONDARY_CHART_COLOR[idx]) ? themeColors.white : themeColors.DarkGrey
              },
              children: trackName
            }
          ),
          " "
        ]
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: [
      jobsLookup[trackName].map((level, jdx) => /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            flexDirection: "column",
            background: themeColors.Background,
            padding: spacing.p15,
            borderRadius: radius.br10,
            gap: spacing.g10,
            cursor: "pointer",
            "&:hover": {
              background: themeColors.GreyHover
            }
          },
          onClick: () => handleLevelSelect(trackName, level),
          children: [
            /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  alignItems: "center",
                  gap: spacing.g10,
                  justifyContent: "flex-start"
                },
                children: [
                  /* @__PURE__ */ jsx(
                    Box,
                    {
                      sx: {
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: radius.br25,
                        background: SECONDARY_CHART_COLOR[idx]
                      },
                      children: /* @__PURE__ */ jsxs(
                        Typography,
                        {
                          variant: "title4",
                          sx: {
                            color: DARK_CONTRAST_COLOR.includes(SECONDARY_CHART_COLOR[idx]) ? themeColors.white : themeColors.DarkGrey
                          },
                          children: [
                            firstLetters(level.trackName),
                            jdx + 1
                          ]
                        }
                      )
                    }
                  ),
                  /* @__PURE__ */ jsx(Typography, { variant: "title3", children: level.levelName })
                ]
              }
            ),
            level.levelDescription && /* @__PURE__ */ jsx(Typography, { variant: "caption", children: level.levelDescription })
          ]
        }
      )),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "dashed",
          type: "button",
          fullWidth: true,
          onClick: () => handleAddLevelClick(trackName),
          startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
          children: polyglot.t("JobLevelSettingsPage.level")
        }
      )
    ] })
  ] }) });
};
