"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { ReactComponent as Eye } from "@/images/side-bar-icons/Eye.svg";
import { ReactComponent as EyeSlash } from "@/images/side-bar-icons/EyeSlash.svg";
export const RevealableColumnHeader = ({ label, onVisibilityChange, visible }) => {
  return /* @__PURE__ */ jsxs("div", { style: { display: "inline-flex", height: "16px" }, children: [
    label,
    /* @__PURE__ */ jsx(
      "div",
      {
        style: { marginLeft: "4px" },
        onClick: (e) => {
          onVisibilityChange(!visible);
          e.stopPropagation();
        },
        children: visible ? /* @__PURE__ */ jsx(Eye, {}) : /* @__PURE__ */ jsx(EyeSlash, { style: { marginTop: "-2px" } })
      }
    )
  ] });
};
export const RevealableCell = ({
  mask = "*******",
  maskAlign = "left",
  revealOnHover,
  visible,
  children
}) => {
  const [hovered, setHovered] = useState(false);
  if (visible) {
    return /* @__PURE__ */ jsx("div", { style: { width: "100%" }, children });
  }
  return /* @__PURE__ */ jsx(
    "div",
    {
      style: { width: "100%", textAlign: maskAlign },
      onMouseEnter: () => setHovered(true),
      onMouseLeave: () => setHovered(false),
      children: revealOnHover && hovered ? children : mask
    }
  );
};
