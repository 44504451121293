"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { getGenderOptions } from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
export const GenderSelect = (_a) => {
  var _b = _a, { name, value, error, helperText, onChange } = _b, autoCompleteProps = __objRest(_b, ["name", "value", "error", "helperText", "onChange"]);
  const { polyglot } = usePolyglot();
  const genderOptions = useMemo(() => getGenderOptions(polyglot), [polyglot]);
  const selectedOption = useMemo(() => {
    var _a2;
    return (_a2 = genderOptions.find((option) => option.value === value)) != null ? _a2 : null;
  }, [value, genderOptions]);
  return /* @__PURE__ */ jsx(
    AutocompleteComponent,
    __spreadProps(__spreadValues({}, autoCompleteProps), {
      name,
      label: polyglot.t("GenderSelect.gender"),
      options: genderOptions,
      value: selectedOption,
      compareValue: value != null ? value : "",
      onChange: (_, x) => {
        var _a2;
        onChange((_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
      },
      error,
      helperText
    })
  );
};
