"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { ReactComponent as Zelt } from "@/images/zelt-text.svg";
export const PayrollLogoZelt = (props) => {
  return /* @__PURE__ */ jsx(
    Zelt,
    {
      style: __spreadValues({
        borderRadius: "50%",
        backgroundColor: "#333",
        fill: "white",
        height: "30px",
        width: "30px",
        padding: "5px",
        flexShrink: 0
      }, props)
    }
  );
};
