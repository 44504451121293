"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { TableSearch } from "@v2/components/table/table-search.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { AbsencePolicyAllowanceType, CalendarYear, TimeUnit } from "@v2/feature/absence/absence.interface";
import { convertMinutesToClockHours, isUnlimitedPolicy } from "@v2/feature/absence/absence.util";
import {
  getAllowanceFromBreakdown,
  getConvertedValue,
  getExpiredCarryOver
} from "@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { generatePath, useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { nestErrorMessage } from "@/lib/errors";
import { USER_ABSENCE_ROUTE } from "@/lib/routes";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { BasicServerTable, DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { UserAPI } from "@/v2/feature/user/user.api";
export const AbsenceBalancesTable = ({
  absencePolicies,
  view,
  stickyHeader
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const { polyglot } = usePolyglot();
  const [usersBalances, setUsersBalances] = useState([]);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useContext(GlobalContext);
  const [yearValue, setYearValue] = useState(
    (_d = (_c = (_b = (_a = state.user.features) == null ? void 0 : _a.timeAway) == null ? void 0 : _b.balancesTable) == null ? void 0 : _c.yearFilter) != null ? _d : CalendarYear.Current
  );
  const [overrideTimeUnit, setOverrideTimeUnit] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const routerHistory = useHistory();
  const { getCachedUserById } = useCachedUsers();
  const [searchInput, setSearchInput] = useState("");
  const debouncedSearch = useDebouncedCallback((value) => {
    try {
      setSearchInput(!value ? "" : value);
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (error) {
      console.error(error);
      showMessage(polyglot.t("AbsenceBalancesTable.errorMessages.handleSearch"), "error");
    }
  }, 300);
  const selectedPolicy = useMemo(() => {
    var _a2, _b2, _c2;
    if (!absencePolicies || absencePolicies.length === 0) return null;
    const defaultPolicy = absencePolicies[0];
    const savedPolicyId = Number((_c2 = (_b2 = (_a2 = state.user.features) == null ? void 0 : _a2.timeAway) == null ? void 0 : _b2.balancesTable) == null ? void 0 : _c2.policyFilter);
    const newPolicyId = isNaN(savedPolicyId) ? defaultPolicy.id : savedPolicyId;
    const newPolicy = absencePolicies.find((p) => p.id === newPolicyId);
    setOverrideTimeUnit(null);
    return newPolicy != null ? newPolicy : null;
  }, [absencePolicies, (_g = (_f = (_e = state.user.features) == null ? void 0 : _e.timeAway) == null ? void 0 : _f.balancesTable) == null ? void 0 : _g.policyFilter]);
  const timeUnitValue = useMemo(() => {
    if (!selectedPolicy) return TimeUnit.Hours;
    const selectedPolicyUnit = selectedPolicy.allowanceType === AbsencePolicyAllowanceType.LimitedHourly || selectedPolicy.allowanceType === AbsencePolicyAllowanceType.UnlimitedHourly ? TimeUnit.Hours : TimeUnit.Days;
    return overrideTimeUnit != null ? overrideTimeUnit : selectedPolicyUnit;
  }, [selectedPolicy, overrideTimeUnit]);
  useEffect(() => {
    (function() {
      return __async(this, null, function* () {
        if (!selectedPolicy) {
          setLoading(false);
          return;
        }
        try {
          setLoading(true);
          const usersBalancesResponse = view === "team" ? yield AbsenceAPI.fetchTeamUsersAbsenceBalanceBreakdown(
            selectedPolicy.id,
            yearValue,
            pagination.pageIndex,
            pagination.pageSize,
            searchInput ? searchInput : void 0
          ) : yield AbsenceAPI.fetchCompanyUsersAbsenceBalanceBreakdown(
            selectedPolicy.id,
            yearValue,
            pagination.pageIndex,
            pagination.pageSize,
            searchInput ? searchInput : void 0
          );
          setUsersBalances(usersBalancesResponse.items);
          setTotalPages(usersBalancesResponse.totalPages);
          setTotalItems(usersBalancesResponse.totalItems);
        } catch (error) {
          showMessage(
            polyglot.t("AbsenceBalancesTable.errorMessages.fetch", { errorMessage: nestErrorMessage(error) }),
            "error"
          );
        } finally {
          setLoading(false);
        }
      });
    })();
  }, [polyglot, showMessage, view, selectedPolicy, pagination.pageIndex, pagination.pageSize, searchInput, yearValue]);
  const rowData = useMemo(() => {
    return usersBalances.map((record) => {
      var _a2, _b2, _c2, _d2, _e2;
      const user = getCachedUserById(record.userId);
      return __spreadProps(__spreadValues({}, record), {
        displayName: (_a2 = user == null ? void 0 : user.displayName) != null ? _a2 : "Unknown",
        userStatus: (_b2 = user == null ? void 0 : user.userEvent) == null ? void 0 : _b2.status,
        userStartDate: user == null ? void 0 : user.startDate,
        site: (_e2 = (_d2 = (_c2 = user == null ? void 0 : user.role) == null ? void 0 : _c2.site) == null ? void 0 : _d2.name) != null ? _e2 : null
      });
    });
  }, [usersBalances, getCachedUserById]);
  const tableColumns = useMemo(() => {
    var _a2;
    if (!selectedPolicy) return [];
    const allowanceColumn = {
      header: () => polyglot.t("AbsenceBalancesTable.allowance"),
      accessorFn: (row) => row,
      id: "allowance",
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const breakdown = original[selectedPolicy.id];
        if (!selectedPolicy || !breakdown) return /* @__PURE__ */ jsx(EmptyCell, {});
        const value = breakdown.userAllowance;
        if (value === null || value === void 0) return /* @__PURE__ */ jsx(EmptyCell, {});
        const allowance = getAllowanceFromBreakdown(selectedPolicy, breakdown, timeUnitValue, polyglot);
        if (allowance === null) return /* @__PURE__ */ jsx(EmptyCell, {});
        return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: allowance });
      }
    };
    const publicHolidayColumn = {
      header: () => polyglot.t("AbsenceBalancesTable.publicHoliday"),
      accessorFn: (row) => row,
      id: "publicHoliday",
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const breakdown = original[selectedPolicy.id];
        if (!selectedPolicy || !breakdown) return /* @__PURE__ */ jsx(EmptyCell, {});
        const value = breakdown.proRatedAbsenceAllowance.proRatedPublicHolidayAllowance;
        if (value === null) return /* @__PURE__ */ jsx(EmptyCell, {});
        const allowance = getConvertedValue(value, breakdown, timeUnitValue, polyglot);
        return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: allowance });
      }
    };
    const oneOffAdjustmentColumn = {
      header: () => polyglot.t("AbsenceBalancesTable.oneOffAdjustment"),
      accessorFn: (row) => row,
      id: "oneOffAdjustment",
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (!selectedPolicy) return /* @__PURE__ */ jsx(EmptyCell, {});
        const breakdown = original[selectedPolicy.id];
        if (!breakdown) return /* @__PURE__ */ jsx(EmptyCell, {});
        const value = breakdown.oneOffAdjustment;
        if (value === null || value === void 0) return /* @__PURE__ */ jsx(EmptyCell, {});
        const oneOffAdjustment = getConvertedValue(value, breakdown, timeUnitValue, polyglot);
        return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: oneOffAdjustment });
      }
    };
    const carryOverColumn = {
      header: () => polyglot.t("AbsenceBalancesTable.carryOver"),
      accessorFn: (row) => row,
      id: "ca",
      enableSorting: false,
      cell: ({ row: { original } }) => {
        var _a3;
        if (!selectedPolicy || !selectedPolicy.carryOverEnabled) return /* @__PURE__ */ jsx(EmptyCell, {});
        const breakdown = original[selectedPolicy.id];
        if (!breakdown) return /* @__PURE__ */ jsx(EmptyCell, {});
        const value = (_a3 = breakdown.carryOver) == null ? void 0 : _a3.allowedUnitsThisCycle;
        if (value === null || value === void 0) return /* @__PURE__ */ jsx(EmptyCell, {});
        const expiredCarryOver = breakdown.carryOver.isCarryOverExpired && breakdown.carryOver.expiredCarryOver && breakdown.carryOver.expiredCarryOver > 0 ? `(Expired: ${getExpiredCarryOver(selectedPolicy, breakdown, polyglot)})` : "";
        const carryOverData = getConvertedValue(
          breakdown.carryOver.allowedUnitsThisCycle,
          breakdown,
          timeUnitValue,
          polyglot
        );
        return /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          carryOverData,
          " ",
          expiredCarryOver
        ] });
      }
    };
    const toilColumn = selectedPolicy.toilTypeId ? {
      header: () => polyglot.t("AbsenceBalancesTable.toil"),
      accessorFn: (row) => row,
      id: "toil",
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const breakdown = original[selectedPolicy.id];
        if (!(selectedPolicy == null ? void 0 : selectedPolicy.toilTypeId) || !breakdown || !breakdown.toil.toilValueInMinutes) return /* @__PURE__ */ jsx(EmptyCell, {});
        const toilValue = getConvertedValue(breakdown.toil.toilValueInMinutes, breakdown, timeUnitValue, polyglot);
        return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: toilValue });
      }
    } : null;
    const balanceColumn = {
      header: () => polyglot.t("AbsenceBalancesTable.balance"),
      accessorFn: (row) => row,
      id: "balance",
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const breakdown = original[selectedPolicy.id];
        if (!selectedPolicy || !breakdown) return /* @__PURE__ */ jsx(EmptyCell, {});
        const getBalanceInDays = (days, polyglot2) => {
          return days !== void 0 && days !== null ? polyglot2.t("General.noOfDays", { smart_count: days }) : null;
        };
        const balanceInHours = convertMinutesToClockHours(breakdown.currentBalance, polyglot);
        const balanceInDays = getBalanceInDays(breakdown.currentBalanceInDays, polyglot);
        const balance = breakdown.isOnRegularSchedule && timeUnitValue === TimeUnit.Days ? balanceInDays : balanceInHours;
        return balance ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: balance }) : /* @__PURE__ */ jsx(EmptyCell, {});
      }
    };
    return [
      {
        header: () => polyglot.t("AbsenceBalancesTable.name"),
        accessorFn: (row) => row,
        id: "displayName",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          const user = getCachedUserById(original.userId);
          if (!user) return /* @__PURE__ */ jsx(EmptyCell, {});
          return /* @__PURE__ */ jsx(UserCell, { userId: original.userId });
        }
      },
      ...isUnlimitedPolicy(selectedPolicy) ? [] : [allowanceColumn, oneOffAdjustmentColumn, carryOverColumn, toilColumn].filter(Boolean),
      {
        header: () => polyglot.t("AbsenceBalancesTable.taken"),
        accessorFn: (row) => row,
        id: `units-taken-${selectedPolicy.id}`,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a3;
          const breakdown = original[selectedPolicy.id];
          if (!selectedPolicy || !breakdown) return /* @__PURE__ */ jsx(EmptyCell, {});
          const value = breakdown.unitsTaken.history;
          if (value === null || value === void 0) return /* @__PURE__ */ jsx(EmptyCell, {});
          const { publicHolidayAutobook } = selectedPolicy;
          const unitsTaken = publicHolidayAutobook ? getConvertedValue(
            value + ((_a3 = breakdown.unitsTaken.publicHolidaysHistory) != null ? _a3 : 0),
            // if PH "Autobooked" enabled -> "Used" should include publicHolidaysHistory
            breakdown,
            timeUnitValue,
            polyglot
          ) : getConvertedValue(value, breakdown, timeUnitValue, polyglot);
          return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: unitsTaken });
        }
      },
      {
        header: () => polyglot.t("AbsenceBalancesTable.booked"),
        accessorFn: (row) => row,
        id: `units-booked-${(_a2 = selectedPolicy == null ? void 0 : selectedPolicy.id) != null ? _a2 : ""}`,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a3;
          const breakdown = original[selectedPolicy.id];
          if (!selectedPolicy || !breakdown) return /* @__PURE__ */ jsx(EmptyCell, {});
          const value = breakdown.unitsTaken.upcoming;
          if (value === null || value === void 0) return /* @__PURE__ */ jsx(EmptyCell, {});
          const { publicHolidayAutobook } = selectedPolicy;
          const unitsBooked = publicHolidayAutobook ? getConvertedValue(
            value + ((_a3 = breakdown.unitsTaken.publicHolidaysUpcoming) != null ? _a3 : 0),
            // if PH "Autobooked" enabled -> "Booked" should include publicHolidaysUpcoming
            breakdown,
            timeUnitValue,
            polyglot
          ) : getConvertedValue(value, breakdown, timeUnitValue, polyglot);
          return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: unitsBooked });
        }
      },
      ...selectedPolicy.publicHolidayOnTop ? [publicHolidayColumn] : [],
      ...isUnlimitedPolicy(selectedPolicy) ? [] : [balanceColumn].filter(Boolean)
    ];
  }, [polyglot, getCachedUserById, selectedPolicy, timeUnitValue]);
  const handleRowClick = useCallback(
    (row) => {
      var _a2, _b2;
      routerHistory.push(generatePath(USER_ABSENCE_ROUTE, { userId: (_b2 = (_a2 = row.original) == null ? void 0 : _a2.userId) != null ? _b2 : "" }));
    },
    [routerHistory]
  );
  const exportBalancesDataCSV = useCallback(() => __async(void 0, null, function* () {
    if (!selectedPolicy || view !== "company") return;
    try {
      window.location.href = AbsenceAPI.exportCompanyUsersBalancesAsCSV(selectedPolicy.id, yearValue);
    } catch (error) {
      console.error(error);
      showMessage(polyglot.t("AbsenceBalancesTable.errorMessages.badRequest"), "error");
    }
  }), [yearValue, polyglot, view, selectedPolicy, showMessage]);
  const handlePolicyChange = useCallback(
    (newPolicyId) => __async(void 0, null, function* () {
      const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
        "timeAway",
        "balancesTable",
        "policyFilter",
        newPolicyId.toString()
      );
      dispatch({
        type: GlobalStateActions.UPDATE_USER,
        payload: updatedGlobalUser
      });
    }),
    [dispatch]
  );
  const handleYearChange = useCallback(
    (newYear) => __async(void 0, null, function* () {
      setYearValue(newYear);
      const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures("timeAway", "balancesTable", "yearFilter", newYear);
      dispatch({
        type: GlobalStateActions.UPDATE_USER,
        payload: updatedGlobalUser
      });
    }),
    [setYearValue, dispatch]
  );
  const handleTimeUnitChange = useCallback(
    (newTimeUnit) => __async(void 0, null, function* () {
      setOverrideTimeUnit(newTimeUnit);
    }),
    [setOverrideTimeUnit]
  );
  const absencePolicyOptions = useMemo(
    () => absencePolicies.map((p) => {
      var _a2;
      return {
        handler: () => __async(void 0, null, function* () {
          yield handlePolicyChange(p.id);
        }),
        label: (_a2 = p.fullName) != null ? _a2 : p.name,
        disables: false
      };
    }),
    [absencePolicies, handlePolicyChange]
  );
  const yearOptions = useMemo(
    () => Object.values(CalendarYear).map((year) => ({
      handler: () => __async(void 0, null, function* () {
        yield handleYearChange(year);
      }),
      label: polyglot.t(`PageFilters.${year}`),
      disabled: false
    })),
    [handleYearChange, polyglot]
  );
  const timeUnitOptions = useMemo(
    () => Object.values(TimeUnit).map((timeUnit) => ({
      handler: () => __async(void 0, null, function* () {
        yield handleTimeUnitChange(timeUnit);
      }),
      label: polyglot.t(`TimeUnitTypes.${timeUnit}`),
      disabled: false
    })),
    [handleTimeUnitChange, polyglot]
  );
  return /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          justifyContent: "space-between"
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "flex-start", gap: "5px", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                options: yearOptions,
                actionButtonDetails: {
                  type: "button",
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  title: polyglot.t(`PageFilters.${yearValue}`),
                  icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                  iconPosition: "end"
                }
              }
            ),
            /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                options: absencePolicyOptions,
                actionButtonDetails: {
                  type: "button",
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  title: (_i = (_h = selectedPolicy == null ? void 0 : selectedPolicy.fullName) != null ? _h : selectedPolicy == null ? void 0 : selectedPolicy.name) != null ? _i : "No policy",
                  icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                  iconPosition: "end"
                }
              }
            ),
            /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                options: timeUnitOptions,
                actionButtonDetails: {
                  type: "button",
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  title: polyglot.t(`TimeUnitTypes.${timeUnitValue}`),
                  icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                  iconPosition: "end"
                }
              }
            ),
            /* @__PURE__ */ jsx(
              TableSearch,
              {
                query: searchInput,
                handleChange: (e) => {
                  debouncedSearch.callback(e.target.value);
                }
              }
            )
          ] }),
          view === "company" && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(LoaderButton, { loading: false, onClick: exportBalancesDataCSV, sizeVariant: "small", colorVariant: "secondary", children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("General.export") })
          ] }) }) })
        ]
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
      BasicServerTable,
      {
        rowData: [...rowData],
        columnData: tableColumns,
        loading,
        rowClick: handleRowClick,
        pagination,
        setPagination,
        totalPages,
        totalItems,
        stickyHeader
      }
    ) })
  ] });
};
