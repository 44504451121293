"use strict";
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { AbsencePolicyTenureAllocation } from "@v2/feature/absence/absence.dto";
import { AbsencePolicyAllowanceType, HolidaysProratingRule } from "@v2/feature/absence/absence.interface";
import {
  COUNTRY_ISO_CODE_MAPPING,
  CustomCountryEnum,
  NoneCountryEnum
} from "@v2/infrastructure/country/country.interface";
import {
  translateAbsencePolicyTenureAllocation,
  translateAbsencePolicyTenureAllocationDescription
} from "@v2/infrastructure/i18n/translate.util";
import { COLOR_PICKER_COLORS } from "@v2/styles/colors.styles";
import { LocalDate } from "@v2/util/local-date";
import { CustomRuleOptions } from "@/v2/components/user-select-type/user-select.interface";
import { round2Digits } from "@/v2/util/number.util";
export const displayAbsenceDay = (dateString, showYear = false) => {
  var _a, _b;
  return (_b = (_a = new LocalDate(dateString)) == null ? void 0 : _a.getDate().toLocaleDateString(void 0, {
    day: "numeric",
    month: "short",
    year: showYear ? "numeric" : void 0
  })) != null ? _b : "N/A";
};
export const getPolicyAllowanceTypeLabel = (allowanceType, polyglot) => {
  switch (allowanceType) {
    case AbsencePolicyAllowanceType.LimitedHourly:
      return polyglot.t("AbsenceUtil.limitedHourly");
    case AbsencePolicyAllowanceType.Limited:
      return polyglot.t("AbsenceUtil.limitedDaily");
    case AbsencePolicyAllowanceType.Unlimited:
      return polyglot.t("AbsenceUtil.unlimitedDaily");
    case AbsencePolicyAllowanceType.UnlimitedHourly:
      return polyglot.t("AbsenceUtil.unlimitedHourly");
    default:
      return polyglot.t("General.unknown");
  }
};
export const getAbsencePolicyTenureAllocationOptions = (polyglot) => {
  return Object.values(AbsencePolicyTenureAllocation).map((o) => ({
    label: translateAbsencePolicyTenureAllocation(o, polyglot),
    description: translateAbsencePolicyTenureAllocationDescription(o, polyglot),
    value: o
  }));
};
export const getPolicyHolidaysProratingRuleOption = (policy, polyglot) => {
  if (policy.holidaysProratingRule === HolidaysProratingRule.FullValueProrated)
    return {
      value: HolidaysProratingRule.FullValueProrated,
      label: polyglot.t("AbsenceUtil.proRata"),
      description: polyglot.t("AbsenceUtil.proRataDesc")
    };
  return getActualPartTimeMethodOptions(false, polyglot).find((o) => o.value === policy.holidaysProratingRule);
};
export const getActualPartTimeMethodOptions = (disableProratedOptions, polyglot) => {
  return [
    {
      value: HolidaysProratingRule.AllInPeriod,
      label: polyglot.t("AbsenceUtil.publicHolidaysAllInPeriod"),
      description: polyglot.t("AbsenceUtil.publicHolidaysAllInPeriodDesc")
    },
    {
      value: HolidaysProratingRule.AllInCycle,
      label: polyglot.t("AbsenceUtil.publicHolidaysAllInCycle"),
      description: polyglot.t("AbsenceUtil.publicHolidaysAllInCycleDesc")
    },
    {
      value: HolidaysProratingRule.AllInPeriodProrated,
      label: polyglot.t("AbsenceUtil.publicHolidaysAllInPeriodProrated"),
      description: polyglot.t("AbsenceUtil.publicHolidaysAllInPeriodProratedDesc"),
      disabled: disableProratedOptions
    }
  ];
};
export const getAbsenceProratingMethod = (policy, polyglot) => {
  if ((policy == null ? void 0 : policy.proratingStartDate) && !policy.proratingFte)
    return {
      value: polyglot.t("AbsenceUtil.prorateByStartDate"),
      description: polyglot.t("AbsenceUtil.prorateByStartDateDesc")
    };
  if ((policy == null ? void 0 : policy.proratingStartDate) && policy.proratingStartDate)
    return {
      value: polyglot.t("AbsenceUtil.prorateByStartDateAndFTE"),
      description: polyglot.t("AbsenceUtil.prorateByStartDateAndFTEDesc")
    };
  return { value: polyglot.t("AbsenceUtil.none"), description: "" };
};
export const NoPublicHolidayCalendarOption = { value: NoneCountryEnum.code, label: NoneCountryEnum.name };
export const CustomPublicHolidayCalendarOption = { value: CustomCountryEnum.code, label: CustomCountryEnum.name };
export const getPublicHolidayCalendarOptions = (options) => [
  ...(options == null ? void 0 : options.excludeNone) ? [] : [NoPublicHolidayCalendarOption],
  ...(options == null ? void 0 : options.includeCustom) ? [CustomPublicHolidayCalendarOption] : [],
  ...Object.entries(COUNTRY_ISO_CODE_MAPPING).map(([name, code]) => {
    return {
      value: code.toLowerCase(),
      label: name
    };
  })
];
const DEFAULT_COLOR_WIDTH = 14;
export const ColorBubble = ({ color, colorWidth = DEFAULT_COLOR_WIDTH }) => /* @__PURE__ */ jsx(
  Box,
  {
    sx: {
      display: "flex",
      mt: "5px",
      bgcolor: color,
      width: `${colorWidth}px`,
      height: `${colorWidth}px`,
      borderRadius: `${colorWidth / 2}px`
    }
  }
);
export const COLOR_OPTIONS = COLOR_PICKER_COLORS.map((color) => ({
  value: color,
  label: /* @__PURE__ */ jsx(ColorBubble, { color })
}));
export const getMonthOptions = (polyglot) => {
  return [
    { value: 1, label: polyglot.t("getMonthOptions.january") },
    { value: 2, label: polyglot.t("getMonthOptions.february") },
    { value: 3, label: polyglot.t("getMonthOptions.march") },
    { value: 4, label: polyglot.t("getMonthOptions.april") },
    { value: 5, label: polyglot.t("getMonthOptions.may") },
    { value: 6, label: polyglot.t("getMonthOptions.june") },
    { value: 7, label: polyglot.t("getMonthOptions.july") },
    { value: 8, label: polyglot.t("getMonthOptions.august") },
    { value: 9, label: polyglot.t("getMonthOptions.september") },
    { value: 10, label: polyglot.t("getMonthOptions.october") },
    { value: 11, label: polyglot.t("getMonthOptions.november") },
    { value: 12, label: polyglot.t("getMonthOptions.december") }
  ];
};
export var ProratingRule = /* @__PURE__ */ ((ProratingRule2) => {
  ProratingRule2["None"] = "None";
  ProratingRule2["StartDate"] = "StartDate";
  ProratingRule2["StartDateAndFtePercent"] = "StartDateAndFtePercent";
  return ProratingRule2;
})(ProratingRule || {});
export const getNoProratingOption = (polyglot) => {
  return { value: "None" /* None */, label: polyglot.t("getNoProratingOption.label") };
};
export const getProratingByStartDateOption = (polyglot) => {
  return { value: "StartDate" /* StartDate */, label: polyglot.t("getProratingByStartDateOption.label") };
};
export const getProratingByStartDateAndFTEPercentOption = (polyglot) => {
  return {
    value: "StartDateAndFtePercent" /* StartDateAndFtePercent */,
    label: polyglot.t("getProratingByStartDateAndFTEPercentOption.label")
  };
};
export const getCustomRule = (customRule, ruleDataOption, polyglot) => {
  var _a, _b;
  const ruleTypeArray = customRule ? customRule == null ? void 0 : customRule.split("=") : [];
  if (ruleTypeArray.length === 0 && !ruleDataOption) return;
  const ruleType = (_a = ruleTypeArray[0]) != null ? _a : [];
  const ruleValue = ruleDataOption && ((_b = ruleDataOption.find((r) => r.value === Number(ruleTypeArray[1]))) == null ? void 0 : _b.label);
  switch (ruleType) {
    case CustomRuleOptions.Site:
      return polyglot.t("AbsenceUtil.siteRule", { list: ruleValue != null ? ruleValue : "" });
    case CustomRuleOptions.Department:
      return polyglot.t("AbsenceUtil.departmentRule", { list: ruleValue != null ? ruleValue : "" });
    case CustomRuleOptions.Entity:
      return polyglot.t("AbsenceUtil.entityRule", { list: ruleValue != null ? ruleValue : "" });
    default:
      return "";
  }
};
export const getRegularTimeFromScheduleData = (startHour, endHour, polyglot) => {
  if (startHour && endHour) return `${startHour} - ${endHour}`;
  if (startHour) return convertTimeHMToClockHours(startHour, polyglot);
  return null;
};
export const convertTimeHMToClockHours = (value, polyglot) => {
  if (!value) return polyglot.t("AbsenceUtil.clockHours", { sign: "", hours: 0 });
  const [h, m] = value.split(":");
  const hours = Number(h);
  const minutes = Number(m);
  if (minutes === 0) return polyglot.t("AbsenceUtil.clockHours", { sign: "", hours });
  return polyglot.t("AbsenceUtil.clockHoursAndMinutes", { sign: "", hours, minutes });
};
export const convertMinutesToClockHours = (value, polyglot) => {
  const minutes = Math.round(Math.abs(value != null ? value : 0));
  if (!minutes) return "0h";
  const minutesOnly = Math.round(minutes % 60);
  const hours = Math.round((minutes - minutesOnly) / 60);
  const sign = value && value < 0 ? "-" : "";
  if (minutesOnly)
    return !polyglot ? `${sign}${hours}h ${minutesOnly}m` : polyglot.t("AbsenceUtil.clockHoursAndMinutes", {
      sign,
      hours,
      minutes: minutesOnly
    });
  return !polyglot ? `${sign}${hours}h` : polyglot.t("AbsenceUtil.clockHours", { sign, hours });
};
export const convertMinutesToWorkDays = (value, currentAverageWorkDayLength, polyglot, inputSign = "") => {
  var _a;
  const minutes = Math.round(Math.abs(value != null ? value : 0));
  if (!minutes) return "0 days";
  if (currentAverageWorkDayLength <= 0) {
    throw new Error("currentAverageWorkDayLength must be greater than 0 to convert minutes to work days");
  }
  const days = minutes / currentAverageWorkDayLength;
  const sign = value && value < 0 ? "-" : inputSign;
  const roundingInDays = (_a = round2Digits(days)) != null ? _a : 0;
  if (polyglot) {
    return polyglot.t("AllowanceDrawer.noOfDaysWithSign", { sign, smart_count: roundingInDays });
  }
  const getDayLabel = (count) => count === 1 ? "day" : "days";
  return `${sign}${roundingInDays} ${getDayLabel(roundingInDays)}`;
};
export const convertMinutesToHHMM = (totalMinutes) => {
  const sign = totalMinutes < 0 ? "-" : "";
  const absoluteTotalMinutes = Math.abs(totalMinutes);
  const hours = Math.floor(absoluteTotalMinutes / 60);
  const minutes = absoluteTotalMinutes % 60;
  return `${sign}${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
};
export const isHourlyPolicy = (policy) => {
  return Boolean(
    (policy == null ? void 0 : policy.allowanceType) === AbsencePolicyAllowanceType.LimitedHourly || (policy == null ? void 0 : policy.allowanceType) === AbsencePolicyAllowanceType.UnlimitedHourly
  );
};
export const isUnlimitedPolicy = (policy) => {
  return Boolean(
    (policy == null ? void 0 : policy.allowanceType) === AbsencePolicyAllowanceType.UnlimitedHourly || (policy == null ? void 0 : policy.allowanceType) === AbsencePolicyAllowanceType.Unlimited
  );
};
export function getPeriodFromAbsence(absence, showHours = true) {
  const startYear = absence.start.slice(0, 4);
  const endYear = absence.end ? absence.end.slice(0, 4) : startYear;
  const showStartYear = startYear !== endYear || !absence.end;
  const startDate = displayAbsenceDay(absence.start, showStartYear);
  const endDate = absence.end ? displayAbsenceDay(absence.end, true) : null;
  const isAM = !absence.startHourTimestamp ? absence.morningOnly : false;
  const isPM = !absence.startHourTimestamp ? absence.afternoonOnly : false;
  const startDateSuffixEl = isPM ? " (PM)" : isAM && !absence.end ? " (AM)" : "";
  const endDateSuffixEl = isAM ? " (AM)" : "";
  const mainText = endDate ? `${startDate}${startDateSuffixEl} - ${endDate}${endDateSuffixEl}` : `${startDate}${startDateSuffixEl}`;
  if (!absence.startHourTimestamp || !absence.endHourTimestamp || !showHours) return mainText;
  const startHour = new Date(absence.startHourTimestamp).toLocaleTimeString(void 0, {
    hour: "2-digit",
    minute: "2-digit"
  });
  const endHour = new Date(absence.endHourTimestamp).toLocaleTimeString(void 0, {
    hour: "2-digit",
    minute: "2-digit"
  });
  return `${mainText} (${startHour}-${endHour})`;
}
export function getNextDateFromBEStringDate(dateString) {
  const [y, m, d] = dateString.split("-");
  const date = new Date(Number(y), Number(m) - 1, Number(d), 0, 0, 0, 0);
  date.setDate(date.getDate() + 1);
  const y1 = date.getFullYear();
  const m1 = (100 + date.getMonth() + 1).toString().slice(-2);
  const d1 = (100 + date.getDate()).toString().slice(-2);
  return `${y1}-${m1}-${d1}`;
}
export function hasOverlap(absenceStartDate, absenceEndDate, policy) {
  if (!policy || !policy.cycleStartMonth || !policy.cycleStartDay) {
    return false;
  }
  const { cycleStartDay: vacationStartDay, cycleStartMonth: vacationStartMonth } = policy;
  const effectiveDate = new LocalDate().getDate();
  const effectiveYear = effectiveDate.getFullYear();
  let startDate = new Date(effectiveYear, vacationStartMonth - 1, vacationStartDay);
  if (startDate > effectiveDate) {
    startDate.setFullYear(effectiveYear - 1);
  }
  const endDate = new LocalDate(startDate).getDate();
  endDate.setFullYear(startDate.getFullYear() + 1);
  endDate.setDate(endDate.getDate() - 1);
  const absenceStart = new LocalDate(absenceStartDate).getDate();
  const absenceEnd = absenceEndDate ? new LocalDate(absenceEndDate).getDate() : new LocalDate(absenceStartDate).getDate();
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);
  absenceStart.setHours(0, 0, 0, 0);
  absenceEnd.setHours(23, 59, 59, 999);
  if (!absenceEndDate) {
    if (absenceStart < startDate || absenceStart > endDate) {
      return true;
    } else {
      return false;
    }
  } else {
    if (absenceStart < startDate && absenceEnd < startDate || absenceStart > endDate && absenceEnd > endDate) {
      return true;
    } else if (absenceStart < startDate || absenceEnd > endDate) {
      return true;
    } else {
      return false;
    }
  }
}
export function syncStartAndEndHourWithTheSameDate(absenceData, polyglot) {
  var _a, _b;
  if (!absenceData.start || !absenceData.startHour || !absenceData.endHour)
    throw new Error(polyglot.t("AbsenceUtil.syncStartAndEndHourMissingFields"));
  const localDate = new LocalDate(absenceData.start);
  const startHour = `${localDate.toDateString()}${absenceData.startHour.slice(10)}`;
  const endHour = `${localDate.toDateString()}${absenceData.endHour.slice(10)}`;
  const date = localDate.getDate();
  const startHourTimestamp = new Date((_a = absenceData.startHourTimestamp) != null ? _a : absenceData.startHour);
  startHourTimestamp.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
  const endHourTimestamp = new Date((_b = absenceData.endHourTimestamp) != null ? _b : absenceData.endHour);
  endHourTimestamp.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
  return { startHour, endHour, startHourTimestamp, endHourTimestamp };
}
