"use strict";
import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, FormControl } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { isValid } from "date-fns";
import dayjs from "dayjs";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { TypeableDateComponent } from "@/v2/components/forms/typeable-date.component";
import { CustomProfileFieldType } from "@/v2/feature/custom-fields/custom-profile-fields.dto";
import { sortSelectOptions } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { fieldSx as defaultFieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export const UserShowCustomFieldComponent = ({
  field,
  onChange,
  autoFocus,
  fieldSx = defaultFieldSx,
  disabled
}) => {
  const { polyglot } = usePolyglot();
  const [localValue, setLocalValue] = useState(field.value);
  const [fieldError, setFieldError] = useState();
  useEffect(() => {
    setLocalValue(field.value);
  }, [field.value]);
  const handleUpdate = useCallback(
    (value) => {
      setLocalValue(value);
      onChange(field.field.fieldId, value);
    },
    [field.field.fieldId, onChange]
  );
  const getFieldByType = useCallback(
    (customField) => {
      var _a, _b;
      switch (customField.field.fieldType) {
        case CustomProfileFieldType.Input:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: customField.field.fieldName,
              required: customField.field.isRequired,
              value: localValue,
              onChange: (e) => handleUpdate(e.target.value),
              autoFocus,
              disabled
            }
          ) });
        case CustomProfileFieldType.DatePicker:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
            DatePickerComponent,
            {
              inputFormat: "DD/MM/YYYY",
              required: customField.field.isRequired,
              value: localValue != null ? localValue : null,
              onChange: (value) => {
                if (dayjs(value).isValid()) {
                  handleUpdate(value);
                }
              },
              name: customField.field.fieldName,
              label: customField.field.fieldName,
              disabled
            }
          ) }) });
        case CustomProfileFieldType.Select:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            SelectComponent,
            {
              required: customField.field.isRequired,
              label: customField.field.fieldName,
              name: customField.field.fieldName,
              options: sortSelectOptions((_b = (_a = customField.field.options) == null ? void 0 : _a.allOptions) != null ? _b : []),
              value: localValue,
              onChange: (e) => handleUpdate(e.target.value),
              disabled
            }
          ) });
        case CustomProfileFieldType.Number:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              required: customField.field.isRequired,
              type: "number",
              label: customField.field.fieldName,
              value: localValue,
              onChange: (e) => handleUpdate(e.target.value),
              autoFocus,
              disabled
            }
          ) });
        case CustomProfileFieldType.DateInput:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TypeableDateComponent,
            {
              name: customField.field.fieldName,
              label: customField.field.fieldName,
              required: customField.field.isRequired,
              value: localValue,
              onChange: (value) => {
                if (!isValid(new Date(value))) {
                  setFieldError(polyglot.t("ShowCustomFieldComponent.errorMessages.invalidDate"));
                } else {
                  handleUpdate(value);
                  setFieldError(void 0);
                }
              },
              autoFocus,
              error: !!fieldError,
              helperText: fieldError,
              disabled
            }
          ) });
        default:
          return null;
      }
    },
    [autoFocus, disabled, fieldError, fieldSx, localValue, polyglot, handleUpdate]
  );
  return getFieldByType(field);
};
