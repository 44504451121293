"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box, IconButton } from "@mui/material";
import { sortString } from "@v2/components/table/table-sorting.util";
import { getTaskStatus } from "@v2/feature/task/components/task.util";
import { TaskStatuses } from "@v2/feature/task/task.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ScopesControl } from "@/component/widgets/Scopes";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Chose } from "@/images/side-bar-icons/Chose.svg";
import { ReactComponent as Mail } from "@/images/side-bar-icons/Mail.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { TooltipCell } from "@/v2/components/table/components/tooltip-cell.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { BasicServerTable } from "@/v2/components/table/server-side-table.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { emojiOptions } from "@/v2/feature/task/subfeature/checklist.interface";
import { tablePrimaryIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { selectColumnSize } from "@/v2/styles/table.styles";
const iconSize = { width: 14, height: 14 };
export const TaskTable = ({
  tasks,
  openSelectedTaskModal,
  openEditTaskModal,
  markAsComplete,
  taskEditable = true,
  loading = false,
  setSelectionModel,
  selectionModel,
  currentUserId,
  deleteAction,
  remindAction,
  sorting: [sortingState, setSortingState],
  pagination: [paginationState, setPaginationState],
  reach,
  stickyHeader
}) => {
  const { polyglot } = usePolyglot();
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: currentUserId });
  const hasTaskAllcope = hasScopes(["task:all"], scopesContext);
  const sortedTaskItems = useMemo(
    () => {
      var _a;
      return (
        // for some reason, items sorted in ascending order appear in reverse order in the table
        // so we manually reverse them here so they end up displayed in the correct order.
        ((_a = sortingState[0]) == null ? void 0 : _a.desc) === false ? tasks.items.slice().reverse() : tasks.items.slice()
      );
    },
    [sortingState, tasks.items]
  );
  const getTasksActionsOptions = useCallback(
    (original) => {
      const menuOptions = [
        {
          icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
          handler: () => {
            openEditTaskModal(original);
          },
          label: polyglot.t("TaskTable.edit"),
          disabled: false
        },
        {
          icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
          handler: () => {
            deleteAction(original.id);
          },
          label: polyglot.t("TaskTable.delete"),
          disabled: false
        }
      ];
      if (original.status !== TaskStatuses.COMPLETE)
        menuOptions.push({
          icon: /* @__PURE__ */ jsx(Mail, __spreadValues({}, iconSize)),
          handler: () => __async(void 0, null, function* () {
            yield remindAction(original.id);
          }),
          label: polyglot.t("TaskTable.remind"),
          disabled: false
        });
      return menuOptions;
    },
    [polyglot, openEditTaskModal, deleteAction, remindAction]
  );
  const columns = useMemo(
    () => [
      ...hasTaskAllcope ? [
        {
          id: "select",
          enableSorting: false,
          minSize: selectColumnSize,
          size: selectColumnSize,
          header: () => {
            const displayedTaskIds = new Set(tasks.items.map(({ id }) => id));
            const allSelected = selectionModel.length > 0 && selectionModel.length === displayedTaskIds.size && selectionModel.every((id) => displayedTaskIds.has(id));
            return /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: "allSelected",
                checked: allSelected,
                value: "allSelceted",
                onChange: (_, checked) => {
                  setSelectionModel(checked ? [...displayedTaskIds] : []);
                }
              }
            );
          },
          cell: ({ row: { original } }) => {
            var _a, _b;
            return /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: (_a = original.id) == null ? void 0 : _a.toString(),
                checked: selectionModel.includes(original.id),
                value: (_b = original.id) == null ? void 0 : _b.toString(),
                onChange: () => {
                  let finalArray;
                  if (selectionModel == null ? void 0 : selectionModel.includes(original.id)) {
                    finalArray = selectionModel.filter((sm) => sm !== original.id);
                  } else finalArray = [...selectionModel, original.id];
                  setSelectionModel(finalArray);
                }
              }
            );
          }
        }
      ] : [],
      {
        id: "name",
        header: () => polyglot.t("TaskTable.name"),
        accessorFn: (row) => row.name,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item.name) != null ? _a : "";
        }),
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(
            TooltipCell,
            {
              title: (_a = original.name) != null ? _a : "",
              maxWidth: 200,
              cell: /* @__PURE__ */ jsx("div", { children: original.name ? /* @__PURE__ */ jsx(Box, { children: original.name }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
            }
          );
        }
      },
      // Temporarily hidden, on Chris' suggestion
      // {
      //   id: 'description',
      //   header: () => 'Description',
      //   accessorFn: (row) => row,
      //   enableSorting: true,
      //   sortingFn: (a, b) => sortString(a, b, (item) => item.description),
      //   cell: ({ row: { original } }) => (
      //     <TooltipCell
      //       title={original.description ?? ''}
      //       maxWidth={260}
      //       cell={
      //         <div>
      //           {original.description ? (
      //             <Box>
      //               <Typography variant="caption" dangerouslySetInnerHTML={{ __html: original.description ?? '' }} truncateHtml />
      //             </Box>
      //           ) : (
      //             <EmptyCell />
      //           )}
      //         </div>
      //       }
      //     />
      //   ),
      // },
      {
        id: "checklist",
        header: () => polyglot.t("TaskTable.checklist"),
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a, _b, _c, _d;
          return /* @__PURE__ */ jsx("div", { children: ((_a = original == null ? void 0 : original.checklist) == null ? void 0 : _a.bgImg) && /* @__PURE__ */ jsx(Box, { children: (_d = (_b = emojiOptions.find((emo) => {
            var _a2;
            return emo.value === ((_a2 = original == null ? void 0 : original.checklist) == null ? void 0 : _a2.bgImg);
          })) == null ? void 0 : _b.label) != null ? _d : (_c = original == null ? void 0 : original.checklist) == null ? void 0 : _c.bgImg }) });
        }
      },
      {
        id: "assignedUser",
        header: () => polyglot.t("TaskTable.assignedUser"),
        accessorFn: (row) => row,
        enableSorting: true,
        cell: (c) => {
          const assignedUserId = c.getValue().assignedUserId;
          return assignedUserId ? /* @__PURE__ */ jsx(UserCell, { userId: assignedUserId }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: "requestedFor",
        header: () => polyglot.t("TaskTable.requestedFor"),
        accessorFn: (row) => row,
        enableSorting: true,
        cell: (c) => {
          const requestedForId = c.getValue().requestedForId;
          return requestedForId ? /* @__PURE__ */ jsx(UserCell, { userId: requestedForId }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: "dueDate",
        header: () => polyglot.t("TaskTable.dueDate"),
        enableSorting: true,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.dueDate ? getDateString(original.dueDate) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "status",
        header: () => polyglot.t("TaskTable.status"),
        accessorFn: (row) => row,
        enableSorting: true,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = getTaskStatus(original, polyglot)) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        id: "action",
        header: () => "",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "flex-end", width: "100%", gap: spacing.g5 }, children: [
          taskEditable && /* @__PURE__ */ jsx(
            ScopesControl,
            {
              scopes: ["task:all"],
              context: original.assignedUserId ? { userId: original.assignedUserId } : void 0,
              children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  options: getTasksActionsOptions(original),
                  actionButtonDetails: {
                    type: "iconButton",
                    colorVariant: "secondary",
                    sizeVariant: "small",
                    title: "actions",
                    icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                  }
                }
              ) })
            }
          ),
          original && original.status === TaskStatuses.INCOMPLETE && currentUserId === original.assignedUserId && reach !== "me" && /* @__PURE__ */ jsx(
            ScopesControl,
            {
              scopes: ["task", "task:all"],
              context: original.assignedUserId ? { userId: original.assignedUserId } : void 0,
              children: /* @__PURE__ */ jsx(
                IconButton,
                {
                  onClick: () => markAsComplete(original),
                  "aria-label": "mark-as-complete",
                  sx: tablePrimaryIconButtonSx,
                  children: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize))
                }
              )
            }
          )
        ] })
      }
    ],
    [
      hasTaskAllcope,
      tasks.items,
      selectionModel,
      setSelectionModel,
      polyglot,
      taskEditable,
      getTasksActionsOptions,
      currentUserId,
      reach,
      markAsComplete
    ]
  );
  const handleRowClick = useCallback(
    (row) => {
      openSelectedTaskModal == null ? void 0 : openSelectedTaskModal(row.original);
    },
    [openSelectedTaskModal]
  );
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    BasicServerTable,
    {
      loading,
      rowData: sortedTaskItems,
      columnData: columns,
      rowClick: handleRowClick,
      pagination: paginationState,
      setPagination: setPaginationState,
      totalPages: tasks.totalPages,
      totalItems: tasks.totalItems,
      sorting: sortingState,
      setSorting: setSortingState,
      stickyHeader,
      fixedSecondColumn: !!columns.find((c) => c.id === "select")
    }
  ) });
};
