"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Chose } from "@/images/side-bar-icons/Chose.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Reject } from "@/images/side-bar-icons/Reject.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { ReactComponent as Reload } from "@/images/side-bar-icons/Reload.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { nestErrorMessage } from "@/lib/errors";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { TableFilter } from "@/v2/components/table/table-filter.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import { AppDetailsTable } from "@/v2/feature/app-integration/features/app-details/components/app-details-table.component";
import { AppRequestAPI, AppRequestEndpoints } from "@/v2/feature/app-integration/features/app-request/app-request.api";
import {
  RequestStatus
} from "@/v2/feature/app-integration/features/app-request/app-request.interface";
import {
  filterByRequestedApp,
  filterByRequestStatus
} from "@/v2/feature/app-integration/features/app-request/app-request.util";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { filterStringToObject } from "@/v2/feature/user/user.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx, tablePrimaryIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { dateAPItoUILong } from "@/v2/util/date-format.util";
const StatusTextIcon = ({
  status,
  colorText,
  icon
}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
    icon,
    /* @__PURE__ */ jsx(
      Typography,
      {
        sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
          color: colorText
        }),
        children: status
      }
    )
  ] });
};
export const AppRequestPage = () => {
  var _a;
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const [showMessage] = useMessage();
  const [_state, dispatch] = useContext(GlobalContext);
  const [filteredAppRequests, setFilteredAppRequests] = useState([]);
  const [filterTypes, setFilterTypes] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("Status=Requested,Pending,Failed");
  const { data: appRequests, isValidating: appRequestsLoading, mutate: refreshAppRequests } = useApiClient(
    AppRequestEndpoints.getAppRequestsForCompany(),
    {
      suspense: false
    }
  );
  const { mutate: refreshRequestsForUser } = useApiClient(
    AppRequestEndpoints.getAppRequestsForUser("Requested,Scheduled,Pending"),
    { suspense: false }
  );
  const { data: allAvailableApps, isValidating: allAvailableAppsLoading } = useApiClient(
    AppIntegrationEndpoints.getAvailableApps(),
    {
      suspense: false
    }
  );
  const getAppNameFromStub = useCallback(
    (stub) => {
      var _a2, _b;
      return (_b = (_a2 = allAvailableApps == null ? void 0 : allAvailableApps.find((eachApp) => eachApp.stub === stub)) == null ? void 0 : _a2.name) != null ? _b : stub;
    },
    [allAvailableApps]
  );
  const getFilterOptions = useCallback(() => __async(void 0, null, function* () {
    const requestStatus = [
      { label: "Requested", value: "Requested" },
      { label: "Scheduled", value: "Scheduled" },
      { label: "Pending", value: "Pending" },
      { label: "Rejected", value: "Rejected" },
      { label: "Failed", value: "Failed" },
      { label: "Completed", value: "Completed" }
    ];
    const FILTERS = ["Apps", "Status"];
    let filters = {};
    const uniqueListOfAppsInRequests = appRequests ? Array.from(new Set(appRequests.map((r) => r.requestInfo.appStub))) : [];
    FILTERS.forEach((filter) => {
      switch (filter) {
        case "Apps":
          filters = __spreadProps(__spreadValues({}, filters), {
            [filter]: uniqueListOfAppsInRequests.map((stub) => {
              return { label: getAppNameFromStub(stub), value: stub };
            })
          });
          break;
        case "Status":
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: requestStatus });
          break;
        default:
          break;
      }
    });
    setFilterTypes(filters);
  }), [appRequests, getAppNameFromStub]);
  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);
  useEffect(() => {
    if (appRequests) {
      let filteredRequests = appRequests;
      if (searchInput) {
        filteredRequests = filteredRequests.filter(
          (r) => {
            var _a2, _b, _c, _d;
            return r.requestedFor && ((_a2 = getCachedUserById(r.requestedFor)) == null ? void 0 : _a2.displayName.toLowerCase().includes(searchInput.toLowerCase())) || r.requestedFor && ((_b = getCachedUserById(r.requestedFor)) == null ? void 0 : _b.firstName.toLowerCase().includes(searchInput.toLowerCase())) || r.requestedFor && ((_c = getCachedUserById(r.requestedFor)) == null ? void 0 : _c.lastName.toLowerCase().includes(searchInput.toLowerCase())) || r.requestedFor && ((_d = getCachedUserById(r.requestedFor)) == null ? void 0 : _d.emailAddress.toLowerCase().includes(searchInput.toLowerCase())) || r.requestInfo && r.requestInfo.appStub && r.requestInfo.appStub.toLowerCase().includes(searchInput.toLowerCase()) || r.status && r.status.toLowerCase().includes(searchInput.toLowerCase());
          }
        );
      }
      if (filterString) {
        const filterOptions = filterStringToObject(filterString);
        if (filterOptions) {
          for (const key of Object.keys(filterOptions)) {
            switch (key) {
              case "Status": {
                filteredRequests = filterByRequestStatus(filteredRequests != null ? filteredRequests : [], filterOptions[key]);
                break;
              }
              case "Apps": {
                filteredRequests = filterByRequestedApp(filteredRequests != null ? filteredRequests : [], filterOptions[key]);
                break;
              }
              default:
                break;
            }
          }
        }
      }
      setFilteredAppRequests(filteredRequests);
    } else {
      setFilteredAppRequests([]);
    }
  }, [appRequests, filterString, getCachedUserById, searchInput]);
  const getIconForRequestStatus = (status) => {
    if ([RequestStatus.Requested, RequestStatus.Scheduled, RequestStatus.Pending].includes(status))
      return /* @__PURE__ */ jsx(
        StatusTextIcon,
        {
          status,
          colorText: themeColors.Grey,
          icon: /* @__PURE__ */ jsx(Waiting, { style: { fill: themeColors.GreyMiddle } })
        }
      );
    if (status === RequestStatus.Failed)
      return /* @__PURE__ */ jsx(StatusTextIcon, { status, colorText: themeColors.RedDark, icon: /* @__PURE__ */ jsx(Rejected, { fill: themeColors.Red }) });
    if (status === RequestStatus.Rejected)
      return /* @__PURE__ */ jsx(StatusTextIcon, { status, colorText: themeColors.RedDark, icon: /* @__PURE__ */ jsx(Rejected, { fill: themeColors.Red }) });
    if (status === RequestStatus.Completed)
      return /* @__PURE__ */ jsx(
        StatusTextIcon,
        {
          status,
          colorText: themeColors.DarkGrey,
          icon: /* @__PURE__ */ jsx(OkGreen, { style: { fill: themeColors.Green } })
        }
      );
    return /* @__PURE__ */ jsx(Fragment, {});
  };
  const approveAppRequest = useCallback(
    (requestInfo) => __async(void 0, null, function* () {
      const { id } = requestInfo;
      if (!id) {
        showMessage("Unable to approve app request as there are missing details", "error");
        return;
      }
      try {
        yield AppRequestAPI.approveAppRequest(id);
        refreshAppRequests();
        refreshRequestsForUser();
        showMessage("Successfully approved & scheduled app access request!", "success");
      } catch (error) {
        showMessage(`Encountered an error while trying to approve access request: ${nestErrorMessage(error)}`, "error");
      }
    }),
    [refreshAppRequests, refreshRequestsForUser, showMessage]
  );
  const retryFailedAppRequest = useCallback(
    (requestInfo) => __async(void 0, null, function* () {
      const { id } = requestInfo;
      if (!id) {
        showMessage("Unable to retry app request as there are missing details", "error");
        return;
      }
      try {
        yield AppRequestAPI.retryFailedAppRequest(id);
        refreshAppRequests();
        refreshRequestsForUser();
        showMessage("Successfully retried & scheduled app access request!", "success");
      } catch (error) {
        showMessage(`Encountered an error while trying to retry access request: ${nestErrorMessage(error)}`, "error");
      }
    }),
    [refreshAppRequests, refreshRequestsForUser, showMessage]
  );
  const rejectAppRequest = useCallback(
    (requestInfo) => __async(void 0, null, function* () {
      const { id } = requestInfo;
      if (!id) {
        showMessage("Unable to reject app request as there are missing details", "error");
        return;
      }
      try {
        yield AppRequestAPI.rejectAppRequest(id);
        refreshAppRequests();
        showMessage("Successfully rejected app access request.", "success");
      } catch (error) {
        showMessage(`Encountered an error while trying to reject access request: ${nestErrorMessage(error)}`, "error");
      }
    }),
    [refreshAppRequests, showMessage]
  );
  const deleteFailedAppRequest = useCallback(
    (requestInfo) => __async(void 0, null, function* () {
      const { id } = requestInfo;
      if (!id) {
        showMessage("Unable to delete app request as there are missing details", "error");
        return;
      }
      try {
        yield AppRequestAPI.deleteFailedAppRequest(id);
        refreshAppRequests();
        showMessage("Successfully deleted app access request.", "success");
      } catch (error) {
        showMessage(`Encountered an error while trying to delete access request: ${nestErrorMessage(error)}`, "error");
      }
    }),
    [refreshAppRequests, showMessage]
  );
  const deletePendingAppRequest = useCallback(
    (requestInfo) => __async(void 0, null, function* () {
      const { id } = requestInfo;
      if (!id) {
        showMessage("Unable to delete app request as there are missing details", "error");
        return;
      }
      try {
        yield AppRequestAPI.deletePendingAppRequest(id);
        refreshAppRequests();
        refreshRequestsForUser();
        showMessage("Successfully deleted pending app access request.", "success");
      } catch (error) {
        showMessage(`Encountered an error while trying to delete access request: ${nestErrorMessage(error)}`, "error");
      }
    }),
    [refreshAppRequests, refreshRequestsForUser, showMessage]
  );
  const deleteRejectedAppRequest = useCallback(
    (requestInfo) => __async(void 0, null, function* () {
      const { id } = requestInfo;
      if (!id) {
        showMessage("Unable to delete app request as there are missing details", "error");
        return;
      }
      try {
        yield AppRequestAPI.deleteRejectedAppRequest(id);
        refreshAppRequests();
        refreshRequestsForUser();
        showMessage("Successfully deleted rejected app access request.", "success");
      } catch (error) {
        showMessage(`Encountered an error while trying to delete access request: ${nestErrorMessage(error)}`, "error");
      }
    }),
    [refreshAppRequests, refreshRequestsForUser, showMessage]
  );
  const getActionsForRequestStatus = useCallback(
    (requestObj) => {
      const { status } = requestObj;
      if (status === RequestStatus.Requested)
        return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(IconButton, { sx: __spreadValues({}, tableIconButtonSx), onClick: () => rejectAppRequest(requestObj), children: /* @__PURE__ */ jsx(Reject, __spreadValues({}, iconSize)) }),
          /* @__PURE__ */ jsx(IconButton, { sx: tablePrimaryIconButtonSx, onClick: () => approveAppRequest(requestObj), children: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize)) })
        ] });
      if (status === RequestStatus.Failed)
        return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(IconButton, { sx: __spreadValues({}, tableIconButtonSx), onClick: () => retryFailedAppRequest(requestObj), children: /* @__PURE__ */ jsx(Reload, __spreadValues({}, iconSize)) }),
          /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => deleteFailedAppRequest(requestObj), children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) })
        ] });
      if (status === RequestStatus.Pending)
        return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => deletePendingAppRequest(requestObj), children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) }) });
      if (status === RequestStatus.Rejected)
        return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => deleteRejectedAppRequest(requestObj), children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) }) });
      return /* @__PURE__ */ jsx(Fragment, {});
    },
    [
      rejectAppRequest,
      approveAppRequest,
      retryFailedAppRequest,
      deleteFailedAppRequest,
      deletePendingAppRequest,
      deleteRejectedAppRequest
    ]
  );
  const appRequestsColumns = useMemo(
    () => [
      {
        header: () => "Name",
        id: "requestedFor",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a2;
          const user = original.requestedFor ? getCachedUserById(original.requestedFor) : void 0;
          return user ? /* @__PURE__ */ jsxs(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                display: "flex",
                alignItems: "center",
                gap: spacing.m5
              }),
              children: [
                /* @__PURE__ */ jsx(UserAvatar, { userId: user.userId, size: "xxsmall" }),
                /* @__PURE__ */ jsx("div", { children: polyglot.t((_a2 = user.displayName) != null ? _a2 : "") })
              ]
            }
          ) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "App",
        id: "app-stub",
        accessorFn: (row) => getAppNameFromStub(row.requestInfo.appStub),
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsxs(Box, { sx: { py: 0.2, display: "flex", alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(
            "img",
            {
              src: `/app-icons-v2/images/${original.requestInfo.appStub}.png`,
              width: 20,
              alt: original.requestInfo.appStub
            }
          ),
          /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                display: "flex",
                alignItems: "center",
                ml: 1,
                gap: spacing.m5
              }),
              children: /* @__PURE__ */ jsx("div", { children: getAppNameFromStub(original.requestInfo.appStub) })
            }
          )
        ] })
      },
      {
        header: () => "Email/Login",
        id: "email",
        accessorFn: (row) => {
          var _a2;
          const user = row.requestedFor ? getCachedUserById(row.requestedFor) : void 0;
          return (_a2 = user == null ? void 0 : user.emailAddress) != null ? _a2 : "";
        },
        enableSorting: false,
        cell: ({ row: { original } }) => {
          const user = original.requestedFor ? getCachedUserById(original.requestedFor) : void 0;
          return user ? /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                display: "flex",
                alignItems: "center",
                gap: spacing.m5
              }),
              children: user.emailAddress
            }
          ) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Status",
        id: "status",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Fragment, { children: original.status && getIconForRequestStatus(original.status) })
      },
      {
        header: () => "Date",
        id: "createdAt",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { py: 0.2, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: original.createdAt ? dateAPItoUILong(new Date(original.createdAt).toISOString()) : ""
          }
        ) })
      },
      {
        header: () => "Action",
        id: "action",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "flex-end" }, children: original.status && getActionsForRequestStatus(original) })
      }
    ],
    [polyglot, getActionsForRequestStatus, getAppNameFromStub, getCachedUserById]
  );
  const requestsRequiringAttention = useMemo(
    () => appRequests == null ? void 0 : appRequests.filter((r) => r.status && ["Requested", "Pending", "Failed"].includes(r.status)),
    [appRequests]
  );
  useEffect(() => {
    dispatch({
      type: GlobalStateActions.UPDATE_ALERTS,
      payload: { apps: { entries: requestsRequiringAttention, type: "admin" } }
    });
  }, [dispatch, requestsRequiringAttention]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Requests" }) }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, sx: __spreadValues({}, spacing.pt20), children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            justifyContent: "flex-start",
            gap: "5px",
            alignItems: "center"
          },
          children: [
            filterTypes && /* @__PURE__ */ jsx(TableFilter, { filterTypes, setFilterString, filterString }),
            /* @__PURE__ */ jsx(
              TableSearch,
              {
                query: searchInput,
                handleChange: (e) => {
                  setSearchInput(e.target.value);
                }
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
        AppDetailsTable,
        {
          column: appRequestsColumns,
          row: filteredAppRequests != null ? filteredAppRequests : [],
          loading: (_a = appRequestsLoading || allAvailableAppsLoading) != null ? _a : false
        }
      ) })
    ] })
  ] });
};
