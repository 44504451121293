"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Copy } from "@/images/side-bar-icons/Copy.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE, SETTINGS_TASK_CHECKLIST_ROUTE } from "@/lib/routes";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { ChecklistFormDrawer } from "@/v2/feature/task/subfeature/checklist/components/checklist-form-drawer.component";
import { emojiOptions } from "@/v2/feature/task/subfeature/checklist.interface";
import { TaskAPI } from "@/v2/feature/task/task.api";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { iconSize } from "@/v2/styles/table.styles";
import { pluralText } from "@/v2/util/string.util";
export const ChecklistsPage = () => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const { trackPage } = useJune();
  const [checkLists, setChecklists] = useState([]);
  const [newChecklistDrawer, setNewChecklistDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [editChecklistOpen, setEditChecklistOpen] = useState(false);
  const [checklist, setChecklist] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const checkLists2 = yield TaskAPI.getChecklists();
      setChecklists(checkLists2);
    } catch (error) {
      showMessage(`${polyglot.t("ChecklistsPage.errorMessages.load")}. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [polyglot, showMessage]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  useEffect(() => {
    trackPage("Company checklists");
  }, []);
  const getTasksActionsOptions = (checklist2) => {
    return [
      {
        icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
        handler: () => {
          setEditChecklistOpen(true);
          setChecklist(checklist2);
        },
        label: polyglot.t("General.edit"),
        disabled: false
      },
      {
        icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          return yield duplicateChecklist(checklist2.id);
        }),
        label: polyglot.t("General.duplicate"),
        disabled: false
      },
      {
        icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          setIsRemovalModalOpen(true);
          setChecklist(checklist2);
        }),
        label: polyglot.t("General.delete"),
        disabled: false
      }
    ];
  };
  const duplicateChecklist = (checklistId) => __async(void 0, null, function* () {
    setLoading(true);
    try {
      yield TaskAPI.copyChecklistItemToChecklist(checklistId);
      showMessage(polyglot.t("TasksChecklistOverview.successMessages.copy"), "success");
    } catch (error) {
      showMessage(`${polyglot.t("TasksChecklistOverview.errorMessages.copy")}. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const deleteChecklist = (checklistId) => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield TaskAPI.deleteChecklistById(checklistId);
      showMessage(polyglot.t("TasksChecklistOverview.successMessages.delete"), "success");
      routerHistory.push(SETTINGS_TASK_CHECKLIST_ROUTE);
      setIsRemovalModalOpen(false);
    } catch (error) {
      showMessage(`${polyglot.t("TasksChecklistOverview.errorMessages.delete")}. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const getConfirmationMessage = useCallback(() => __async(void 0, null, function* () {
    const defaultMessage = polyglot.t("TasksChecklistConfirmRemove.normalConfirmMessage");
    if (checklist == null ? void 0 : checklist.id) {
      const checklistCurrentlyUsedInOnboarding = yield OnboardingAPI.isChecklistCurrentlyUsedInOnboarding(
        checklist == null ? void 0 : checklist.id
      );
      if (!checklistCurrentlyUsedInOnboarding) {
        return defaultMessage;
      } else if (checklistCurrentlyUsedInOnboarding) {
        return polyglot.t("TasksChecklistConfirmRemove.checklistUsedInOnboardingConfirmMessage");
      }
    }
    return defaultMessage;
  }), [checklist, polyglot]);
  useEffect(() => {
    if (isRemovalModalOpen && checklist) {
      (() => __async(void 0, null, function* () {
        const message = yield getConfirmationMessage();
        setConfirmationMessage(message);
      }))();
    }
  }, [isRemovalModalOpen, checklist, getConfirmationMessage]);
  const filteredChecklist = useMemo(() => {
    if (!checkLists) return [];
    if (!searchInput) return checkLists;
    return checkLists.filter((checklist2) => {
      var _a;
      return (_a = checklist2.name) == null ? void 0 : _a.toLowerCase().includes(searchInput.toLowerCase());
    });
  }, [checkLists, searchInput]);
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
    /* @__PURE__ */ jsx(
      SettingsSectionContent,
      {
        title: polyglot.t("ChecklistsPage.checklists"),
        contentWidth: "100%",
        headerWidth: "100%",
        buttons: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => setNewChecklistDrawer(true),
              children: polyglot.t("ChecklistsPage.new")
            },
            "new"
          )
        ],
        children: /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      TableSearch,
                      {
                        query: searchInput,
                        handleChange: (e) => {
                          setSearchInput(e.target.value);
                        }
                      }
                    )
                  },
                  {
                    type: SectionItemType.Component,
                    value: filteredChecklist ? /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: filteredChecklist.map((item) => {
                      var _a, _b, _c, _d, _e, _f;
                      return /* @__PURE__ */ jsx(
                        SettingsItemCard,
                        {
                          title: /* @__PURE__ */ jsxs(Typography, { variant: "title4", children: [
                            (_c = (_b = (_a = emojiOptions.find((emo) => emo.value === item.bgImg)) == null ? void 0 : _a.label) != null ? _b : item.bgImg) != null ? _c : "\u{1F5D2}\uFE0F",
                            " ",
                            (_d = item == null ? void 0 : item.name) != null ? _d : ""
                          ] }),
                          boxSx: responsiveCardStyle,
                          contentItemsSets: [
                            {
                              name: `${((_e = item == null ? void 0 : item.checklistItems) == null ? void 0 : _e.length) || 0} item${pluralText(
                                ((_f = item == null ? void 0 : item.checklistItems) == null ? void 0 : _f.length) || 0
                              )}`,
                              type: ContentItemType.chip,
                              textColor: "DarkGrey",
                              backgroundColor: "white",
                              border: "middle"
                            }
                          ],
                          boxAction: () => routerHistory.push(
                            generatePath(SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE, { checklistId: item.id })
                          ),
                          advanceActionOptions: getTasksActionsOptions(item)
                        },
                        item.name
                      );
                    }) }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("ChecklistsPage.noChecklists") })
                  }
                ]
              }
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: newChecklistDrawer, setIsOpen: setNewChecklistDrawer, children: /* @__PURE__ */ jsx(
      ChecklistFormDrawer,
      {
        checklist: null,
        onClose: () => __async(void 0, null, function* () {
          setNewChecklistDrawer(false);
          yield refresh();
        })
      }
    ) }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: editChecklistOpen, setIsOpen: setEditChecklistOpen, children: /* @__PURE__ */ jsx(
      ChecklistFormDrawer,
      {
        checklist,
        onClose: () => __async(void 0, null, function* () {
          setEditChecklistOpen(false);
          yield refresh();
        })
      }
    ) }),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("TasksChecklistConfirmRemove.title"),
        description: confirmationMessage,
        isOpen: isRemovalModalOpen,
        setIsOpen: setIsRemovalModalOpen,
        loading,
        action: () => __async(void 0, null, function* () {
          if (checklist == null ? void 0 : checklist.id) yield deleteChecklist(checklist == null ? void 0 : checklist.id);
        })
      }
    )
  ] });
};
