"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { AbsenceEndpoints } from "@/v2/feature/absence/absence.api";
import { AbsenceDrawer } from "@/v2/feature/absence/sections/absence-drawer/absence-drawer.section";
import { ImportedAbsenceTableCell } from "@/v2/feature/entity-import/wizard/components/absence/imported-absence-table-cell.component";
import { validateAbsenceImport } from "@/v2/feature/entity-import/wizard/entity-import-validator.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { ddMMYYYYToIsoDateString, isoDateAndTimeFormat } from "@/v2/infrastructure/date/date-format.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AbsenceImportResultTable = ({
  result,
  completeImport,
  onUpdateAndRevalidate,
  loading,
  setLoading
}) => {
  const {
    data: absencePolicies,
    mutate: refreshAbsencePolicies
  } = useApiClient(AbsenceEndpoints.getAbsencePoliciesExtended(), { suspense: false });
  const [showMessage] = useMessage();
  const [rowBeingEdited, setRowBeingEdited] = useState();
  const { cachedUsers: allUsersIncludingTerminated } = useCachedUsers();
  const [isOpen, setIsOpen] = useState(false);
  const { rowErrors, rowData } = useMemo(() => {
    var _a;
    if (!(result == null ? void 0 : result.errors.some((e) => e.entity))) return { rowErrors: [], rowData: [] };
    const rowErrorsInit = result.errors.map((record) => record.errors);
    const rowDataInit = result.errors.flatMap((record) => record.entity);
    const rowsWithDataAndErrors = [];
    for (let i = 0; i < Math.max(rowErrorsInit.length, rowDataInit.length); i++) {
      rowsWithDataAndErrors.push({ data: rowDataInit[i], error: (_a = rowErrorsInit[i]) != null ? _a : [] });
    }
    rowsWithDataAndErrors.sort((a, b) => b.error.length - a.error.length);
    const rowErrors2 = rowsWithDataAndErrors.map((a) => a.error);
    const rowData2 = rowsWithDataAndErrors.map((a) => a.data);
    return { rowData: rowData2, rowErrors: rowErrors2 };
  }, [result]);
  const notifyPendingErrors = useCallback(() => {
    showMessage("We found some issues in your formatting. Please correct the mistakes and try again", "info");
  }, [showMessage]);
  const notifyDataValid = useCallback(() => {
    showMessage("Your imported data looks good! Continue to finish import.", "success");
  }, [showMessage]);
  const refreshPolicies = useCallback(() => __async(void 0, null, function* () {
    if (refreshAbsencePolicies) {
      yield refreshAbsencePolicies();
    }
  }), [refreshAbsencePolicies]);
  const hasErrors = useMemo(() => {
    return rowErrors.some((errArr) => errArr.length > 0);
  }, [rowErrors]);
  useEffect(() => {
    if (hasErrors) {
      notifyPendingErrors();
    } else {
      notifyDataValid();
    }
  }, [notifyDataValid, notifyPendingErrors, hasErrors]);
  const getErrorForRow = useCallback(
    (index) => {
      return rowErrors ? rowErrors[index] : [];
    },
    [rowErrors]
  );
  const absenceColumns = useMemo(() => {
    return [
      {
        header: () => "First name",
        accessorFn: (row) => row,
        id: "firstName",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAbsenceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "firstName"
            }
          );
        }
      },
      {
        header: () => "Last name",
        accessorFn: (row) => row,
        id: "lastName",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAbsenceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "lastName"
            }
          );
        }
      },
      {
        header: () => "Work Email",
        accessorFn: (row) => row,
        id: "workEmail",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAbsenceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "workEmail"
            }
          );
        }
      },
      {
        header: () => "Policy name",
        accessorFn: (row) => row,
        id: "policyName",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAbsenceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "policyName"
            }
          );
        }
      },
      {
        header: () => "Start",
        accessorFn: (row) => row,
        id: "start",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAbsenceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "start"
            }
          );
        }
      },
      {
        header: () => "End",
        accessorFn: (row) => row,
        id: "end",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAbsenceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "end"
            }
          );
        }
      },
      {
        header: () => "Start hour",
        accessorFn: (row) => row,
        id: "startHour",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAbsenceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "startHour"
            }
          );
        }
      },
      {
        header: () => "End hour",
        accessorFn: (row) => row,
        id: "endHour",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAbsenceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "endHour"
            }
          );
        }
      },
      {
        header: () => "Afternoon only",
        accessorFn: (row) => row,
        id: "afternoonOnly",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAbsenceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "afternoonOnly"
            }
          );
        }
      },
      {
        header: () => "Morning only",
        accessorFn: (row) => row,
        id: "morningOnly",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAbsenceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "morningOnly"
            }
          );
        }
      },
      {
        header: () => "Status",
        accessorFn: (row) => row,
        id: "status",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAbsenceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "status"
            }
          );
        }
      },
      {
        header: () => "Notes",
        accessorFn: (row) => row,
        id: "notes",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(
            ImportedAbsenceTableCell,
            {
              rowData: info.getValue(),
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "notes"
            }
          );
        }
      },
      {
        header: () => "Validation",
        accessorFn: (row) => row,
        id: "validation",
        enableSorting: false,
        cell: (info) => {
          var _a, _b;
          return /* @__PURE__ */ jsx(
            ImportedAbsenceTableCell,
            {
              rowErrors: getErrorForRow(info.row.index),
              fieldKey: "validation",
              statusColumn: (_b = rowErrors && ((_a = rowErrors[info.row.index]) == null ? void 0 : _a.length)) != null ? _b : 0
            }
          );
        }
      }
    ];
  }, [getErrorForRow, rowErrors]);
  const absenceMappingForValues = useCallback(
    (values, allValues) => {
      var _a;
      const foundIndex = allValues.findIndex((record) => record.id === values.id);
      const olderRecord = allValues[foundIndex];
      let mappedValues;
      const ddMMYYYStartValue = () => {
        var _a2;
        if (!values.start) return "";
        if (isoDateAndTimeFormat.test(values.start)) return values.start;
        if (values.start.includes("T")) return values.start.split("T")[0];
        return (_a2 = ddMMYYYYToIsoDateString(values.start)) != null ? _a2 : "";
      };
      const ddMMYYYEndValue = () => {
        var _a2;
        if (!values.end) return "";
        if (isoDateAndTimeFormat.test(values.end)) return values.end;
        if (values.end.includes("T")) return values.end.split("T")[0];
        return (_a2 = ddMMYYYYToIsoDateString(values.end)) != null ? _a2 : "";
      };
      const ddMMYYYValueForStart = ddMMYYYStartValue();
      const ddMMYYYValueForEnd = ddMMYYYEndValue();
      const policy = absencePolicies == null ? void 0 : absencePolicies.find((p) => p.id === values.policyId);
      mappedValues = __spreadProps(__spreadValues({}, values), {
        firstName: olderRecord.firstName,
        lastName: olderRecord.lastName,
        workEmail: olderRecord.workEmail,
        policyName: (_a = policy == null ? void 0 : policy.fullName) != null ? _a : olderRecord.policyName,
        start: values.start && ddMMYYYValueForStart ? ddMMYYYValueForStart : "",
        end: values.end && ddMMYYYValueForEnd ? ddMMYYYValueForEnd : "",
        status: olderRecord.status,
        notes: olderRecord.notes,
        afternoonOnly: values.afternoonOnly === "Yes",
        morningOnly: values.morningOnly === "Yes"
      });
      return { mappedValues, foundIndex };
    },
    [absencePolicies]
  );
  const handleRowClick = (row) => {
    if (rowData)
      setRowBeingEdited(absenceMappingForValues(row.original, rowData).mappedValues);
    setIsOpen(true);
  };
  const currentFormForEditing = useMemo(() => {
    const updateDatasetWithRecord = (values, policy) => {
      if (!rowData) return { updatedRecords: [], foundIndex: -1 };
      const updatedRow = __spreadValues({}, values);
      if (policy) updatedRow.policyName = policy.name;
      const { mappedValues, foundIndex } = absenceMappingForValues(updatedRow, rowData);
      if (foundIndex < 0) return { updatedRecords: rowData, foundIndex: -1 };
      const updatedRecords = rowData ? [...rowData] : [];
      if (foundIndex >= 0) updatedRecords[foundIndex] = mappedValues;
      return { updatedRecords, foundIndex };
    };
    const importHandler = (values, policy) => __async(void 0, null, function* () {
      setLoading(true);
      const { updatedRecords, foundIndex } = updateDatasetWithRecord(values, policy);
      const validationResult = yield validateAbsenceImport(
        updatedRecords,
        allUsersIncludingTerminated,
        absencePolicies != null ? absencePolicies : [],
        foundIndex
      );
      onUpdateAndRevalidate(validationResult);
      setIsOpen(false);
      setLoading(false);
    });
    return /* @__PURE__ */ jsx(
      AbsenceDrawer,
      {
        isOpen,
        onClose: () => {
          setIsOpen(false);
          setRowBeingEdited(void 0);
        },
        refresh: refreshPolicies,
        absence: rowBeingEdited,
        userId: rowBeingEdited == null ? void 0 : rowBeingEdited.userId,
        setIsAbsenceDrawerOpen: setIsOpen,
        usedForDataImport: true,
        importHandler: (values, policy) => __async(void 0, null, function* () {
          yield importHandler(values, policy);
        })
      }
    );
  }, [
    isOpen,
    refreshPolicies,
    rowBeingEdited,
    rowData,
    absenceMappingForValues,
    setLoading,
    allUsersIncludingTerminated,
    absencePolicies,
    onUpdateAndRevalidate
  ]);
  const totalPendingErrors = useMemo(() => {
    var _a, _b;
    return (_b = (_a = rowErrors == null ? void 0 : rowErrors.flat()) == null ? void 0 : _a.length) != null ? _b : 0;
  }, [rowErrors]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          pt: spacing.p25,
          flex: 1,
          overflow: "auto",
          borderTop: "1px solid",
          borderColor: themeColors.GreyLight
        },
        children: /* @__PURE__ */ jsx(BasicTable, { rowData: rowData != null ? rowData : [], columnData: absenceColumns, rowClick: handleRowClick, loading })
      }
    ),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: currentFormForEditing }),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        style: { marginTop: "30px" },
        disabled: loading,
        onClick: () => {
          if (hasErrors) {
            notifyPendingErrors();
          } else {
            completeImport();
          }
        },
        children: totalPendingErrors ? "Try again" : "Continue"
      }
    )
  ] });
};
