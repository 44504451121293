"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { EmploymentsTabFilter } from "../../../app-router.util";
import useMessage from "@/hooks/notification.hook";
import cover from "@/images/app-empty.svg";
import { ReactComponent as Reload } from "@/images/side-bar-icons/Reload.svg";
import { EmptyStateComponent } from "@/v2/components/empty-state.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import {
  APP_ACTION_DRAWER_MODES
} from "@/v2/feature/app-integration/app-integration.interface";
import { AppDetailsEndpoints } from "@/v2/feature/app-integration/features/app-details/app-details.api";
import { AppDetailConfigurationModal } from "@/v2/feature/app-integration/features/app-details/components/app-detail-configuration-modal.component";
import { AppDetailsActionButton } from "@/v2/feature/app-integration/features/app-details/components/app-details-action-button.component";
import { AppDetailsTable } from "@/v2/feature/app-integration/features/app-details/components/app-details-table.component";
import { AppNameHeader } from "@/v2/feature/app-integration/features/app-details/components/app-name-header.component";
import { AppActionsDrawer } from "@/v2/feature/app-integration/features/app-details/sections/app-actions-drawer/app-actions-drawer.section";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { OnboardNewEmployee } from "@/v2/feature/onboarding/components/onboard-new-employee.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { primarySmallBtn, secondarySmallBtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { toTitleCase } from "@/v2/util/string.util";
export const APPS_SUPPORTING_EMPLOYMENT_MANAGEMENT = ["remote", "deel", "oysterhr"];
export const APPS_SUPPORTING_CANDIDATE_MANAGEMENT = ["teamtailor", "lever"];
export const AppEmploymentManagementPage = ({
  teamAccessUserList,
  countriesForEmployment,
  employmentList,
  appStub,
  app,
  loading,
  error,
  onAppChange,
  hasAppsAllOrAppOwner
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [showModal, setShowModal] = useState(false);
  const [isAppActionsDrawerOpen, setIsAppActionsDrawerOpen] = useState(false);
  const [selectedActiveUserLogin, setSelectedActiveUserLogin] = useState(void 0);
  const [preselectedUser, setPreselectedUser] = useState(void 0);
  const [employeeForOnboarding, setEmployeeForOnboarding] = useState(void 0);
  const [actionsDrawerMode, setActionsDrawerMode] = useState(void 0);
  const [onboardUserDrawerOpen, setOnboardUserDrawerOpen] = useState(false);
  const [filterString, setFilterString] = useState("all");
  const [searchInput, setSearchInput] = useState("");
  const [filteredEmploymentList, setFilteredEmploymentList] = useState(employmentList);
  const { data: candidateOnboardingTemplate } = useApiClient(AppDetailsEndpoints.getOnboardingConfiguration(appStub), {
    suspense: false
  });
  const { data: delayedActions, mutate: refreshDelayedActions } = useApiClient(
    AppIntegrationEndpoints.getDelayedAppActionsByStub(appStub),
    {
      suspense: false
    }
  );
  const teamEmailList = useMemo(() => {
    return teamAccessUserList.flatMap(
      (eachUser) => {
        var _a, _b;
        return eachUser && (eachUser == null ? void 0 : eachUser.emails) && ((_a = eachUser == null ? void 0 : eachUser.emails) == null ? void 0 : _a.length) > 0 ? [eachUser == null ? void 0 : eachUser.emails[0].email, (_b = eachUser.emails[0]) == null ? void 0 : _b.personalEmail] : [];
      }
    );
  }, [teamAccessUserList]);
  useEffect(() => {
    setFilteredEmploymentList(
      filterString === "all" ? employmentList : employmentList.filter((eachUser) => eachUser.status === filterString)
    );
  }, [employmentList, filterString]);
  useEffect(() => {
    setFilteredEmploymentList(
      searchInput.length === 0 ? employmentList : employmentList.filter(
        (eachUser) => {
          var _a;
          return eachUser.displayName && ((_a = eachUser.displayName) == null ? void 0 : _a.toLowerCase().includes(searchInput.toLowerCase()));
        }
      )
    );
  }, [searchInput, employmentList]);
  const createEmployment = () => {
    setActionsDrawerMode(APP_ACTION_DRAWER_MODES.createEmployment);
    setIsAppActionsDrawerOpen(true);
  };
  const getDisplayNameForEmployment = useCallback(
    (employment) => {
      if (appStub === "remote") return employment == null ? void 0 : employment.full_name;
      if (appStub === "deel") return employment == null ? void 0 : employment.displayName;
      if (appStub === "oysterhr") return employment == null ? void 0 : employment.displayName;
      return "N/A";
    },
    [appStub]
  );
  const getTitleForEmployment = useCallback(
    (employment) => {
      if (appStub === "remote") return employment == null ? void 0 : employment.job_title;
      if (appStub === "deel") return employment == null ? void 0 : employment.title;
      if (appStub === "oysterhr") return employment == null ? void 0 : employment.role;
      return "N/A";
    },
    [appStub]
  );
  const getEmailForEmployment = useCallback(
    (employment) => {
      if (appStub === "remote") return employment == null ? void 0 : employment.personal_email;
      if (appStub === "deel") return employment == null ? void 0 : employment.primaryEmail;
      if (appStub === "oysterhr") return employment == null ? void 0 : employment.primaryEmail;
      return "";
    },
    [appStub]
  );
  const getStartDateForEmployment = useCallback(
    (employment) => {
      if (appStub === "remote") return employment == null ? void 0 : employment.provisional_start_date;
      if (appStub === "deel") return employment == null ? void 0 : employment.startDate;
      if (appStub === "oysterhr") return employment == null ? void 0 : employment.startDate;
      return void 0;
    },
    [appStub]
  );
  const getEmploymentType = useCallback(
    (employment) => {
      if (appStub === "remote") return toTitleCase(employment == null ? void 0 : employment.type);
      if (appStub === "deel") return (employment == null ? void 0 : employment.userType).toUpperCase();
      if (appStub === "oysterhr") return employment == null ? void 0 : employment.userType;
      return void 0;
    },
    [appStub]
  );
  const employmentColumn = useMemo(
    () => [
      {
        header: () => "Account Name",
        id: "fullName",
        accessorFn: (row) => getDisplayNameForEmployment(row),
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              display: "flex",
              alignItems: "center",
              gap: spacing.m5,
              color: themeColors.DarkGrey
            }),
            children: getDisplayNameForEmployment(original)
          }
        )
      },
      ...appStub === "remote" ? [
        {
          header: () => "Country",
          id: "country",
          accessorFn: (row) => {
            var _a;
            return (_a = row.original.country) == null ? void 0 : _a.name;
          },
          enableSorting: false,
          cell: ({ row: { original } }) => {
            var _a;
            return /* @__PURE__ */ jsx(Box, { children: appStub === "remote" && /* @__PURE__ */ jsx(
              Typography,
              {
                sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                  display: "flex",
                  alignItems: "center",
                  gap: spacing.m15,
                  color: themeColors.DarkGrey
                }),
                children: ((_a = original == null ? void 0 : original.country) == null ? void 0 : _a.name) || "N/A"
              }
            ) });
          }
        }
      ] : [],
      {
        header: () => "Type",
        id: "type",
        accessorFn: (row) => getEmploymentType(row),
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                display: "flex",
                alignItems: "center",
                gap: spacing.m15,
                color: themeColors.DarkGrey
              }),
              children: (_a = getEmploymentType(original)) != null ? _a : "N/A"
            }
          ) });
        }
      },
      {
        header: () => `${app == null ? void 0 : app.name} Status`,
        id: "status",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              display: "flex",
              alignItems: "center",
              gap: spacing.m15,
              color: themeColors.DarkGrey
            }),
            children: (original == null ? void 0 : original.status) ? original.status : "N/A"
          }
        ) })
      },
      {
        header: () => "",
        id: "action",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
          AppDetailsActionButton,
          {
            setIsAppActionsDrawerOpen,
            setSelectedActiveUserLogin,
            setGroupManagementDrawerOpen: () => {
            },
            setGroupManagementDrawerMode: () => {
            },
            refresh: onAppChange,
            appStub,
            alreadyImported: teamEmailList.includes(getEmailForEmployment(original)),
            employment: original,
            delayedActions: delayedActions != null ? delayedActions : { delayed: [] },
            refreshDelayedActions,
            table: "employments",
            hasAppsAllOrAppOwner,
            setPreselectedUser,
            setEmployeeForOnboarding,
            setActionsDrawerMode,
            error,
            setOnboardUserDrawerOpen
          }
        )
      }
    ],
    [
      appStub,
      getDisplayNameForEmployment,
      getEmploymentType,
      app == null ? void 0 : app.name,
      onAppChange,
      teamEmailList,
      getEmailForEmployment,
      delayedActions,
      refreshDelayedActions,
      hasAppsAllOrAppOwner,
      error
    ]
  );
  const teamListWithoutExistingEmployments = useMemo(() => {
    const employmentEmailList = employmentList.map((b) => getEmailForEmployment(b));
    return teamAccessUserList.filter(
      (teamUser) => {
        var _a, _b;
        return teamUser && (teamUser == null ? void 0 : teamUser.emails) && ((_a = teamUser == null ? void 0 : teamUser.emails[0]) == null ? void 0 : _a.email) && !employmentEmailList.includes((_b = teamUser == null ? void 0 : teamUser.emails[0]) == null ? void 0 : _b.email);
      }
    );
  }, [employmentList, getEmailForEmployment, teamAccessUserList]);
  const mapEmploymentToUserForOnboarding = (user) => {
    var _a, _b, _c, _d, _e;
    const nameParts = (_b = (_a = user == null ? void 0 : user.displayName) == null ? void 0 : _a.split(" ")) == null ? void 0 : _b.filter(Boolean);
    if (!nameParts || (nameParts == null ? void 0 : nameParts.length) === 0) {
      showMessage(`Unable to import user without name details. Please update candidate and try again`, "error");
    }
    return {
      userId: 0,
      firstName: nameParts ? nameParts[0] : "",
      lastName: nameParts ? nameParts[1] : "",
      emailAddress: "",
      personalEmail: (_c = getEmailForEmployment(user)) != null ? _c : "",
      jobTitle: (_d = getTitleForEmployment(user)) != null ? _d : "",
      phone: "",
      startDate: (_e = getStartDateForEmployment(user)) != null ? _e : void 0,
      templateId: candidateOnboardingTemplate && (candidateOnboardingTemplate == null ? void 0 : candidateOnboardingTemplate.selected) ? candidateOnboardingTemplate.selected : void 0
    };
  };
  const getInitialValuesForOnboarding = () => {
    if (APPS_SUPPORTING_EMPLOYMENT_MANAGEMENT.includes(appStub) && employeeForOnboarding)
      return mapEmploymentToUserForOnboarding(employeeForOnboarding);
    return { userId: 0, personalEmail: null };
  };
  const getExternalIdForOnboarding = () => {
    if (APPS_SUPPORTING_EMPLOYMENT_MANAGEMENT.includes(appStub) && employeeForOnboarding)
      return (employeeForOnboarding == null ? void 0 : employeeForOnboarding.id) ? String(employeeForOnboarding == null ? void 0 : employeeForOnboarding.id) : void 0;
    return void 0;
  };
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(AppNameHeader, { title: polyglot.t("AppEmploymentManagementPage.title"), app }) }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: loading != null ? loading : false, children: [
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "left"
          },
          children: !error && /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(
              TabFilterButtons,
              {
                filters: EmploymentsTabFilter,
                setFilterValue: setFilterString,
                filterValue: filterString,
                hasSearch: true,
                onFilterChange: ({ filterValue, searchInput: searchInput2 }) => {
                  setFilterString(filterValue);
                  setSearchInput(searchInput2);
                }
              }
            ),
            hasAppsAllOrAppOwner && /* @__PURE__ */ jsxs(Box, { sx: { marginLeft: "auto" }, children: [
              /* @__PURE__ */ jsx(
                Button,
                {
                  sx: __spreadValues({}, secondarySmallBtn),
                  startIcon: /* @__PURE__ */ jsx(Reload, __spreadValues({}, iconSize)),
                  onClick: () => onAppChange(appStub),
                  children: "Refresh"
                }
              ),
              (app == null ? void 0 : app.allowsEmploymentCreation) && /* @__PURE__ */ jsx(
                Button,
                {
                  sx: __spreadProps(__spreadValues({}, primarySmallBtn), { ml: spacing.m10 }),
                  variant: "contained",
                  onClick: () => createEmployment(),
                  children: "Create employment"
                }
              )
            ] })
          ] })
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: spacing.mt20, children: [
        /* @__PURE__ */ jsx(AppDetailsTable, { column: employmentColumn, row: filteredEmploymentList != null ? filteredEmploymentList : [], loading: loading != null ? loading : false }),
        error && !(app == null ? void 0 : app.authorised) && /* @__PURE__ */ jsx(
          EmptyStateComponent,
          {
            header: "Authorize this app",
            subheader: "Zelt allows you to manage your whole toolstack from one place.",
            detail: "To start using your app connect your account in Settings first.",
            cover
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        AppDetailConfigurationModal,
        {
          open: showModal,
          handleClose: () => setShowModal(false),
          app,
          appStub,
          refresh: () => onAppChange(appStub)
        }
      ),
      /* @__PURE__ */ jsx(
        OnboardNewEmployee,
        {
          isOpen: onboardUserDrawerOpen,
          setIsOpen: setOnboardUserDrawerOpen,
          initialValues: getInitialValuesForOnboarding(),
          onDraftUserCreated: () => setOnboardUserDrawerOpen(false),
          mode: "onboard",
          appStub,
          externalId: getExternalIdForOnboarding()
        }
      ),
      /* @__PURE__ */ jsx(
        AppActionsDrawer,
        {
          appStub,
          countriesForEmployment,
          usersWithoutAccess: teamListWithoutExistingEmployments,
          usersWithAccess: employmentList,
          isOpen: isAppActionsDrawerOpen,
          onClose: () => setIsAppActionsDrawerOpen(false),
          refreshApp: () => onAppChange(appStub),
          refreshDelayedActions,
          setIsAppActionsDrawerOpen,
          preselectedUser,
          mode: actionsDrawerMode,
          selectedActiveUserLogin,
          setSelectedActiveUserLogin,
          directoryMode: "employments"
        }
      )
    ] })
  ] });
};
