"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box } from "@mui/material";
import { DeviceTransitDrawer } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/device-transit-action-item.component";
import { ChangeOwnerDrawer } from "@v2/feature/device/components/device-details/change-owner-drawer.component";
import { DeleteDeviceDrawer } from "@v2/feature/device/components/device-details/delete-device-drawer.component";
import { DisenrollDeviceDrawer } from "@v2/feature/device/components/device-details/disenroll-device-drawer.component";
import { ReassignDeviceDrawer } from "@v2/feature/device/components/device-details/reassign-device-drawer.component";
import { DeviceInventoryReassignDrawer } from "@v2/feature/device/components/devices-list-overview/device-inventory-reassign-drawer.component";
import { DeviceAPI, DeviceEndpoints } from "@v2/feature/device/device.api";
import { DeviceOrderStatus, DeviceOwnership, DevicePossessionType } from "@v2/feature/device/device.interface";
import { isEnroled } from "@v2/feature/device/device.util";
import { DeviceCredentialsDrawer } from "@v2/feature/device/features/activation-modal/components/device-credentials-drawer.component";
import { DeviceOrderDrawer } from "@v2/feature/device/features/devices-company/components/device-order-drawer.component";
import { eventSourceErrorHandler } from "@v2/infrastructure/push-events/push-events.util";
import { iconSize } from "@v2/styles/table.styles";
import { keyBy } from "lodash";
import { generatePath, useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { useEventSource } from "@/hooks/event-source-hook";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as BackBtn } from "@/images/side-bar-icons/BackBtn.svg";
import { ReactComponent as Close } from "@/images/side-bar-icons/Close.svg";
import { ReactComponent as EnvelopeSimple } from "@/images/side-bar-icons/EnvelopeSimple.svg";
import { ReactComponent as Lock } from "@/images/side-bar-icons/Lock.svg";
import { ReactComponent as NextBtn } from "@/images/side-bar-icons/NextBtn.svg";
import { ReactComponent as OrderBag } from "@/images/side-bar-icons/OrderBag.svg";
import { ReactComponent as Reload } from "@/images/side-bar-icons/Reload.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DEVICES_COMPANY_DIRECTORY_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { LockDeviceDrawer } from "@/v2/feature/device/components/device-details/lock-device-drawer.component";
import { OrderActionsDrawer } from "@/v2/feature/device/components/device-details/order-actions-drawer.component";
import { WipeDeviceDrawer } from "@/v2/feature/device/components/device-details/wipe-device-drawer.component";
import { EnrollmentStatus } from "@/v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DeviceActionsButton = ({
  configurableDevice,
  fetchDeviceDetails,
  activeTransit,
  refreshDevicesAppData,
  assignedUser
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m;
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);
  const [isChangeOwnerDrawerOpen, setIsChangeOwnerDrawerOpen] = useState(false);
  const [isReassignDrawerOpen, setIsReassignDrawerOpen] = useState(false);
  const [isMoveToInventoryDrawerOpen, setIsMoveToInventoryDrawerOpen] = useState(false);
  const [isAssignOwnerDrawerOpen, setIsAssignOwnerDrawerOpen] = useState(false);
  const [isLockDeviceDrawerOpen, setIsLockDeviceDrawerOpen] = useState(false);
  const [isWipeDeviceDrawerOpen, setIsWipeDeviceDrawerOpen] = useState(false);
  const [isDisenrollDeviceDrawerOpen, setIsDisenrollDeviceDrawerOpen] = useState(false);
  const [isOrderActionsDrawerOpen, setIsOrderActionsDrawerOpen] = useState(false);
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState(false);
  const { getScopesContext } = useScopes();
  const { devicePossession, order } = configurableDevice;
  const [isTransitDrawerOpen, setIsTransitDrawerOpen] = useState(false);
  const [selectedDeviceTransit] = useState(activeTransit);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDeviceOrder] = useState(configurableDevice.order);
  const context = (devicePossession == null ? void 0 : devicePossession.possessionType) === DevicePossessionType.User ? getScopesContext({ userId: devicePossession == null ? void 0 : devicePossession.possessionId }) : void 0;
  const manageOrder = (_a = assignedUser && (configurableDevice == null ? void 0 : configurableDevice.devicePossession) && order && order.status !== DeviceOrderStatus.Delivered && !activeTransit) != null ? _a : false;
  const routerHistory = useHistory();
  const companyId = globalState.user.company.companyId;
  const isProduction = process.env.REACT_APP_ENV === "production";
  const environmentPrefix = `${isProduction ? "prod" : "test"}`;
  const channelName = `device-actions-${environmentPrefix}-company_id-${companyId}`;
  const reassignDeviceFinishedDataHandler = (data) => __async(void 0, null, function* () {
    if (data.message && data.companyId === companyId) {
      routerHistory.push(generatePath(DEVICES_COMPANY_DIRECTORY_ROUTE));
      yield Promise.all([fetchDeviceDetails(), refreshDevicesAppData()]);
      return;
    }
  });
  useEventSource((_b = DeviceEndpoints.getEventSourceUrlReassignAction(channelName)) == null ? void 0 : _b.url, {
    onMessage: reassignDeviceFinishedDataHandler,
    onError: eventSourceErrorHandler
  });
  const deviceNotMDMActive = !(((_c = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _c.enrollmentStatus) === "enrolled" || ((_d = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _d.enrollmentStatus) === EnrollmentStatus.ENROLMENT_FINISHED);
  const mobileDevices = ["ipados", "ios"];
  const isMobileAppleDevice = mobileDevices.includes((_f = (_e = devicePossession.device) == null ? void 0 : _e.os) != null ? _f : "");
  const isPossessionActive = !!devicePossession.startDate && !devicePossession.endDate;
  const headerOptions = [
    {
      title: "Device",
      options: [
        {
          icon: /* @__PURE__ */ jsx(Reload, __spreadValues({}, iconSize)),
          handler: () => {
            setIsReassignDrawerOpen(true);
          },
          label: "Reassign",
          disabled: false,
          hidden: !checkScopes(globalState.user, ["devices:all"], context) || !isPossessionActive || !!activeTransit
        },
        {
          icon: /* @__PURE__ */ jsx(BackBtn, __spreadValues({}, iconSize)),
          handler: () => {
            setIsMoveToInventoryDrawerOpen(true);
          },
          label: "Move to inventory",
          disabled: false,
          hidden: !checkScopes(globalState.user, ["devices:all"], context) || !isPossessionActive || !!activeTransit
        },
        {
          label: "Order actions",
          handler: () => {
            setIsOrderActionsDrawerOpen(true);
          },
          icon: /* @__PURE__ */ jsx(OrderBag, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey })),
          disabled: false,
          hidden: !checkScopes(globalState.user, ["devices"], context) || !isPossessionActive || !!activeTransit || ((_g = devicePossession.device) == null ? void 0 : _g.ownership) === DeviceOwnership.Company || devicePossession.possessionType === DevicePossessionType.ZeltStorage
        },
        {
          label: "Delete",
          handler: () => setIsDeleteDrawerOpen(true),
          icon: /* @__PURE__ */ jsx(Trash, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey })),
          hidden: !checkScopes(globalState.user, ["devices:all"], context) || !isPossessionActive || !!activeTransit || ((_h = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _h.ownership) !== DeviceOwnership.Company || devicePossession.possessionType === DevicePossessionType.ZeltStorage
        }
      ]
    },
    {
      title: "Security",
      hidden: !isPossessionActive,
      options: [
        {
          icon: /* @__PURE__ */ jsx(EnvelopeSimple, __spreadValues({}, iconSize)),
          handler: () => __async(void 0, null, function* () {
            try {
              if (devicePossession && devicePossession.id) yield DeviceAPI.sendEnrollmentRequest(devicePossession.id);
              showMessage("Notification was successfully sent.", "success");
            } catch (error) {
              showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
            }
          }),
          label: "Ask to enrol",
          hidden: Boolean(
            devicePossession.possessionType !== DevicePossessionType.User || isEnroled((_i = devicePossession.device) == null ? void 0 : _i.enrollmentStatus) || !checkScopes(globalState.user, ["devices:all"], context)
          ),
          disabled: Boolean(
            devicePossession.possessionType !== DevicePossessionType.User || isEnroled((_j = devicePossession.device) == null ? void 0 : _j.enrollmentStatus)
          )
        },
        {
          label: "Lock device",
          handler: () => {
            setIsLockDeviceDrawerOpen(true);
          },
          icon: /* @__PURE__ */ jsx(Lock, __spreadValues({}, iconSize)),
          hidden: !checkScopes(globalState.user, ["devices"], context) || !isPossessionActive || deviceNotMDMActive
        },
        {
          label: "Wipe device",
          handler: () => {
            setIsWipeDeviceDrawerOpen(true);
          },
          icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
          hidden: !checkScopes(globalState.user, ["devices:all"], context) || !isPossessionActive || deviceNotMDMActive
        },
        {
          label: "Disenrol device",
          handler: () => {
            setIsDisenrollDeviceDrawerOpen(true);
          },
          icon: /* @__PURE__ */ jsx(Close, __spreadValues({}, iconSize)),
          hidden: !checkScopes(globalState.user, ["devices:all"], context) || !isPossessionActive || deviceNotMDMActive || ((_k = devicePossession.device) == null ? void 0 : _k.enrolmentType) !== "OPEN_ENROLMENT"
        }
      ]
    },
    {
      title: "Shipping",
      hidden: !activeTransit,
      options: [
        {
          label: "Manage shipping",
          handler: () => {
            setIsTransitDrawerOpen(true);
          },
          icon: /* @__PURE__ */ jsx(NextBtn, __spreadValues({}, iconSize)),
          hidden: !checkScopes(globalState.user, ["devices"], context) || !activeTransit
        }
      ]
    },
    {
      title: "Order",
      hidden: !manageOrder,
      options: [
        {
          label: "Manage order",
          handler: () => {
            setIsDrawerOpen(true);
          },
          icon: /* @__PURE__ */ jsx(NextBtn, __spreadValues({}, iconSize)),
          hidden: !checkScopes(globalState.user, ["devices"], context) || !manageOrder
        }
      ]
    }
  ];
  const allOptionsHidden = headerOptions == null ? void 0 : headerOptions.every(
    (option) => {
      var _a2, _b2;
      return (_b2 = (_a2 = option.options) == null ? void 0 : _a2.every((subOption) => subOption.hidden)) != null ? _b2 : true;
    }
  );
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width: "100%" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", alignItems: "center", gap: spacing.g10, width: "100%" }, children: [
      !deviceNotMDMActive && isPossessionActive && devicePossession.possessionType !== DevicePossessionType.ZeltStorage && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          colorVariant: "primary",
          sizeVariant: "small",
          disabled: false,
          fullWidth: true,
          onClick: () => __async(void 0, null, function* () {
            try {
              if (devicePossession && devicePossession.id) yield DeviceAPI.scanDevice(devicePossession.id);
              showMessage("Action launched successfully", "success");
            } catch (error) {
              showMessage("The launch of the action failed", "error");
            }
          }),
          children: "Sync"
        }
      ),
      headerOptions && !allOptionsHidden && /* @__PURE__ */ jsx(
        StyledMenuComponent,
        {
          headerOptions,
          actionButtonDetails: {
            type: "button",
            colorVariant: "secondary",
            sizeVariant: "small",
            title: "Actions",
            icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
            iconPosition: "end",
            fullWidth: true
          },
          sx: { width: "100%" }
        }
      )
    ] }),
    isActivationModalOpen && (devicePossession == null ? void 0 : devicePossession.possessionType) === DevicePossessionType.User && /* @__PURE__ */ jsx(
      DeviceCredentialsDrawer,
      {
        isOpen: isActivationModalOpen,
        setIsOpen: setIsActivationModalOpen,
        devicePossession
      }
    ),
    /* @__PURE__ */ jsx(
      ChangeOwnerDrawer,
      {
        devicePossession,
        isOpen: isChangeOwnerDrawerOpen,
        setIsOpen: setIsChangeOwnerDrawerOpen,
        onClose: () => __async(void 0, null, function* () {
          yield Promise.all([fetchDeviceDetails(), refreshDevicesAppData()]);
          setIsChangeOwnerDrawerOpen(false);
        })
      }
    ),
    /* @__PURE__ */ jsx(
      ReassignDeviceDrawer,
      {
        devicePossession,
        isOpen: isReassignDrawerOpen,
        setIsOpen: setIsReassignDrawerOpen,
        onClose: () => __async(void 0, null, function* () {
          setIsReassignDrawerOpen(false);
        }),
        isInventory: false
      }
    ),
    /* @__PURE__ */ jsx(
      ReassignDeviceDrawer,
      {
        devicePossession,
        isOpen: isMoveToInventoryDrawerOpen,
        setIsOpen: setIsMoveToInventoryDrawerOpen,
        onClose: () => __async(void 0, null, function* () {
          setIsMoveToInventoryDrawerOpen(false);
        }),
        isInventory: true
      }
    ),
    /* @__PURE__ */ jsx(
      LockDeviceDrawer,
      {
        devicePossession,
        isOpen: isLockDeviceDrawerOpen,
        setIsOpen: setIsLockDeviceDrawerOpen,
        onClose: () => __async(void 0, null, function* () {
          yield Promise.all([fetchDeviceDetails(), refreshDevicesAppData()]);
          setIsLockDeviceDrawerOpen(false);
        }),
        isMobileAppleDevice
      }
    ),
    /* @__PURE__ */ jsx(
      OrderActionsDrawer,
      {
        devicePossession,
        isOpen: isOrderActionsDrawerOpen,
        setIsOpen: setIsOrderActionsDrawerOpen,
        onClose: () => __async(void 0, null, function* () {
          yield Promise.all([fetchDeviceDetails(), refreshDevicesAppData()]);
          setIsOrderActionsDrawerOpen(false);
        })
      }
    ),
    /* @__PURE__ */ jsx(
      WipeDeviceDrawer,
      {
        devicePossession,
        isOpen: isWipeDeviceDrawerOpen,
        setIsOpen: setIsWipeDeviceDrawerOpen,
        onClose: () => __async(void 0, null, function* () {
          yield Promise.all([fetchDeviceDetails(), refreshDevicesAppData()]);
          setIsWipeDeviceDrawerOpen(false);
        })
      }
    ),
    /* @__PURE__ */ jsx(
      DisenrollDeviceDrawer,
      {
        devicePossession,
        isOpen: isDisenrollDeviceDrawerOpen,
        setIsOpen: setIsDisenrollDeviceDrawerOpen,
        onClose: () => __async(void 0, null, function* () {
          yield Promise.all([fetchDeviceDetails(), refreshDevicesAppData()]);
          setIsDisenrollDeviceDrawerOpen(false);
        })
      }
    ),
    /* @__PURE__ */ jsx(
      DeviceInventoryReassignDrawer,
      {
        isOpen: isAssignOwnerDrawerOpen,
        setIsOpen: setIsAssignOwnerDrawerOpen,
        onClose: () => __async(void 0, null, function* () {
          setIsChangeOwnerDrawerOpen(false);
        }),
        devicePossession
      }
    ),
    /* @__PURE__ */ jsx(
      DeleteDeviceDrawer,
      {
        isOpen: isDeleteDrawerOpen,
        setIsOpen: setIsDeleteDrawerOpen,
        devicePossession,
        onDelete: () => __async(void 0, null, function* () {
        })
      }
    ),
    selectedDeviceTransit && /* @__PURE__ */ jsx(
      DeviceTransitDrawer,
      {
        deviceTransitId: selectedDeviceTransit.id,
        refresh: () => __async(void 0, null, function* () {
          routerHistory.push(generatePath(DEVICES_COMPANY_DIRECTORY_ROUTE));
        }),
        afterClose: () => setIsTransitDrawerOpen(false),
        isOpen: isTransitDrawerOpen,
        setIsOpen: setIsTransitDrawerOpen,
        sites: (_l = configurableDevice.sites) != null ? _l : []
      }
    ),
    selectedDeviceOrder && /* @__PURE__ */ jsx(
      DeviceOrderDrawer,
      {
        deviceOrder: selectedDeviceOrder,
        refresh: () => __async(void 0, null, function* () {
          setIsDrawerOpen(false);
          yield refreshDevicesAppData();
        }),
        sitesById: keyBy((_m = configurableDevice.sites) != null ? _m : [], "id"),
        reach: "company",
        setIsOpen: setIsDrawerOpen,
        isOpen: isDrawerOpen,
        onClose: () => {
          setIsDrawerOpen(false);
        }
      }
    )
  ] });
};
