"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { FieldCard } from "@v2/components/field-card.component";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { BenefitPaymentsDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/components/benefit-payments-drawer.component";
import { RequestOnDemandBenefitDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/components/request-on-demand-benefit-drawer.component";
import { CustomBenefitEndpoints } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import {
  CustomBenefitPaymentMethodType,
  UserCustomBenefitPaymentStatus,
  UserCustomBenefitRequestType
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import {
  calculatePaidAmount,
  isAllowanceBenefit
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { BenefitOptOutDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/components/benefit-opt-out-drawer.component";
import { UserBenefitOverviewTitle } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/components/user-benefit-overview-title.component";
import {
  UserCustomBenefitRequestStatus
} from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto";
import { UserCustomBenefitListTableComponent } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit-list-table.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
import { round2Digits } from "@v2/util/number.util";
import { generatePath, matchPath, useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import {
  BENEFITS_ME_ROUTE,
  CUSTOM_BENEFIT_EXPENSE_ROUTE,
  USER_BENEFITS_CUSTOM_BENEFIT_EXPENSE_ROUTE,
  USER_BENEFITS_OVERVIEW_ROUTE,
  USER_BENEFITS_ROUTE
} from "@/lib/routes";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { RootStyle } from "@/v2/styles/root.styles";
export const AllowanceBenefitOverview = ({
  userId,
  userCustomBenefit,
  refresh,
  loading
}) => {
  var _a, _b, _c, _d, _e, _f;
  const {
    data: userBenefitsRequests,
    mutate: refreshReq
  } = useApiClient(
    CustomBenefitEndpoints.getUserCustomBenefitRequestsByBenefitId(
      userId,
      userCustomBenefit.customBenefitId,
      userCustomBenefit.id
    ),
    { suspense: false }
  );
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const [showMessage] = useMessage();
  const { hasScopes } = useScopes();
  const [isOpen, setIsOpen] = useState(false);
  const [isPaymentsDrawerOpen, setIsPaymentsDrawerOpen] = useState(false);
  const [selectedPaymentsDrawerType, setSelectedPaymentsDrawerType] = useState("used");
  const [isOptOutOpen, setIsOptOutOpen] = useState(false);
  const location = useLocation();
  const routerHistory = useHistory();
  const isMePage = location.pathname.includes("/me/");
  const isCurrentUser = userId === state.user.userId;
  const used = useMemo(() => {
    var _a2, _b2, _c2, _d2, _e2;
    if (!userCustomBenefit.userCustomBenefitPayments) return (_a2 = userCustomBenefit.openingBalance) != null ? _a2 : 0;
    return calculatePaidAmount(
      userCustomBenefit.userCustomBenefitPayments,
      (_b2 = userCustomBenefit.customBenefit) == null ? void 0 : _b2.type,
      (_c2 = userCustomBenefit.customBenefit) == null ? void 0 : _c2.allowanceType,
      new LocalDate().toDateString(),
      UserCustomBenefitPaymentStatus.Approved,
      (_d2 = userCustomBenefit.customBenefit) == null ? void 0 : _d2.cycleStartDay,
      (_e2 = userCustomBenefit.customBenefit) == null ? void 0 : _e2.cycleStartMonth
    );
  }, [userCustomBenefit]);
  const requested = useMemo(() => {
    if (!userCustomBenefit.userCustomBenefitPayments) return 0;
    return userCustomBenefit.userCustomBenefitPayments.reduce((total, payment) => {
      if (payment.status === UserCustomBenefitPaymentStatus.Pending) return total + payment.amount;
      return total;
    }, 0);
  }, [userCustomBenefit]);
  const remainingBalance = useMemo(() => {
    if (!userCustomBenefit.openingBalance) return 0;
    if (!userCustomBenefit.userCustomBenefitPayments) return 0;
    return round2Digits(userCustomBenefit.openingBalance - used - requested);
  }, [userCustomBenefit, used, requested]);
  const hasNoPendingOptOutRequest = useMemo(() => {
    if (!userBenefitsRequests) return void 0;
    return userBenefitsRequests.filter(
      (req) => req.type === UserCustomBenefitRequestType.OptOut && req.status === UserCustomBenefitRequestStatus.Pending
    ).length === 0;
  }, [userBenefitsRequests]);
  const refreshRequests = useCallback(() => __async(void 0, null, function* () {
    if (refreshReq) yield refreshReq();
  }), [refreshReq]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(UserBenefitOverviewTitle, { userCustomBenefit }),
        showBack: true,
        backPath: matchPath(location.pathname, USER_BENEFITS_ROUTE) ? generatePath(USER_BENEFITS_OVERVIEW_ROUTE, { userId }) : BENEFITS_ME_ROUTE,
        actions: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "5px" }, children: [
          hasNoPendingOptOutRequest && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              onClick: () => {
                setIsOptOutOpen(true);
              },
              children: polyglot.t("BenefitModule.optOut")
            }
          ),
          remainingBalance > 0 && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              colorVariant: "primary",
              sizeVariant: "small",
              onClick: () => {
                var _a2, _b2, _c2;
                if (((_a2 = userCustomBenefit.customBenefit) == null ? void 0 : _a2.paymentMethod) === CustomBenefitPaymentMethodType.Expenses && isMePage && isCurrentUser)
                  routerHistory.push(
                    generatePath(CUSTOM_BENEFIT_EXPENSE_ROUTE, { id: userCustomBenefit.customBenefitId })
                  );
                else if (((_b2 = userCustomBenefit.customBenefit) == null ? void 0 : _b2.paymentMethod) === CustomBenefitPaymentMethodType.Expenses && hasScopes(["insurance:all"], { userId }))
                  routerHistory.push(
                    generatePath(USER_BENEFITS_CUSTOM_BENEFIT_EXPENSE_ROUTE, {
                      userId,
                      id: userCustomBenefit.customBenefitId
                    })
                  );
                else if (((_c2 = userCustomBenefit.customBenefit) == null ? void 0 : _c2.paymentMethod) !== CustomBenefitPaymentMethodType.Expenses)
                  setIsOpen(true);
                else showMessage("This case is not implemented", "error");
              },
              children: polyglot.t("BenefitModule.newRequest")
            }
          )
        ] }),
        showAction: hasScopes(["insurance"], { userId }) && ((_a = userCustomBenefit == null ? void 0 : userCustomBenefit.customBenefit) == null ? void 0 : _a.type) && isAllowanceBenefit(userCustomBenefit.customBenefit.type)
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: { mt: "20px" }, children: [
      !hasNoPendingOptOutRequest && /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { mb: "20px" }, children: polyglot.t("BenefitModule.optOutRequested") }),
      /* @__PURE__ */ jsxs(Box, { sx: { width: "600px", display: "flex", gap: "5px", alignItems: "center", mb: "30px" }, children: [
        /* @__PURE__ */ jsx(
          FieldCard,
          {
            name: polyglot.t("BenefitModule.allowance"),
            value: formatMoney({
              amount: (_b = userCustomBenefit.openingBalance) != null ? _b : 0,
              asDecimal: true
            }),
            action: void 0,
            color: "secondary"
          }
        ),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "\u2013" }),
        /* @__PURE__ */ jsx(
          FieldCard,
          {
            name: polyglot.t("BenefitModule.used"),
            value: formatMoney({ amount: used, asDecimal: true }),
            color: "warning",
            action: used ? () => {
              setSelectedPaymentsDrawerType("used");
              setIsPaymentsDrawerOpen(true);
            } : void 0
          }
        ),
        requested !== 0 && /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "\u2013" }),
        requested !== 0 && /* @__PURE__ */ jsx(
          FieldCard,
          {
            name: polyglot.t("BenefitModule.requested"),
            value: formatMoney({ amount: requested, asDecimal: true }),
            action: requested ? () => {
              setSelectedPaymentsDrawerType("requested");
              setIsPaymentsDrawerOpen(true);
            } : void 0,
            color: "warning"
          }
        ),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "=" }),
        /* @__PURE__ */ jsx(
          FieldCard,
          {
            name: polyglot.t("BenefitModule.remaining"),
            value: formatMoney({ amount: remainingBalance, asDecimal: true }),
            action: void 0,
            color: "success"
          }
        )
      ] }),
      isOpen && /* @__PURE__ */ jsx(
        RequestOnDemandBenefitDrawer,
        {
          isOpen,
          setIsOpen,
          userCustomBenefit,
          remainingBalance,
          refresh
        }
      ),
      isPaymentsDrawerOpen && userCustomBenefit.userCustomBenefitPayments && /* @__PURE__ */ jsx(
        BenefitPaymentsDrawer,
        {
          isOpen: isPaymentsDrawerOpen,
          setIsOpen: setIsPaymentsDrawerOpen,
          type: selectedPaymentsDrawerType,
          userCustomBenefitPayments: userCustomBenefit.userCustomBenefitPayments,
          benefitType: (_c = userCustomBenefit.customBenefit) == null ? void 0 : _c.type,
          allowanceType: (_d = userCustomBenefit.customBenefit) == null ? void 0 : _d.allowanceType,
          cycleStartDay: (_e = userCustomBenefit.customBenefit) == null ? void 0 : _e.cycleStartDay,
          cycleStartMonth: (_f = userCustomBenefit.customBenefit) == null ? void 0 : _f.cycleStartMonth
        }
      ),
      /* @__PURE__ */ jsx(
        UserCustomBenefitListTableComponent,
        {
          benefitId: userCustomBenefit.customBenefitId,
          userBenefit: userCustomBenefit,
          userId
        }
      ),
      /* @__PURE__ */ jsx(
        BenefitOptOutDrawer,
        {
          isOpen: isOptOutOpen,
          setIsOpen: setIsOptOutOpen,
          userId,
          userBenefit: userCustomBenefit,
          refresh: refreshRequests
        }
      )
    ] })
  ] });
};
