"use strict";
import { jsx } from "react/jsx-runtime";
import { NumberCell } from "@v2/components/table/number-cell.component";
import { sortNumeric } from "@v2/components/table/table-sorting.util";
import {
  CustomBenefitAllowanceType,
  CustomBenefitType,
  UserCustomBenefitPaymentStatus
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { CurrencyCell } from "@/v2/components/table/currency-cell.component";
import { LocalDate } from "@/v2/util/local-date";
export const isUsingOpeningBalance = (type) => !!type && [CustomBenefitType.Allowance, CustomBenefitType.Loan].includes(type);
export const isAllowanceBenefit = (type) => type === CustomBenefitType.Allowance;
export const isLoanBenefit = (type) => type === CustomBenefitType.Loan;
export const isRecurringBenefit = (type) => type === CustomBenefitType.Recurring;
export const isYearlyAllowanceBenefit = (allowanceType) => allowanceType === CustomBenefitAllowanceType.Yearly;
export const getYearlyAllowancePeriod = (customBenefit) => {
  const { cycleStartDay, cycleStartMonth } = customBenefit;
  if (!(cycleStartDay && cycleStartMonth)) {
    return "";
  }
  const startDate = new Date((/* @__PURE__ */ new Date()).getFullYear(), cycleStartMonth - 1, cycleStartDay);
  const endDate = new Date(startDate);
  endDate.setFullYear(endDate.getFullYear() + 1);
  endDate.setDate(endDate.getDate() - 1);
  const startDateString = startDate.toLocaleDateString(void 0, { month: "long", day: "numeric" });
  const endDateString = endDate.toLocaleDateString(void 0, { month: "long", day: "numeric" });
  return `${startDateString} - ${endDateString}`;
};
const getNextYearlyCyleDate = (cycleStartDay, cycleStartMonth) => {
  const today = /* @__PURE__ */ new Date();
  const nextRenewalDate = new Date(today.getFullYear(), cycleStartMonth - 1, cycleStartDay);
  if (nextRenewalDate <= today) {
    nextRenewalDate.setFullYear(nextRenewalDate.getFullYear() + 1);
  }
  return nextRenewalDate;
};
export const getYearlyAllowanceNextRenewal = (customBenefit, format) => {
  const { cycleStartDay, cycleStartMonth } = customBenefit;
  if (!(cycleStartDay && cycleStartMonth)) {
    return "";
  }
  const nextCycleDate = getNextYearlyCyleDate(cycleStartDay, cycleStartMonth);
  const localDate = new LocalDate(nextCycleDate);
  return format === "dayMonth" ? localDate.toDayMonthString() : localDate.toDateString();
};
export const calculatePaidAmount = (benefitPayments, benefitType, allowanceType, date, status = UserCustomBenefitPaymentStatus.Approved, cycleStartDay, cycleStartMonth) => {
  const isAllowance = isAllowanceBenefit(benefitType);
  let start = null;
  let end = null;
  if (isAllowance && allowanceType === CustomBenefitAllowanceType.Yearly) {
    if (cycleStartDay && cycleStartMonth) {
      const yearPeriod = getYearlyAllowancePeriodDate(cycleStartDay, cycleStartMonth);
      start = yearPeriod.cycleStart.toDateString();
      end = yearPeriod.cycleEnd.toDateString();
    } else {
      start = new LocalDate(date).getFirstDayOfYear().toDateString();
      end = new LocalDate(date).getLastDayOfYear().toDateString();
    }
  } else if (isAllowance && allowanceType === CustomBenefitAllowanceType.Monthly) {
    start = new LocalDate(date).getFirstDayOfMonth().toDateString();
    end = new LocalDate(date).getLastDayOfMonth().toDateString();
  }
  return benefitPayments.filter((p) => {
    if (start && end) return p.status === status && p.date >= start && p.date <= end;
    return p.status === status;
  }).reduce((sum, p) => sum + p.amount, 0);
};
export const getOpeningBalanceColumns = (isLoan, polyglot) => [
  {
    header: () => isLoan ? polyglot.t("BenefitModule.borrowed") : polyglot.t("BenefitModule.allowance"),
    accessorFn: (row) => row,
    id: "openingBalance",
    enableSorting: true,
    sortingFn: (a, b) => sortNumeric(a, b, (item) => {
      var _a;
      return (_a = item.openingBalance) != null ? _a : 0;
    }),
    cell: (info) => {
      const userBenefit = info.getValue();
      return /* @__PURE__ */ jsx(CurrencyCell, { value: userBenefit == null ? void 0 : userBenefit.openingBalance, formatOptions: { noCurrencySymbol: true } });
    }
  },
  ...isLoan ? [
    {
      header: () => polyglot.t("BenefitModule.numberOfInstallments"),
      accessorFn: (row) => row,
      id: "numberOfInstallments",
      enableSorting: true,
      sortingFn: (a, b) => sortNumeric(a, b, (item) => {
        var _a;
        return (_a = item.numberOfInstallments) != null ? _a : 0;
      }),
      cell: (info) => {
        const userBenefit = info.getValue();
        return /* @__PURE__ */ jsx(NumberCell, { value: userBenefit == null ? void 0 : userBenefit.numberOfInstallments });
      }
    }
  ] : []
];
function getYearlyAllowancePeriodDate(cycleStartDay, cycleStartMonth, date) {
  const currentDate = date ? new Date(date) : /* @__PURE__ */ new Date();
  const currentYear = currentDate.getFullYear();
  let cycleStartDate = new Date(currentYear, cycleStartMonth - 1, cycleStartDay);
  if (cycleStartDate > currentDate) {
    cycleStartDate = new Date(currentYear - 1, cycleStartMonth - 1, cycleStartDay);
  }
  let cycleEndDate = new Date(
    cycleStartDate.getFullYear() + 1,
    cycleStartDate.getMonth(),
    cycleStartDate.getDate() - 1
  );
  return {
    cycleStart: new LocalDate(cycleStartDate),
    cycleEnd: new LocalDate(cycleEndDate)
  };
}
export const groupPaymentsByCycle = (payments, startDay, startMonth) => {
  const groupedPayments = {};
  const sortedPayments = payments.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  sortedPayments.forEach((payment) => {
    const { cycleEnd, cycleStart } = getYearlyAllowancePeriodDate(startDay, startMonth, payment.date);
    const cycleLabel = `${cycleStart.toDateString()} - ${cycleEnd.toDateString()}`;
    if (!groupedPayments[cycleLabel]) {
      groupedPayments[cycleLabel] = [];
    }
    groupedPayments[cycleLabel].push(payment);
  });
  return groupedPayments;
};
export const reformatCycleLabels = (groupedPayments) => {
  const reformattedGroup = {};
  Object.entries(groupedPayments).forEach(([key, payments]) => {
    const dates = key.split(" - ");
    const startDate = new Date(dates[0]);
    const endDate = new Date(dates[1]);
    const formatDate = (date) => {
      return `${date.getDate().toString().padStart(2, "0")} ${date.toLocaleString("default", {
        month: "short"
      })} ${date.getFullYear()}`;
    };
    const newKey = `${formatDate(startDate)} - ${formatDate(endDate)}`;
    reformattedGroup[newKey] = payments;
  });
  return reformattedGroup;
};
