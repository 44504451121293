"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, FormControl, Stack } from "@mui/material";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as yup from "yup";
import { ContractorInvoiceAPI } from "@/api-client/contractor-invoice-api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  INVOICES_COMPANY_OVERVIEW_ROUTE,
  INVOICES_ME_OVERVIEW_ROUTE,
  INVOICES_ME_ROUTE,
  INVOICES_TEAM_OVERVIEW_ROUTE,
  USER_INVOICES_ROUTE
} from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { getTaxRateForTotalSection } from "@/v2/feature/payments/expenses.util";
import {
  getNewLineItemValidationSchema,
  InvoiceLineItemTable
} from "@/v2/feature/payments/pages/components/invoice-line-item-table.component";
import { VatNumberModal } from "@/v2/feature/payments/pages/components/vat-number-modal.component";
import {
  ContractorInvoiceStatus,
  DEFAULT_CURRENCY
} from "@/v2/feature/payments/payments.interface";
import {
  getFinalGrossForLineItemInvoice,
  getInvoiceTotalsBasedOnLineItems
} from "@/v2/feature/payments/utils/invoice.util";
import { PaymentSettingsNewTypeDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-new-type-drawer.component";
import { PaymentTypeSettingsEndpoints } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { popularCurrencyShortOptions } from "@/v2/infrastructure/currency/currency.interface";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
import { LocalDate } from "@/v2/util/local-date";
export const NewInvoicePage = ({
  preselectedEmployee,
  fromUserProfile = false,
  reach = "company",
  editMode = false
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w;
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const [submitting, setSubmitting] = useState(false);
  const [vatModalOpen, setVatModalOpen] = useState(false);
  const [approverList, setApproverList] = useState([]);
  const [savingDraft, setSavingDraft] = useState(false);
  const { getScopesContext, hasScopes } = useScopes();
  const { data, mutate: refreshInvoicePaymentTypeSettingsData } = useApiClient(
    PaymentTypeSettingsEndpoints.getInvoiceTypesForCompanyId(),
    {
      suspense: false
    }
  );
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const [typeDrawerOpen, setIsTypeDrawerOpen] = useState(false);
  const invoiceToEdit = useMemo(
    () => {
      var _a2;
      return ((_a2 = routerHistory.location.state) == null ? void 0 : _a2.invoiceToEdit) || null;
    },
    [routerHistory]
  );
  const currentUserIsInvoiceAdmin = hasScopes(
    ["invoices:all", "invoices:manager"],
    getScopesContext({ userId: user == null ? void 0 : user.userId })
  );
  const currentUserIsManagerOfInvoiceOwner = (invoiceToEdit == null ? void 0 : invoiceToEdit.from) ? checkScopes(user, ["invoices:manager"], { userId: invoiceToEdit.from }) : false;
  if (editMode && invoiceToEdit && invoiceToEdit.from !== user.userId && !currentUserIsInvoiceAdmin && !currentUserIsManagerOfInvoiceOwner) {
    routerHistory.push({
      pathname: generatePath(INVOICES_ME_ROUTE)
    });
  }
  const isInvoiceAdmin = checkScopes(user, ["invoices:all"], { userId: user.userId });
  const formik = useFormik({
    initialValues: {
      typeId: (_a = invoiceToEdit == null ? void 0 : invoiceToEdit.typeId) != null ? _a : 0,
      invoiceDate: (_b = invoiceToEdit == null ? void 0 : invoiceToEdit.invoiceDate) != null ? _b : new LocalDate().toDateString(),
      dueDate: (_c = invoiceToEdit == null ? void 0 : invoiceToEdit.dueDate) != null ? _c : "",
      invoiceNumber: (_d = invoiceToEdit == null ? void 0 : invoiceToEdit.invoiceNumber) != null ? _d : "",
      status: (_e = invoiceToEdit == null ? void 0 : invoiceToEdit.status) != null ? _e : ContractorInvoiceStatus.Pending,
      lineItems: (_f = invoiceToEdit == null ? void 0 : invoiceToEdit.lineItems) != null ? _f : [],
      isVatIncluded: (_g = invoiceToEdit == null ? void 0 : invoiceToEdit.isVatIncluded) != null ? _g : true,
      vatNumber: (_h = invoiceToEdit == null ? void 0 : invoiceToEdit.vatNumber) != null ? _h : null,
      taxRate: (_i = invoiceToEdit == null ? void 0 : invoiceToEdit.taxRate) != null ? _i : null,
      taxAmount: (_j = invoiceToEdit == null ? void 0 : invoiceToEdit.taxAmount) != null ? _j : 0,
      taxId: (_k = invoiceToEdit == null ? void 0 : invoiceToEdit.taxId) != null ? _k : null,
      notes: (_l = invoiceToEdit == null ? void 0 : invoiceToEdit.notes) != null ? _l : null,
      from: editMode ? invoiceToEdit.from : preselectedEmployee != null ? preselectedEmployee : 0,
      currency: editMode ? invoiceToEdit == null ? void 0 : invoiceToEdit.currency : preselectedEmployee === user.userId ? (_m = user.currency) != null ? _m : DEFAULT_CURRENCY : DEFAULT_CURRENCY,
      totalAmount: (_n = invoiceToEdit == null ? void 0 : invoiceToEdit.totalAmount) != null ? _n : 0,
      amount: (_o = invoiceToEdit == null ? void 0 : invoiceToEdit.amount) != null ? _o : 0,
      selectedInvoiceType: null
    },
    enableReinitialize: editMode,
    // TODO: add proper validation
    validationSchema: yup.object({
      currency: yup.string().required("Currency is required"),
      isVatIncluded: yup.boolean(),
      lineItems: yup.array().of(getNewLineItemValidationSchema(polyglot)).min(1, polyglot.t("ValidationMessages.minLength", { num: 1 })).required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setSubmitting(true);
        const {
          typeId,
          invoiceDate,
          invoiceNumber,
          totalAmount,
          from,
          vatNumber,
          amount,
          taxId,
          notes,
          currency,
          lineItems,
          status
        } = values;
        if (!totalAmount || !invoiceDate || !typeId || !from) return;
        const taxPresent = (lineItems || []).some((item) => item.isTaxIncluded);
        const inDraftMode = status === ContractorInvoiceStatus.Draft && savingDraft;
        const invoiceData = {
          typeId,
          invoiceDate,
          dueDate: new LocalDate(dayjs(invoiceDate).add(7, "day").toDate()).toDateString(),
          invoiceNumber,
          isVatIncluded: taxPresent,
          vatNumber: taxPresent ? vatNumber : null,
          taxRate: null,
          taxAmount: 0,
          taxId: taxPresent && taxId ? taxId : null,
          notes: notes != null ? notes : null,
          from,
          amount: Number(amount),
          status: inDraftMode ? ContractorInvoiceStatus.Draft : ContractorInvoiceStatus.Pending,
          totalAmount,
          currency,
          lineItems
        };
        if (editMode && (invoiceToEdit == null ? void 0 : invoiceToEdit.id)) {
          yield ContractorInvoiceAPI.updateInvoice(invoiceToEdit == null ? void 0 : invoiceToEdit.id, invoiceData);
        } else {
          yield ContractorInvoiceAPI.createInvoice(invoiceData);
        }
        if (inDraftMode) {
          showMessage(polyglot.t("NewInvoicePage.successMessages.invoiceDraft"), "success");
        } else {
          showMessage(
            polyglot.t(
              editMode ? "NewInvoicePage.successMessages.invoiceEdit" : "NewInvoicePage.successMessages.invoiceCreation"
            ),
            "success"
          );
        }
        routerHistory.push(
          reach === "team" ? INVOICES_TEAM_OVERVIEW_ROUTE : fromUserProfile && preselectedEmployee ? generatePath(USER_INVOICES_ROUTE, { userId: preselectedEmployee }) : preselectedEmployee ? INVOICES_ME_OVERVIEW_ROUTE : INVOICES_COMPANY_OVERVIEW_ROUTE
        );
      } catch (error) {
        showMessage(
          polyglot.t(
            editMode ? "NewInvoicePage.errorMessages.invoiceEdit" : "NewInvoicePage.errorMessages.invoiceCreation",
            { errorMessage: nestErrorMessage(error) }
          ),
          "error"
        );
      } finally {
        setSubmitting(false);
      }
    }),
    validateOnMount: true
  });
  const invoiceTypes = useMemo(() => {
    if (data == null ? void 0 : data.settings) {
      if (!preselectedEmployee && !formik.values.from) return data == null ? void 0 : data.settings;
      if (preselectedEmployee)
        return data.settings.filter((setting) => {
          var _a2;
          return (_a2 = setting == null ? void 0 : setting.selectedMembersIds) == null ? void 0 : _a2.includes(preselectedEmployee);
        });
      if (formik.values.from)
        return data.settings.filter(
          (setting) => {
            var _a2;
            return formik.values.from ? (_a2 = setting == null ? void 0 : setting.selectedMembersIds) == null ? void 0 : _a2.includes(formik.values.from) : true;
          }
        );
    } else {
      return [];
    }
  }, [data, formik.values.from, preselectedEmployee]);
  const invoiceTypeOptions = useMemo(() => {
    return invoiceTypes && invoiceTypes.length > 0 ? invoiceTypes.filter((it) => Boolean(it.id)).map((it) => ({
      label: it.name,
      value: it.id !== null ? it.id : ""
    })) : [];
  }, [invoiceTypes]);
  useEffect(() => {
    let finalAmount = 0;
    if (formik.values.amount) {
      finalAmount += +formik.values.amount;
    }
    formik.setFieldValue("totalAmount", +finalAmount);
  }, [formik.values.isVatIncluded, formik.values.amount]);
  useEffect(() => {
    const fetchApprovalList = () => __async(void 0, null, function* () {
      if (formik.values.from && formik.values.typeId) {
        const list = yield ContractorInvoiceAPI.getApprovalListForNewInvoice(formik.values.from, formik.values.typeId);
        setApproverList(list);
      } else {
        setApproverList([]);
      }
    });
    fetchApprovalList();
  }, [formik.values.from, formik.values.typeId]);
  useEffect(() => {
    const generateInvoiceNumber = (userId) => {
      if (!userId) return "";
      const today = /* @__PURE__ */ new Date();
      const year = today.getFullYear();
      const fromUser = getCachedUserById(userId);
      if (!fromUser) return "";
      const { firstName, lastName } = fromUser;
      const userInitials = `${firstName[0]}${lastName[0]}`;
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const hour = today.getHours().toString().padStart(2, "0");
      const minute = today.getMinutes().toString().padStart(2, "0");
      const second = today.getSeconds().toString().padStart(2, "0");
      return `INV-${userInitials}-${year}${month}${day}-${hour}${minute}${second}`;
    };
    const invNumber = editMode ? invoiceToEdit == null ? void 0 : invoiceToEdit.invoiceNumber : generateInvoiceNumber(formik.values.from);
    formik.setFieldValue("invoiceNumber", invNumber);
  }, [formik.values.from]);
  const updateLineItems = useCallback(
    (updatedValues) => {
      formik.setFieldValue("lineItems", updatedValues, false);
      const { totalGross, totalAmount, totalTaxAmount } = getInvoiceTotalsBasedOnLineItems(updatedValues);
      formik.setFieldValue("gross", totalGross);
      formik.setFieldValue("amount", totalAmount);
      formik.setFieldValue("taxAmount", totalTaxAmount);
      formik.validateForm();
    },
    [formik]
  );
  const selectedInvoiceType = useMemo(() => {
    return formik.values.typeId ? invoiceTypes == null ? void 0 : invoiceTypes.find((type) => type.id === formik.values.typeId) : null;
  }, [invoiceTypes, formik.values.typeId]);
  useEffect(() => {
    formik.setFieldValue("selectedInvoiceType", selectedInvoiceType != null ? selectedInvoiceType : null);
  }, [selectedInvoiceType]);
  const accountingCodeOptions = useMemo(() => {
    var _a2, _b2, _c2, _d2;
    if (formik.values.typeId && selectedInvoiceType && ((_a2 = selectedInvoiceType == null ? void 0 : selectedInvoiceType.accountingCodeConfig) == null ? void 0 : _a2.length) && ((_b2 = selectedInvoiceType == null ? void 0 : selectedInvoiceType.accountingCodeConfig) == null ? void 0 : _b2.length) > 0) {
      return ((_c2 = selectedInvoiceType == null ? void 0 : selectedInvoiceType.accountingCodeConfig) == null ? void 0 : _c2.length) > 0 ? (_d2 = selectedInvoiceType.accountingCodeConfig) == null ? void 0 : _d2.map((eachOption) => {
        return { label: eachOption.accountingCodeDescription, value: eachOption.accountingCode };
      }) : [];
    }
  }, [formik.values.typeId, selectedInvoiceType]);
  const hasMoreThanOneLineItem = ((_p = formik.values.lineItems) == null ? void 0 : _p.length) && ((_q = formik.values.lineItems) == null ? void 0 : _q.length) > 1 ? true : false;
  const handleSaveDraft = useCallback(() => {
    setSavingDraft(true);
    formik.setFieldValue("status", ContractorInvoiceStatus.Draft);
    formik.handleSubmit();
  }, [formik]);
  const handleSubmit = useCallback(() => {
    setSavingDraft(false);
    formik.handleSubmit();
  }, [formik]);
  const errorInLineItems = useMemo(() => {
    var _a2, _b2;
    const touchedLineItems = Boolean(
      formik.touched && formik.touched.lineItems && ((_a2 = formik.touched.lineItems) == null ? void 0 : _a2.length) > 0
    );
    const errorInFields = Boolean(formik.errors && formik.errors.lineItems && ((_b2 = formik.errors.lineItems) == null ? void 0 : _b2.length) > 0);
    return errorInFields && touchedLineItems;
  }, [formik.errors, formik.touched]);
  const groupedItems = (formik.values.lineItems || []).filter((item) => item.taxRate !== null && item.amount !== null && item.gross !== null).reduce((acc, item) => {
    const taxRate = item.taxRate;
    const gross = item.gross;
    const amount = item.amount;
    if (!acc[taxRate]) {
      acc[taxRate] = 0;
    }
    acc[taxRate] += gross - amount;
    return acc;
  }, {});
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t(editMode ? "NewInvoicePage.titleEdit" : "NewInvoicePage.title") }),
        showBack: true,
        sx: { width: "618px", mx: "auto" }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { width: "100%", overflowY: "auto", mx: "auto" }, children: /* @__PURE__ */ jsx(Box, { sx: { width: "618px", mx: "auto" }, children: /* @__PURE__ */ jsx(
      ContentWrapper,
      {
        noHorizontalPadding: true,
        sx: {
          display: "flex",
          justifyContent: "center",
          width: "618px",
          px: "9px",
          pt: "2px"
        },
        loading: false,
        children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width: "100%", justifyContent: "center" }, children: [
          /* @__PURE__ */ jsx(Box, { sx: { width: "618px", mx: "auto", mt: spacing.mt20, minHeight: "50vh", overflowY: "auto" }, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: [
            !preselectedEmployee && /* @__PURE__ */ jsx(FormControl, { sx: fieldSx, size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
              SingleUserSelect,
              {
                name: "from",
                options: reach,
                value: formik.values.from ? Number(formik.values.from) : null,
                onChange: (_, x) => __async(void 0, null, function* () {
                  var _a2;
                  const userId = (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null;
                  yield formik.setFieldValue("from", userId);
                }),
                label: polyglot.t("NewInvoicePage.employee"),
                error: Boolean(formik.errors.from),
                helperText: formik.errors.from
              }
            ) }),
            /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({}, fieldSx), size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
              DatePickerComponent,
              {
                inputFormat: "DD/MM/YYYY",
                value: (_r = formik.values.invoiceDate) != null ? _r : null,
                onChange: (value) => {
                  if (dayjs(value).isValid()) {
                    formik.setFieldValue("invoiceDate", value);
                  }
                },
                name: "invoiceDate",
                label: polyglot.t("NewInvoicePage.invoiceDate"),
                error: formik.touched.invoiceDate && Boolean(formik.errors.invoiceDate),
                helperText: formik.touched.invoiceDate && formik.errors.invoiceDate
              }
            ) }),
            /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({}, fieldSx), size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
              SelectComponent,
              {
                name: "typeId",
                label: polyglot.t("NewInvoicePage.policy"),
                options: invoiceTypeOptions,
                value: formik.values.typeId,
                onChange: (e) => {
                  formik.setFieldValue("typeId", e.target.value);
                },
                editList: {
                  handler: () => setIsTypeDrawerOpen(true),
                  isHidden: !isInvoiceAdmin
                }
              }
            ) }),
            /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({}, fieldSx), size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
              SelectComponent,
              {
                name: "currency",
                label: polyglot.t("NewExpensePage.currency"),
                options: popularCurrencyShortOptions,
                value: (_s = formik.values.currency) != null ? _s : DEFAULT_CURRENCY,
                onChange: (e) => {
                  formik.setFieldValue("currency", e.target.value);
                },
                error: formik.touched.currency && Boolean(formik.errors.currency),
                helperText: formik.touched.currency && formik.errors.currency
              }
            ) }),
            /* @__PURE__ */ jsx(
              InvoiceLineItemTable,
              {
                invoice: formik.values,
                accountingCodeOptions: accountingCodeOptions != null ? accountingCodeOptions : [],
                updateLineItems,
                errorInLineItems,
                selectedInvoiceType: selectedInvoiceType != null ? selectedInvoiceType : void 0
              }
            ),
            /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({}, fieldSx), size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
              TextfieldComponent,
              {
                name: "notes",
                label: polyglot.t("NewInvoicePage.notes"),
                value: formik.values.notes,
                type: "text",
                onChange: formik.handleChange,
                error: formik.touched.notes && Boolean(formik.errors.notes),
                helperText: formik.touched.notes && formik.errors.notes,
                clearText: () => formik.setFieldValue("notes", ""),
                multiline: true,
                minRows: 1
              }
            ) }),
            /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({}, fieldSx), size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
              TextfieldComponent,
              {
                name: "invoiceNumber",
                label: polyglot.t("NewInvoicePage.invoiceNumber"),
                value: formik.values.invoiceNumber,
                type: "text",
                onChange: formik.handleChange,
                error: formik.touched.invoiceNumber && Boolean(formik.errors.invoiceNumber),
                helperText: formik.touched.invoiceNumber && formik.errors.invoiceNumber,
                clearText: () => formik.setFieldValue("invoiceNumber", "")
              }
            ) }),
            vatModalOpen && /* @__PURE__ */ jsx(VatNumberModal, { setOpen: setVatModalOpen, open: vatModalOpen, formik }),
            /* @__PURE__ */ jsx(
              ViewerItem,
              {
                title: `${polyglot.t("NewInvoicePage.vatNumber")} ${!hasMoreThanOneLineItem ? getTaxRateForTotalSection(formik.values.taxRate) : ""}`,
                value: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: !formik.values.vatNumber ? "RedDark" : "DarkGrey", children: polyglot.t(
                  !formik.values.vatNumber ? "NewInvoicePage.missingVatNumber" : "NewInvoicePage.vatNumberProvided"
                ) }),
                valueOnClickColor: !formik.values.vatNumber ? "RedDark" : "DarkGrey",
                valueOnClick: () => {
                  setVatModalOpen(true);
                }
              }
            ),
            /* @__PURE__ */ jsx(
              ViewerItem,
              {
                title: polyglot.t("NewInvoicePage.amount"),
                value: formatCurrency(
                  formik.values.amount ? formik.values.amount : 0,
                  void 0,
                  (_t = formik.values.currency) != null ? _t : DEFAULT_CURRENCY
                )
              }
            ),
            Object.keys(groupedItems).length > 0 ? Object.keys(groupedItems).map((taxRate) => /* @__PURE__ */ jsx(
              ViewerItem,
              {
                title: `${polyglot.t("NewInvoicePage.taxAmount")} ${getTaxRateForTotalSection(
                  Number(taxRate)
                )}`,
                value: formatCurrency(
                  groupedItems[Number(taxRate)],
                  void 0,
                  formik.values.currency || DEFAULT_CURRENCY
                )
              },
              taxRate
            )) : null,
            /* @__PURE__ */ jsx(
              ViewerItem,
              {
                title: polyglot.t("NewInvoicePage.gross"),
                value: formatCurrency(
                  (_v = getFinalGrossForLineItemInvoice(
                    (_u = formik.values.lineItems) != null ? _u : [
                      { amount: formik.values.amount, taxRate: formik.values.taxRate }
                    ]
                  )) != null ? _v : 0,
                  void 0,
                  (_w = formik.values.currency) != null ? _w : DEFAULT_CURRENCY
                )
              }
            ),
            (approverList == null ? void 0 : approverList.length) > 0 && /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.mt20 }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", sx: { color: themeColors.grey, mb: spacing.mb5 }, children: polyglot.t("NewExpensePage.approvers") }),
              /* @__PURE__ */ jsx(Stack, { direction: "column", sx: { gap: spacing.g10 }, children: approverList == null ? void 0 : approverList.map((approver) => /* @__PURE__ */ jsx(UserCell, { userId: approver })) })
            ] }),
            /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  mt: spacing.mt30,
                  gap: spacing.s2
                },
                children: [
                  (invoiceToEdit && invoiceToEdit.status === ContractorInvoiceStatus.Draft || !invoiceToEdit) && /* @__PURE__ */ jsx(
                    ButtonComponent,
                    {
                      fullWidth: true,
                      sizeVariant: "medium",
                      colorVariant: "secondary",
                      onClick: () => handleSaveDraft(),
                      loading: submitting,
                      children: polyglot.t("OnboardingUserInvite.saveDraft")
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    ButtonComponent,
                    {
                      fullWidth: true,
                      loading: submitting,
                      colorVariant: "primary",
                      sizeVariant: "medium",
                      onClick: () => handleSubmit(),
                      children: polyglot.t("General.submit")
                    }
                  )
                ]
              }
            )
          ] }) }) }) }),
          typeDrawerOpen && /* @__PURE__ */ jsx(
            PaymentSettingsNewTypeDrawer,
            {
              isOpen: typeDrawerOpen,
              setIsOpen: setIsTypeDrawerOpen,
              refreshAllSettings: refreshInvoicePaymentTypeSettingsData,
              typeForEdit: void 0
            }
          )
        ] })
      }
    ) }) })
  ] });
};
export const ViewerItem = ({
  title,
  value,
  valueOnClick,
  valueOnClickColor = "DarkGrey"
}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "caption", children: title }),
    /* @__PURE__ */ jsx(
      Typography,
      {
        onClick: valueOnClick,
        variant: "title4",
        color: valueOnClickColor,
        sx: { textDecoration: valueOnClick ? "underline" : "none", cursor: valueOnClick ? "pointer" : "default" },
        children: value
      }
    )
  ] });
};
