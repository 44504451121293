"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  SETTINGS_ONBOARDING_EDIT_FLOWS_ROUTE,
  SETTINGS_ONBOARDING_FLOWS_ROUTE,
  SETTINGS_ONBOARDING_ROUTE
} from "@/lib/routes";
import { OnboardingSettings } from "@/v2/feature/onboarding/onboarding-settings.page";
import { OnboardingTemplateEdit } from "@/v2/feature/onboarding/onboarding-template-edit.page";
export const OnboardingSettingsRouter = () => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: SETTINGS_ONBOARDING_EDIT_FLOWS_ROUTE, component: OnboardingTemplateEdit }),
    /* @__PURE__ */ jsx(Route, { path: SETTINGS_ONBOARDING_FLOWS_ROUTE, children: /* @__PURE__ */ jsx(OnboardingSettings, {}) }),
    /* @__PURE__ */ jsx(Redirect, { from: SETTINGS_ONBOARDING_ROUTE, to: SETTINGS_ONBOARDING_FLOWS_ROUTE, exact: true })
  ] });
};
