"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { Redirect, Switch, useLocation } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  BENEFITS_COMPANY_ROUTE,
  BENEFITS_ME_ROUTE,
  EXPENSES_COMPANY_ROUTE,
  EXPENSES_ME_ROUTE,
  EXPENSES_TEAM_ROUTE,
  INVOICES_COMPANY_ROUTE,
  INVOICES_ME_ROUTE,
  INVOICES_TEAM_ROUTE,
  MONEY_ROUTE,
  PAYMENT_COMPANY_ROUTE,
  PAYMENT_ME_ROUTE,
  PAYMENT_TEAM_ROUTE,
  PAYROLL_COMPANY_ROUTE,
  PAYROLL_ME_ROUTE
} from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
import { BenefitsRouter } from "@/v2/feature/benefits/benefits.router";
import { ExpenseRouter } from "@/v2/feature/expense/expense.router";
import { InvoiceRouter } from "@/v2/feature/invoice/invoice.router";
import { PaymentsRouter } from "@/v2/feature/payments/payments.router";
import { PayrollRouter } from "@/v2/feature/payroll/payroll.router";
export const MoneyRouter = () => {
  var _a, _b, _c, _d, _e;
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { getScopesContext, hasScopes } = useScopes();
  const scopesContext = getScopesContext(user);
  const location = useLocation();
  const hasPayrollAllScope = useMemo(() => hasScopes(["payroll:all", "payroll.selectedEntity:all"]), [hasScopes]);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["payroll:read"],
        context: scopesContext,
        path: [PAYROLL_COMPANY_ROUTE, PAYROLL_ME_ROUTE],
        component: PayrollRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["payments"],
        path: [PAYMENT_COMPANY_ROUTE, PAYMENT_ME_ROUTE, PAYMENT_TEAM_ROUTE],
        context: scopesContext,
        component: PaymentsRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["invoices"],
        path: [INVOICES_COMPANY_ROUTE, INVOICES_ME_ROUTE, INVOICES_TEAM_ROUTE],
        context: scopesContext,
        component: InvoiceRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["expenses"],
        path: [EXPENSES_COMPANY_ROUTE, EXPENSES_ME_ROUTE, EXPENSES_TEAM_ROUTE],
        context: scopesContext,
        component: ExpenseRouter
      }
    ),
    !((_b = (_a = user.navigation) == null ? void 0 : _a.benefits) == null ? void 0 : _b.isHidden) && /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["insurance", "pension"],
        context: scopesContext,
        path: [BENEFITS_ME_ROUTE, BENEFITS_COMPANY_ROUTE],
        component: BenefitsRouter
      }
    ),
    (hasPayrollAllScope || hasPayrollAllScope && !((_c = location.pathname) == null ? void 0 : _c.includes("/me"))) && /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["payroll:read", "payroll.selectedEntity:all"],
        context: scopesContext,
        path: MONEY_ROUTE,
        children: /* @__PURE__ */ jsx(Redirect, { to: PAYROLL_COMPANY_ROUTE })
      }
    ),
    Boolean(user.contractType === "Employee") && !Boolean((_e = (_d = user == null ? void 0 : user.navigation) == null ? void 0 : _d.salary) == null ? void 0 : _e.isHidden) && /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["payroll:read"], context: scopesContext, path: MONEY_ROUTE, children: /* @__PURE__ */ jsx(Redirect, { to: PAYROLL_ME_ROUTE }) }),
    canAccessScopes(user, ["expenses:all"]) && /* @__PURE__ */ jsx(Redirect, { from: MONEY_ROUTE, to: EXPENSES_COMPANY_ROUTE }),
    canAccessScopes(user, ["expenses:manager"]) && /* @__PURE__ */ jsx(Redirect, { from: MONEY_ROUTE, to: EXPENSES_TEAM_ROUTE }),
    canAccessScopes(user, ["expenses"]) && /* @__PURE__ */ jsx(Redirect, { from: MONEY_ROUTE, to: EXPENSES_ME_ROUTE }),
    canAccessScopes(user, ["invoices:all"]) && /* @__PURE__ */ jsx(Redirect, { from: MONEY_ROUTE, to: INVOICES_COMPANY_ROUTE }),
    canAccessScopes(user, ["invoices:manager"]) && /* @__PURE__ */ jsx(Redirect, { from: MONEY_ROUTE, to: INVOICES_TEAM_ROUTE }),
    canAccessScopes(user, ["invoices"]) && /* @__PURE__ */ jsx(Redirect, { from: MONEY_ROUTE, to: INVOICES_ME_ROUTE }),
    canAccessScopes(user, ["payments:all"]) && /* @__PURE__ */ jsx(Redirect, { from: MONEY_ROUTE, to: PAYMENT_COMPANY_ROUTE }),
    canAccessScopes(user, ["payments:manager"]) && /* @__PURE__ */ jsx(Redirect, { from: MONEY_ROUTE, to: PAYMENT_TEAM_ROUTE }),
    canAccessScopes(user, ["payments"]) && /* @__PURE__ */ jsx(Redirect, { from: MONEY_ROUTE, to: PAYMENT_ME_ROUTE })
  ] });
};
