"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceOrderStatus, DevicePossessionType, DeviceTransitStatus } from "@v2/feature/device/device.interface";
import { useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { MyDevicesActive } from "@/v2/feature/device/features/my-devices/components/my-devices-active.component";
import { MyDevicesEmptyOverview } from "@/v2/feature/device/features/my-devices/components/my-devices-empty-overview.component";
import { MyDevicesOrders } from "@/v2/feature/device/features/my-devices/components/my-devices-orders.component";
import { MyDevicesReturns } from "@/v2/feature/device/features/my-devices/components/my-devices-returns.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserProfileDevicesPage = () => {
  const [loading, setLoading] = useState(false);
  const [devicePossessions, setDevicePossessions] = useState([]);
  const [deviceTransits, setDeviceTransits] = useState([]);
  const [deviceOrders, setDeviceOrders] = useState([]);
  const params = useParams();
  const userId = Number(params.userId);
  const [showMessage] = useMessage();
  const refreshLocalDevices = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const [devicePossessions2, deviceTransits2, deviceOrders2] = yield Promise.all([
        DeviceAPI.getActiveDevicePossessionsByUserId(userId),
        DeviceAPI.getUserInTransitDevices(userId),
        DeviceAPI.getUserDeviceOrders(userId)
      ]);
      setDevicePossessions(devicePossessions2);
      setDeviceTransits(deviceTransits2);
      setDeviceOrders(deviceOrders2.filter((deviceOrder) => deviceOrder.status !== DeviceOrderStatus.Delivered));
    } catch (error) {
      showMessage("Could not load user devices list", "error");
    } finally {
      setLoading(false);
    }
  }), [userId, showMessage]);
  useEffect(() => {
    refreshLocalDevices();
  }, [refreshLocalDevices]);
  const notifiableDeviceOrders = deviceOrders.some(
    (deviceOrder) => [
      DeviceOrderStatus.Requested,
      DeviceOrderStatus.Placed,
      DeviceOrderStatus.Accepted,
      DeviceOrderStatus.Shipping
    ].includes(deviceOrder.status)
  );
  const validShippingOrders = deviceOrders.filter((d) => d.status !== DeviceOrderStatus.Cancelled);
  const nonOrderTransits = deviceTransits.filter((deviceTransit) => {
    var _a;
    return (deviceTransit.status === DeviceTransitStatus.Pending || deviceTransit.status === DeviceTransitStatus.Shipping) && ((_a = deviceTransit.sender) == null ? void 0 : _a.possessionType) !== DevicePossessionType.ZeltStock;
  });
  const notifiableDeviceTransits = Boolean((nonOrderTransits == null ? void 0 : nonOrderTransits.length) > 0);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: "Devices" }) }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, sx: __spreadValues({}, spacing.pt20), children: /* @__PURE__ */ jsx(
      Suspense,
      {
        fallback: /* @__PURE__ */ jsx(
          SkeletonLoader,
          {
            variant: "rectangular",
            width: "83%",
            height: "100%",
            sx: __spreadValues({ borderRadius: "10px", backgroundColor: themeColors.Background }, spacing.px40)
          }
        ),
        children: devicePossessions.length > 0 || notifiableDeviceOrders || notifiableDeviceTransits ? /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "column", lg: "row", xl: "row" },
              justifyContent: "space-between",
              gap: spacing.g60
            },
            children: /* @__PURE__ */ jsx(Box, { sx: { width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "70%", xl: "70%" } }, children: /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  gap: spacing.g60
                },
                children: [
                  (validShippingOrders == null ? void 0 : validShippingOrders.length) > 0 && /* @__PURE__ */ jsx(MyDevicesOrders, { deviceOrders: validShippingOrders, refreshDevicesData: refreshLocalDevices }),
                  (nonOrderTransits == null ? void 0 : nonOrderTransits.length) > 0 && /* @__PURE__ */ jsx(
                    MyDevicesReturns,
                    {
                      deviceTransits: nonOrderTransits,
                      refreshDevicesData: refreshLocalDevices,
                      userId
                    }
                  ),
                  (devicePossessions == null ? void 0 : devicePossessions.length) > 0 && /* @__PURE__ */ jsx(MyDevicesActive, { devicePossessions, userId })
                ]
              }
            ) })
          }
        ) : /* @__PURE__ */ jsx(Box, { sx: spacing.px40, children: /* @__PURE__ */ jsx(
          MyDevicesEmptyOverview,
          {
            refresh: () => __async(void 0, null, function* () {
              yield refreshLocalDevices();
            })
          }
        ) })
      }
    ) })
  ] });
};
