"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { Typography } from "@v2/components/typography/typography.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { SiteEndpoints } from "@/api-client/site.api";
import { UserRoleAPI } from "@/api-client/user-role.api";
import { ScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { DateLabelComponent } from "@/v2/components/forms/date-label.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { sortCustomFields } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { JobPositionEndpoints } from "@/v2/feature/job-position/job-position.api";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import {
  CardStructure,
  FieldStructure
} from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import {
  calculateSkeletonHeight,
  cardSx,
  definitionListSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import {
  RoleForm,
  RoleSchema
} from "@/v2/feature/user/features/user-profile/details/components/user-profile-role-form.component";
import { displayUserName } from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { buildColumnsForCustomFields } from "@/v2/feature/user/features/user-profile/details/user-profile.util";
import { UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const SCOPE = "user.role";
const skeletonHeight = calculateSkeletonHeight(RoleSchema);
const iconSize = { width: 14, height: 14 };
export const RoleCard = ({ userId, onSubmit }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const { nonTerminatedCachedUsers: companyUsers, getCachedUserById, refreshCachedUsers } = useCachedUsers({
    refresh: true
  });
  const [data, setData] = useState();
  const [formMode, setFormMode] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { data: overview } = useApiClient(UserEndpoints.getUserSummaryById(userId), { suspense: false });
  const directReport = useMemo(() => {
    var _a2;
    return (_a2 = overview == null ? void 0 : overview.directReports) != null ? _a2 : [];
  }, [overview]);
  const { data: deptsData, mutate: refreshDept } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), {
    suspense: false
  });
  const departments = useMemo(() => {
    var _a2;
    return (_a2 = deptsData == null ? void 0 : deptsData.map((dept) => ({ label: dept.name, value: dept.id }))) != null ? _a2 : [];
  }, [deptsData]);
  const { data: siteData, mutate: refreshSites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const sites = useMemo(() => {
    var _a2;
    return (_a2 = siteData == null ? void 0 : siteData.map((site) => ({ label: site.name, value: site.id }))) != null ? _a2 : [];
  }, [siteData]);
  const { data: jobPositionData, mutate: refreshJobPosition } = useApiClient(JobPositionEndpoints.listJobPositions(), {
    suspense: false
  });
  const nextPossibleId = useMemo(() => {
    var _a2;
    if (!jobPositionData) return "";
    const lastJobPosition = jobPositionData == null ? void 0 : jobPositionData.sort((a, b) => Number(a.internalCode) - Number(b.internalCode));
    const lastId = (_a2 = lastJobPosition[jobPositionData.length - 1]) == null ? void 0 : _a2.internalCode;
    if (lastId && !Number.isNaN(Number(lastId))) {
      return String(Number(lastId) + 1);
    } else {
      return Math.floor(Math.random() * 1e6).toString();
    }
  }, [jobPositionData]);
  const DEFAULT_NEW_JOB_POSITION = {
    id: void 0,
    internalCode: nextPossibleId,
    title: "",
    description: ""
  };
  const jobPositions = useMemo(() => {
    if (!jobPositionData) return [];
    return jobPositionData ? jobPositionData == null ? void 0 : jobPositionData.map((eachJobPosition) => {
      return {
        label: eachJobPosition.title,
        value: eachJobPosition.id
      };
    }) : [];
  }, [jobPositionData]);
  const managers = useMemo(
    () => {
      var _a2;
      return (_a2 = companyUsers.filter((u) => u.userId !== userId)) == null ? void 0 : _a2.map((rec) => {
        return __spreadValues({ label: rec.displayName, value: rec.userId }, rec);
      });
    },
    [companyUsers, userId]
  );
  const [showDetails, setShowDetails] = useState(false);
  const refreshRole = useCallback(() => __async(void 0, null, function* () {
    var _a2;
    try {
      const role = yield UserRoleAPI.getUserRoleInfo(userId);
      refreshCachedUsers == null ? void 0 : refreshCachedUsers();
      setData(role);
      if (!((_a2 = data == null ? void 0 : data.entries) == null ? void 0 : _a2.length)) setShowDetails(false);
    } catch (e) {
      showMessage("Role information could not be loaded. Please try again.", "error");
    }
  }), [(_a = data == null ? void 0 : data.entries) == null ? void 0 : _a.length, refreshCachedUsers, showMessage, userId]);
  useEffect(() => {
    refreshRole();
  }, [refreshRole]);
  const columnData = useMemo(
    () => [
      {
        header: () => polyglot.t("RoleCard.jobTitle"),
        accessorFn: identity,
        id: "jobTitle",
        enableSorting: false,
        cell: ({
          row: {
            original: { jobTitle }
          }
        }) => jobTitle ? /* @__PURE__ */ jsx("div", { children: polyglot.t(jobTitle) }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => polyglot.t("RoleCard.department"),
        accessorFn: identity,
        id: "department",
        enableSorting: false,
        cell: ({
          row: {
            original: { departmentId }
          }
        }) => {
          const department = departmentId && departments.find((d) => d.value === departmentId);
          return department ? /* @__PURE__ */ jsx("div", { children: polyglot.t(department.label) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => polyglot.t("RoleCard.site"),
        accessorFn: identity,
        id: "site",
        enableSorting: false,
        cell: ({
          row: {
            original: { siteId }
          }
        }) => {
          const site = sites.find((s) => s.value === siteId);
          return site ? /* @__PURE__ */ jsx("div", { style: { textTransform: "capitalize" }, children: polyglot.t(site.label) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => polyglot.t("RoleCard.reportsTo"),
        accessorFn: (row) => row.managerId,
        id: "reportsTo",
        enableSorting: false,
        cell: ({
          row: {
            original: { managerId }
          }
        }) => {
          const reportsTo = managerId ? getCachedUserById(managerId) : void 0;
          return reportsTo ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(UserAvatar, { userId: reportsTo.userId, size: "xxsmall" }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { marginLeft: spacing.m10 }, children: polyglot.t(displayUserName(reportsTo)) })
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => polyglot.t("RoleCard.directReports"),
        accessorFn: identity,
        id: "directReports",
        enableSorting: false,
        cell: ({
          row: {
            original: { id }
          }
        }) => {
          var _a2;
          return /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                display: "flex",
                flexDirection: "row",
                gap: spacing.m5,
                visibility: id === ((_a2 = data == null ? void 0 : data.effectiveRecord) == null ? void 0 : _a2.id) ? "visible" : "hidden"
              },
              children: directReport && directReport.length > 0 ? directReport.map((user) => /* @__PURE__ */ jsx(UserAvatar, { userId: user.userId, size: "xxsmall" })) : /* @__PURE__ */ jsx(EmptyCell, {})
            }
          );
        }
      },
      {
        header: () => polyglot.t("RoleCard.lastChangeType"),
        accessorFn: identity,
        id: "lastChangeType",
        enableSorting: false,
        cell: ({
          row: {
            original: { lastChangeType }
          }
        }) => lastChangeType ? /* @__PURE__ */ jsx("div", { style: { textTransform: "capitalize" }, children: lastChangeType }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => polyglot.t("RoleCard.lastChangeReason"),
        accessorFn: identity,
        id: "lastChangeReason",
        enableSorting: false,
        cell: ({
          row: {
            original: { lastChangeReason }
          }
        }) => lastChangeReason ? /* @__PURE__ */ jsx("div", { style: { textTransform: "capitalize" }, children: polyglot.t(lastChangeReason) }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      ...buildColumnsForCustomFields(data == null ? void 0 : data.entries, { size: 80 }),
      {
        header: () => polyglot.t("RoleCard.effectiveDate"),
        accessorFn: identity,
        id: "effectiveDate",
        enableSorting: false,
        cell: ({
          row: {
            original: { effectiveDate }
          }
        }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: 0.5 }, children: effectiveDate ? /* @__PURE__ */ jsx(DateLabelComponent, { date: effectiveDate }) : "-" })
      },
      {
        accessorFn: identity,
        header: () => "",
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { style: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            title: "edit",
            onClick: () => {
              setIsOpen(true);
              setFormMode(original);
            },
            sx: tableIconButtonSx,
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) }) })
      }
    ],
    [
      polyglot,
      (_b = data == null ? void 0 : data.effectiveRecord) == null ? void 0 : _b.id,
      data == null ? void 0 : data.entries,
      departments,
      directReport,
      getCachedUserById,
      scopesContext,
      sites
    ]
  );
  const handleSubmit = useCallback(
    (x) => {
      refreshRole();
      setFormMode(null);
      setIsOpen(false);
      onSubmit(x);
    },
    [onSubmit, refreshRole]
  );
  const onRefresh = useCallback(() => {
    refreshRole();
    setFormMode(null);
  }, [refreshRole]);
  return pipe(
    data ? O.some(data) : O.none,
    O.fold(
      () => /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          height: skeletonHeight,
          sx: [cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]
        }
      ),
      ({ entries, effectiveRecord }) => {
        var _a2, _b2, _c, _d, _e;
        const manager = (effectiveRecord == null ? void 0 : effectiveRecord.managerId) ? getCachedUserById(effectiveRecord == null ? void 0 : effectiveRecord.managerId) : void 0;
        return /* @__PURE__ */ jsx(
          CardStructure,
          {
            cardTitle: polyglot.t("RoleCard.cardTitle"),
            cardScope: [SCOPE],
            showDetails,
            setShowDetails,
            userId,
            addButtonTitle: polyglot.t("RoleCard.newRole"),
            addAction: () => {
              setIsOpen(true);
              setFormMode(null);
            },
            editAction: effectiveRecord && (() => {
              setIsOpen(true);
              setFormMode(effectiveRecord);
            }),
            showMissingInfo: !effectiveRecord,
            cardFieldCustomUpdates: effectiveRecord == null ? void 0 : effectiveRecord.customUpdates,
            cardFieldStubs: ["role.jobPosition", "role.department", "role.site", "role.manager"],
            cardFieldDetails: effectiveRecord && /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
              /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("RoleCard.jobTitle"),
                  fieldValue: effectiveRecord.jobTitle ? polyglot.t(effectiveRecord.jobTitle) : "",
                  fieldStub: "role.jobTitle"
                }
              ),
              /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("RoleCard.department"),
                  fieldValue: polyglot.t(
                    (effectiveRecord == null ? void 0 : effectiveRecord.departmentId) ? (_b2 = (_a2 = departments.find((d) => d.value === (effectiveRecord == null ? void 0 : effectiveRecord.departmentId))) == null ? void 0 : _a2.label) != null ? _b2 : "" : ""
                  ),
                  fieldStub: "role.department"
                }
              ),
              /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("RoleCard.site"),
                  fieldValue: polyglot.t(
                    (effectiveRecord == null ? void 0 : effectiveRecord.siteId) ? (_d = (_c = sites.find((s) => s.value === (effectiveRecord == null ? void 0 : effectiveRecord.siteId))) == null ? void 0 : _c.label) != null ? _d : "" : ""
                  ),
                  fieldStub: "role.site"
                }
              ),
              /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("RoleCard.reportsTo"),
                  fieldValue: manager ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [
                    /* @__PURE__ */ jsx(UserAvatar, { userId: manager.userId, size: "xxsmall" }),
                    /* @__PURE__ */ jsx(Typography, { variant: "title4", sx: { marginLeft: spacing.m5 }, children: polyglot.t((_e = manager.displayName) != null ? _e : "") })
                  ] }) : null,
                  fieldStub: "role.manager"
                }
              ),
              /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("RoleCard.directReports"),
                  fieldValue: !!(directReport == null ? void 0 : directReport.length) ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "row", gap: spacing.m5 }, children: directReport.map((user) => /* @__PURE__ */ jsx(UserAvatar, { userId: user.userId, size: "xxsmall" })) }) : null,
                  fieldStub: "role.manager"
                }
              ),
              sortCustomFields(effectiveRecord.customUpdates).map(
                (f) => !f.field.isHidden && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: f.field.fieldName, fieldValue: f.value }, f.field.fieldId)
              )
            ] }),
            tableColumn: columnData,
            tableRowData: [...entries],
            drawerDetails: /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
              RoleForm,
              {
                userId,
                departments,
                managers,
                sites,
                jobPositions,
                initialValues: formMode,
                onSubmit: handleSubmit,
                onRefresh,
                onClose: () => setIsOpen(false),
                refreshSites,
                refreshDept,
                refreshJobPosition,
                defaultJobPosition: DEFAULT_NEW_JOB_POSITION
              }
            ) }) })
          },
          effectiveRecord == null ? void 0 : effectiveRecord.id
        );
      }
    )
  );
};
