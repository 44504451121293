"use strict";
export var AbsenceStatus = /* @__PURE__ */ ((AbsenceStatus2) => {
  AbsenceStatus2["Approved"] = "approved";
  AbsenceStatus2["Pending"] = "pending";
  AbsenceStatus2["Rejected"] = "rejected";
  return AbsenceStatus2;
})(AbsenceStatus || {});
export var HolidaysProratingRule = /* @__PURE__ */ ((HolidaysProratingRule2) => {
  HolidaysProratingRule2["AllInCycle"] = "AllInCycle";
  HolidaysProratingRule2["AllInPeriod"] = "AllInPeriod";
  HolidaysProratingRule2["AllInPeriodProrated"] = "AllInPeriodProrated";
  HolidaysProratingRule2["FullValueProrated"] = "FullValueProrated";
  return HolidaysProratingRule2;
})(HolidaysProratingRule || {});
export var AccrualCliff = /* @__PURE__ */ ((AccrualCliff2) => {
  AccrualCliff2["Probation"] = "Probation";
  return AccrualCliff2;
})(AccrualCliff || {});
export var AccrualUnit = /* @__PURE__ */ ((AccrualUnit2) => {
  AccrualUnit2["Day"] = "Day";
  AccrualUnit2["Week"] = "Week";
  AccrualUnit2["Month"] = "Month";
  return AccrualUnit2;
})(AccrualUnit || {});
export var AllowanceRoundingType = /* @__PURE__ */ ((AllowanceRoundingType2) => {
  AllowanceRoundingType2["NoRounding"] = "NoRounding";
  AllowanceRoundingType2["UpToHalfDay"] = "UpToHalfDay";
  AllowanceRoundingType2["UpToFullDay"] = "UpToFullDay";
  return AllowanceRoundingType2;
})(AllowanceRoundingType || {});
export var AbsencePolicyAllowanceType = /* @__PURE__ */ ((AbsencePolicyAllowanceType2) => {
  AbsencePolicyAllowanceType2["Limited"] = "Limited";
  AbsencePolicyAllowanceType2["Unlimited"] = "Unlimited";
  AbsencePolicyAllowanceType2["LimitedHourly"] = "LimitedHourly";
  AbsencePolicyAllowanceType2["UnlimitedHourly"] = "UnlimitedHourly";
  return AbsencePolicyAllowanceType2;
})(AbsencePolicyAllowanceType || {});
export var AbsenceLengthUnit = /* @__PURE__ */ ((AbsenceLengthUnit2) => {
  AbsenceLengthUnit2["Day"] = "day";
  AbsenceLengthUnit2["Minute"] = "minute";
  return AbsenceLengthUnit2;
})(AbsenceLengthUnit || {});
export var OverlapsRule = /* @__PURE__ */ ((OverlapsRule2) => {
  OverlapsRule2["Everyone"] = "Everyone";
  OverlapsRule2["DirectReports"] = "DirectReports";
  OverlapsRule2["Department"] = "Department";
  return OverlapsRule2;
})(OverlapsRule || {});
export var TimeUnit = /* @__PURE__ */ ((TimeUnit2) => {
  TimeUnit2["Hours"] = "hours";
  TimeUnit2["Days"] = "days";
  return TimeUnit2;
})(TimeUnit || {});
export var CalendarYear = /* @__PURE__ */ ((CalendarYear2) => {
  CalendarYear2["Last"] = "last";
  CalendarYear2["Current"] = "current";
  CalendarYear2["Next"] = "next";
  return CalendarYear2;
})(CalendarYear || {});
