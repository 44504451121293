"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { JobLevelEndpoints } from "@/v2/feature/job-level/job-level.api";
import { JobPositionAPI } from "@/v2/feature/job-position/job-position.api";
import {
  drawerContentSx,
  editDeleteHeaderSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { spacing } from "@/v2/styles/spacing.styles";
export const JobPositionFormDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  selectedJobPosition,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { data: jobLevels } = useApiClient(JobLevelEndpoints.listJobLevel(), { suspense: false });
  const isEditMode = !!(selectedJobPosition == null ? void 0 : selectedJobPosition.id);
  const [loading, setLoading] = useState(false);
  const jobsLookup = useMemo(() => {
    const groupMap = /* @__PURE__ */ new Map();
    jobLevels == null ? void 0 : jobLevels.forEach((item) => {
      if (!groupMap.has(item.trackName)) {
        groupMap.set(item.trackName, { counter: 1, options: [] });
      }
      const group = groupMap.get(item.trackName);
      group.options.push({
        value: item.levelId,
        label: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
            item.trackName.split(" ").map((word) => word[0]).join(""),
            group.counter
          ] }),
          /* @__PURE__ */ jsx(Typography, { variant: "caption", children: item.levelName })
        ] }),
        description: `${item.trackName}`
      });
      group.counter += 1;
    });
    const flattenedArray = Array.from(groupMap.values()).flatMap((group) => group.options).map((option) => __spreadProps(__spreadValues({}, option), {
      description: `${option.description}`
    }));
    return flattenedArray;
  }, [jobLevels]);
  const handleSubmit = (values) => __async(void 0, null, function* () {
    const action = isEditMode ? "update" : "add";
    try {
      setLoading(true);
      if (isEditMode) {
        yield JobPositionAPI.updateJobPosition(values.id, values);
      } else {
        yield JobPositionAPI.createJobPosition(values);
      }
      yield refresh();
      onClose();
      showMessage(polyglot.t(`JobPositionFormDrawer.successMessages.${action}`), "success");
    } catch (error) {
      showMessage(
        polyglot.t(`JobPositionFormDrawer.errorMessages.${action}`, { nestError: nestErrorMessage(error) }),
        "error"
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  });
  const formik = useFormik({
    initialValues: __spreadValues({}, selectedJobPosition),
    validationSchema: Yup.object().shape({
      internalCode: Yup.string().required(polyglot.t("validation.requiredField")),
      title: Yup.string().required(polyglot.t("validation.requiredField")),
      description: Yup.string().nullable().notRequired(),
      levelId: Yup.number().nullable().notRequired()
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, editDeleteHeaderSx), { display: "flex", justifyContent: "space-between", width: "100%" }), children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: isEditMode ? polyglot.t("JobPositionFormDrawer.edit") : polyglot.t("JobPositionFormDrawer.new") }) }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "title",
        label: polyglot.t("JobPositionFormDrawer.jobTitle"),
        value: formik.values.title,
        onChange: formik.handleChange,
        error: formik.touched.title && !!formik.errors.title,
        helperText: formik.touched.title && formik.errors.title,
        type: "text",
        required: true,
        clearText: () => formik.setFieldValue("title", "")
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "internalCode",
        label: polyglot.t("JobPositionFormDrawer.internalCode"),
        value: formik.values.internalCode,
        onChange: formik.handleChange,
        error: formik.touched.internalCode && !!formik.errors.internalCode,
        helperText: formik.touched.internalCode && formik.errors.internalCode,
        type: "text",
        required: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        multiline: true,
        name: "description",
        label: polyglot.t("JobPositionFormDrawer.jobDescription"),
        value: formik.values.description,
        onChange: formik.handleChange,
        error: formik.touched.description && !!formik.errors.description,
        helperText: formik.touched.description && formik.errors.description,
        type: "text",
        clearText: () => formik.setFieldValue("description", "")
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "levelId",
        label: polyglot.t("JobPositionFormDrawer.level"),
        options: jobsLookup,
        value: formik.values.levelId,
        compareValue: formik.values.levelId,
        error: !!formik.errors.levelId && formik.touched.levelId,
        onChange: formik.handleChange,
        helperText: formik.errors.levelId && formik.touched.levelId
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "primary", sizeVariant: "medium", disabled: loading, fullWidth: true, type: "submit", children: polyglot.t("General.save") }) })
  ] }) }) });
};
