"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { IconButton, Stack } from "@mui/material";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { sortBoolean, sortString } from "@/v2/components/table/table-sorting.util";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const getMode = (code) => code.isControlCode ? "Control" : code.isSystemCode ? "System" : "Custom";
export const CALCULATION_METHOD_OPTIONS = [
  { label: "Fixed Amount", value: "fixedAmount" },
  { label: "Multiple of Rate (Hours)", value: "multipleHourly" },
  { label: "Multiple of Rate (Days)", value: "multipleDaily" },
  { label: "Percentage of Base", value: "percentage" }
];
export const PAYCODE_TYPE_OPTIONS = [
  { label: "Addition", value: "addition" },
  { label: "Deduction", value: "deduction" }
];
export const PayrollSettingsPayCodesTable = ({
  payCodes,
  accountingConfig,
  onEditPayCodeClick
}) => {
  const sortedPayCodes = useMemo(() => {
    return payCodes == null ? void 0 : payCodes.sort(
      (a, b) => Number(a.isControlCode) - Number(b.isControlCode) || Number(a.isSystemCode) - Number(b.isSystemCode) || a.code.localeCompare(b.code)
    );
  }, [payCodes]);
  const accountingCodes = useMemo(() => {
    var _a;
    return new Map((_a = accountingConfig == null ? void 0 : accountingConfig.accountingCodes.fields) == null ? void 0 : _a.map((f) => [f.payCode, `${f.code} - ${f.nominalName}`]));
  }, [accountingConfig == null ? void 0 : accountingConfig.accountingCodes.fields]);
  const columns = useMemo(
    () => [
      {
        id: "code",
        header: () => "Code",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.code),
        cell: (c) => /* @__PURE__ */ jsx("div", { children: c.row.original.code })
      },
      {
        id: "name",
        header: () => "Name",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.title),
        cell: (c) => /* @__PURE__ */ jsx("div", { children: c.row.original.title })
      },
      {
        id: "type",
        header: () => "Type",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isDeduction, true),
        cell: (c) => /* @__PURE__ */ jsx("div", { children: c.row.original.isDeduction ? "Deduction" : "Addition" })
      },
      {
        id: "niable",
        header: () => "NI",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isNiable),
        cell: (c) => /* @__PURE__ */ jsx("div", { children: c.row.original.isNiable ? "Yes" : "No" })
      },
      {
        id: "taxable",
        header: () => "Tax",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isTaxable),
        cell: (c) => /* @__PURE__ */ jsx("div", { children: c.row.original.isTaxable ? "Yes" : "No" })
      },
      {
        id: "pensionable",
        header: () => "Pension",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isPensionable),
        cell: (c) => /* @__PURE__ */ jsx("div", { children: c.row.original.isPensionable ? "Yes" : "No" })
      },
      {
        id: "attachmentorderable",
        header: () => "Attachment Orders",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isAttachable),
        cell: (c) => /* @__PURE__ */ jsx("div", { children: c.row.original.isAttachable ? "Yes" : "No" })
      },
      {
        id: "kind",
        header: () => "Kind",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => getMode(item)),
        cell: (c) => /* @__PURE__ */ jsx("div", { children: getMode(c.row.original) })
      },
      {
        id: "accounting-code",
        header: () => "Accounting code",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => accountingCodes.get(item.code)),
        cell: (c) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = accountingCodes.get(c.row.original.code)) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        id: "action",
        header: () => "",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: (c) => /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10, justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            sx: tableIconButtonSx,
            title: `Edit ${c.row.original.code}`,
            onClick: () => onEditPayCodeClick(c.row.original),
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) })
      }
    ],
    [accountingCodes, onEditPayCodeClick]
  );
  return /* @__PURE__ */ jsx(BasicTable, { loading: !sortedPayCodes, rowData: sortedPayCodes != null ? sortedPayCodes : [], columnData: columns, hidePagination: true });
};
