"use strict";
export class LocalDate {
  /**
   * Creates a LocalDate from a string or a JS local date
   * @param initDate string or local date
   * @param options values for hour, minute, seconds and ms when date is today
   */
  constructor(initDate, options) {
    if (!initDate) {
      this.date = /* @__PURE__ */ new Date();
      if ((options == null ? void 0 : options.h) !== void 0 && (options == null ? void 0 : options.h) !== null) this.date.setHours(options.h);
      if ((options == null ? void 0 : options.min) !== void 0 && (options == null ? void 0 : options.min) !== null) this.date.setMinutes(options.min);
      if ((options == null ? void 0 : options.s) !== void 0 && (options == null ? void 0 : options.s) !== null) this.date.setSeconds(options.s);
      if ((options == null ? void 0 : options.ms) !== void 0 && (options == null ? void 0 : options.ms) !== null) this.date.setMilliseconds(options.ms);
    } else if (typeof initDate !== "string") {
      this.date = new Date(initDate);
    } else if (initDate.includes("T")) {
      this.date = new Date(initDate);
    } else if (LocalDate.isISOShortDate(initDate)) {
      const [y, m, d] = initDate.split("-");
      this.date = /* @__PURE__ */ new Date(`${m}/${d}/${y}`);
    } else {
      this.date = new Date(initDate);
    }
  }
  static isISOShortDate(value) {
    if (typeof value !== "string") return false;
    if (!/^\d{4}-\d{2}-\d{2}$/.test(value)) return false;
    const [y, m, d] = value.split("-");
    const date = new Date(Number(y), Number(m) - 1, Number(d));
    if (date.getFullYear() !== Number(y)) return false;
    if (date.getMonth() !== Number(m) - 1) return false;
    if (date.getDate() !== Number(d)) return false;
    return true;
  }
  getDate() {
    return this.date;
  }
  setDate(date) {
    this.date = date;
  }
  // Converts local date to '2023-05-23' string format
  toDateString() {
    const y = this.date.getFullYear();
    const m = (100 + this.date.getMonth() + 1).toString().slice(-2);
    const d = (100 + this.date.getDate()).toString().slice(-2);
    return `${y}-${m}-${d}`;
  }
  // Converts local date to '2023-05-23T12:14:00' string format
  toFullString() {
    const y = this.date.getFullYear();
    const m = (100 + this.date.getMonth() + 1).toString().slice(-2);
    const d = (100 + this.date.getDate()).toString().slice(-2);
    const time = this.date.toTimeString().split(" ")[0];
    return `${y}-${m}-${d}T${time}`;
  }
  /**
   * Converts local date to '2023-08-04 11:25:00' string format
   */
  toDateTimeString() {
    const y = this.date.getFullYear();
    const m = (100 + this.date.getMonth() + 1).toString().slice(-2);
    const d = (100 + this.date.getDate()).toString().slice(-2);
    const time = this.date.toTimeString().split(" ")[0];
    return `${y}-${m}-${d} ${time}`;
  }
  // Converts local date to '12:14:00' string format
  toTimeOnlyString(options) {
    if (!options) return this.date.toTimeString().split(" ")[0];
    const [h, m, s] = this.date.toTimeString().split(" ")[0].split(":");
    let result = [];
    if (options.hour) result.push(h);
    if (options.minute) result.push(m);
    if (options.seconds) result.push(s);
    return result.join(":");
  }
  // Converts local date to ISO FORMAT - should be used only for timestamps
  toISOString() {
    return this.date.toISOString();
  }
  // Converts local date to '01 Apr' string format
  toDayMonthString() {
    const d = (100 + this.date.getDate()).toString().slice(-2);
    const m = this.date.toLocaleString("default", { month: "short" });
    return `${d} ${m}`;
  }
  // Converts local date to '01 Jul 2024' string format
  toDayMonthYearString() {
    const d = (100 + this.date.getDate()).toString().slice(-2);
    const m = this.date.toLocaleString("default", { month: "short" });
    const y = this.date.getFullYear();
    return `${d} ${m} ${y}`;
  }
  getNextDateString() {
    const nextDate = new Date(this.date);
    nextDate.setDate(nextDate.getDate() + 1);
    const y = nextDate.getFullYear();
    const m = (100 + nextDate.getMonth() + 1).toString().slice(-2);
    const d = (100 + nextDate.getDate()).toString().slice(-2);
    return `${y}-${m}-${d}`;
  }
  getPreviousDateString() {
    const prevDate = new Date(this.date);
    prevDate.setDate(prevDate.getDate() - 1);
    const y = prevDate.getFullYear();
    const m = (100 + prevDate.getMonth() + 1).toString().slice(-2);
    const d = (100 + prevDate.getDate()).toString().slice(-2);
    return `${y}-${m}-${d}`;
  }
  toLocaleDateString(locales, options) {
    return this.date.toLocaleDateString(locales, options);
  }
  getFirstDayOfMonth() {
    const auxDate = new Date(this.date);
    auxDate.setDate(1);
    return new LocalDate(auxDate);
  }
  getLastDayOfMonth() {
    const auxDate = this.getFirstDayOfMonth().getDate();
    auxDate.setMonth(auxDate.getMonth() + 1, 0);
    return new LocalDate(auxDate);
  }
  getFirstDayOfYear() {
    const year = this.date.getFullYear();
    return new LocalDate(`${year}-01-01`);
  }
  getLastDayOfYear() {
    const year = this.date.getFullYear();
    return new LocalDate(`${year}-12-31`);
  }
  isDate() {
    return !Number.isNaN(this.date.getTime());
  }
}
