"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import {
  calcPaycodeTotalForPayrunEntry,
  getOptionalPayCodesInUse
} from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { EditIncomePage } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-income-page.component";
import { EditPayrunEntryDrawer } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/edit-payrun-entry-drawer.component";
import {
  CurrencyWithDiff,
  ValueWithDiff
} from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/value-with-diff.component";
import { DEFAULT_SIZE } from "@/v2/styles/table.styles";
import { sum } from "@/v2/util/array.util";
export const PayrunIncomeTable = ({
  entries,
  previousEntries,
  payCodes,
  payrunClosed,
  saveIncomeUpdates,
  sx
}) => {
  const [editingIncome, setEditingIncome] = useState();
  const optionalAdditionsInUse = useMemo(() => getOptionalPayCodesInUse(payCodes != null ? payCodes : [], entries, false), [
    entries,
    payCodes
  ]);
  const paycodeMap = useMemo(() => {
    const codeMap = /* @__PURE__ */ new Map();
    for (const eachPaycode of payCodes != null ? payCodes : []) {
      codeMap.set(eachPaycode.code, eachPaycode);
    }
    return codeMap;
  }, [payCodes]);
  const [incomeTotals, prevIncomeTotals] = useMemo(() => {
    const calculateTotals = (entries2) => ({
      salary: sum(entries2, (e) => {
        var _a;
        return (_a = e.salary) != null ? _a : 0;
      }),
      income: sum(entries2, (e) => {
        var _a;
        return (_a = e.income) != null ? _a : 0;
      })
    });
    return [
      calculateTotals(entries),
      previousEntries.length ? calculateTotals(previousEntries) : {}
    ];
  }, [entries, previousEntries]);
  const [optionalAdditionsTotals, prevOptionalAdditionsTotals] = useMemo(() => {
    const calculateTotals = (entries2) => {
      const totals = {};
      for (const { code } of optionalAdditionsInUse) {
        const paycodeDetail = paycodeMap.get(code);
        totals[code] = sum(entries2, (e) => calcPaycodeTotalForPayrunEntry(e, code, paycodeDetail));
      }
      return totals;
    };
    return [calculateTotals(entries), previousEntries.length ? calculateTotals(previousEntries) : {}];
  }, [entries, optionalAdditionsInUse, paycodeMap, previousEntries]);
  const columnData = useMemo(() => {
    const previousById = new Map(previousEntries.map((item) => [item.employee.id, item]));
    const getPreviousPayrunEntry = (context) => {
      return previousById.get(context.getValue().employee.id);
    };
    const hideColumnIfNoValues = (visibleSize, getValue) => {
      return entries.some(getValue) || previousEntries.some(getValue) ? visibleSize : 0;
    };
    const columns = [
      {
        id: "employee",
        header: () => "Employee",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.employee.name),
        cell: (c) => /* @__PURE__ */ jsx(UserCell, { userId: c.row.original.userId }),
        footer: () => "Total",
        size: DEFAULT_SIZE
      },
      {
        id: "salary",
        header: () => "Salary",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (entry) => {
          var _a;
          return (_a = entry.salary) != null ? _a : 0;
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: getPreviousPayrunEntry(c),
            getValue: (entry) => {
              var _a;
              return (_a = entry.salary) != null ? _a : 0;
            }
          }
        ),
        footer: () => /* @__PURE__ */ jsx(CurrencyWithDiff, { currentValue: incomeTotals.salary, previousValue: prevIncomeTotals.salary }),
        size: DEFAULT_SIZE
      },
      {
        id: "salary-basis",
        header: () => "Salary basis",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.payOptions.basis),
        cell: (c) => /* @__PURE__ */ jsx("div", { children: c.getValue().salaryBasis }),
        size: DEFAULT_SIZE
      },
      {
        id: "units",
        header: () => /* @__PURE__ */ jsx("span", { title: "Only applicable for employees with Hourly or Daily rate", children: "Units" }),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (entry) => {
          var _a;
          return (_a = entry.salaryUnits) != null ? _a : 0;
        }),
        cell: (c) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: ((_a = c.getValue().salaryUnits) != null ? _a : 0).toFixed(2).replace(".00", "") });
        },
        size: DEFAULT_SIZE
      },
      {
        id: "rate",
        header: () => "Rate",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.payOptions.payAmount),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: getPreviousPayrunEntry(c),
            getValue: (item) => {
              var _a;
              return (_a = item.salaryRate) != null ? _a : 0;
            }
          }
        ),
        size: hideColumnIfNoValues(20, (e) => e.payOptions.payAmount - e.totals.basicPay)
      },
      ...optionalAdditionsInUse.sort((a, b) => a.title.localeCompare(b.title, void 0, { sensitivity: "base" })).map(({ code, title }) => ({
        id: code,
        header: () => title,
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          const paycodeDetail = paycodeMap.get(code);
          return calcPaycodeTotalForPayrunEntry(item, code, paycodeDetail);
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: getPreviousPayrunEntry(c),
            getValue: (item) => {
              const paycodeDetail = paycodeMap.get(code);
              return calcPaycodeTotalForPayrunEntry(item, code, paycodeDetail);
            },
            dimIfZero: true
          }
        ),
        footer: () => /* @__PURE__ */ jsx(
          CurrencyWithDiff,
          {
            currentValue: optionalAdditionsTotals[code],
            previousValue: prevOptionalAdditionsTotals[code]
          }
        ),
        size: DEFAULT_SIZE
      })),
      {
        id: "total-income",
        header: () => "Total income",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (entry) => {
          var _a;
          return (_a = entry.income) != null ? _a : 0;
        }),
        cell: (c) => /* @__PURE__ */ jsx(
          ValueWithDiff,
          {
            current: c.getValue(),
            previous: getPreviousPayrunEntry(c),
            getValue: (entry) => {
              var _a;
              return (_a = entry.income) != null ? _a : 0;
            }
          }
        ),
        footer: () => /* @__PURE__ */ jsx(CurrencyWithDiff, { currentValue: incomeTotals.income, previousValue: prevIncomeTotals.income }),
        size: DEFAULT_SIZE
      }
    ];
    return columns.filter((column) => column.size);
  }, [
    entries,
    incomeTotals,
    optionalAdditionsInUse,
    optionalAdditionsTotals,
    previousEntries,
    prevIncomeTotals,
    prevOptionalAdditionsTotals,
    paycodeMap
  ]);
  return /* @__PURE__ */ jsxs(Box, { sx, children: [
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: entries,
        columnData,
        hidePagination: true,
        showFooter: true,
        rowClick: (row) => setEditingIncome(row.original)
      }
    ),
    /* @__PURE__ */ jsx(
      EditPayrunEntryDrawer,
      {
        userId: editingIncome == null ? void 0 : editingIncome.userId,
        payrunEntry: editingIncome,
        payCodes,
        payrunClosed,
        saveIncomeUpdates,
        onClose: () => setEditingIncome(void 0),
        Content: EditIncomePage
      }
    )
  ] });
};
