"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
const _OnboardingAPI = class _OnboardingAPI {
  static createOnboardingTemplate(values) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/onboarding/template", values)).data;
    });
  }
  static getOnboardingTemplates() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/onboarding/template")).data;
    });
  }
  static assignTemplateTasksToUser(userId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/onboarding/tasks/${userId}`)).data;
    });
  }
  static getInitOnboardingState(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/onboarding/users/${userId}/init-state`)).data || null;
    });
  }
  static initiateIdentityChecks(userId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/onboarding/id-check/${userId}`)).data;
    });
  }
  static initiateIdentityChecksFromUserProfile(userId, packageForIdCheck, checkCountry) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/onboarding/id-check/${userId}/profile/${packageForIdCheck.id}`, { checkCountry })).data;
    });
  }
  static refreshIdentityCheckResult(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/onboarding/id-check/${userId}/result`)).data;
    });
  }
  static downloadIdCheckReport(state) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/onboarding/id-check-download-result`, state)).data;
    });
  }
};
_OnboardingAPI.updateOnboardingTemplate = (value) => __async(_OnboardingAPI, null, function* () {
  return (yield axios.patch("/apiv2/onboarding/template", value)).data;
});
_OnboardingAPI.deleteOnboardingTemplate = (templateId) => __async(_OnboardingAPI, null, function* () {
  yield axios.delete(`/apiv2/onboarding/template/${templateId}`);
});
_OnboardingAPI.getOnboardingUser = (userId) => __async(_OnboardingAPI, null, function* () {
  return (yield axios.get(`/apiv2/onboarding/users/${userId}`)).data || null;
});
_OnboardingAPI.createUserOnboarding = (values, refreshUserCache) => __async(_OnboardingAPI, null, function* () {
  const userId = (yield axios.post("/apiv2/users-onboarding/create-user", values)).data;
  yield refreshUserCache();
  return userId;
});
_OnboardingAPI.updateUserOnboarding = (values, refreshUserCache) => __async(_OnboardingAPI, null, function* () {
  const userId = (yield axios.patch("/apiv2/users-onboarding/update-user", values)).data;
  yield refreshUserCache();
  return userId;
});
_OnboardingAPI.onboardingSteps = () => __async(_OnboardingAPI, null, function* () {
  return (yield axios.get("/apiv2/users-onboarding/onboarding-steps")).data;
});
_OnboardingAPI.onboardingForms = () => __async(_OnboardingAPI, null, function* () {
  return (yield axios.get("/apiv2/users-onboarding/onboarding-forms")).data;
});
_OnboardingAPI.onboardingMissingFields = () => __async(_OnboardingAPI, null, function* () {
  return (yield axios.get("/apiv2/users-onboarding/missing-fields")).data;
});
_OnboardingAPI.onboardingStepsStatus = () => __async(_OnboardingAPI, null, function* () {
  return (yield axios.get("/apiv2/users-onboarding/steps-status")).data;
});
_OnboardingAPI.checkPasswordExists = (userId) => __async(_OnboardingAPI, null, function* () {
  return (yield axios.get(`/apiv2/users-onboarding/password-exist/${userId}`)).data;
});
_OnboardingAPI.isChecklistCurrentlyUsedInOnboarding = (checklistId) => __async(_OnboardingAPI, null, function* () {
  return (yield axios.get(`/apiv2/onboarding/is-checklist-used/${checklistId}`)).data;
});
export let OnboardingAPI = _OnboardingAPI;
export class OnboardingEndpoints {
  static getOnboardingUsers() {
    return {
      url: "/apiv2/onboarding/users"
    };
  }
  static isAppCurrentlyUsedInOnboarding(appStub) {
    return {
      url: `/apiv2/onboarding/is-app-used/${appStub}`
    };
  }
  static isContractTemplateCurrentlyUsedInOnboarding(contractTemplateId) {
    return {
      url: `/apiv2/onboarding/is-contract-template-used/${contractTemplateId}`
    };
  }
  static isIdCheckPackageCurrentlyUsedInOnboarding(packageId) {
    return {
      url: `/apiv2/onboarding/is-id-check-package-used/${packageId}`
    };
  }
  static isChecklistCurrentlyUsedInOnboarding(checklistId) {
    return {
      url: `/apiv2/onboarding/is-checklist-used/${checklistId}`
    };
  }
  static getIDCheckPackages() {
    return {
      url: "/apiv2/onboarding/id-check/packages"
    };
  }
  static getUserMissingFields() {
    return {
      url: "/apiv2/users-onboarding/missing-fields"
    };
  }
  static getStepsStatus() {
    return {
      url: "/apiv2/users-onboarding/steps-status"
    };
  }
  static getIDCheckResult(userId) {
    return {
      url: `/apiv2/onboarding/id-check/${userId}/result`
    };
  }
}
