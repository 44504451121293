"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { BenefitsSettingsRouter } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings.router";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { useCompanyConfigState } from "@/hooks/company-config.hook";
import useScopes from "@/hooks/scopes.hook";
import {
  SETTINGS_BENEFITS_ROUTE,
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE,
  SETTINGS_MONEY_SALARY_ROUTE,
  SETTINGS_MONEY_INVOICES_ROUTE,
  SETTINGS_MONEY_EXPENSES_ROUTE,
  SETTINGS_MONEY_INVOICE_TYPES_DETAILS_ROUTE,
  SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ROUTE
} from "@/lib/routes";
import { PaymentType } from "@/v2/feature/payments/payments.dto";
import { PayrollCompanySettingsPayrollList } from "@/v2/feature/payroll/features/payroll-settings/payroll-company-settings-payroll-list.page";
import { PayrollCompanySettingsRouter } from "@/v2/feature/payroll/features/payroll-settings/payroll-company-settings-payroll.router";
import { ExpenseTypeSettingsDetailsRouter } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/subfeature/expense-type-settings-details.router";
import { InvoiceTypeSettingsDetailsRouter } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/subfeature/invoice-type-settings-details.router";
import { PaymentsSettingsPage } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payments-settings.page";
import { PayrollContext } from "@/v2/feature/payroll/payroll-company.router";
export const MoneySettingsRouter = ({ context }) => {
  const { refreshCompanyConfig } = useCompanyConfigState();
  const { hasScopes } = useScopes();
  const hasPayrollAll = hasScopes(["payroll:all"]);
  const hasBenefitsAll = hasScopes(["pension:all", "insurance:all"]);
  return /* @__PURE__ */ jsx(PayrollContext.Provider, { value: { refreshPayrollState: () => refreshCompanyConfig() }, children: /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["insurance:all", "pension:all"],
        path: SETTINGS_BENEFITS_ROUTE,
        component: BenefitsSettingsRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["payments:all", "expenses:all"],
        path: SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ROUTE,
        component: ExpenseTypeSettingsDetailsRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["payments:all", "invoices:all"],
        path: SETTINGS_MONEY_INVOICE_TYPES_DETAILS_ROUTE,
        component: InvoiceTypeSettingsDetailsRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["payroll:all", "payroll.selectedEntity:all"],
        path: SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE,
        component: PayrollCompanySettingsRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        context,
        scopes: ["payroll:all", "payroll.selectedEntity:all"],
        path: SETTINGS_MONEY_SALARY_ROUTE,
        exact: true,
        children: /* @__PURE__ */ jsx(PayrollCompanySettingsPayrollList, {})
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { context, scopes: ["invoices:all"], path: SETTINGS_MONEY_INVOICES_ROUTE, exact: true, children: /* @__PURE__ */ jsx(PaymentsSettingsPage, { type: PaymentType.Invoice }) }),
    /* @__PURE__ */ jsx(RouteScopesControl, { context, scopes: ["expenses:all"], path: SETTINGS_MONEY_EXPENSES_ROUTE, exact: true, children: /* @__PURE__ */ jsx(PaymentsSettingsPage, { type: PaymentType.Expense }) }),
    hasPayrollAll && /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_MONEY_SALARY_ROUTE }),
    hasBenefitsAll && /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_BENEFITS_ROUTE })
  ] }) });
};
