"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { spacing } from "@v2/styles/spacing.styles";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
export const ActivePolicies = ({ device, loading }) => {
  var _a;
  const policiesTableColumns = useMemo(
    () => [
      {
        header: () => "Name",
        accessorFn: (row) => row.name,
        id: "name",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: (original == null ? void 0 : original.name) ? original.name : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => "Description",
        accessorFn: (row) => row.description,
        id: "description",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: (original == null ? void 0 : original.description) ? original.description : /* @__PURE__ */ jsx(EmptyCell, {}) })
      }
    ],
    []
  );
  return /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(BasicTable, { rowData: (_a = device.policies) != null ? _a : [], columnData: policiesTableColumns, loading, hidePagination: true }) });
};
