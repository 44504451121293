"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { useLayoutEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";
import { sortCustomFields } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { UserShowCustomFieldComponent } from "@/v2/feature/user/components/user-show-custom-field.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserCustomField = ({ values, onChange, formFields, disabled, fieldSx }) => {
  const [customUpdates, setCustomUpdates] = useState(values);
  useLayoutEffect(() => {
    if (!formFields || formFields.length === 0) return;
    setCustomUpdates(
      formFields.map((field) => {
        var _a;
        return {
          fieldId: field.fieldId,
          formId: field.formId,
          field,
          value: ((_a = values.find((v) => v.fieldId === field.fieldId)) == null ? void 0 : _a.value) || ""
        };
      })
    );
  }, [formFields, values]);
  const handleFieldChange = (fieldId, value) => {
    const updatedFields = customUpdates.map((item) => item.fieldId === fieldId ? __spreadProps(__spreadValues({}, item), { value }) : item);
    setCustomUpdates(updatedFields);
    onChange(updatedFields);
  };
  const sortedCustomFields = useMemo(() => sortCustomFields(customUpdates), [customUpdates]);
  return sortedCustomFields.length > 0 ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: sortedCustomFields.map((field) => {
    return /* @__PURE__ */ jsx(
      UserShowCustomFieldComponent,
      {
        field,
        onChange: handleFieldChange,
        disabled,
        fieldSx
      },
      field.fieldId
    );
  }) }) : null;
};
