"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class DocumentNewAPI {
  static getDocumentsByCompanyId(page, pageSize, filterString, searchInput, reach, statusFilterValue) {
    return __async(this, null, function* () {
      var _a;
      const urlParams = new URLSearchParams(__spreadProps(__spreadValues(__spreadValues({}, filterString && { filterString }), searchInput && { searchInput }), {
        reach,
        status: statusFilterValue,
        page: page.toString(),
        pageSize: pageSize.toString()
      }));
      return (_a = (yield axios.get(`/apiv2/documents-new/company?${urlParams}`)).data) != null ? _a : [];
    });
  }
  static getDocumentsByManager(page, pageSize, filterString, searchInput, reach, statusFilterValue) {
    return __async(this, null, function* () {
      var _a;
      const urlParams = new URLSearchParams(__spreadProps(__spreadValues(__spreadValues({}, filterString && { filterString }), searchInput && { searchInput }), {
        reach,
        status: statusFilterValue,
        page: page.toString(),
        pageSize: pageSize.toString()
      }));
      return (_a = (yield axios.get(`/apiv2/documents-new/team?${urlParams}`)).data) != null ? _a : [];
    });
  }
  static getDocumentsByUserId(userId, page, pageSize, filterString, searchInput, viewFilter) {
    return __async(this, null, function* () {
      var _a;
      const urlParams = new URLSearchParams(__spreadProps(__spreadValues(__spreadValues({}, filterString && { filterString }), searchInput && { searchInput }), {
        view: viewFilter,
        page: page.toString(),
        pageSize: pageSize.toString()
      }));
      return (_a = (yield axios.get(`/apiv2/documents-new/user/${userId}?${urlParams}`)).data) != null ? _a : [];
    });
  }
  static getAllDocumentsByUserId(userId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.get(`/apiv2/documents-new/user/all/${userId}`)).data) != null ? _a : [];
    });
  }
  static getDocumensById(documentId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.get(`/apiv2/documents-new/${documentId}`)).data) != null ? _a : [];
    });
  }
  static createDocument(document) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.post(`/apiv2/documents-new`, document)).data) != null ? _a : [];
    });
  }
  static createP45Document(document) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.post(`/apiv2/documents-new/p45`, document)).data) != null ? _a : [];
    });
  }
  static updateDocument(document, documentId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.patch(`/apiv2/documents-new/${documentId}`, document)).data) != null ? _a : [];
    });
  }
  static sendReminder(documentId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.post(`/apiv2/documents-new/${documentId}/remind`)).data) != null ? _a : [];
    });
  }
  static cancelDocument(documentId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.delete(`/apiv2/documents-new/${documentId}/cancel`)).data) != null ? _a : [];
    });
  }
  static deleteDocument(documentId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.delete(`/apiv2/documents-new/${documentId}`)).data) != null ? _a : [];
    });
  }
  static deleteFileByDocumentId(documentId) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.delete(`/apiv2/documents-new/file/by-doc/${documentId}`)).data) != null ? _a : [];
    });
  }
  static deleteFileByUuid(fileUuid) {
    return __async(this, null, function* () {
      var _a;
      return (_a = (yield axios.delete(`/apiv2/documents-new/file/by-file/${fileUuid}`)).data) != null ? _a : [];
    });
  }
  static downloadViaUuid(fileUuid, originalFilename) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/documents-new/download-via-uuid", { fileUuid, originalFilename })).data;
    });
  }
  static fetchFileDataById(documentId, type) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/documents-new/${documentId}/file-data`, { documentId, type })).data;
    });
  }
  static bulkDelete(documentIds) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/documents-new/bulk/delete`, { documentIds })).data;
    });
  }
  // static async bulkUpload(formData: DocumentNewImportDto[]): Promise<void> {
  //   return (
  //     (
  //       await axios.post(`/apiv2/documents-new/bulk-upload`, formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       })
  //     ).data ?? []
  //   );
  // }
  static uploadDocument(fileData, documentToUpload) {
    return __async(this, null, function* () {
      return yield axios.post(`/apiv2/documents-new/upload`, {
        fileData,
        documentToUpload
      });
    });
  }
}
export class DocumentNewEndpoints {
}
