"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { ReportsTestPage } from "@v2/feature/reports/reports-test/reports-test.page";
import { ReportsV2CompanyRouter } from "@v2/feature/reports/reports-v2-company.router";
import { Redirect, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import {
  REPORT_COMPANY_REPORTS_TEST,
  REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE,
  REPORT_COMPANY_REPORTS_V2_ROUTE,
  REPORTS_COMPANY_INSIGHTS_ROUTE
} from "@/lib/routes";
import { AnalyticsCompanyRouter } from "@/v2/feature/analytics/analytics-company.router";
const AnalyticsScopes = ["company.settings:all", "payroll:all"];
export const ReportCompanyRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext, hasScopesAccess } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const hasAccessToAnalytics = hasScopesAccess(AnalyticsScopes);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: AnalyticsScopes,
        context: scopesContext,
        path: [REPORTS_COMPANY_INSIGHTS_ROUTE],
        component: AnalyticsCompanyRouter
      }
    ),
    /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        scopes: ["reports:all"],
        context: scopesContext,
        path: [REPORT_COMPANY_REPORTS_V2_ROUTE],
        component: ReportsV2CompanyRouter
      }
    ),
    /* @__PURE__ */ jsx(RouteScopesControl, { scopes: ["reports:all"], context: scopesContext, path: [REPORT_COMPANY_REPORTS_TEST], children: /* @__PURE__ */ jsx(ReportsTestPage, {}) }),
    /* @__PURE__ */ jsx(Redirect, { to: hasAccessToAnalytics ? REPORTS_COMPANY_INSIGHTS_ROUTE : REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE })
  ] });
};
