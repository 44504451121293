"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { Typography } from "@v2/components/typography/typography.component";
export const NumberCell = ({
  value,
  cellSx = { maxWidth: "100px" }
}) => /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ width: "100%", display: "flex", justifyContent: "flex-end" }, cellSx), children: value !== null && value !== void 0 ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: value }) : /* @__PURE__ */ jsx(EmptyCell, { textAlign: "right" }) });
