"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import { keyBy } from "lodash";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { CompanyEndpoints } from "@/api-client/company.api";
import { SiteEndpoints } from "@/api-client/site.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Download } from "@/images/side-bar-icons/Download.svg";
import { ReactComponent as Eye } from "@/images/side-bar-icons/Eye.svg";
import { nestErrorMessage } from "@/lib/errors";
import { checkScopes } from "@/lib/scopes";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserSelectFiltersOptions } from "@/v2/components/user-select-type/user-select.interface";
import { DocumentNewAPI } from "@/v2/feature/document-new/api-client/document-new.api";
import { NewDocumentAction } from "@/v2/feature/document-new/components/new-document-action.component";
import { NewDocumentPreview } from "@/v2/feature/document-new/components/new-document-preview.component";
import {
  getBelongsToByMemberRule,
  getCustomRuleString,
  getFileName,
  getImageByFileName,
  signatoriesRequiredForContract
} from "@/v2/feature/document-new/document-new.util";
import {
  ActionView,
  DocumentFileOperation,
  DocumentNewStatus
} from "@/v2/feature/document-new/interfaces/document-new.interface";
import { DisplayDocumentNewStatus } from "@/v2/feature/document-new/utils/new-document-status.util";
import { stringToDownloadFile, stringToPreviewFile } from "@/v2/feature/documents/documents.util";
import { SignatoryType } from "@/v2/feature/templates/templates.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { dateAPItoDisplay } from "@/v2/util/date-format.util";
export const NewDocumentViewModal = ({
  isOpen,
  setIsOpen,
  actions,
  documentTypes,
  document,
  onClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(NewDocumentViewContent, { documentTypes: documentTypes != null ? documentTypes : [], document, actions }) });
};
const NewDocumentViewContent = ({
  documentTypes,
  document,
  actions
}) => {
  var _a;
  const [globalState] = useContext(GlobalContext);
  const { polyglot } = usePolyglot();
  const hasDocumentsAllScope = checkScopes(globalState.user, ["documents:all"], { userId: globalState.user.userId });
  const { data: sitesForCompany } = useApiClient(
    SiteEndpoints.getSites(),
    { suspense: false },
    document && document.membersRule === UserSelectFiltersOptions.CustomRule
  );
  const { data: departmentsForCompany } = useApiClient(
    DepartmentEndpoints.getCompanyDepartments(),
    {
      suspense: false
    },
    document && document.membersRule === UserSelectFiltersOptions.CustomRule
  );
  const { data: entitiesForCompany } = useApiClient(
    CompanyEndpoints.getCompanyEntities(),
    { suspense: false },
    document && document.membersRule === UserSelectFiltersOptions.CustomRule
  );
  const ruleString = useMemo(
    () => document && document.membersRule === UserSelectFiltersOptions.CustomRule && document.customRule ? getCustomRuleString(document.customRule, sitesForCompany, departmentsForCompany, entitiesForCompany != null ? entitiesForCompany : []) : "",
    [departmentsForCompany, sitesForCompany, entitiesForCompany, document]
  );
  const hasDocumentsManagerScope = useMemo(() => {
    if (document && document.userId) {
      return checkScopes(globalState.user, ["documents:manager"], { userId: document.userId });
    }
    return false;
  }, [document, globalState.user]);
  const managerOrAdmin = useMemo(() => {
    return hasDocumentsAllScope || hasDocumentsManagerScope;
  }, [hasDocumentsAllScope, hasDocumentsManagerScope]);
  if (!document) {
    /* @__PURE__ */ jsxs(Box, { style: drawerContentSx, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("General.oops") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("NewDocumentViewModal.errorMessages.notFound") })
    ] });
  }
  const documentTypeLookup = useMemo(() => {
    return keyBy(documentTypes != null ? documentTypes : [], "id");
  }, [documentTypes]);
  const expiryDate = document && ((_a = document.documentSettings) == null ? void 0 : _a.expiryDate) ? dateAPItoDisplay(document.documentSettings.expiryDate) : void 0;
  const showAction = useMemo(
    () => Boolean(
      (document == null ? void 0 : document.showSign) || (document == null ? void 0 : document.showEdit) || (document == null ? void 0 : document.showDownload) || (document == null ? void 0 : document.showCancel) || (document == null ? void 0 : document.showDelete) || (document == null ? void 0 : document.showRemind)
    ),
    [document]
  );
  const isPersonalDocument = useMemo(() => (document == null ? void 0 : document.membersRule) === UserSelectFiltersOptions.SelectSpecific, [
    document
  ]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      style: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.s2,
        height: "100%"
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("General.document") }),
          showAction && document && /* @__PURE__ */ jsx(NewDocumentAction, { document, actions, view: ActionView.Modal })
        ] }),
        /* @__PURE__ */ jsxs(
          Box,
          {
            style: {
              display: "flex",
              flexDirection: "column",
              gap: spacing.g10
            },
            children: [
              document && /* @__PURE__ */ jsx(
                ViewItem,
                {
                  label: polyglot.t("General.status"),
                  value: /* @__PURE__ */ jsx(DisplayDocumentNewStatus, { document, nameVariant: "title4" })
                }
              ),
              (document == null ? void 0 : document.documentSettings) && !isPersonalDocument && /* @__PURE__ */ jsx(
                ViewItem,
                {
                  label: polyglot.t("General.pinned"),
                  value: Boolean(document == null ? void 0 : document.documentSettings.isPinned) ? polyglot.t("General.yes") : "No"
                }
              ),
              (document == null ? void 0 : document.typeId) && /* @__PURE__ */ jsx(ViewItem, { label: polyglot.t("General.type"), value: documentTypeLookup[document == null ? void 0 : document.typeId].label }),
              (document == null ? void 0 : document.name) && /* @__PURE__ */ jsx(ViewItem, { label: polyglot.t("General.name"), value: document == null ? void 0 : document.name }),
              document && /* @__PURE__ */ jsx(
                ViewItem,
                {
                  label: polyglot.t("NewDocumentViewModal.belongsTo"),
                  value: getBelongsToByMemberRule(document, "title4", ruleString)
                }
              ),
              (document == null ? void 0 : document.uploadedBy) && /* @__PURE__ */ jsx(
                ViewItem,
                {
                  label: polyglot.t("NewDocumentViewModal.lastUpdatedBy"),
                  value: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(UserCell, { userId: document == null ? void 0 : document.uploadedBy, nameVariant: "title4" }) })
                }
              ),
              (document == null ? void 0 : document.updatedAt) && /* @__PURE__ */ jsx(
                ViewItem,
                {
                  label: polyglot.t("NewDocumentViewModal.lastUpdatedOn"),
                  value: getDateString(document.updatedAt)
                }
              )
            ]
          }
        ),
        ((document == null ? void 0 : document.showDownload) || (document == null ? void 0 : document.showPreview)) && ((document == null ? void 0 : document.fileUuid) || document.contractId && document.contract && document.contract.totalSignaturesRequired === document.contract.signaturesObtained) && /* @__PURE__ */ jsxs(
          Box,
          {
            style: {
              display: "flex",
              flexDirection: "column",
              gap: spacing.g10
            },
            children: [
              /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("NewDocumentViewModal.attachment") }),
              /* @__PURE__ */ jsx(DocumentView, { document })
            ]
          }
        ),
        (document == null ? void 0 : document.documentSettings) && !(document == null ? void 0 : document.contractId) && /* @__PURE__ */ jsxs(
          Box,
          {
            style: {
              display: "flex",
              flexDirection: "column",
              gap: spacing.g10
            },
            children: [
              /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("NewDocumentViewModal.settings") }),
              (document == null ? void 0 : document.documentSettings) && managerOrAdmin && isPersonalDocument && /* @__PURE__ */ jsx(
                ViewItem,
                {
                  label: polyglot.t("NewDocumentViewModal.hidden"),
                  value: Boolean(document == null ? void 0 : document.documentSettings.hideFromEmployee) ? polyglot.t("General.yes") : polyglot.t("General.no")
                }
              ),
              (document == null ? void 0 : document.documentSettings) && managerOrAdmin && isPersonalDocument && /* @__PURE__ */ jsx(
                ViewItem,
                {
                  label: polyglot.t("NewDocumentViewModal.private"),
                  value: Boolean(document == null ? void 0 : document.documentSettings.private) ? polyglot.t("General.yes") : polyglot.t("General.no")
                }
              ),
              /* @__PURE__ */ jsx(
                ViewItem,
                {
                  label: expiryDate ? polyglot.t("NewDocumentViewModal.expiryDate") : polyglot.t("NewDocumentViewModal.hasExpiry"),
                  value: expiryDate ? /* @__PURE__ */ jsx(
                    Typography,
                    {
                      variant: "title4",
                      color: document.status === DocumentNewStatus.Expired ? "RedDark" : "DarkGrey",
                      children: expiryDate
                    }
                  ) : polyglot.t("General.no")
                }
              )
            ]
          }
        ),
        (document == null ? void 0 : document.note) && !(document == null ? void 0 : document.contractId) && /* @__PURE__ */ jsxs(
          Box,
          {
            style: {
              display: "flex",
              flexDirection: "column",
              gap: spacing.g10
            },
            children: [
              /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("NewDocumentViewModal.note") }),
              /* @__PURE__ */ jsx(Typography, { variant: "caption", children: document == null ? void 0 : document.note })
            ]
          }
        ),
        (document == null ? void 0 : document.contractId) && document.contract && /* @__PURE__ */ jsx(ViewContractBlock, { contract: document.contract })
      ]
    }
  );
};
const ViewContractBlock = ({ contract }) => {
  const { polyglot } = usePolyglot();
  const signaturesRequired = useMemo(() => {
    if (!contract.templateText) return [];
    return signatoriesRequiredForContract(contract.templateText);
  }, [contract.templateText]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      style: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.g10
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title3", children: polyglot.t("NewDocumentViewModal.signatures") }),
        signaturesRequired.length ? /* @__PURE__ */ jsxs(
          Box,
          {
            style: {
              display: "flex",
              flexDirection: "column",
              gap: spacing.g10
            },
            children: [
              signaturesRequired.includes(SignatoryType.additional) && /* @__PURE__ */ jsx(
                ViewItem,
                {
                  label: contract.companySignatureTimestamp ? polyglot.t("NewDocumentViewModal.alreadySigned") : polyglot.t("NewDocumentViewModal.pendingSignature"),
                  value: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(UserCell, { userId: contract.companySignatory, nameVariant: "title4" }) })
                }
              ),
              signaturesRequired.includes(SignatoryType.recipient) && /* @__PURE__ */ jsx(
                ViewItem,
                {
                  label: contract.recipientSignatureTimestamp ? polyglot.t("NewDocumentViewModal.alreadySigned") : polyglot.t("NewDocumentViewModal.pendingSignature"),
                  value: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(UserCell, { userId: contract.recipient, nameVariant: "title4" }) })
                }
              )
            ]
          }
        ) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("NewDocumentViewModal.noSignatureRequired") })
      ]
    }
  );
};
const ViewItem = ({ label, value }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { width: "40%", textAlign: "left" }, children: label }),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          width: "60%",
          display: "flex",
          justifyContent: "flex-end"
        },
        children: /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "title4",
            sx: {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%"
            },
            children: value
          }
        )
      }
    )
  ] });
};
const DocumentView = ({ document }) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [error, setError] = useState(null);
  const [docData, setDocData] = useState(null);
  const fileURLRef = useRef(null);
  const handleDownload = useCallback(() => __async(void 0, null, function* () {
    try {
      const response = yield DocumentNewAPI.fetchFileDataById(document.id, DocumentFileOperation.download);
      stringToDownloadFile(response.data.data, response.contentType, response.extension, document.name);
    } catch (error2) {
      showMessage(
        polyglot.t("NewDocumentViewModal.errorMessages.download", { errorMessage: nestErrorMessage(error2) }),
        "error"
      );
    }
  }), [document.id, document.name, showMessage, polyglot]);
  const handlePreview = useCallback(() => __async(void 0, null, function* () {
    try {
      const response = yield DocumentNewAPI.fetchFileDataById(document.id, DocumentFileOperation.preview);
      const docDataResponse = yield stringToPreviewFile(
        response.data.data,
        response.contentType,
        response.extension,
        document.name
      );
      setDocData(docDataResponse);
      if (docDataResponse && docDataResponse.uri) fileURLRef.current = docDataResponse.uri;
      setIsPreviewOpen(true);
      setError(null);
    } catch (error2) {
      setError(polyglot.t("NewDocumentViewModal.errorMessages.preview"));
      console.error("Error previewing document:", error2);
    }
  }), [document.id, document.name, polyglot]);
  const closePreview = useCallback(() => {
    setIsPreviewOpen(false);
    if (fileURLRef.current) {
      URL.revokeObjectURL(fileURLRef.current);
      fileURLRef.current = null;
    }
  }, []);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1, maxWidth: "60%" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: getImageByFileName(document) }),
      /* @__PURE__ */ jsx(
        Typography,
        {
          variant: "caption",
          sx: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%"
          },
          children: getFileName(document)
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1, maxWidth: "40%" }, children: [
      (document == null ? void 0 : document.showPreview) && /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: handlePreview, children: /* @__PURE__ */ jsx(Eye, __spreadValues({}, iconSize)) }),
      (document == null ? void 0 : document.showDownload) && /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: handleDownload, children: /* @__PURE__ */ jsx(Download, __spreadValues({}, iconSize)) })
    ] }),
    /* @__PURE__ */ jsx(
      NewDocumentPreview,
      {
        docData,
        onClose: closePreview,
        isOpen: isPreviewOpen,
        error,
        title: document.name
      }
    )
  ] });
};
