"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { UserSelectFiltersOptions } from "@v2/components/user-select-type/user-select.interface";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { isHourlyPolicy } from "@v2/feature/absence/absence.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { borders } from "@v2/styles/borders.styles";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import update from "immutability-helper";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_TIME_POLICY_DETAILS_GENERAL_ROUTE } from "@/lib/routes";
import { ChipComponent } from "@/v2/components/chip/chip.component";
export const SmallCircle = () => /* @__PURE__ */ jsx(Box, { sx: { width: "3px", height: "3px", bgcolor: "#2F2F2F", borderRadius: "15px" } });
export const DraggableAbsencePolicies = ({
  absencePolicies,
  sites,
  departments,
  entities,
  approvalRules
}) => {
  const { polyglot } = usePolyglot();
  const [policies, setPolicies] = useState([...absencePolicies]);
  const [initialOrder, setInitialOrder] = useState(policies.map((policy) => policy.id));
  const [hasOrderChanged, setHasOrderChanged] = useState(false);
  const [showMessage] = useMessage();
  useEffect(() => {
    setPolicies([...absencePolicies]);
  }, [absencePolicies]);
  const orderPolicies = useCallback(
    (updatedPoliciesOrder) => __async(void 0, null, function* () {
      if (JSON.stringify(initialOrder) === JSON.stringify(updatedPoliciesOrder)) return;
      try {
        yield AbsenceAPI.orderAbsencePolicies(updatedPoliciesOrder);
        setInitialOrder(updatedPoliciesOrder);
      } catch (error) {
        showMessage(polyglot.t("DraggableAbsencePolicies.error", { nestError: nestErrorMessage(error) }), "error");
      }
    }),
    [polyglot, showMessage, initialOrder]
  );
  const handleDragDrop = (results) => __async(void 0, null, function* () {
    const { source, destination, type } = results;
    if (!destination) return;
    if (source.index === destination.index) return;
    if (type === "group") {
      const sourceIndex = source.index;
      const destinationIndex = destination.index;
      setPolicies(
        (prev) => update(prev, {
          $splice: [
            [sourceIndex, 1],
            [destinationIndex, 0, prev[sourceIndex]]
          ]
        })
      );
      setHasOrderChanged(true);
    }
  });
  useEffect(() => {
    if (!hasOrderChanged) return;
    const updatedPoliciesOrder = policies.map((policy) => policy.id);
    orderPolicies(updatedPoliciesOrder);
    setHasOrderChanged(false);
  }, [orderPolicies, hasOrderChanged, policies]);
  return /* @__PURE__ */ jsx(DragDropContext, { onDragEnd: handleDragDrop, children: /* @__PURE__ */ jsx(Droppable, { droppableId: "ROOT", type: "group", children: (provided) => /* @__PURE__ */ jsxs("div", __spreadProps(__spreadValues({}, provided.droppableProps), { ref: provided.innerRef, children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: policies.map((policy, index) => /* @__PURE__ */ jsx(Draggable, { draggableId: policy.id.toString(), index, children: (provided2) => /* @__PURE__ */ jsx("div", __spreadProps(__spreadValues(__spreadValues({}, provided2.dragHandleProps), provided2.draggableProps), { ref: provided2.innerRef, children: /* @__PURE__ */ jsx(
      PolicyCard,
      {
        absencePolicy: policy,
        index,
        sites,
        departments,
        entities,
        approvalRules
      },
      index
    ) })) }, policy.id)) }),
    provided.placeholder
  ] })) }) });
};
const PolicyCard = ({ absencePolicy, index, sites, departments, entities, approvalRules }) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const isArchived = useMemo(() => Boolean(absencePolicy.deletedAt), [absencePolicy.deletedAt]);
  const goToPolicy = useCallback(() => {
    routerHistory.push(generatePath(SETTINGS_TIME_POLICY_DETAILS_GENERAL_ROUTE, { policyId: absencePolicy.id }));
  }, [absencePolicy.id, routerHistory]);
  const policyAllowance = useMemo(() => {
    if (absencePolicy.allowance === null) return polyglot.t("AbsencePolicyAllowanceSection.unlimited");
    const isHourly = isHourlyPolicy(absencePolicy);
    return isHourly ? polyglot.t("DraggableAbsencePolicies.numHours", { smart_count: absencePolicy.allowance }) : polyglot.t("DraggableAbsencePolicies.numDays", { smart_count: absencePolicy.allowance });
  }, [polyglot, absencePolicy]);
  const policyMembers = useMemo(() => {
    var _a, _b, _c, _d;
    if (absencePolicy.membersRule === UserSelectFiltersOptions.Everyone)
      return polyglot.t("DraggableAbsencePolicies.membersAll");
    if (absencePolicy.membersRule === UserSelectFiltersOptions.SelectSpecific)
      return polyglot.t("DraggableAbsencePolicies.membersCount", {
        count: (_b = (_a = absencePolicy.selectedMembersIds) == null ? void 0 : _a.length) != null ? _b : 0
      });
    if (absencePolicy.membersRule === UserSelectFiltersOptions.CustomRule) {
      if (!absencePolicy.customRule) return polyglot.t("DraggableAbsencePolicies.membersCustomRuleUndefined");
      const [key, ids] = (_c = absencePolicy.customRule) == null ? void 0 : _c.split("=");
      if (key === "site") {
        const siteNames = ids.split(",").map((id) => {
          var _a2;
          return (_a2 = sites.find((site) => site.id === Number(id))) == null ? void 0 : _a2.name;
        }).filter(Boolean).join(", ");
        return polyglot.t("DraggableAbsencePolicies.membersSite", { siteNames });
      }
      if (key === "department") {
        const departmentNames = ids.split(",").map((id) => {
          var _a2;
          return (_a2 = departments.find((dep) => dep.id === Number(id))) == null ? void 0 : _a2.name;
        }).filter(Boolean).join(", ");
        return polyglot.t("DraggableAbsencePolicies.membersDepartment", { departmentNames });
      }
      if (key === "entity") {
        const entityNames = ids.split(",").map((id) => {
          var _a2;
          return (_a2 = entities.find((entity) => entity.id === Number(id))) == null ? void 0 : _a2.legalName;
        }).filter(Boolean).join(", ");
        return polyglot.t("DraggableAbsencePolicies.membersEntity", { entityNames });
      }
      return polyglot.t("DraggableAbsencePolicies.membersCustomRuleDefault", {
        customRule: (_d = absencePolicy.customRule) != null ? _d : "??"
      });
    }
    return polyglot.t("DraggableAbsencePolicies.membersNotSpecified");
  }, [polyglot, absencePolicy, sites, departments, entities]);
  const approvalIsRequired = useMemo(() => {
    const approvalRule = approvalRules.find((rule) => rule.id === absencePolicy.backoffApprovalRuleId);
    if ((approvalRule == null ? void 0 : approvalRule.name) === "Auto approve") return polyglot.t("DraggableAbsencePolicies.approvalIsNotRequired");
    return polyglot.t("DraggableAbsencePolicies.approvalIsRequired");
  }, [polyglot, absencePolicy, approvalRules]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        width: "600px",
        padding: spacing.s2,
        borderRadius: "15px",
        backgroundColor: themeColors.white,
        cursor: "move",
        border: borders.middle,
        "&:hover": {
          transform: "scale(1.03)",
          bgcolor: themeColors.TableHover
        },
        filter: isArchived ? "grayscale(75%)" : "none",
        display: "flex",
        flexDirection: "column",
        gap: spacing.s3,
        boxSizing: "border-box",
        transition: "transform 0.2s ease-in-out, background-color 0.2s ease-in-out"
      },
      onDoubleClick: goToPolicy,
      children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              gap: spacing.g10,
              cursor: "pointer"
            },
            onClick: (e) => {
              goToPolicy();
              e.stopPropagation();
            },
            children: [
              /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "14px", alignItems: "center" }, children: /* @__PURE__ */ jsx(Box, { sx: { width: "14px", height: "14px", borderRadius: "50%", bgcolor: absencePolicy.color } }) }),
              /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.title4, children: absencePolicy.fullName }) }),
              isArchived && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", color: themeColors.Grey }, children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: polyglot.t("DraggableAbsencePolicies.archived") }) })
            ]
          }
        ),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              gap: "5px",
              alignItems: "center",
              flexWrap: "wrap"
            },
            children: [
              /* @__PURE__ */ jsx(
                ChipComponent,
                {
                  name: policyAllowance,
                  backgroundColor: "white",
                  textColor: "DarkGrey",
                  textVariant: "caption",
                  border: "middle"
                }
              ),
              /* @__PURE__ */ jsx(
                ChipComponent,
                {
                  name: policyMembers,
                  backgroundColor: "white",
                  textColor: "DarkGrey",
                  textVariant: "caption",
                  border: "middle"
                }
              ),
              /* @__PURE__ */ jsx(
                ChipComponent,
                {
                  name: approvalIsRequired,
                  backgroundColor: "white",
                  textColor: "DarkGrey",
                  textVariant: "caption",
                  border: "middle"
                }
              )
            ]
          }
        )
      ]
    },
    index
  );
};
