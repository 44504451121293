"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { ApprovalRuleDrawer } from "@/v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component";
import { getStepDescription, isApprovalRuleAutoApprove } from "@/v2/feature/approval-rule/approval-rule.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { translateDefaultApprovalRule } from "@/v2/infrastructure/i18n/translate.util";
export const ApprovalRulesList = ({ approvalRules, refresh }) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedApprovalRule, setSelectedApprovalRule] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const filteredApprovalRules = useMemo(() => {
    if (!searchInput) return approvalRules;
    const search = searchInput.trim().toLowerCase();
    return approvalRules.filter((approvalRule) => {
      var _a;
      return (_a = approvalRule.name) == null ? void 0 : _a.toLowerCase().includes(search);
    });
  }, [approvalRules, searchInput]);
  const getChipsFromApprovalRule = useCallback(
    (approvalRule) => {
      const chips = [];
      if (approvalRule.approvalSteps.length === 0 || isApprovalRuleAutoApprove(approvalRule)) {
        chips.push({
          type: ContentItemType.chip,
          name: "Auto approval",
          textColor: "DarkGrey",
          backgroundColor: "white",
          border: "middle"
        });
        return chips;
      }
      chips.push({
        type: ContentItemType.chip,
        name: polyglot.t("ApprovalRuleModule.noOfSteps", { smart_count: approvalRule.approvalSteps.length }),
        textColor: "DarkGrey",
        backgroundColor: "white",
        border: "middle"
      });
      const labels = approvalRule.approvalSteps.map(
        (approvalStep) => getStepDescription(approvalStep, getCachedUserById, polyglot, 4)
      );
      chips.push(
        ...labels.map(
          (label) => ({
            type: ContentItemType.chip,
            name: label,
            textColor: "DarkGrey",
            backgroundColor: "white",
            border: "middle"
          })
        )
      );
      return chips;
    },
    [polyglot, getCachedUserById]
  );
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(
      TableSearch,
      {
        query: searchInput,
        handleChange: (e) => {
          var _a, _b;
          return setSearchInput((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
        },
        style: { marginBottom: "16px" }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: filteredApprovalRules.map((aRule) => /* @__PURE__ */ jsx(
      SettingsItemCard,
      {
        title: aRule.name,
        description: aRule.isDefault ? translateDefaultApprovalRule(aRule.name, polyglot) : aRule.description || "",
        boxSx: responsiveCardStyle,
        contentItemsSets: getChipsFromApprovalRule(aRule),
        boxAction: () => {
          setSelectedApprovalRule(aRule);
          setIsDrawerOpen(true);
        }
      },
      aRule.id
    )) }),
    /* @__PURE__ */ jsx(
      ApprovalRuleDrawer,
      {
        isOpen: isDrawerOpen,
        setIsOpen: setIsDrawerOpen,
        approvalRule: selectedApprovalRule,
        afterClose: () => {
          setSelectedApprovalRule(null);
        },
        refresh
      }
    )
  ] });
};
