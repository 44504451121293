"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { getMonthOptions } from "@v2/feature/absence/absence.util";
import { getCustomBenefitAllowanceTypeOptions } from "@v2/feature/benefits/subfeature/benefits-settings/components/new-benefit-drawer.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import {
  CustomBenefitAllowanceType
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import {
  isAllowanceBenefit,
  isRecurringBenefit
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { shouldBeNullTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
const getValidationSchema = (isAllowance, polyglot) => yup.object({
  defaultEmployerContribution: yup.number().nullable().typeError(polyglot.t("ValidationMessages.validValue")).notRequired(),
  defaultEmployeeContribution: yup.number().nullable().typeError(polyglot.t("ValidationMessages.validValue")).notRequired(),
  defaultOpeningBalance: yup.number().nullable().typeError(polyglot.t("ValidationMessages.validValue")).notRequired(),
  allowanceType: isAllowance ? yup.string().nullable().oneOf(Object.values(CustomBenefitAllowanceType), polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")) : yup.mixed().nullable().test(shouldBeNullTest).notRequired(),
  cycleStartMonth: yup.number().integer(polyglot.t("validation.selectValid")).min(1, polyglot.t("validation.selectValid")).max(12, polyglot.t("validation.selectValid")).typeError(polyglot.t("validation.selectValid")).notRequired()
});
export const EditCustomBenefitBalanceDrawer = ({ isOpen, setIsOpen, customBenefit, refresh }) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(EditCustomBenefitBalanceDrawerContent, { setIsOpen, refresh, customBenefit })
  }
) });
export const EditCustomBenefitBalanceDrawerContent = ({ setIsOpen, refresh, customBenefit }) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const isRecurring = isRecurringBenefit(customBenefit.type);
  const isAllowance = isAllowanceBenefit(customBenefit.type);
  const formik = useFormik({
    initialValues: {
      allowanceType: customBenefit.allowanceType,
      defaultEmployerContribution: customBenefit.defaultEmployerContribution,
      defaultEmployeeContribution: customBenefit.defaultEmployeeContribution,
      defaultOpeningBalance: customBenefit.defaultOpeningBalance,
      cycleStartMonth: customBenefit.cycleStartMonth || 1
    },
    validationSchema: getValidationSchema(isAllowance, polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a2;
      const valuesToSubmit = {
        allowanceType: isAllowance ? (_a2 = values.allowanceType) != null ? _a2 : CustomBenefitAllowanceType.OneOff : null,
        defaultEmployerContribution: isRecurring && values.defaultEmployerContribution ? Number(values.defaultEmployerContribution) : null,
        defaultEmployeeContribution: isRecurring && values.defaultEmployeeContribution ? Number(values.defaultEmployeeContribution) : null,
        defaultOpeningBalance: isAllowance && values.defaultOpeningBalance ? Number(values.defaultOpeningBalance) : null,
        cycleStartMonth: isAllowance && values.allowanceType === CustomBenefitAllowanceType.Yearly ? Number(values.cycleStartMonth) : null
      };
      try {
        setLoading(true);
        yield CustomBenefitAPI.updateCustomBenefitBalance(customBenefit.id, valuesToSubmit);
        showMessage(polyglot.t("BenefitModule.benefitUpdated"), "success");
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  const isYearlyAllowanceSelected = formik.values.allowanceType === CustomBenefitAllowanceType.Yearly;
  const monthsOptions = useMemo(() => {
    return getMonthOptions(polyglot);
  }, [polyglot]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("BenefitModule.editBenefit") }),
    isRecurring && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "defaultEmployerContribution",
        label: polyglot.t("BenefitModule.employerContribution"),
        value: formik.values.defaultEmployerContribution,
        onChange: formik.handleChange,
        error: formik.touched.defaultEmployerContribution && !!formik.errors.defaultEmployerContribution,
        helperText: (_a = formik.touched.defaultEmployerContribution && formik.errors.defaultEmployerContribution) != null ? _a : " "
      }
    ),
    isRecurring && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "defaultEmployeeContribution",
        label: polyglot.t("BenefitModule.employeeContribution"),
        value: formik.values.defaultEmployeeContribution,
        onChange: formik.handleChange,
        error: formik.touched.defaultEmployeeContribution && !!formik.errors.defaultEmployeeContribution,
        helperText: (_b = formik.touched.defaultEmployeeContribution && formik.errors.defaultEmployeeContribution) != null ? _b : " "
      }
    ),
    isAllowance && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "allowanceType",
        label: polyglot.t("BenefitModule.allowanceType"),
        options: getCustomBenefitAllowanceTypeOptions(polyglot),
        value: (_c = formik.values.allowanceType) != null ? _c : "",
        onChange: formik.handleChange,
        error: !!formik.errors.allowanceType && formik.touched.allowanceType,
        helperText: formik.touched.allowanceType && formik.errors.allowanceType
      }
    ),
    isAllowance && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "defaultOpeningBalance",
        label: polyglot.t("BenefitModule.standardAllowance"),
        value: formik.values.defaultOpeningBalance,
        onChange: formik.handleChange,
        error: formik.touched.defaultOpeningBalance && !!formik.errors.defaultOpeningBalance,
        helperText: (_d = formik.touched.defaultOpeningBalance && formik.errors.defaultOpeningBalance) != null ? _d : " "
      }
    ),
    isYearlyAllowanceSelected && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "cycleStartMonth",
        label: polyglot.t("BenefitModule.cycleStartMonth"),
        options: monthsOptions,
        value: formik.values.cycleStartMonth,
        onChange: formik.handleChange,
        compareValue: formik.values.cycleStartMonth,
        error: !!formik.errors.cycleStartMonth && formik.touched.cycleStartMonth,
        helperText: formik.touched.cycleStartMonth && formik.errors.cycleStartMonth
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        sizeVariant: "medium",
        colorVariant: "primary",
        loading,
        fullWidth: true
      }
    ) })
  ] }) });
};
