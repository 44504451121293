"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { TabFilterButtons } from "@v2/components/tab-filter-buttons.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DeviceEndpoints } from "@v2/feature/device/device.api";
import { EnrolmentType } from "@v2/feature/device/device.interface";
import {
  getCompanyNamesKeyedByCompanyIds,
  getDeviceOwnerAsSuperadminByDevicePossession,
  getUserNamesKeyedByUserIds
} from "@v2/feature/device/device.util";
import { DEBOUNCE_300_MS } from "@v2/feature/documents/documents.util";
import { SuperAdminOverviewInHouseMdmDeviceDrawer } from "@v2/feature/super-admin/features/super-admin-devices/components/super-admin-overview-in-house-mdm-device-drawer.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { secondaryCTABtn } from "@v2/styles/buttons.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { pipe } from "fp-ts/function";
import { keyBy } from "lodash";
import CsvDownloader from "react-csv-downloader";
import { Subject } from "rxjs";
import * as RX from "rxjs/operators";
import { SiteAPI } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Clock } from "@/images/side-bar-icons/Clock.svg";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { nestErrorMessage } from "@/lib/errors";
import { timeSince } from "@/lib/moment.lib";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
export const SuperAdminInHouseMdmDevicesOverviewPage = ({ users, pageConfig, companies }) => {
  var _a, _b, _c;
  const [loading, setLoading] = useState(false);
  const [devicePossessions, setDevicePossessions] = useState([]);
  const [devicePossessionToBeEdited, setDevicePossessionToBeEdited] = useState(null);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const companiesNames = useMemo(() => getCompanyNamesKeyedByCompanyIds(companies), [companies]);
  const userNames = useMemo(() => getUserNamesKeyedByUserIds(users), [users]);
  const [showMessage] = useMessage();
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("all");
  const [changeSearchInput$] = useState(() => new Subject());
  const [sitesById, setSitesById] = useState({});
  const { data: superAdminDevicesResponseDto } = useApiClient(DeviceEndpoints.getAllDevicesAsSuperAdmin(true), {
    suspense: false
  });
  const getAllSites = useCallback(() => __async(void 0, null, function* () {
    try {
      const sites = yield SiteAPI.listSitesAsSuperAdmin();
      setSitesById(keyBy(sites, "id"));
    } catch (error) {
      showMessage(`Could not get sites list. ${nestErrorMessage(error)}`, "error");
    }
  }), [showMessage]);
  useEffect(() => {
    pipe(
      changeSearchInput$,
      RX.debounceTime(DEBOUNCE_300_MS),
      ($) => $.subscribe((value) => setSearchInput(value)),
      (s) => () => s.unsubscribe()
    );
    getAllSites();
  }, [changeSearchInput$, getAllSites]);
  const filterDevicePossessions = useCallback(
    (devicePossessions2) => devicePossessions2.filter((devicePossession) => {
      var _a2, _b2, _c2, _d;
      const userName = getDeviceOwnerAsSuperadminByDevicePossession(devicePossession, userNames, sitesById);
      const modelName = (_a2 = devicePossession.device) == null ? void 0 : _a2.modelName;
      const serialNumber = (_b2 = devicePossession.device) == null ? void 0 : _b2.serialNumber;
      const os = (_c2 = devicePossession.device) == null ? void 0 : _c2.os;
      const isAppleOS = (os == null ? void 0 : os.toLowerCase().includes("ios")) || (os == null ? void 0 : os.toLowerCase().includes("macos")) || (os == null ? void 0 : os.toLowerCase().includes("ipados"));
      const manufacturer = (_d = devicePossession.device) == null ? void 0 : _d.manufacturer;
      const isAppleDevice = manufacturer == null ? void 0 : manufacturer.toLowerCase().includes("apple");
      const companyName = devicePossession.companyId ? companiesNames[devicePossession.companyId] : null;
      return (isAppleDevice || isAppleOS) && ((userName == null ? void 0 : userName.toLowerCase().includes(searchInput.toLowerCase())) || (modelName == null ? void 0 : modelName.toLowerCase().includes(searchInput.toLowerCase())) || (serialNumber == null ? void 0 : serialNumber.toLowerCase().includes(searchInput.toLowerCase())) || (companyName == null ? void 0 : companyName.toLowerCase().includes(searchInput.toLowerCase())));
    }),
    [userNames, sitesById, searchInput, companiesNames]
  );
  const tableData = useMemo(() => {
    const searchFilteredDevices = filterDevicePossessions(devicePossessions);
    if (filterString === "all") return searchFilteredDevices;
    return searchFilteredDevices.filter((devicePossession) => {
      var _a2;
      return ((_a2 = devicePossession.device) == null ? void 0 : _a2.os) === filterString;
    });
  }, [devicePossessions, filterString, filterDevicePossessions]);
  const columnData = useMemo(() => {
    return [
      {
        header: () => "Device Id",
        accessorFn: (row) => row,
        id: "device.id",
        enableSorting: true,
        cell: (info) => {
          const possession = info.getValue();
          return possession.device ? /* @__PURE__ */ jsx(Box, { children: possession.device.id }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 55
      },
      {
        header: () => "Serial Number",
        accessorFn: (row) => row,
        id: "device.serialNumber",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const serialNumber = (_a2 = possession.device) == null ? void 0 : _a2.serialNumber;
          return serialNumber ? /* @__PURE__ */ jsx(Box, { children: serialNumber }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 125
      },
      {
        header: () => "Company Name",
        accessorFn: (row) => row,
        id: "device.companyName",
        enableSorting: true,
        cell: (info) => {
          const possession = info.getValue();
          const companyName = possession.companyId ? companiesNames[possession.companyId] : null;
          return companyName ? /* @__PURE__ */ jsx(Box, { children: companyName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 125
      },
      {
        header: () => "Device",
        accessorFn: (row) => row,
        id: "device.modelName",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const modelName = (_a2 = possession.device) == null ? void 0 : _a2.modelName;
          return modelName ? /* @__PURE__ */ jsx(Box, { children: modelName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 125
      },
      {
        header: () => "Used By",
        accessorFn: (row) => row,
        id: "usedBy",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          const ownedBy = getDeviceOwnerAsSuperadminByDevicePossession(possession, userNames, sitesById);
          return /* @__PURE__ */ jsx(Box, { children: ownedBy });
        },
        size: 150
      },
      {
        header: () => "MDM Database",
        accessorFn: (row) => row,
        id: "device.inHouseMdm",
        enableSorting: true,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const inHouseMdm = (_a2 = possession.device) == null ? void 0 : _a2.inHouseMdm;
          return inHouseMdm ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
            " Migrated"
          ] }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center", color: themeColors.Grey }, children: [
            /* @__PURE__ */ jsx(Clock, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Grey } })),
            " Not migrated"
          ] });
        },
        size: 100
      },
      {
        header: () => "Enrolment Type",
        accessorFn: (row) => row,
        id: "device.enrolmentType",
        enableSorting: true,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const enrolmentType = (_a2 = possession.device) == null ? void 0 : _a2.enrolmentType.valueOf();
          let textColor;
          let text;
          if (enrolmentType === EnrolmentType.ABM_DEP) {
            textColor = "green";
            text = "DEP";
          } else if (enrolmentType === EnrolmentType.OPEN_ENROLMENT) {
            textColor = "black";
            text = "Open enrolment";
          } else if (enrolmentType === EnrolmentType.ABM) {
            textColor = "red";
            textColor = "Not full DEP";
          } else if (enrolmentType === EnrolmentType.NONE) {
            textColor = "orange";
            text = "None";
          }
          return text ? /* @__PURE__ */ jsx(Box, { style: { color: textColor }, children: text }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "Enrolment Status",
        accessorFn: (row) => row,
        id: "device.enrolmentStatus",
        enableSorting: true,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const enrolmentStatus = (_a2 = possession.device) == null ? void 0 : _a2.enrollmentStatus;
          let textColor;
          let text;
          if (enrolmentStatus === "ENROLMENT_FINISHED") {
            textColor = "green";
            text = "Enroled successfully";
          } else {
            textColor = "black";
            text = enrolmentStatus;
          }
          return enrolmentStatus ? /* @__PURE__ */ jsx(Box, { style: { color: textColor }, children: text }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "Last Sync",
        accessorFn: (row) => row,
        id: "device.encryptionEnabled",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const syncTime = (_a2 = possession.device) == null ? void 0 : _a2.lastCheckIn;
          return syncTime ? /* @__PURE__ */ jsx(Box, { children: syncTime.toString() }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 100
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          return possession.companyId ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end", gap: 1 }, children: /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: tableIconButtonSx,
              onClick: () => {
                setIsEditDrawerOpen(true);
              },
              children: "..."
            }
          ) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 100
      }
    ];
  }, [userNames, sitesById, companiesNames]);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b2, _c2;
    try {
      const devicePossessions2 = (_b2 = (_a2 = superAdminDevicesResponseDto == null ? void 0 : superAdminDevicesResponseDto.devicePossessions) == null ? void 0 : _a2.items) != null ? _b2 : [];
      setDevicePossessions(devicePossessions2);
      setDevicePossessionToBeEdited((_c2 = devicePossessions2.find((d) => d.id === (devicePossessionToBeEdited == null ? void 0 : devicePossessionToBeEdited.id))) != null ? _c2 : null);
    } catch (error) {
      showMessage(`Could not retrieve devices. ${nestErrorMessage(error)}`, "error");
    }
  }), [devicePossessionToBeEdited == null ? void 0 : devicePossessionToBeEdited.id, showMessage, (_a = superAdminDevicesResponseDto == null ? void 0 : superAdminDevicesResponseDto.devicePossessions) == null ? void 0 : _a.items]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      setLoading(true);
      try {
        yield refresh();
      } catch (error) {
        showMessage(`Could not retrieve devices. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    }))();
  }, [refresh, showMessage]);
  const getCompanyNameById = useCallback(
    (companyId) => {
      var _a2;
      if (!companyId) return void 0;
      return (_a2 = companies.find((company) => company.companyId === companyId)) == null ? void 0 : _a2.name;
    },
    [companies]
  );
  const formatDevicePossessionForCsv = (devicePossessions2) => devicePossessions2.map((devicePossession) => {
    var _a2, _b2, _c2, _d, _e, _f, _g, _h, _i, _j;
    return {
      possessionId: devicePossession.id,
      deviceId: (_a2 = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _a2.id,
      company: (_b2 = getCompanyNameById(devicePossession.companyId)) != null ? _b2 : devicePossession.possessionType,
      ownedBy: getDeviceOwnerAsSuperadminByDevicePossession(devicePossession, userNames, sitesById),
      serialNumber: ((_c2 = devicePossession.device) == null ? void 0 : _c2.serialNumber) || "",
      modelName: ((_d = devicePossession.device) == null ? void 0 : _d.modelName) || "",
      isEnrolled: ((_e = devicePossession.device) == null ? void 0 : _e.enrollmentStatus) === "enrolled",
      lastCheckIn: ((_f = devicePossession.device) == null ? void 0 : _f.lastCheckIn) ? timeSince(new Date((_g = devicePossession.device) == null ? void 0 : _g.lastCheckIn)) : "",
      price: `\xA3${(_h = devicePossession.device) == null ? void 0 : _h.price}`,
      contractLength: ((_i = devicePossession.device) == null ? void 0 : _i.contractLength) ? `${(_j = devicePossession.device) == null ? void 0 : _j.contractLength} months` : ""
    };
  });
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: "Devices" }),
    ((_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_c = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _c.tabs }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, secondLevel: true, children: [
      devicePossessions.length > 0 && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({ display: "flex", justifyContent: "flex-start", width: "100%" }, spacing.mb20), { gap: 2 }), children: /* @__PURE__ */ jsx(
          TabFilterButtons,
          {
            filters: [
              { name: "All", value: "all" },
              { name: "Mac", value: "macos" },
              { name: "IPhone", value: "ios" },
              { name: "IPad", value: "ipados" }
            ],
            filterValue: filterString,
            setFilterValue: setFilterString,
            hasSearch: true,
            onFilterChange: ({ filterValue, searchInput: searchInput2 }) => {
              setFilterString(filterValue);
              setSearchInput(searchInput2);
            }
          }
        ) }),
        /* @__PURE__ */ jsx(
          CsvDownloader,
          {
            filename: "inhouse_mdm_devices",
            separator: ",",
            datas: () => formatDevicePossessionForCsv(filterDevicePossessions(tableData)),
            children: /* @__PURE__ */ jsxs(
              Box,
              {
                sx: __spreadProps(__spreadValues({
                  display: "flex",
                  alignItems: "center",
                  gap: spacing.g5
                }, secondaryCTABtn), {
                  cursor: "pointer",
                  height: "20px"
                }),
                children: [
                  /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
                  /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "Export" }),
                  " "
                ]
              }
            )
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: tableData,
          columnData,
          rowClick: (row) => {
            setDevicePossessionToBeEdited(row.original);
            setIsEditDrawerOpen(true);
          }
        }
      ),
      (devicePossessionToBeEdited == null ? void 0 : devicePossessionToBeEdited.companyId) && /* @__PURE__ */ jsx(
        SuperAdminOverviewInHouseMdmDeviceDrawer,
        {
          isOpen: isEditDrawerOpen,
          setIsOpen: setIsEditDrawerOpen,
          devicePossession: devicePossessionToBeEdited,
          refresh,
          companyName: companiesNames[devicePossessionToBeEdited.companyId]
        }
      )
    ] })
  ] });
};
