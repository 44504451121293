"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { EmptyStateBox } from "@v2/components/empty-state-box.component";
import { capitalize } from "lodash";
import { generatePath, useHistory } from "react-router-dom";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import { useEventSource } from "@/hooks/event-source-hook";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SURVEYS_COMPANY_ONGOING_DETAIL_ROUTE, SURVEYS_TEAM_ONGOING_DETAIL_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { FiltersDrawer } from "@/v2/components/table/filters-drawer.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { getStatus } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import {
  CycleState,
  CycleTypeArray,
  SurveyCycleStateArray
} from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { SurveyCycleEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { SurveyCycleCreationModal } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/components/sc-creation-modal.component";
import { getAnonymitySetting } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle.util";
import { UserAPI } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { eventSourceErrorHandler } from "@/v2/infrastructure/push-events/push-events.util";
import { ReachType } from "@/v2/interfaces/general.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
import { isDefined } from "@/v2/util/string.util";
export const SurveyCycleOngoingListingPage = ({ reach }) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const [searchInput, setSearchInput] = useState("");
  const [state, dispatch] = useContext(GlobalContext);
  const { user: currentUser } = state;
  const currentFilterString = isDefined((_c = (_b = (_a = currentUser.features) == null ? void 0 : _a.surveys) == null ? void 0 : _b.ongoing) == null ? void 0 : _c.selectedFilter) ? (_f = (_e = (_d = currentUser.features) == null ? void 0 : _d.surveys) == null ? void 0 : _e.ongoing) == null ? void 0 : _f.selectedFilter : null;
  const [filterString, setFilterString] = useState(currentFilterString || "");
  const {
    data: ongoingCycles,
    mutate: refreshOngoingCycles,
    isLoading: loadingCycles,
    isValidating: validatingCycles
  } = useApiClient(SurveyCycleEndpoints.getOngoingCycles(reach, searchInput, filterString), {
    suspense: false
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const companyId = state.user.company.companyId;
  const isProduction = process.env.REACT_APP_ENV === "production";
  const environmentPrefix = `${isProduction ? "prod" : "test"}`;
  const channelName = `survey_cycle-${environmentPrefix}-company_id-${companyId}`;
  const surveyCycleUpdatedDataHandler = (data) => {
    if (data.message && data.companyId === companyId) {
      refreshOngoingCycles == null ? void 0 : refreshOngoingCycles();
      return;
    }
  };
  useEventSource((_g = SurveyCycleEndpoints.getEventSourceUrlForSurveyCycleLaunch(channelName)) == null ? void 0 : _g.url, {
    onMessage: surveyCycleUpdatedDataHandler,
    onError: eventSourceErrorHandler
  });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Surveys" }),
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsDrawerOpen(true), children: "Start survey" }),
        showAction: Boolean(ongoingCycles && ongoingCycles.length > 0)
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: loadingCycles, sx: __spreadValues({}, spacing.pt20), children: [
      !loadingCycles && (!ongoingCycles || ongoingCycles && ongoingCycles.length === 0) && !searchInput && !filterString ? /* @__PURE__ */ jsx(
        EmptyStateBox,
        {
          title: "Create first survey",
          subtitle: "Ongoing survey cycles will be shown on this page once you\u2019ve started. You can begin a new survey by using a template or by creating your own questions.",
          onClick: () => setIsDrawerOpen(true),
          buttonTitle: "Start survey"
        }
      ) : /* @__PURE__ */ jsx(
        SurveyCycleOngoingTable,
        {
          ongoingCycles: ongoingCycles || [],
          validatingCycles,
          reach,
          searchInput,
          setSearchInput,
          filterString,
          setFilterString,
          dispatch
        }
      ),
      /* @__PURE__ */ jsx(
        SurveyCycleCreationModal,
        {
          surveyCycle: void 0,
          isOpen: isDrawerOpen,
          setIsOpen: setIsDrawerOpen,
          onClose: () => setIsDrawerOpen(false),
          refresh: () => __async(void 0, null, function* () {
            yield refreshOngoingCycles == null ? void 0 : refreshOngoingCycles();
          }),
          reach
        }
      )
    ] })
  ] });
};
const SurveyCycleOngoingTable = ({
  ongoingCycles,
  validatingCycles,
  reach,
  searchInput,
  setSearchInput,
  filterString,
  setFilterString,
  dispatch
}) => {
  const routerHistory = useHistory();
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const tableColumns = useMemo(
    () => [
      {
        header: () => "Internal name",
        accessorFn: (row) => row.internalName,
        id: "internalName",
        enableSorting: false,
        cell: ({
          row: {
            original: { internalName }
          }
        }) => {
          return internalName ? /* @__PURE__ */ jsx("div", { children: internalName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 300
      },
      {
        header: () => "Type",
        accessorFn: (row) => row,
        id: "type",
        enableSorting: false,
        cell: ({
          row: {
            original: { type }
          }
        }) => type ? /* @__PURE__ */ jsx("div", { children: capitalize(type) }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "Anonymity",
        accessorFn: (row) => row,
        id: "visibilitySettings",
        enableSorting: false,
        cell: ({
          row: {
            original: { visibilitySettings }
          }
        }) => visibilitySettings ? /* @__PURE__ */ jsx("div", { children: getAnonymitySetting(visibilitySettings) }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "Created by",
        accessorFn: (row) => row,
        id: "createdBy",
        enableSorting: false,
        cell: ({
          row: {
            original: { owner }
          }
        }) => /* @__PURE__ */ jsx("div", { children: owner ? /* @__PURE__ */ jsx(UserCell, { userId: owner }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => "Start date",
        accessorFn: (row) => row,
        id: "timelineSettings.startDate",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return ((_a = original == null ? void 0 : original.timelineSettings) == null ? void 0 : _a.startDate) ? /* @__PURE__ */ jsx("div", { children: new LocalDate((_b = original == null ? void 0 : original.timelineSettings) == null ? void 0 : _b.startDate).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Status",
        accessorFn: (row) => row,
        id: "state",
        enableSorting: false,
        cell: ({
          row: {
            original: { state }
          }
        }) => state ? /* @__PURE__ */ jsx("div", { children: getStatus()[state] }) : /* @__PURE__ */ jsx(EmptyCell, {})
      }
    ],
    []
  );
  const handleRowClick = useCallback(
    (row) => {
      if (row.original.state !== CycleState.Launching) {
        const reachRoute = reach === ReachType.Company ? SURVEYS_COMPANY_ONGOING_DETAIL_ROUTE : SURVEYS_TEAM_ONGOING_DETAIL_ROUTE;
        routerHistory.push(
          generatePath(reachRoute, {
            cycleId: row.original.id
          })
        );
      }
    },
    [routerHistory, reach]
  );
  const customRowStyle = (row) => {
    if (row.original.state === CycleState.Launching)
      return { color: themeColors.Grey, background: themeColors.tableHoverGrey };
    return {};
  };
  const updateUserFeaturesSelectedFilters = useCallback(
    (filterString2) => __async(void 0, null, function* () {
      try {
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
          "surveys",
          "ongoing",
          "selectedFilter",
          filterString2
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error) {
        showMessage(`${polyglot.t("PeopleDirectoryPage.errorMessages.update")}. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [dispatch, showMessage, polyglot]
  );
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(
        FiltersDrawer,
        {
          filtersOptions: [
            {
              filters: {
                type: CycleTypeArray.map((d) => {
                  return { value: d, label: capitalize(d) };
                }),
                status: SurveyCycleStateArray.map((d) => {
                  return { value: d, label: capitalize(d) };
                })
              }
            }
          ],
          selectedFilters: filterString,
          setSelectedFilters: setFilterString,
          onApply: updateUserFeaturesSelectedFilters
        }
      ),
      /* @__PURE__ */ jsx(
        TableSearch,
        {
          query: searchInput,
          handleChange: (e) => {
            setSearchInput(e.target.value);
          }
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: ongoingCycles != null ? ongoingCycles : [],
        columnData: tableColumns,
        loading: validatingCycles,
        rowClick: handleRowClick,
        customRowStyle
      }
    )
  ] });
};
