"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { sortBoolean, sortString } from "@v2/components/table/table-sorting.util";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { OffboardingManagerChangeDrawer } from "@v2/feature/offboarding/offboarding-process/manager-change/offboarding-manager-change.drawer.component";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { generatePath } from "react-router-dom";
import { UserRoleEndpoints } from "@/api-client/user-role.api";
import { USER_OFFBOARDING_ROUTE } from "@/lib/routes";
export const OffboardingManagerChangePage = ({ userId, refresh, loading }) => {
  const { data: userRolesData, isLoading } = useApiClient(UserRoleEndpoints.getOffboardingUserRoleChanges(userId), {
    suspense: false
  });
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const [searchInput, setSearchInput] = useState("");
  const [isManagerSelectDrawerOpen, setIsManagerSelectDrawerOpen] = useState(false);
  const userRoles = useMemo(() => {
    var _a, _b;
    if (!userRolesData) return [];
    const roles = [];
    for (const userRole of userRolesData.effectiveRoles) {
      const user = getCachedUserById(userRole.userId);
      roles.push(__spreadProps(__spreadValues({}, userRole), { userDisplayName: (_a = user == null ? void 0 : user.displayName) != null ? _a : "", isEffective: true, isFuture: false }));
    }
    for (const userRole of userRolesData.futureRoles) {
      const user = getCachedUserById(userRole.userId);
      roles.push(__spreadProps(__spreadValues({}, userRole), { userDisplayName: (_b = user == null ? void 0 : user.displayName) != null ? _b : "", isEffective: false, isFuture: true }));
    }
    return roles;
  }, [userRolesData, getCachedUserById]);
  const filteredUserRoles = useMemo(() => {
    if (!searchInput) return userRoles;
    const search = searchInput.toLowerCase();
    return userRoles.filter((role) => role.userDisplayName.toLowerCase().includes(search));
  }, [searchInput, userRoles]);
  const tableColumns = useMemo(
    () => [
      {
        header: () => polyglot.t("General.employee"),
        accessorFn: (row) => row,
        id: "userId",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.userDisplayName),
        cell: (info) => {
          const userRole = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(UserAvatar, { userId: userRole.userId, size: "xxsmall", showName: true }) });
        }
      },
      {
        header: () => polyglot.t("General.effectiveDate"),
        accessorFn: (row) => row,
        id: "effectiveDate",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.effectiveDate),
        cell: (info) => {
          const userRole = info.getValue();
          const date = new LocalDate(userRole.effectiveDate).toLocaleDateString();
          return /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: date }) });
        }
      },
      {
        header: () => polyglot.t("General.actions"),
        accessorFn: (row) => row,
        id: "id",
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isEffective),
        cell: (info) => {
          const userRole = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: userRole.isEffective ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "A new role will be created" }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: "The role will be updated" }) });
        }
      }
    ],
    [polyglot]
  );
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("OffboardingPage.managerChange") }),
        showBack: true,
        backPath: generatePath(USER_OFFBOARDING_ROUTE, { userId })
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "600px", width: "100%" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput,
            handleChange: (e) => {
              setSearchInput(e.target.value);
            }
          }
        ),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "primary",
            onClick: () => {
              setIsManagerSelectDrawerOpen(true);
            },
            children: polyglot.t("OffboardingPage.selectNewManager")
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(BasicTable, { rowData: filteredUserRoles, columnData: tableColumns, loading: isLoading }) }),
      /* @__PURE__ */ jsx(
        OffboardingManagerChangeDrawer,
        {
          isOpen: isManagerSelectDrawerOpen,
          setIsOpen: setIsManagerSelectDrawerOpen,
          userId,
          refresh
        }
      )
    ] }) })
  ] });
};
