"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { SiteAPI } from "@/api-client/index.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SiteFormDrawer } from "@/v2/feature/site/site-settings/features/components/site-form-drawer.component";
import { SiteListingTable } from "@/v2/feature/site/site-settings/features/components/site-listing-table.component";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SiteSettingsPage = () => {
  const { polyglot } = usePolyglot();
  const { trackPage } = useJune();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedSite, setSelectedSite] = useState();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const refreshSites = useCallback(() => __async(void 0, null, function* () {
    setLoading(true);
    try {
      const siteData = yield SiteAPI.listSites();
      setSites(siteData);
    } catch (error) {
      showMessage(
        polyglot.t("SiteSettingsPage.errorMessages.refresh", { nestError: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  }), [showMessage, polyglot]);
  useEffect(() => {
    refreshSites();
  }, []);
  const filteredSites = useMemo(() => {
    return searchInput.length > 0 ? sites.filter((user) => user.name.toLowerCase().includes(searchInput.toLowerCase())) : sites;
  }, [sites, searchInput]);
  useEffect(() => {
    trackPage("Company site settings");
  }, []);
  const openForm = useCallback((site) => {
    setSelectedSite(site);
    setIsFormOpen(true);
  }, []);
  const closeForm = useCallback(() => {
    setIsFormOpen(false);
    setTimeout(() => setSelectedSite(void 0), 500);
  }, []);
  const openDelete = useCallback((site) => {
    setSelectedSite(site);
    setIsDeleteOpen(true);
  }, []);
  const closeDelete = useCallback(() => {
    setIsDeleteOpen(false);
    setTimeout(() => setSelectedSite(void 0), 500);
  }, []);
  const deleteSite = useCallback(() => __async(void 0, null, function* () {
    try {
      setDeleteLoading(true);
      if (selectedSite) yield SiteAPI.deleteSite(selectedSite.id);
      else throw new Error(polyglot.t("SiteDeleteDrawer.errorMessages.noSiteToDelete"));
      showMessage(polyglot.t("SiteDeleteDrawer.successMessages.delete", { siteName: selectedSite.name }), "success");
      yield refreshSites();
      closeDelete();
    } catch (error) {
      showMessage(polyglot.t("SiteDeleteDrawer.errorMessages.delete", { nestError: nestErrorMessage(error) }), "error");
    } finally {
      setDeleteLoading(false);
    }
  }), [polyglot, selectedSite, refreshSites, showMessage, closeDelete]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Sites" }),
        showBack: true,
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsFormOpen(true), children: polyglot.t("SiteSettingsPage.addNewSite") }),
        showAction: true
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { children: [
      /* @__PURE__ */ jsx(
        TableSearch,
        {
          query: searchInput,
          handleChange: (e) => {
            setSearchInput(e.target.value);
          }
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
        /* @__PURE__ */ jsx(
          SiteListingTable,
          {
            sites: filteredSites,
            loading,
            openFormDrawer: openForm,
            openDeleteDrawer: openDelete
          }
        ),
        /* @__PURE__ */ jsx(
          SiteFormDrawer,
          {
            isOpen: isFormOpen,
            setIsOpen: setIsFormOpen,
            selectedSite,
            refreshSites,
            onClose: closeForm
          }
        ),
        /* @__PURE__ */ jsx(
          DeleteDrawer,
          {
            title: polyglot.t("SiteDeleteDrawer.title"),
            description: polyglot.t("SiteDeleteDrawer.confirmDeletion", {
              siteName: (selectedSite == null ? void 0 : selectedSite.name) || "this site"
            }),
            action: deleteSite,
            isOpen: isDeleteOpen,
            setIsOpen: setIsDeleteOpen,
            loading: deleteLoading,
            onClose: closeDelete
          }
        )
      ] })
    ] })
  ] });
};
