"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AttendanceAPI } from "@v2/feature/attendance/attendance.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
export const AttendanceSettingsUpsertMultiplierDrawer = ({
  isOpen,
  setIsOpen,
  multiplier,
  refresh,
  onClose,
  afterClose
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [deleteDrawerOpen, setDeleteDrawerOpen] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        const data = {
          name: values.name,
          value: Number(values.value)
        };
        setLoading(true);
        if (multiplier == null ? void 0 : multiplier.id) yield AttendanceAPI.updateAttendancePayMultiplier(multiplier.id, data);
        else yield AttendanceAPI.createAttendancePayMultiplier(data);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }),
            "error"
          );
        }
      } finally {
        setLoading(false);
      }
    }),
    [showMessage, refresh, setIsOpen, polyglot, multiplier]
  );
  const deleteMultiplier = useCallback(() => __async(void 0, null, function* () {
    if (!multiplier) return;
    try {
      yield AttendanceAPI.deleteAttendanceMultiplier(multiplier.id);
      showMessage(
        polyglot.t("MultiplierDeleteDrawer.successMessages.delete", { multiplierName: multiplier.name }),
        "success"
      );
      yield refresh();
      setIsOpen(false);
      setDeleteDrawerOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t("MultiplierDeleteDrawer.errorMessages.delete", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [showMessage, polyglot, refresh, setIsOpen, multiplier]);
  const formik = useFormik({
    initialValues: {
      name: (_a = multiplier == null ? void 0 : multiplier.name) != null ? _a : "",
      value: (_b = multiplier == null ? void 0 : multiplier.value) != null ? _b : 1
    },
    validationSchema: yup.object({
      name: yup.string().required(polyglot.t("validation.requiredField")),
      value: yup.number().typeError(polyglot.t("validation.selectValid")).min(0, polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField"))
    }),
    onSubmit
  });
  useEffect(() => {
    var _a2;
    if (multiplier) {
      formik.setFieldValue("name", multiplier.name);
      formik.setFieldValue("value", (_a2 = multiplier.value) != null ? _a2 : 1);
    }
  }, [multiplier]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "title2", children: multiplier ? polyglot.t("SettingsRouterPageConfig.updateMultiplier") : polyglot.t("SettingsRouterPageConfig.newMultiplier") }),
        multiplier && /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => setDeleteDrawerOpen(true), children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) })
      ] }),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "name",
          label: polyglot.t("General.name"),
          value: formik.values.name,
          onChange: formik.handleChange,
          error: formik.touched.name && !!formik.errors.name,
          helperText: (_c = formik.touched.name && formik.errors.name) != null ? _c : " "
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "value",
          label: polyglot.t("AbsenceModule.payMultiplier"),
          value: formik.values.value,
          onChange: formik.handleChange,
          error: formik.touched.value && !!formik.errors.value,
          helperText: (_d = formik.touched.value && formik.errors.value) != null ? _d : " "
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      ) }),
      /* @__PURE__ */ jsx(
        UpgradeToProModal,
        {
          isOpen: upgradeModalOpen,
          setIsDrawerOpen: (isOpen2) => setUpgradeModalOpen(isOpen2),
          planName: PlanNames.PEOPLE_PRO,
          messageSuffix: "proGeneric"
        }
      )
    ] }) }) }),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("MultiplierDeleteDrawer.title"),
        description: polyglot.t("MultiplierDeleteDrawer.description"),
        isOpen: deleteDrawerOpen,
        setIsOpen: setDeleteDrawerOpen,
        loading,
        action: deleteMultiplier
      }
    )
  ] });
};
