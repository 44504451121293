"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  AppsWithLogins
} from "@v2/feature/app-integration/app-integration.interface";
import { AppDetailsActionButton } from "@v2/feature/app-integration/features/app-details/components/app-details-action-button.component";
import { AppDetailsTable } from "@v2/feature/app-integration/features/app-details/components/app-details-table.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { useUserIdParam } from "@/hooks/userid-param.hook";
import { nestErrorMessage } from "@/lib/errors";
import { checkScopes } from "@/lib/scopes";
import { getIconForAppStatus } from "@/v2/feature/app-integration/app-integration-detailed-personal.router";
import { AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import {
  getScheduledActionDateString,
  hasScheduledAction,
  userHasDelayedAppAction
} from "@/v2/feature/app-integration/app-integration.util";
import { isAppOwner } from "@/v2/feature/app-integration/features/app-details/app-details.util";
import { AppAccessDetailDrawer } from "@/v2/feature/app-integration/features/app-details/components/app-access-detail-drawer.component";
import { AppActionsDrawer } from "@/v2/feature/app-integration/features/app-details/sections/app-actions-drawer/app-actions-drawer.section";
import { AppGroupManagementDrawer } from "@/v2/feature/app-integration/features/app-details/sections/app-group-management-drawer/app-group-management-drawer.section";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserAppsPage = () => {
  var _a;
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const [isAppActionsDrawerOpen, setIsAppActionsDrawerOpen] = useState(false);
  const [preselectedUser, setPreselectedUser] = useState(void 0);
  const [actionsDrawerMode, setActionsDrawerMode] = useState(void 0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerMode, setDrawerMode] = useState(void 0);
  const [appStub, setAppStub] = useState(void 0);
  const [openAccessDetailModal, setOpenAccessDetailModal] = useState(false);
  const [userForAccessDetail, setUserForAccessDetail] = useState(void 0);
  const [scheduledActionDate, setScheduledActionDate] = useState(void 0);
  const [statusColumnForRow, setStatusColumnForRow] = useState(void 0);
  const [appStubForRow, setAppStubForRow] = useState(void 0);
  const [hasAppsAllOrAppOwnerValue, setHasAppsAllOrAppOwnerValue] = useState(false);
  const [appNameForRow, setAppNameForRow] = useState(void 0);
  const [showMessage] = useMessage();
  const userId = useUserIdParam();
  const { getCachedUserById } = useCachedUsers();
  const userObjectForCurrentRoute = getCachedUserById(userId);
  const [combinedAppList, setCombinedAppList] = useState([]);
  const {
    data: userAppStubs,
    isValidating: userAppStubsLoading,
    error: userAppStubsError,
    mutate: userAppsFetch
  } = useApiClient(AppIntegrationEndpoints.getUserApps(userId), { suspense: false });
  const {
    data: companyApps,
    isValidating: companyAppsLoading,
    error: companyAppsError,
    mutate: companyAppsFetch
  } = useApiClient(AppIntegrationEndpoints.getInstalledApps(), { suspense: false });
  const {
    data: delayedActions,
    mutate: refreshDelayedActions
  } = useApiClient(AppIntegrationEndpoints.getAllDelayedAppActions(), { suspense: false });
  const { data: personalEmailForUser } = useApiClient(
    (preselectedUser == null ? void 0 : preselectedUser.userId) ? AppIntegrationEndpoints.getPersonalEmailForAppsByUserId(userId) : { url: void 0 },
    {
      suspense: false
    }
  );
  const getAppNameFromStub = useCallback(
    (stub) => {
      var _a2, _b;
      return (_b = (_a2 = companyApps == null ? void 0 : companyApps.find((eachApp) => eachApp.stub === stub)) == null ? void 0 : _a2.name) != null ? _b : "";
    },
    [companyApps]
  );
  const getAppInstanceFromStub = useCallback(
    (stub) => {
      return companyApps == null ? void 0 : companyApps.find((eachApp) => eachApp.stub === stub);
    },
    [companyApps]
  );
  const getApps = useCallback(() => __async(void 0, null, function* () {
    try {
      userAppsFetch();
      companyAppsFetch();
      refreshDelayedActions();
    } catch (error) {
      showMessage(`${polyglot.t("UserAppsPage.errorMessages.badRequest")}. ${nestErrorMessage(error)}`, "error");
    }
  }), [userAppsFetch, companyAppsFetch, refreshDelayedActions, showMessage, polyglot]);
  useEffect(() => {
    if (userAppStubsError || companyAppsError) {
      showMessage(
        `${polyglot.t("UserAppsPage.errorMessages.badRequest")}. ${nestErrorMessage({
          response: userAppStubsError != null ? userAppStubsError : companyAppsError
        })}`,
        "error"
      );
    }
  }, [polyglot, userAppStubsError, companyAppsError, showMessage]);
  useEffect(() => {
    const allAppsList = companyApps && companyApps.length > 0 ? companyApps.map((eachApp) => {
      var _a2;
      const emailsForUser = [];
      emailsForUser.push({ email: userObjectForCurrentRoute == null ? void 0 : userObjectForCurrentRoute.emailAddress, status: "No access" });
      if (personalEmailForUser) emailsForUser.push({ email: personalEmailForUser, status: "No access" });
      const existingApp = userAppStubs == null ? void 0 : userAppStubs.find((eachExistingApp) => eachExistingApp.stub === eachApp.stub);
      const delayedApp = (_a2 = delayedActions == null ? void 0 : delayedActions.delayed) == null ? void 0 : _a2.find(
        (eachAction) => eachAction.data.appStub === eachApp.stub && eachAction.data.userId === userId
      );
      if (existingApp) return existingApp;
      if (delayedApp && delayedApp.opts.delay)
        return __spreadValues({
          userId,
          emails: void 0,
          userStatus: `Scheduled`,
          id: void 0
        }, eachApp);
      return __spreadValues({
        userId,
        emails: emailsForUser != null ? emailsForUser : [],
        userStatus: "No access",
        id: void 0
      }, eachApp);
    }).sort((eachApp) => eachApp.userStatus === "No access" ? 1 : -1) : [];
    setCombinedAppList(allAppsList);
  }, [
    userAppStubs,
    companyApps,
    userId,
    delayedActions == null ? void 0 : delayedActions.delayed,
    globalState.user,
    userObjectForCurrentRoute == null ? void 0 : userObjectForCurrentRoute.emailAddress,
    personalEmailForUser
  ]);
  const hasAppsAllOrAppOwner = useCallback(
    (app) => {
      return checkScopes(globalState.user, ["apps:all"], { userId: globalState.user.userId }) || isAppOwner(globalState.user.userId, app);
    },
    [globalState.user]
  );
  const statusColumn = useCallback(
    (original) => {
      var _a2, _b, _c;
      const appStub2 = original.stub;
      if (appStub2 && AppsWithLogins.includes(appStub2) && original && original.emails && ((_a2 = original == null ? void 0 : original.emails) == null ? void 0 : _a2.length) > 0) {
        return (_b = original == null ? void 0 : original.emails.slice(0, 1)) == null ? void 0 : _b.map((emailObj) => /* @__PURE__ */ jsx(Box, { sx: { py: 0.2, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: original.userId && getIconForAppStatus(polyglot, emailObj.status)
          },
          `${original.userId}-status-${emailObj.email}`
        ) }, `${original.userId}-status-container`));
      } else if (delayedActions && (original == null ? void 0 : original.userId) && appStub2 && userHasDelayedAppAction(delayedActions, Number(original.userId), appStub2)) {
        return /* @__PURE__ */ jsx(Box, { sx: { py: 0.2, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: original.userId && getIconForAppStatus(polyglot, "Scheduled")
          },
          `${original.userId}-status`
        ) }) }, `${original.userId}-status-container`);
      } else if (delayedActions && (original == null ? void 0 : original.userId) && appStub2 && !userHasDelayedAppAction(delayedActions, Number(original.userId), appStub2) && (original == null ? void 0 : original.emails) && original.emails.length > 0) {
        return (_c = original == null ? void 0 : original.emails.slice(0, 1)) == null ? void 0 : _c.map((emailObj) => /* @__PURE__ */ jsx(Box, { sx: { py: 0.2, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: original.userId && getIconForAppStatus(polyglot, emailObj.status)
          },
          `${original.userId}-status`
        ) }, `${original.userId}-status-container`));
      } else {
        return /* @__PURE__ */ jsx(Box, { sx: { py: 0.2, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: original.userId && getIconForAppStatus(polyglot, original.userStatus)
          },
          `${original.userId}-status`
        ) }, `${original.userId}-status-container`);
      }
    },
    [delayedActions, polyglot]
  );
  const teamAccessColumns = useMemo(
    () => [
      {
        header: () => polyglot.t("UserAppsPage.app"),
        id: "app-stub",
        accessorFn: (row) => row.stub && getAppNameFromStub(row.stub),
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsxs(Box, { sx: { py: 0.2, display: "flex", alignItems: "center" }, children: [
          /* @__PURE__ */ jsx("img", { src: `/app-icons-v2/images/${original.stub}.png`, width: 20, alt: original.stub }),
          /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                display: "flex",
                alignItems: "center",
                ml: 1,
                gap: spacing.m5
              }),
              children: original.stub && getAppNameFromStub(original.stub)
            }
          )
        ] })
      },
      {
        header: () => polyglot.t("UserAppsPage.primaryEmail"),
        id: "primaryEmail",
        enableSorting: false,
        accessorFn: (row) => {
          var _a2, _b;
          return (_b = (_a2 = row.emails) == null ? void 0 : _a2.slice(0, 1)) != null ? _b : row.primaryEmail;
        },
        cell: ({ row: { original } }) => {
          var _a2;
          return /* @__PURE__ */ jsx("div", { children: (original == null ? void 0 : original.emails) ? (_a2 = original == null ? void 0 : original.emails.slice(0, 1)) == null ? void 0 : _a2.map((emailObj) => /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                display: "flex",
                alignItems: "center",
                gap: spacing.m5
              }),
              children: emailObj.email
            }
          )) : /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
                display: "flex",
                alignItems: "center",
                gap: spacing.m5
              }),
              children: original.primaryEmail
            }
          ) });
        }
      },
      {
        header: () => polyglot.t("UserAppsPage.userStatus"),
        id: "userStatus",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => statusColumn(original)
      },
      {
        header: () => "",
        id: "action",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a2;
          return (original == null ? void 0 : original.stub) && /* @__PURE__ */ jsx(
            AppDetailsActionButton,
            {
              refresh: getApps,
              appStub: original == null ? void 0 : original.stub,
              user: original,
              table: "team",
              setActionsDrawerMode,
              setPreselectedUser,
              setIsAppActionsDrawerOpen,
              hasAppsAllOrAppOwner: (_a2 = hasAppsAllOrAppOwner(getAppInstanceFromStub(original == null ? void 0 : original.stub))) != null ? _a2 : false,
              error: false,
              setAppStub,
              setGroupManagementDrawerOpen: setIsDrawerOpen,
              setGroupManagementDrawerMode: setDrawerMode,
              delayedActions: delayedActions && delayedActions.delayed ? delayedActions : { delayed: [] }
            }
          );
        }
      }
    ],
    [polyglot, getAppNameFromStub, statusColumn, getApps, hasAppsAllOrAppOwner, getAppInstanceFromStub, delayedActions]
  );
  const handleRowClicked = useCallback(
    ({ original }) => {
      var _a2;
      setOpenAccessDetailModal(true);
      setUserForAccessDetail(original);
      const matchingAction = (original == null ? void 0 : original.userId) && !Number.isNaN(Number(original == null ? void 0 : original.userId)) ? hasScheduledAction(delayedActions, Number(original == null ? void 0 : original.userId), original.stub) : void 0;
      setScheduledActionDate(matchingAction ? getScheduledActionDateString(matchingAction) : void 0);
      if (original.stub) {
        const appInstance = getAppInstanceFromStub(original == null ? void 0 : original.stub);
        setStatusColumnForRow(statusColumn(original));
        setAppStubForRow(original.stub);
        setHasAppsAllOrAppOwnerValue((_a2 = hasAppsAllOrAppOwner(appInstance)) != null ? _a2 : false);
        setAppNameForRow(appInstance == null ? void 0 : appInstance.name);
      }
    },
    [delayedActions, getAppInstanceFromStub, hasAppsAllOrAppOwner, statusColumn]
  );
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.title2), children: "Apps" }) }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: userAppStubsLoading || companyAppsLoading, sx: __spreadValues({}, spacing.pt20), children: [
      /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
        AppDetailsTable,
        {
          column: teamAccessColumns,
          row: combinedAppList,
          loading: (_a = userAppStubsLoading || companyAppsLoading) != null ? _a : false,
          handleRowClicked
        }
      ) }),
      appStub && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          AppActionsDrawer,
          {
            appStub,
            usersWithoutAccess: [],
            isOpen: isAppActionsDrawerOpen,
            onClose: () => setIsAppActionsDrawerOpen(false),
            refreshApp: getApps,
            setIsAppActionsDrawerOpen,
            preselectedUser,
            mode: actionsDrawerMode
          }
        ),
        /* @__PURE__ */ jsx(
          AppGroupManagementDrawer,
          {
            appStub,
            selectedGroupMembership: void 0,
            externalUserList: [],
            usersWithoutAccess: [],
            usersWithAccess: [],
            isOpen: isDrawerOpen,
            onClose: () => setIsDrawerOpen(false),
            refreshApp: getApps,
            setIsDrawerOpen,
            mode: drawerMode,
            groupMembers: {},
            groupList: []
          }
        )
      ] }),
      userForAccessDetail && statusColumnForRow && appNameForRow && appStubForRow && /* @__PURE__ */ jsx(
        AppAccessDetailDrawer,
        {
          isOpen: openAccessDetailModal,
          setIsOpen: setOpenAccessDetailModal,
          appUserDetail: userForAccessDetail,
          appName: appNameForRow,
          appStatusValue: statusColumnForRow,
          scheduledActionDate,
          appActionButtons: /* @__PURE__ */ jsx(
            AppDetailsActionButton,
            {
              setAppAccessDetailsDrawerMode: setOpenAccessDetailModal,
              setIsAppActionsDrawerOpen,
              setGroupManagementDrawerOpen: setIsDrawerOpen,
              setGroupManagementDrawerMode: setDrawerMode,
              appStub: appStubForRow,
              user: userForAccessDetail,
              setAppStub,
              delayedActions: delayedActions != null ? delayedActions : { delayed: [] },
              refreshDelayedActions,
              table: "team",
              setPreselectedUser,
              setActionsDrawerMode,
              hasAppsAllOrAppOwner: hasAppsAllOrAppOwnerValue,
              appAccessDetailsDrawerMode: true,
              refresh: getApps,
              error: false
            }
          )
        }
      )
    ] })
  ] });
};
