"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { Box, Skeleton, Typography, useMediaQuery } from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Desc } from "@/images/side-bar-icons/ArrowDownSmall.svg";
import { ReactComponent as Asc } from "@/images/side-bar-icons/ArrowUpSmall.svg";
import { ReactComponent as SortIcon } from "@/images/side-bar-icons/SortIcon.svg";
import { useDynamicShadowEffect } from "@/v2/components/table/dynamic-shadow-effect.hook";
import { PaginationDetail } from "@/v2/components/table/pagination-detail.component";
import { TableDataWithTooltip } from "@/v2/components/table/table-data-with-tooltip.component";
import { TableHeadingWithTooltip } from "@/v2/components/table/table-heading-with-tooltip.component";
import "@/v2/components/table/styles/basic-table.scss";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import {
  iconSize,
  Table,
  TableBody,
  TableHeadRowBorder,
  TableData,
  TableHead,
  TableHeadRow,
  TableRow,
  DEFAULT_MAX_SIZE,
  DEFAULT_MIN_SIZE,
  DEFAULT_SIZE
} from "@/v2/styles/table.styles";
export const DEFAULT_PAGE_SIZE = 20;
export const BasicServerTable = ({
  rowData,
  columnData,
  sorting,
  setSorting,
  pagination,
  setPagination,
  loading,
  totalPages,
  rowClick,
  customRowStyle,
  hiddenColumns,
  fixedFirstColumn = true,
  fixedSecondColumn = false,
  fixedLastColumn = false,
  totalItems,
  stickyHeader,
  stickyHeaderHeight = "calc(100vh - 161px)"
  // Height of the table when sticky header is enabled
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const firstColumnRef = useRef(null);
  const tableContainerRef = useRef(null);
  const [firstColumnWidth, setFirstColumnWidth] = useState(30);
  const paginatedData = rowData.slice(pagination.pageIndex * 10, pagination.pageIndex * 10 + 10);
  const table = useReactTable({
    data: totalPages ? rowData : paginatedData,
    columns: columnData.filter((column) => !column.id || !(hiddenColumns == null ? void 0 : hiddenColumns.includes(column.id))),
    defaultColumn: {
      minSize: DEFAULT_MIN_SIZE,
      size: DEFAULT_SIZE,
      maxSize: DEFAULT_MAX_SIZE
    },
    pageCount: totalPages ? totalPages + 1 : Math.ceil(rowData.length / pagination.pageSize),
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    debugTable: process.env.REACT_APP_ENV !== "production",
    manualPagination: true
  });
  const stickyColumns = useMemo(() => {
    const stickyColumns2 = [];
    if (!columnData.length) return stickyColumns2;
    if (fixedFirstColumn) {
      stickyColumns2.push({
        id: columnData[0].id,
        stickyPosition: { key: "left", value: 0 },
        isLastLeftPinned: !fixedSecondColumn
      });
    }
    if (fixedFirstColumn && fixedSecondColumn && columnData.length > 1) {
      stickyColumns2.push({
        id: columnData[1].id,
        stickyPosition: { key: "left", value: firstColumnWidth },
        isLastLeftPinned: true
      });
    }
    const lastColumn = columnData[columnData.length - 1];
    if (columnData.length > 1 && (fixedLastColumn || lastColumn.id === "action" || lastColumn.id === "actions")) {
      stickyColumns2.push({
        id: columnData[columnData.length - 1].id,
        stickyPosition: { key: "right", value: 0 },
        isFirstRightPinned: true
      });
    }
    return stickyColumns2;
  }, [fixedFirstColumn, fixedSecondColumn, fixedLastColumn, columnData, firstColumnWidth]);
  const isLargeScreen = useMediaQuery("(min-width:768px)");
  useDynamicShadowEffect(tableContainerRef, stickyColumns, isLargeScreen);
  const isActionClick = (event) => {
    const target = event.target;
    const button = target.closest("button");
    const actionButton = button == null ? void 0 : button.closest('[data-column-id="actions"], [data-column-id="action"]');
    const selectColumn = target.closest('[data-column-id="select"]');
    return !!(actionButton || selectColumn);
  };
  useLayoutEffect(() => {
    if (!fixedSecondColumn || !fixedFirstColumn) return;
    if (firstColumnRef.current) {
      const width = firstColumnRef.current.offsetWidth;
      setFirstColumnWidth(width);
    }
  }, [fixedSecondColumn, fixedFirstColumn, columnData]);
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs(
      "div",
      {
        ref: tableContainerRef,
        style: stickyHeader ? {
          overflowX: "auto",
          width: "100%",
          minHeight: 0,
          maxHeight: stickyHeaderHeight,
          overflowY: "auto",
          position: "relative"
        } : { overflowX: "auto", maxHeight: "inherit", width: "100%" },
        children: [
          /* @__PURE__ */ jsxs(Table, { children: [
            /* @__PURE__ */ jsx(TableHead, { style: stickyHeader ? { position: "sticky", top: 0, zIndex: 3 } : {}, children: (_a = table.getHeaderGroups()) == null ? void 0 : _a.map((headerGroup) => /* @__PURE__ */ jsxs(React.Fragment, { children: [
              /* @__PURE__ */ jsx(TableHeadRow, { children: headerGroup.headers.map((header) => {
                var _a2;
                const cellStyle = {};
                let tooltipText = "";
                const headerDef = header.column.columnDef.header;
                if (typeof headerDef === "function") {
                  tooltipText = headerDef(header.getContext());
                }
                if (typeof headerDef === "string") {
                  tooltipText = headerDef;
                }
                return /* @__PURE__ */ jsx(
                  TableHeadingWithTooltip,
                  {
                    tooltipText,
                    maxWidth: header.column.columnDef.maxSize || DEFAULT_MAX_SIZE,
                    minWidth: header.column.columnDef.minSize || DEFAULT_MIN_SIZE,
                    style: __spreadValues({}, cellStyle),
                    stickyPosition: (_a2 = stickyColumns.find((col) => col.id === header.column.id)) == null ? void 0 : _a2.stickyPosition,
                    children: header.isPlaceholder ? null : /* @__PURE__ */ jsxs(
                      "div",
                      {
                        style: { display: "flex", alignItems: "center", gap: spacing.g5 },
                        onClick: header.column.getToggleSortingHandler(),
                        children: [
                          /* @__PURE__ */ jsx("span", { style: __spreadValues({}, themeFonts.caption), children: flexRender(header.column.columnDef.header, header.getContext()) }),
                          header.column.columnDef.enableSorting && /* @__PURE__ */ jsx("div", { className: "sortIcon", children: (() => {
                            const sortState = header.column.getIsSorted();
                            switch (sortState) {
                              case "asc":
                                return /* @__PURE__ */ jsx(Asc, { width: 12, height: 12, style: { color: themeColors.DarkGrey } });
                              case "desc":
                                return /* @__PURE__ */ jsx(Desc, { width: 12, height: 12, style: { color: themeColors.DarkGrey } });
                              default:
                                return /* @__PURE__ */ jsx(SortIcon, __spreadValues({ className: "defaultIcon" }, iconSize));
                            }
                          })() })
                        ]
                      }
                    )
                  },
                  header.id
                );
              }) }),
              /* @__PURE__ */ jsx(TableHeadRowBorder, { children: /* @__PURE__ */ jsx("td", { colSpan: headerGroup.headers.length }) })
            ] }, headerGroup.id)) }),
            loading && /* @__PURE__ */ jsx(TableBody, { children: [1, 2, 3, 4].map((row) => /* @__PURE__ */ jsx(TableRow, { children: table.getAllColumns().map((cell, i) => /* @__PURE__ */ jsx(TableData, { maxWidth: DEFAULT_MAX_SIZE, minWidth: DEFAULT_MIN_SIZE, children: /* @__PURE__ */ jsx(Skeleton, { variant: "text", sx: { backgroundColor: themeColors.Background } }) }, i)) }, row)) }),
            (totalPages ? rowData : paginatedData).length > 0 && !loading && /* @__PURE__ */ jsx(TableBody, { children: (_b = table.getRowModel()) == null ? void 0 : _b.rows.map((row) => /* @__PURE__ */ jsx(
              TableRow,
              {
                onClick: (event) => {
                  if (!rowClick) return;
                  if (isActionClick(event)) return;
                  rowClick(row);
                },
                style: { cursor: rowClick ? "pointer" : "default" },
                children: row.getVisibleCells().map((cell, idx) => {
                  var _a2, _b2, _c, _d, _e;
                  const cellStyle = {};
                  const cellValue = cell.getValue();
                  const tooltipText = typeof cellValue === "string" ? cellValue : "";
                  const stickyPosition = (_a2 = stickyColumns.find((col) => col.id === cell.column.id)) == null ? void 0 : _a2.stickyPosition;
                  return typeof cellValue === "string" ? /* @__PURE__ */ jsx(
                    TableDataWithTooltip,
                    {
                      ref: idx === 0 ? firstColumnRef : null,
                      tooltipText,
                      columnId: cell.column.id,
                      maxWidth: cell.column.columnDef.maxSize || DEFAULT_MAX_SIZE,
                      minWidth: cell.column.columnDef.minSize || DEFAULT_MIN_SIZE,
                      cellStyle: __spreadValues(__spreadValues({}, cellStyle), customRowStyle == null ? void 0 : customRowStyle(row)),
                      meta: (_c = (_b2 = cell.column) == null ? void 0 : _b2.columnDef) == null ? void 0 : _c.meta,
                      stickyPosition,
                      children: flexRender(cell.column.columnDef.cell, cell.getContext())
                    },
                    cell.id
                  ) : /* @__PURE__ */ jsx(
                    TableData,
                    {
                      ref: idx === 0 ? firstColumnRef : null,
                      "data-column-id": cell.column.id,
                      maxWidth: cell.column.columnDef.maxSize || DEFAULT_MAX_SIZE,
                      minWidth: cell.column.columnDef.minSize || DEFAULT_MIN_SIZE,
                      cellStyle: __spreadValues(__spreadValues({}, cellStyle), customRowStyle == null ? void 0 : customRowStyle(row)),
                      meta: (_e = (_d = cell.column) == null ? void 0 : _d.columnDef) == null ? void 0 : _e.meta,
                      stickyPosition,
                      children: flexRender(cell.column.columnDef.cell, cell.getContext())
                    },
                    cell.id
                  );
                })
              },
              row.id
            )) })
          ] }),
          (totalPages ? rowData : paginatedData).length < 1 && !loading && /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                width: "100%",
                display: "flex",
                marginTop: spacing.m10,
                height: "50px",
                alignItems: "center",
                justifyContent: "center"
              },
              children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.Grey }), children: polyglot.has("BasicServerTable.couldNotFindAnything") ? polyglot.t("BasicServerTable.couldNotFindAnything") : "Sorry, we couldn't find anything..." })
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      PaginationDetail,
      {
        totalPageCount: totalPages ? totalPages : table.getPageCount(),
        totalRecordCount: totalItems != null ? totalItems : 0,
        current: table.getState().pagination.pageIndex,
        onNextAction: () => {
          table.nextPage();
        },
        nextDisabled: pagination.pageIndex === totalPages,
        onPreviousAction: () => {
          table.previousPage();
        },
        previousDisabled: pagination.pageIndex === 1,
        paginationState: table.getState().pagination,
        setPaginationState: (pageSize, pageIndex) => {
          table.setPagination({ pageSize, pageIndex });
        },
        handleState: (page) => {
          let pageState = page > 0 ? Number(page) : 1;
          if (pageState < 1) pageState = 1;
          if (totalPages && pageState > totalPages) pageState = totalPages;
          table.setPageIndex(pageState);
        }
      }
    )
  ] });
};
