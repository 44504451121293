"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { ApprovalRuleDrawer } from "@v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  PaymentCategoryEnum
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface";
import { PaymentTypeSettingsAPI } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
const getApprovalRulesOptions = (approvalRules) => approvalRules.map((rule) => ({
  label: rule.name,
  value: rule.id
}));
export const PaymentSettingsEditApprovalRulesDrawer = ({
  isOpen,
  setIsOpen,
  paymentTypeSetting,
  approvalRules,
  refreshApprovalRules,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { hasScopes } = useScopes();
  const [isApprovalDrawerOpen, setIsApprovalDrawerOpen] = useState(false);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      if (!paymentTypeSetting.id || !values.approvalRuleId) return;
      try {
        setLoading(true);
        yield PaymentTypeSettingsAPI.updateApproval(paymentTypeSetting.id, values.approvalRuleId);
        setIsOpen(false);
        showMessage("Successfully updated approval settings", "success");
      } catch (error) {
        showMessage(
          polyglot.t("AbsenceDrawerPage.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
        yield refresh();
      }
    }),
    [paymentTypeSetting.id, setIsOpen, showMessage, polyglot, refresh]
  );
  const formik = useFormik({
    initialValues: {
      approvalRuleId: paymentTypeSetting == null ? void 0 : paymentTypeSetting.approvalRuleId
    },
    enableReinitialize: true,
    onSubmit
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t("PaymentSettingsPage.editApprovalRules") }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "approvalRuleId",
        label: polyglot.t("ApprovalRuleModule.approvalRule"),
        options: getApprovalRulesOptions(approvalRules),
        value: formik.values.approvalRuleId,
        onChange: formik.handleChange,
        compareValue: formik.values.approvalRuleId,
        error: !!formik.errors.approvalRuleId && formik.touched.approvalRuleId,
        helperText: formik.touched.approvalRuleId && formik.errors.approvalRuleId,
        editList: paymentTypeSetting.type === PaymentCategoryEnum.EXPENSE ? {
          isHidden: !hasScopes(["company.settings:all"]),
          handler: () => {
            setIsApprovalDrawerOpen(true);
          }
        } : void 0
      }
    ),
    /* @__PURE__ */ jsx(
      ApprovalRuleDrawer,
      {
        isOpen: isApprovalDrawerOpen,
        setIsOpen: setIsApprovalDrawerOpen,
        approvalRule: null,
        refresh: () => __async(void 0, null, function* () {
          if (refreshApprovalRules) yield refreshApprovalRules();
        })
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true
      }
    ) })
  ] }) }) });
};
