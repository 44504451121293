"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ContractAPI } from "@/api-client/contract.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DocumentNewAPI } from "@/v2/feature/document-new/api-client/document-new.api";
import { NewDocumentPreview } from "@/v2/feature/document-new/components/new-document-preview.component";
import {
  companySignatureRequired,
  recipientSignatureRequired,
  signatoriesRequiredForContract
} from "@/v2/feature/document-new/document-new.util";
import {
  DocumentFileOperation
} from "@/v2/feature/document-new/interfaces/document-new.interface";
import { stringToPreviewFile } from "@/v2/feature/documents/documents.util";
import { OnboardingActionCard } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/components/onboarding-action-card.component";
export const OnboardingContractActionCard = ({
  step,
  pendingContracts,
  setContractToSign,
  setOpenMissingFieldContractModal
}) => {
  var _a;
  const [completed, setCompleted] = useState(false);
  const [requiresAdditional, setRequiresAdditional] = useState(false);
  const [contractDoc, setContractDoc] = useState(void 0);
  const [loading, setLoading] = useState(false);
  const [docData, setDocData] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [error, setError] = useState(null);
  const fileURLRef = useRef(null);
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const pendingContractForStep = useCallback(() => {
    return pendingContracts[step - 1];
  }, [pendingContracts, step]);
  const pendingContractRequiresAdditional = useCallback(() => {
    var _a2, _b, _c;
    const pc = pendingContractForStep();
    const signatoriesRequired = ((_a2 = pc == null ? void 0 : pc.contract) == null ? void 0 : _a2.templateText) ? signatoriesRequiredForContract((_b = pc == null ? void 0 : pc.contract) == null ? void 0 : _b.templateText) : null;
    const requiresSignaturesFromAdditional = (pc == null ? void 0 : pc.contract) && companySignatureRequired(pc, signatoriesRequired);
    return Boolean(requiresSignaturesFromAdditional && !((_c = pc == null ? void 0 : pc.contract) == null ? void 0 : _c.companySignatureTimestamp));
  }, [pendingContractForStep]);
  const pendingContractIsFinalised = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b, _c, _d, _e;
    const pc = pendingContractForStep();
    const signatoriesRequired = ((_a2 = pc == null ? void 0 : pc.contract) == null ? void 0 : _a2.templateText) ? signatoriesRequiredForContract((_b = pc == null ? void 0 : pc.contract) == null ? void 0 : _b.templateText) : null;
    let hasMissingFields = false;
    if (pc == null ? void 0 : pc.contractId) {
      const missingFields = yield ContractAPI.getEmployeeFieldsForContractById(pc.contractId);
      hasMissingFields = (_c = Object.values(missingFields)) == null ? void 0 : _c.some((v) => v === true);
    }
    const requiresSignaturesFromRecipient = (pc == null ? void 0 : pc.contract) && recipientSignatureRequired(pc, signatoriesRequired);
    return (!requiresSignaturesFromRecipient || !!((_d = pc == null ? void 0 : pc.contract) == null ? void 0 : _d.recipientSignatureTimestamp) && !!((_e = pc == null ? void 0 : pc.contract) == null ? void 0 : _e.recipient)) && !hasMissingFields;
  }), [pendingContractForStep]);
  const handleContractSignAction = useCallback(() => {
    const pc = pendingContractForStep();
    if (pc && pc.contract) {
      setContractToSign(pc.contract);
      setOpenMissingFieldContractModal(true);
    }
  }, [pendingContractForStep, setContractToSign, setOpenMissingFieldContractModal]);
  const handlePreviewClick = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const document = pendingContractForStep();
      if (!document) return;
      const response = yield DocumentNewAPI.fetchFileDataById(document.id, DocumentFileOperation.preview);
      const docDataResponse = yield stringToPreviewFile(
        response.data.data,
        response.contentType,
        response.extension,
        document.name
      );
      setDocData(docDataResponse);
      if (docDataResponse && docDataResponse.uri) fileURLRef.current = docDataResponse.uri;
      setIsPreviewOpen(true);
      setError(null);
    } catch (e) {
      console.error("::URL Download error", e);
      showMessage(polyglot.t("DocumentTable.errorMessages.preview", { errorMessage: nestErrorMessage(e) }), "error");
    } finally {
      setLoading(false);
    }
  }), [pendingContractForStep, polyglot, showMessage]);
  const fetchContractStatus = useCallback(() => __async(void 0, null, function* () {
    const pc = pendingContractForStep();
    setContractDoc(pc);
    if (pc) {
      const isFinalised = yield pendingContractIsFinalised();
      setCompleted(isFinalised);
      const requiresAdditional2 = pendingContractRequiresAdditional();
      setRequiresAdditional(requiresAdditional2);
    } else {
      setCompleted(false);
    }
  }), [pendingContractForStep, pendingContractIsFinalised, pendingContractRequiresAdditional]);
  useEffect(() => {
    fetchContractStatus();
  }, [fetchContractStatus]);
  const closePreview = useCallback(() => {
    setIsPreviewOpen(false);
    if (fileURLRef.current) {
      URL.revokeObjectURL(fileURLRef.current);
      fileURLRef.current = null;
    }
  }, []);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      OnboardingActionCard,
      {
        label: polyglot.t("OnboardingOverview.contract"),
        completed,
        chips: contractDoc ? [contractDoc.name] : void 0,
        actionButton: contractDoc && !completed ? /* @__PURE__ */ jsx(ButtonComponent, { onClick: handleContractSignAction, sizeVariant: "small", colorVariant: "primary", fullWidth: true, children: polyglot.t("OnboardingOverview.sign") }) : void 0,
        completedButton: contractDoc && !requiresAdditional ? /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            onClick: handlePreviewClick,
            sizeVariant: "small",
            colorVariant: "secondary",
            fullWidth: true,
            loading,
            children: "View"
          }
        ) : void 0
      }
    ),
    /* @__PURE__ */ jsx(
      NewDocumentPreview,
      {
        docData,
        onClose: closePreview,
        isOpen: isPreviewOpen,
        error,
        title: (_a = docData == null ? void 0 : docData.fileName) != null ? _a : "Document"
      }
    )
  ] });
};
