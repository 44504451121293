"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import {
  allPartiesHaveSignedContract,
  companySignatureRequired,
  downloadFileByUrl,
  downloadIncompleteContractsByID,
  downloadTemplatesByID,
  isPreviewableFile,
  recipientSignatureRequired
} from "@v2/feature/documents/documents.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { constVoid, pipe } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";
import * as RA from "fp-ts/ReadonlyArray";
import * as RNEA from "fp-ts/ReadonlyNonEmptyArray";
import { generatePath, Link, useHistory } from "react-router-dom";
import { ContractAPI } from "@/api-client/contract.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Cancel } from "@/images/app-icons/Close.svg";
import { ReactComponent as MissingDoc } from "@/images/documents/MissingDoc.svg";
import { ReactComponent as UploadDoc } from "@/images/documents/UploadedDoc.svg";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Download } from "@/images/side-bar-icons/Download.svg";
import { ReactComponent as Eye } from "@/images/side-bar-icons/Eye.svg";
import { ReactComponent as Mail } from "@/images/side-bar-icons/Mail.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  ADMIN_USER_DOCUMENTS_ROUTE,
  DOCUMENTS_ME_ROUTE,
  TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE,
  TEMPLATE_CONTRACT_SIGN_ROUTE
} from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { IconTextCell } from "@/v2/components/table/icon-text-cell.component";
import { BasicServerTable } from "@/v2/components/table/server-side-table.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { DisplayDocumentStatus } from "@/v2/feature/documents/components/display-document-status.component";
import { ShareViaEmailDrawer } from "@/v2/feature/documents/components/document-share-via-email-drawer.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { PlusAvatar, UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { selectColumnSize } from "@/v2/styles/table.styles";
import { dateAPItoDisplay } from "@/v2/util/date-format.util";
import { getDisplayName } from "@/v2/util/string-format.util";
const iconSize = { width: 14, height: 14 };
export const MultiUserAvatars = ({ currentUser, users, size = "xxsmall" }) => {
  const [firstThreeUsers, remainingUsers] = pipe(users, RA.splitAt(3));
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: 0.5, alignItems: "center" }, children: [
    firstThreeUsers.map(({ User: { userId } }) => /* @__PURE__ */ jsx(
      Link,
      {
        style: { textDecoration: "none" },
        to: currentUser.userId === userId ? generatePath(DOCUMENTS_ME_ROUTE, { userId }) : generatePath(ADMIN_USER_DOCUMENTS_ROUTE, { userId }),
        children: /* @__PURE__ */ jsx(UserAvatar, { userId, tooltip: true, size })
      },
      `avatar:${userId}`
    )),
    remainingUsers.length > 0 ? /* @__PURE__ */ jsx(
      Tooltip,
      {
        title: /* @__PURE__ */ jsx(Fragment, { children: remainingUsers.map((u) => /* @__PURE__ */ jsx("div", { children: getDisplayName(u.User) }, u.User.userId)) }),
        children: /* @__PURE__ */ jsx(PlusAvatar, { count: remainingUsers.length, size })
      }
    ) : /* @__PURE__ */ jsx(Fragment, {})
  ] });
};
export const DocumentTable = ({
  documents,
  documentTypes,
  onEditClick,
  onVerified = constVoid,
  sorting,
  setSorting,
  pagination,
  setPagination,
  totalPages,
  personalDocuments = false,
  onDelete,
  loading,
  paginationReset = false,
  onMissingContractFields,
  setSelectedDocBuffer,
  setSelectedDocContentType,
  setSelectedDocName,
  setOpenPreviewModal,
  totalItems,
  setSelectionModel,
  selectionModel,
  stickyHeader
}) => {
  var _a, _b, _c;
  const [state] = useContext(GlobalContext);
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext(state.user);
  const [showMessage] = useMessage();
  const [openShareViaEmailModal, setOpenShareViaEmailModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const isContractAdmin = hasScopes(["templates:all"], scopesContext);
  const handleDownloadClick = useCallback(
    (zeltDocument) => __async(void 0, null, function* () {
      try {
        const PDFBlob = yield ContractAPI.downloadAuditTrail({
          contractId: zeltDocument.contractId
        });
        const file = new Blob([PDFBlob], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        let link = document.createElement("a");
        link.download = `${zeltDocument.contractId}-${/* @__PURE__ */ new Date()}-${Date.now()}.pdf`;
        link.href = fileURL;
        link.click();
      } catch (e) {
        console.error("::Download error", e);
        showMessage(
          polyglot.t("DocumentTable.errorMessages.downloadTrail", { errorMessage: nestErrorMessage(e) }),
          "error"
        );
      }
    }),
    [polyglot, showMessage]
  );
  const handlePreviewClick = useCallback(
    (zeltDocument) => __async(void 0, null, function* () {
      var _a2, _b2;
      try {
        const fileUuidFromAttachment = zeltDocument.attachments && zeltDocument.attachments[0] && ((_a2 = zeltDocument.attachments[0]) == null ? void 0 : _a2.fileUuid);
        const finalUuid = (_b2 = zeltDocument.fileUuid) != null ? _b2 : fileUuidFromAttachment;
        if (!finalUuid) return;
        yield DocumentAPI.previewViaUuid(finalUuid).then((_0) => __async(void 0, [_0], function* ({ contentType, file }) {
          setSelectedDocName(zeltDocument.name);
          setSelectedDocBuffer(file);
          setSelectedDocContentType(contentType);
          setOpenPreviewModal(true);
        }));
      } catch (e) {
        console.error("::URL Download error", e);
        showMessage(polyglot.t("DocumentTable.errorMessages.preview", { errorMessage: nestErrorMessage(e) }), "error");
      }
    }),
    [polyglot, setOpenPreviewModal, setSelectedDocBuffer, setSelectedDocContentType, setSelectedDocName, showMessage]
  );
  const handleShareViaEmailClick = useCallback(
    (zeltDocument) => __async(void 0, null, function* () {
      try {
        setSelectedDocument(zeltDocument);
        setOpenShareViaEmailModal(true);
      } catch (e) {
        showMessage(polyglot.t("DocumentTable.errorMessages.share", { errorMessage: nestErrorMessage(e) }), "error");
      }
    }),
    [showMessage, polyglot]
  );
  const remindPendingContractParticipants = useCallback(
    (contractId) => __async(void 0, null, function* () {
      try {
        yield ContractAPI.remindPending({
          contractId
        });
        showMessage(polyglot.t("DocumentTable.successMessages.remind"), "success");
      } catch (e) {
        console.error("::Remind error", e);
        showMessage(polyglot.t("DocumentTable.errorMessages.remind", { errorMessage: nestErrorMessage(e) }), "error");
      }
    }),
    [showMessage, polyglot]
  );
  const remindUserToUploadDocument = useCallback(
    (zeltDocument) => __async(void 0, null, function* () {
      try {
        const belongsToUserId = zeltDocument.belongsTo && zeltDocument.belongsTo.length > 0 ? zeltDocument.belongsTo[0].User.userId : void 0;
        if (belongsToUserId) {
          yield DocumentAPI.remindUserForPendingDocument(zeltDocument.name, belongsToUserId);
          showMessage(polyglot.t("DocumentTable.successMessages.remindToUpload"), "success");
        } else {
          console.error("Failed to send reminder to user as belongs to userId is missing: ", {
            belognsTo: zeltDocument.belongsTo
          });
        }
      } catch (e) {
        showMessage(
          polyglot.t("DocumentTable.errorMessages.remindToUpload", { errorMessage: nestErrorMessage(e) }),
          "error"
        );
      }
    }),
    [showMessage, polyglot]
  );
  const deleteContractDocument = useCallback(
    (contractId) => __async(void 0, null, function* () {
      try {
        yield ContractAPI.deleteContract({
          contractId
        });
        showMessage(polyglot.t("DocumentTable.successMessages.delete"), "success");
        if (onDelete) {
          yield onDelete();
        }
      } catch (e) {
        console.error("::Delete error", e);
        showMessage(polyglot.t("DocumentTable.errorMessages.delete", { errorMessage: nestErrorMessage(e) }), "error");
      }
    }),
    [polyglot, showMessage, onDelete]
  );
  const getDocumentsWithAttachmentsMenuOptions = useCallback(
    (row, contractSignAction, isEditableOrRemovable) => {
      var _a2, _b2, _c2, _d, _e, _f, _g, _h, _i;
      const documentWithAttachmentMenuOptions = [];
      const { contractId, contract } = row;
      const belongsToOnlyYou = row.belongsTo && row.belongsTo.length === 1 && row.belongsTo[0].User.userId === state.user.userId;
      const reporteeScopeContext = {
        userId: row.belongsTo && ((_a2 = row.belongsTo) == null ? void 0 : _a2.length) === 1 ? (_c2 = (_b2 = row.belongsTo[0]) == null ? void 0 : _b2.User) == null ? void 0 : _c2.userId : 0
      };
      const stdUserCan = state.user.userId === row.uploadedBy || belongsToOnlyYou;
      const attachmentExists = !!(row.attachments && row.attachments.length && ((_d = row.attachments[0]) == null ? void 0 : _d.fileUuid) || row.fileUuid);
      const hasFileUuid = row.fileUuid && typeof row.fileUuid === "string";
      const fileExistsForNonContractAndHasDocAllScope = (attachmentExists || hasFileUuid) && !contractId && hasScopes(["documents:all"], scopesContext);
      const nonContractAndStdUserCanOrDocManagerScopes = !contractId && (stdUserCan || hasScopes(["documents:manager"], reporteeScopeContext));
      const hasSignatories = (contract == null ? void 0 : contract.signatoriesRequired) && ((_e = contract == null ? void 0 : contract.signatoriesRequired) == null ? void 0 : _e.length) > 0;
      const currentUserIsContractRecipient = contractId && (contract == null ? void 0 : contract.recipient) === state.user.userId;
      const unfinalisedContractIsMissingInfo = !!((contract == null ? void 0 : contract.id) && !hasFileUuid && ((contract == null ? void 0 : contract.hasMissingPersonalFields) || (contract == null ? void 0 : contract.hasMissingWorkFields)));
      const allPartiesHaveSigned = allPartiesHaveSignedContract(row);
      const contractSignedByAllAndStdUserCanOrDocManagerScope = allPartiesHaveSigned && (stdUserCan || hasScopes(["documents:manager"], reporteeScopeContext));
      const missingPersonalInfoAndCanFill = currentUserIsContractRecipient && unfinalisedContractIsMissingInfo;
      const hasBelongsTo = row.belongsTo && ((_f = row.belongsTo) == null ? void 0 : _f.length) > 0;
      const shouldRemindToUpload = !attachmentExists && hasBelongsTo && !contractId;
      if (shouldRemindToUpload) {
        documentWithAttachmentMenuOptions.push({
          icon: /* @__PURE__ */ jsx(Mail, __spreadValues({}, iconSize)),
          handler: () => {
            remindUserToUploadDocument(row);
          },
          label: polyglot.t("DocumentTable.remindToUpload"),
          disabled: false
        });
      }
      if (contractId && (hasSignatories && !allPartiesHaveSigned || unfinalisedContractIsMissingInfo) && isContractAdmin && isEditableOrRemovable) {
        documentWithAttachmentMenuOptions.push({
          icon: /* @__PURE__ */ jsx(Cancel, __spreadValues({}, iconSize)),
          handler: () => {
            deleteContractDocument(contractId);
          },
          label: polyglot.t("General.cancel"),
          disabled: false
        });
      }
      if ((contractId && hasSignatories && allPartiesHaveSigned || contractId && !hasSignatories) && (attachmentExists || row.fileUuid)) {
        documentWithAttachmentMenuOptions.push({
          icon: /* @__PURE__ */ jsx(Download, __spreadValues({}, iconSize)),
          handler: () => __async(void 0, null, function* () {
            var _a3, _b3, _c3, _d2, _e2;
            if ((row == null ? void 0 : row.fileUuid) && typeof row.fileUuid === "string" || ((_a3 = row == null ? void 0 : row.attachments) == null ? void 0 : _a3.length) && ((_b3 = row == null ? void 0 : row.attachments[0]) == null ? void 0 : _b3.fileUuid)) {
              const fileUuidForRow = (_e2 = row == null ? void 0 : row.fileUuid) != null ? _e2 : ((_c3 = row == null ? void 0 : row.attachments) == null ? void 0 : _c3.length) && ((_d2 = row == null ? void 0 : row.attachments[0]) == null ? void 0 : _d2.fileUuid);
              const contractNameForDownload = row.contractId ? row.name : void 0;
              if (fileUuidForRow)
                yield DocumentAPI.downloadViaUuid(fileUuidForRow, contractNameForDownload).then((url) => {
                  downloadFileByUrl(url, row.fileName);
                });
              else {
                showMessage(polyglot.t("DocumentTable.WarningMessages.available"), "warning");
              }
            }
          }),
          label: polyglot.t("DocumentTable.download"),
          disabled: false
        });
      } else if (row.fileUuid && !contractId) {
        documentWithAttachmentMenuOptions.push({
          icon: /* @__PURE__ */ jsx(Download, __spreadValues({}, iconSize)),
          handler: () => __async(void 0, null, function* () {
            if ((row == null ? void 0 : row.fileUuid) && typeof row.fileUuid === "string") {
              yield DocumentAPI.downloadViaUuid(row == null ? void 0 : row.fileUuid).then((url) => {
                downloadFileByUrl(url, row.fileName);
              });
            }
          }),
          label: polyglot.t("General.download"),
          disabled: false
        });
      } else if (contractId && (((_g = row.contract) == null ? void 0 : _g.templateId) || ((_i = (_h = row == null ? void 0 : row.attachments) == null ? void 0 : _h[0]) == null ? void 0 : _i.url) && attachmentExists)) {
        documentWithAttachmentMenuOptions.push({
          icon: /* @__PURE__ */ jsx(Download, __spreadValues({}, iconSize)),
          handler: () => {
            var _a3, _b3, _c3, _d2, _e2, _f2, _g2, _h2, _i2, _j, _k, _l;
            if ((_b3 = (_a3 = row == null ? void 0 : row.attachments) == null ? void 0 : _a3[0]) == null ? void 0 : _b3.url) {
              const documentUrl = (_d2 = (_c3 = row == null ? void 0 : row.attachments) == null ? void 0 : _c3[0]) == null ? void 0 : _d2.url;
              downloadFileByUrl(documentUrl, documentUrl);
            } else if ((_e2 = row.contract) == null ? void 0 : _e2.id) {
              downloadIncompleteContractsByID(row == null ? void 0 : row.name, void 0, {
                contractId: (_f2 = row.contract) == null ? void 0 : _f2.id,
                contractRecipientUserId: (_g2 = row.contract) == null ? void 0 : _g2.recipient,
                companySignatoryUserId: (_h2 = row.contract) == null ? void 0 : _h2.companySignatory
              });
            } else if ((_i2 = row.contract) == null ? void 0 : _i2.templateId) {
              downloadTemplatesByID(row == null ? void 0 : row.name, void 0, {
                templateId: (_j = row.contract) == null ? void 0 : _j.templateId,
                contractRecipientUserId: (_k = row.contract) == null ? void 0 : _k.recipient,
                companySignatoryUserId: (_l = row.contract) == null ? void 0 : _l.companySignatory
              });
            }
          },
          label: polyglot.t("General.download"),
          disabled: false
        });
      }
      if (fileExistsForNonContractAndHasDocAllScope || nonContractAndStdUserCanOrDocManagerScopes || contractSignedByAllAndStdUserCanOrDocManagerScope) {
        documentWithAttachmentMenuOptions.push({
          icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
          handler: () => {
            onEditClick(row);
          },
          label: polyglot.t("General.edit"),
          disabled: false
        });
      }
      if ((attachmentExists || hasFileUuid) && allPartiesHaveSigned && contractId && hasScopes(["templates:all"], scopesContext)) {
        documentWithAttachmentMenuOptions.push({
          icon: /* @__PURE__ */ jsx(Download, __spreadValues({}, iconSize)),
          handler: () => {
            handleDownloadClick(row);
          },
          label: polyglot.t("DocumentTable.auditTrail"),
          disabled: false
        });
      }
      if ((attachmentExists || hasFileUuid) && (contractId && hasSignatories && allPartiesHaveSigned && isPreviewableFile(row) || !contractId && isPreviewableFile(row) || contractId && !hasSignatories && isPreviewableFile(row))) {
        documentWithAttachmentMenuOptions.push({
          icon: /* @__PURE__ */ jsx(Eye, __spreadValues({}, iconSize)),
          handler: () => {
            handlePreviewClick(row);
          },
          label: polyglot.t("DocumentTable.preview"),
          disabled: false
        });
      }
      if ((attachmentExists || hasFileUuid) && !personalDocuments && !contractId) {
        documentWithAttachmentMenuOptions.push({
          icon: /* @__PURE__ */ jsx(Mail, __spreadValues({}, iconSize)),
          handler: () => {
            handleShareViaEmailClick(row);
          },
          label: polyglot.t("DocumentTable.shareViaEmail"),
          disabled: false
        });
      }
      if (contractId && (hasSignatories && !allPartiesHaveSigned || unfinalisedContractIsMissingInfo)) {
        documentWithAttachmentMenuOptions.push({
          icon: /* @__PURE__ */ jsx(Mail, __spreadValues({}, iconSize)),
          handler: () => {
            remindPendingContractParticipants(contractId);
          },
          label: polyglot.t("DocumentTable.remind"),
          disabled: false
        });
      }
      if (contractId && missingPersonalInfoAndCanFill) {
        documentWithAttachmentMenuOptions.push({
          handler: () => __async(void 0, null, function* () {
            contractSignAction(row);
          }),
          label: polyglot.t("DocumentTable.fillMissingFields"),
          disabled: false
        });
      }
      return documentWithAttachmentMenuOptions;
    },
    [
      state.user.userId,
      hasScopes,
      scopesContext,
      isContractAdmin,
      personalDocuments,
      polyglot,
      remindUserToUploadDocument,
      deleteContractDocument,
      showMessage,
      onEditClick,
      handleDownloadClick,
      handlePreviewClick,
      handleShareViaEmailClick,
      remindPendingContractParticipants
    ]
  );
  const columnData = useMemo(() => {
    const handleContractSignAction = (row) => __async(void 0, null, function* () {
      var _a2, _b2, _c2, _d, _e, _f, _g, _h, _i, _j, _k, _l;
      if ((row == null ? void 0 : row.contract) && row.contract.id && recipientSignatureRequired(row) && !row.contract.recipientSignatureTimestamp && ((_a2 = row.contract) == null ? void 0 : _a2.recipient) === state.user.userId || ((_b2 = row.contract) == null ? void 0 : _b2.hasMissingPersonalFields) && ((_c2 = row.contract) == null ? void 0 : _c2.recipient) === state.user.userId) {
        const missingFields = yield ContractAPI.getEmployeeFieldsForContractById(row == null ? void 0 : row.contract.id);
        if ((_d = Object.values(missingFields)) == null ? void 0 : _d.some((v) => v === true)) {
          onMissingContractFields == null ? void 0 : onMissingContractFields(missingFields, row.contract);
        } else {
          routerHistory.push(
            generatePath(TEMPLATE_CONTRACT_SIGN_ROUTE, {
              templateId: (_e = row.contract) == null ? void 0 : _e.templateId,
              contractId: (_f = row.contract) == null ? void 0 : _f.id,
              userId: (_g = row.contract) == null ? void 0 : _g.recipient
            })
          );
        }
      } else if ((row == null ? void 0 : row.contract) && row.contract.id && companySignatureRequired(row) && !row.contract.companySignatureTimestamp && ((_h = row.contract) == null ? void 0 : _h.companySignatory) === state.user.userId) {
        const stateToPassThrough = {
          companySignatoryUserId: (_i = row.contract) == null ? void 0 : _i.companySignatory
        };
        routerHistory.push(
          generatePath(TEMPLATE_CONTRACT_COMPANY_SIGN_ROUTE, {
            templateId: (_j = row.contract) == null ? void 0 : _j.templateId,
            contractId: (_k = row.contract) == null ? void 0 : _k.id,
            userId: (_l = row.contract) == null ? void 0 : _l.companySignatory
          }),
          stateToPassThrough
        );
      }
    });
    const actionsColumnLogic = (row, isEditableOrRemovable) => {
      var _a2, _b2, _c2, _d, _e, _f, _g, _h, _i, _j;
      const { contract } = row;
      const menuOptionsForRow = getDocumentsWithAttachmentsMenuOptions(
        row,
        handleContractSignAction,
        isEditableOrRemovable
      );
      const hasFileUuid = !!row.fileUuid;
      const hasContract = row.contractId && contract;
      const hasAttachments = hasFileUuid || hasContract || row.attachments && row.attachments.length && ((_a2 = row.attachments[0]) == null ? void 0 : _a2.fileUuid);
      const hasNoContract = !hasContract;
      const isSignable = hasContract && (recipientSignatureRequired(row) && !((_b2 = row.contract) == null ? void 0 : _b2.recipientSignatureTimestamp) && ((_c2 = row.contract) == null ? void 0 : _c2.recipient) === ((_d = state == null ? void 0 : state.user) == null ? void 0 : _d.userId) || companySignatureRequired(row) && !((_e = row.contract) == null ? void 0 : _e.companySignatureTimestamp) && ((_f = row.contract) == null ? void 0 : _f.companySignatory) === ((_g = state == null ? void 0 : state.user) == null ? void 0 : _g.userId));
      const hasSignatories = (contract == null ? void 0 : contract.signatoriesRequired) && ((_h = contract == null ? void 0 : contract.signatoriesRequired) == null ? void 0 : _h.length) > 0;
      const allPartiesHaveSigned = allPartiesHaveSignedContract(row);
      const isEditable = hasNoContract && isEditableOrRemovable;
      const fileUuidFromAttachment = row.attachments && row.attachments[0] && ((_i = row.attachments[0]) == null ? void 0 : _i.fileUuid);
      const finalUuid = (_j = row.fileUuid) != null ? _j : fileUuidFromAttachment;
      const contractFinalised = !!((contract == null ? void 0 : contract.id) && finalUuid);
      const currentUserIsRecipient = hasContract && state.user.userId === contract.recipient;
      const contractIsMissingInformation = hasContract && ((contract == null ? void 0 : contract.hasMissingPersonalFields) || (contract == null ? void 0 : contract.hasMissingWorkFields));
      if (hasAttachments) {
        return /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              alignItems: "center",
              gap: spacing.g10
            },
            children: [
              hasSignatories && isSignable && !allPartiesHaveSigned && /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  sizeVariant: "small",
                  colorVariant: "primary",
                  onClick: () => handleContractSignAction(row),
                  title: "Sign Contract",
                  children: polyglot.t("DocumentTable.signContract")
                }
              ),
              contractIsMissingInformation && !contractFinalised && currentUserIsRecipient && !!!recipientSignatureRequired(row) && /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  sizeVariant: "small",
                  colorVariant: "primary",
                  onClick: () => handleContractSignAction(row),
                  title: "Fill missing",
                  children: polyglot.t("DocumentTable.fillMissingFields")
                }
              ),
              /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  options: menuOptionsForRow,
                  actionButtonDetails: {
                    type: "iconButton",
                    colorVariant: "secondary",
                    sizeVariant: "small",
                    title: "actions",
                    icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                  }
                }
              )
            ]
          }
        );
      } else if (isEditable && !hasFileUuid) {
        return /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              alignItems: "center",
              gap: spacing.g10
            },
            children: [
              /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  sizeVariant: "small",
                  colorVariant: "primary",
                  onClick: () => onEditClick(row),
                  title: polyglot.t("DocumentTable.upload"),
                  children: polyglot.t("DocumentTable.upload")
                }
              ),
              /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  options: menuOptionsForRow,
                  actionButtonDetails: {
                    type: "iconButton",
                    colorVariant: "secondary",
                    sizeVariant: "small",
                    title: "actions",
                    icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                  }
                }
              )
            ]
          }
        );
      } else {
        return null;
      }
    };
    const belongsToColumnLogic = (row, hasScopesAll) => {
      var _a2;
      return pipe(
        (_a2 = row.belongsTo) != null ? _a2 : [],
        RNEA.fromReadonlyArray,
        O.fold(
          () => row.canAllEmployeesSee ? /* @__PURE__ */ jsxs("span", { children: [
            " ",
            polyglot.t("DocumentTable.everyone")
          ] }) : /* @__PURE__ */ jsx("span", { children: polyglot.t("DocumentTable.unassigned") }),
          (users) => users.length === 1 ? /* @__PURE__ */ jsx(
            "div",
            {
              onClick: () => {
                var _a3;
                if (hasScopesAll)
                  routerHistory.push(
                    generatePath(ADMIN_USER_DOCUMENTS_ROUTE, { userId: (_a3 = users[0].User.userId) != null ? _a3 : "" })
                  );
              },
              children: /* @__PURE__ */ jsx(UserCell, { userId: users[0].User.userId })
            }
          ) : /* @__PURE__ */ jsx(MultiUserAvatars, { currentUser: state.user, users, size: "xxsmall" })
        )
      );
    };
    const selectableDocumentIds = new Set(documents.map(({ id }) => id));
    return [
      ...setSelectionModel && selectionModel && selectableDocumentIds.size > 0 ? [
        {
          id: "select",
          enableSorting: false,
          minSize: selectColumnSize,
          size: selectColumnSize,
          header: () => {
            const allSelected = selectionModel.length > 0 && selectionModel.length === selectableDocumentIds.size && selectionModel.every((id) => selectableDocumentIds.has(id));
            return /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: "allSelected",
                checked: allSelected,
                value: "allSelected",
                onChange: (_, checked) => {
                  setSelectionModel(checked ? [...selectableDocumentIds] : []);
                }
              }
            );
          },
          cell: ({ row: { original } }) => {
            var _a2, _b2, _c2, _d;
            return selectableDocumentIds.has(original.id) ? /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: (_b2 = (_a2 = original.id) == null ? void 0 : _a2.toString()) != null ? _b2 : "",
                checked: selectionModel.includes(original.id),
                value: (_d = (_c2 = original.id) == null ? void 0 : _c2.toString()) != null ? _d : "",
                onChange: () => {
                  let finalArray;
                  if (selectionModel == null ? void 0 : selectionModel.includes(original.id)) {
                    finalArray = selectionModel.filter((sm) => sm !== original.id);
                  } else finalArray = [...selectionModel, original.id];
                  setSelectionModel(finalArray);
                }
              }
            ) : null;
          }
        }
      ] : [],
      {
        header: () => polyglot.t("DocumentTable.name"),
        accessorFn: (row) => row,
        id: "name",
        enableSorting: false,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        maxSize: 250,
        cell: (info) => {
          var _a2, _b2;
          const rowValue = info.getValue();
          const isAlert = !!((_b2 = (_a2 = state == null ? void 0 : state.alerts) == null ? void 0 : _a2.documents) == null ? void 0 : _b2.entries.find((doc) => (doc == null ? void 0 : doc.id) === rowValue.id));
          return /* @__PURE__ */ jsx(
            IconTextCell,
            {
              icon: isAlert ? /* @__PURE__ */ jsx(MissingDoc, { width: 24, height: 30 }) : /* @__PURE__ */ jsx(UploadDoc, { width: 24, height: 30 }),
              text: rowValue.name,
              textSx: themeFonts.caption
            }
          );
        }
      },
      {
        header: () => polyglot.t("DocumentTable.type"),
        accessorFn: (row) => row,
        id: "type",
        enableSorting: false,
        cell: (info) => {
          const rowValue = info.getValue();
          return /* @__PURE__ */ jsx("div", { children: rowValue.type });
        }
      },
      {
        header: () => polyglot.t("DocumentTable.createdOn"),
        accessorFn: (row) => row,
        id: "createdAt",
        enableSorting: true,
        cell: (info) => {
          const rowValue = info.getValue();
          return /* @__PURE__ */ jsx("div", { children: getDateString(rowValue.createdAt) });
        }
      },
      {
        header: () => polyglot.t("DocumentTable.belongsTo"),
        accessorFn: (row) => row,
        id: "avatar",
        enableSorting: false,
        cell: (info) => {
          const row = info.getValue();
          const hasScopesAll = hasScopes(["documents:all"], scopesContext);
          return /* @__PURE__ */ jsx("div", { children: belongsToColumnLogic(row, hasScopesAll) });
        }
      },
      {
        header: () => polyglot.t("DocumentTable.documentStatus"),
        accessorFn: (row) => row,
        id: "documentStatus",
        enableSorting: false,
        cell: (info) => {
          const row = info.getValue();
          return /* @__PURE__ */ jsx(DisplayDocumentStatus, { document: row });
        }
      },
      {
        header: () => polyglot.t("DocumentTable.expiryDate"),
        accessorFn: (row) => row,
        id: "expiryDate",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(
          a,
          b,
          (item) => {
            var _a2, _b2;
            return ((_a2 = item.expirySettings) == null ? void 0 : _a2.expiryDate) ? new Date((_b2 = item.expirySettings) == null ? void 0 : _b2.expiryDate).getTime() : 0;
          }
        ),
        cell: (info) => {
          var _a2, _b2, _c2;
          const row = info.getValue();
          return ((_a2 = row == null ? void 0 : row.expirySettings) == null ? void 0 : _a2.documentExpires) && ((_b2 = row == null ? void 0 : row.expirySettings) == null ? void 0 : _b2.expiryDate) ? /* @__PURE__ */ jsx("div", { children: dateAPItoDisplay((_c2 = row == null ? void 0 : row.expirySettings) == null ? void 0 : _c2.expiryDate) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      //will be added later when designs come
      // {
      //   header: () => 'Verification',
      //   accessorFn: (row) => row,
      //   id: 'verification',
      //   enableSorting: false,
      //   cell: (info) => {
      //     const row: ZeltDocument = info.getValue();
      //     const hasScopesAll = hasScopes(['documents:all'], scopesContext);
      //     const belongsToNone = (row.belongsTo ?? []).length === 0;
      //     const isEditableOrRemovable = hasScopesAll || (row.type !== 'payslip' && !belongsToNone);
      //     const documentType = documentTypes.find((t) => t.value === row.type);
      //     return (
      //       <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', alignItems: 'center', gap: 1 }}>
      //         {documentType?.needsVerification ? (
      //           row.documentVerification ? (
      //             <Tooltip
      //               title={`Verified by ${
      //                 row.documentVerification.User.displayName ??
      //                 `${row.documentVerification.User.firstName} ${row.documentVerification.User.lastName}`
      //               }`}
      //             >
      //               <Box sx={primarySx}>
      //                 <Chose {...iconSize} />
      //               </Box>
      //             </Tooltip>
      //           ) : isEditableOrRemovable && hasScopes(['documents:all']) && (row.attachments ?? []).length !== 0 ? (
      //             <IconButton sx={tableIconButtonSx} onClick={() => verify$.next(row)} title="Verify">
      //               <Chose {...iconSize} />
      //             </IconButton>
      //           ) : (
      //             <></>
      //           )
      //         ) : (
      //           <></>
      //         )}
      //       </Box>
      //     );
      //   },
      // },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const row = info.getValue();
          const hasScopesAll = hasScopes(["documents:all"], scopesContext);
          const hasScopesManager = checkScopes(state.user, ["documents"], scopesContext);
          const categoriesNotEditableByManager = ["Company Documents"];
          const belongsToNone = ((_a2 = row.belongsTo) != null ? _a2 : []).length === 0;
          const documentCategoriesEditableByManager = documentTypes.filter((d) => !categoriesNotEditableByManager.includes(d.category)).map((d) => d.value);
          const documentCategoriesEditableByStandardUser = documentTypes.filter((eachDocumentType) => eachDocumentType.canUserAdd).map((d) => d.value);
          const belongsToOnlyYou = row.belongsTo && row.belongsTo.length === 1 && row.belongsTo[0].User.userId === state.user.userId;
          const isEditableOrRemovable = hasScopesAll || !personalDocuments && hasScopesManager && [...documentCategoriesEditableByManager].includes(row.type) && !belongsToNone || documentCategoriesEditableByStandardUser.includes(row.type) && belongsToOnlyYou;
          return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end", width: "100%", alignItems: "center" }, children: actionsColumnLogic(row, isEditableOrRemovable) });
        }
      }
    ];
  }, [
    documents,
    setSelectionModel,
    selectionModel,
    state.user,
    (_b = (_a = state == null ? void 0 : state.alerts) == null ? void 0 : _a.documents) == null ? void 0 : _b.entries,
    onMissingContractFields,
    routerHistory,
    getDocumentsWithAttachmentsMenuOptions,
    polyglot,
    onEditClick,
    documentTypes,
    hasScopes,
    scopesContext,
    personalDocuments
  ]);
  const customRowStyle = (row) => {
    var _a2, _b2;
    const isAlert = !!((_b2 = (_a2 = state == null ? void 0 : state.alerts) == null ? void 0 : _a2.documents) == null ? void 0 : _b2.entries.find((doc) => (doc == null ? void 0 : doc.id) === row.original.id));
    if (isAlert) return { color: themeColors.Grey };
    return {};
  };
  const tableDocuments = sorting && sorting.length > 0 && ((_c = sorting[0]) == null ? void 0 : _c.desc) === false && documents ? [...documents].reverse() : [...documents];
  const getTableBasedOnProps = () => {
    return pagination && setPagination && sorting && setSorting ? /* @__PURE__ */ jsx(
      BasicServerTable,
      {
        rowData: tableDocuments,
        columnData,
        sorting,
        setSorting,
        pagination,
        setPagination,
        totalPages,
        totalItems,
        loading,
        customRowStyle,
        stickyHeader,
        fixedSecondColumn: !!columnData.find((c) => c.id === "select")
      }
    ) : /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: tableDocuments,
        columnData,
        loading,
        customRowStyle,
        paginationReset,
        stickyHeader,
        fixedSecondColumn: !!columnData.find((c) => c.id === "select")
      }
    );
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    getTableBasedOnProps(),
    openShareViaEmailModal && /* @__PURE__ */ jsx(
      ShareViaEmailDrawer,
      {
        selectedDocument,
        isOpen: openShareViaEmailModal,
        setSelectedDocument,
        setIsOpen: setOpenShareViaEmailModal
      }
    )
  ] });
};
