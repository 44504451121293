"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { TabFilterButtons } from "@v2/components/tab-filter-buttons.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceOwnership } from "@v2/feature/device/device.interface";
import { getModelImage } from "@v2/feature/device/device.util";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { RefinancePlanCreateAccountDrawer } from "@v2/feature/super-admin/features/super-admin-refinancing/components/refinance-plan-create-account-drawer.component";
import { RefinancePlanDrawer } from "@v2/feature/super-admin/features/super-admin-refinancing/components/refinance-plan-drawer.component";
import { SearchCompanyNationalIdDrawer } from "@v2/feature/super-admin/features/super-admin-refinancing/components/search-company-national-id-drawer.component";
import { RefinancingAPI } from "@v2/feature/super-admin/features/super-admin-refinancing/refinancing.api";
import {
  RefinancingCompanyStatus
} from "@v2/feature/super-admin/features/super-admin-refinancing/refinancing.interface";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { BackofficeRootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize } from "@v2/styles/table.styles";
import { isBefore } from "date-fns";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Task } from "@/images/dashboard-icons/Task.svg";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Eye } from "@/images/side-bar-icons/Eye.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
const ZELT_ID = 320;
const TabFilter = [
  { name: "All", value: "all" },
  { name: "Invoiced by Zelt", value: "InvoicedByZelt" },
  { name: "Invoiced by Two", value: "InvoicedByTwo" }
];
export const SuperAdminRefinancingDevicesPage = ({ companies }) => {
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState(false);
  const [isPlanDrawerOpen, setIsPlanDrawerOpen] = useState(false);
  const [isCreateRepaymentAccountDrawerOpen, setIsCreateRepaymentAccountDrawerOpen] = useState(false);
  const [idempotencyIdForRefinancingPlan, setIdempotencyIdForRefinancingPlan] = useState(0);
  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [deviceOrders, setDeviceOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("all");
  const [searchInput, setSearchInput] = useState("");
  const today = useMemo(() => {
    const now = /* @__PURE__ */ new Date();
    now.setHours(23);
    now.setMinutes(59);
    return now;
  }, []);
  const [showMessage] = useMessage();
  const refreshOrders = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const deviceOrders2 = (yield DeviceAPI.getDeviceOrdersAsSuperadmin(true)).filter(
        (order) => order.companyId !== ZELT_ID
      );
      const companyIds = [...new Set(deviceOrders2.map((order) => order.companyId))];
      const externallyInvoicedOrderIds = deviceOrders2.filter((order) => order.externallyInvoiced).map((order) => order.id);
      const companyRefinancingArray = yield RefinancingAPI.getCompaniesRefinancingData(companyIds);
      const companyRefinancingPlansArray = yield RefinancingAPI.getCompaniesRepaymentPlansForOrders(
        companyIds,
        externallyInvoicedOrderIds
      );
      const ordersWithCompanyDetails = deviceOrders2.filter((order) => {
        var _a;
        return ((_a = order.device) == null ? void 0 : _a.ownership) === DeviceOwnership.Rental;
      }).map((order) => __spreadProps(__spreadValues({}, order), {
        company: companies.find((c) => c.companyId === order.companyId),
        refinancing: companyRefinancingArray.find((c) => c.companyId === order.companyId),
        refinancingPlan: companyRefinancingPlansArray.find((plan) => plan.orderId === order.id)
      }));
      setDeviceOrders(ordersWithCompanyDetails);
      setSelectedCompanyData(null);
      setIsSearchDrawerOpen(false);
      setIsPlanDrawerOpen(false);
      setIsCreateRepaymentAccountDrawerOpen(false);
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    } finally {
      setLoading(false);
    }
    setIdempotencyIdForRefinancingPlan(Math.floor(Math.random() * 1e3) + 1e3);
  }), [companies, showMessage]);
  useEffect(() => {
    refreshOrders();
  }, [refreshOrders]);
  const columnRows = useMemo(() => {
    let orders = [...deviceOrders];
    if (filterValue.includes("InvoicedByZelt")) {
      orders = orders.filter(
        (order) => {
          var _a;
          return ((_a = order.refinancingPlan) == null ? void 0 : _a.fulfilmentStatus) !== "SUCCEEDED" || !order.externallyInvoiced || isBefore(today, new Date(order.externallyInvoiced));
        }
      );
    } else if (filterValue.includes("InvoicedByTwo")) {
      orders = orders.filter(
        (order) => {
          var _a;
          return ((_a = order.refinancingPlan) == null ? void 0 : _a.fulfilmentStatus) === "SUCCEEDED" && order.externallyInvoiced && !isBefore(today, new Date(order.externallyInvoiced));
        }
        // TODO: @two - handle the case when the invoice by two period is finished, so the device comes back to Zelt invoicing
      );
    }
    if (searchInput) {
      orders = orders.filter((order) => {
        var _a, _b, _c, _d, _e, _f;
        const defaultEntity = (_b = (_a = order.company) == null ? void 0 : _a.entities) == null ? void 0 : _b.find((e) => e.isDefaultBillingEntity);
        return ((_c = order.company) == null ? void 0 : _c.name.toLowerCase().includes(searchInput.toLowerCase())) || ((_d = defaultEntity == null ? void 0 : defaultEntity.nationalId) == null ? void 0 : _d.includes(searchInput)) || ((_e = defaultEntity == null ? void 0 : defaultEntity.legalName) == null ? void 0 : _e.includes(searchInput)) || ((_f = order.deviceModel) == null ? void 0 : _f.modelName.toLowerCase().includes(searchInput.toLowerCase()));
      });
    }
    return orders;
  }, [today, filterValue, searchInput, deviceOrders]);
  const columnData = useMemo(() => {
    return [
      {
        header: () => "Device",
        accessorFn: (row) => row,
        id: "modelName",
        cell: (info) => {
          var _a, _b, _c;
          const deviceOrder = info.getValue();
          return ((_a = deviceOrder.deviceModel) == null ? void 0 : _a.modelName) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Box, { children: getModelImage((_b = deviceOrder.deviceModel) == null ? void 0 : _b.type, (_c = deviceOrder.deviceModel) == null ? void 0 : _c.modelName, {
              width: "30px",
              height: "auto"
            }) }),
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: deviceOrder.deviceModel.modelName })
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Status",
        accessorFn: (row) => row,
        id: "status",
        cell: (info) => {
          const deviceOrder = info.getValue();
          return deviceOrder.status ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: deviceOrder.status }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Company name",
        accessorFn: (row) => row,
        id: "companyName",
        cell: (info) => {
          var _a;
          const deviceOrder = info.getValue();
          return ((_a = deviceOrder.company) == null ? void 0 : _a.name) ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: deviceOrder.company.name }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Legal name",
        accessorFn: (row) => row,
        id: "legalName",
        cell: (info) => {
          var _a, _b;
          const deviceOrder = info.getValue();
          if (!((_a = deviceOrder.company) == null ? void 0 : _a.entities)) return /* @__PURE__ */ jsx(EmptyCell, {});
          const defaultEntity = (_b = deviceOrder.company) == null ? void 0 : _b.entities.find((e) => e.isDefaultBillingEntity);
          return (defaultEntity == null ? void 0 : defaultEntity.legalName) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: defaultEntity.legalName }),
            deviceOrder.company.entities[0].nationalId && /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.caption, children: [
              "#",
              defaultEntity.nationalId
            ] })
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Company status",
        accessorFn: (row) => row,
        id: "companyStatus",
        cell: (info) => {
          var _a;
          const deviceOrder = info.getValue();
          return ((_a = deviceOrder.refinancing) == null ? void 0 : _a.creditCheckStatus) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
            deviceOrder.refinancing.creditCheckStatus === RefinancingCompanyStatus.Approved ? /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })) : /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Red } })),
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: deviceOrder.refinancing.creditCheckStatus })
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "Repayment account",
        accessorFn: (row) => row,
        id: "repaymentAccount",
        cell: (info) => {
          var _a, _b;
          const deviceOrder = info.getValue();
          return ((_a = deviceOrder.refinancing) == null ? void 0 : _a.accountId) ? /* @__PURE__ */ jsx(Tooltip, { title: (_b = deviceOrder.refinancing) == null ? void 0 : _b.accountId, placement: "top", children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "CREATED" })
          ] }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "Funding status",
        accessorFn: (row) => row,
        id: "fundingStatus",
        cell: (info) => {
          var _a;
          const deviceOrder = info.getValue();
          return deviceOrder.refinancingPlan ? /* @__PURE__ */ jsx(Tooltip, { title: deviceOrder.refinancingPlan.externalId, placement: "top", children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
            deviceOrder.refinancingPlan.fundingStatus === "ACCEPTED" ? /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })) : deviceOrder.refinancingPlan.fundingStatus === "REJECTED" ? /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Red } })) : /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.GreyMiddle } })),
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: (_a = deviceOrder.refinancingPlan.fundingStatus) != null ? _a : "CREATED" })
          ] }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "Fulfilment status",
        accessorFn: (row) => row,
        id: "fulfilmentStatus",
        cell: (info) => {
          var _a;
          const deviceOrder = info.getValue();
          return deviceOrder.refinancingPlan ? /* @__PURE__ */ jsx(Tooltip, { title: deviceOrder.refinancingPlan.externalId, placement: "top", children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
            deviceOrder.refinancingPlan.fulfilmentStatus === "SUCCEEDED" ? /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })) : deviceOrder.refinancingPlan.fulfilmentStatus === "FAILED" ? /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Red } })) : /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.GreyMiddle } })),
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: (_a = deviceOrder.refinancingPlan.fulfilmentStatus) != null ? _a : "CREATED" })
          ] }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Device",
        accessorFn: (row) => row,
        id: "device.serialNumber",
        cell: (info) => {
          var _a;
          const deviceOrder = info.getValue();
          return ((_a = deviceOrder.device) == null ? void 0 : _a.serialNumber) ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: deviceOrder.device.serialNumber }) : /* @__PURE__ */ jsx(Tooltip, { title: "Missing serial number", placement: "top", children: /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Red } })) });
        }
      },
      {
        header: () => "Order details",
        accessorFn: (row) => row,
        id: "contractLength",
        cell: (info) => {
          const deviceOrder = info.getValue();
          return deviceOrder.contractLength ? /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.caption, children: [
            deviceOrder.contractLength,
            " months"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Delivery date",
        accessorFn: (row) => row,
        id: "deliveryDate",
        cell: (info) => {
          const deviceOrder = info.getValue();
          return deviceOrder.deliveryDate ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: new Date(deviceOrder.deliveryDate).toLocaleDateString() }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Price",
        accessorFn: (row) => row,
        id: "price",
        cell: (info) => {
          const deviceOrder = info.getValue();
          return deviceOrder.price ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: formatMoney({ amount: deviceOrder.price }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Invoiced by",
        accessorFn: (row) => row,
        id: "externallyInvoiced",
        cell: (info) => {
          const deviceOrder = info.getValue();
          return deviceOrder.externallyInvoiced && isBefore(new Date(deviceOrder.externallyInvoiced), today) ? /* @__PURE__ */ jsxs(Box, { children: [
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Invoiced by Two" }),
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: new Date(deviceOrder.externallyInvoiced).toLocaleDateString() })
          ] }) : (
            //   TODO: @two - handle the case when the invoice by two period is finished, so the device comes back to Zelt invoicing
            deviceOrder.externallyInvoiced ? /* @__PURE__ */ jsx(
              Tooltip,
              {
                title: `Scheduled to be invoiced by Two starting with ${new Date(
                  deviceOrder.externallyInvoiced
                ).toLocaleDateString()}`,
                placement: "top",
                children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Invoiced by Zelt" })
              }
            ) : /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: "Invoiced by Zelt" })
          );
        }
      },
      {
        id: "action",
        header: () => "",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: (info) => {
          var _a, _b, _c, _d;
          const deviceOrder = info.getValue();
          return /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
                gap: spacing.gap5
              },
              children: /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  options: [
                    {
                      handler: () => {
                        setSelectedCompanyData(deviceOrder);
                        setIsSearchDrawerOpen(true);
                      },
                      label: "Credit check",
                      icon: /* @__PURE__ */ jsx(Eye, __spreadValues({}, iconSize))
                    },
                    {
                      handler: () => __async(void 0, null, function* () {
                        var _a2;
                        if (!!((_a2 = deviceOrder.refinancing) == null ? void 0 : _a2.accountId)) {
                          showMessage("An account for this company has been already created.", "info");
                          return;
                        }
                        setIsCreateRepaymentAccountDrawerOpen(true);
                        setSelectedCompanyData(deviceOrder);
                      }),
                      label: "Create repayment account",
                      icon: /* @__PURE__ */ jsx(Task, __spreadValues({}, iconSize)),
                      hidden: !!((_a = deviceOrder.refinancing) == null ? void 0 : _a.accountId) || ((_b = deviceOrder.refinancing) == null ? void 0 : _b.creditCheckStatus) !== RefinancingCompanyStatus.Approved
                    },
                    {
                      handler: () => {
                        setSelectedCompanyData(deviceOrder);
                        setIsPlanDrawerOpen(true);
                      },
                      label: "Refinance",
                      icon: /* @__PURE__ */ jsx(Task, __spreadValues({}, iconSize)),
                      hidden: !((_c = deviceOrder.refinancing) == null ? void 0 : _c.accountId),
                      disabled: ((_d = deviceOrder.refinancing) == null ? void 0 : _d.creditCheckStatus) !== RefinancingCompanyStatus.Approved || !deviceOrder.deviceId
                    }
                  ],
                  actionButtonDetails: {
                    type: "iconButton",
                    colorVariant: "secondary",
                    sizeVariant: "small",
                    title: "actions",
                    icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                  }
                }
              )
            }
          );
        }
      }
    ];
  }, [today, showMessage]);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Refinancing" })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(
          TabFilterButtons,
          {
            filters: TabFilter,
            setFilterValue,
            filterValue,
            hasSearch: true,
            onFilterChange: ({ filterValue: filterValue2, searchInput: searchInput2 }) => {
              setFilterValue(filterValue2);
              setSearchInput(searchInput2);
            }
          }
        ),
        /* @__PURE__ */ jsx(ButtonComponent, { onClick: refreshOrders, type: "button", sizeVariant: "small", colorVariant: "secondary", children: "Refresh" })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(BasicTable, { rowData: [...columnRows], columnData }) }),
      isSearchDrawerOpen && selectedCompanyData && /* @__PURE__ */ jsx(
        SearchCompanyNationalIdDrawer,
        {
          isOpen: isSearchDrawerOpen,
          setIsOpen: setIsSearchDrawerOpen,
          order: selectedCompanyData,
          refresh: refreshOrders
        }
      ),
      isPlanDrawerOpen && selectedCompanyData && /* @__PURE__ */ jsx(
        RefinancePlanDrawer,
        {
          isOpen: isPlanDrawerOpen,
          setIsOpen: setIsPlanDrawerOpen,
          order: selectedCompanyData,
          refresh: refreshOrders,
          idempotencyId: idempotencyIdForRefinancingPlan
        }
      ),
      isCreateRepaymentAccountDrawerOpen && selectedCompanyData && /* @__PURE__ */ jsx(
        RefinancePlanCreateAccountDrawer,
        {
          isOpen: isCreateRepaymentAccountDrawerOpen,
          setIsOpen: setIsCreateRepaymentAccountDrawerOpen,
          order: selectedCompanyData,
          refresh: refreshOrders
        }
      )
    ] })
  ] });
};
