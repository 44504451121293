"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { IconEmptyCell } from "@v2/components/table/empty-cell.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { DevicePossessionType } from "@v2/feature/device/device.interface";
import { SuperAdminDeviceTransitDrawer } from "@v2/feature/super-admin/features/super-admin-devices/components/super-admin-device-transit-drawer.component";
import { spacing } from "@v2/styles/spacing.styles";
import {
  getDeviceOwnerAsSuperadminByDevicePossession,
  getModelImage,
  getTransitStatus
} from "@/v2/feature/device/device.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
export const DevicesTransitsTableSuperAdmin = ({
  userNames,
  companiesNames,
  deviceTransits,
  refresh,
  sites
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDeviceTransit, setSelectedDeviceTransit] = useState(void 0);
  const { getCachedUserById } = useCachedUsers();
  const [searchInput, setSearchInput] = useState("");
  const filteredDevices = useMemo(() => {
    return deviceTransits.filter((deviceTransit) => {
      var _a, _b, _c, _d, _e;
      const receiverName = ((_a = deviceTransit.receiver) == null ? void 0 : _a.possessionType) === DevicePossessionType.User ? (_b = getCachedUserById(deviceTransit.receiver.possessionId)) == null ? void 0 : _b.displayName : void 0;
      const senderName = ((_c = deviceTransit.sender) == null ? void 0 : _c.possessionType) === DevicePossessionType.User ? (_d = getCachedUserById(deviceTransit.sender.possessionId)) == null ? void 0 : _d.displayName : void 0;
      const modelName = (_e = deviceTransit.device) == null ? void 0 : _e.modelName;
      return (receiverName == null ? void 0 : receiverName.toLowerCase().includes(searchInput.toLowerCase())) || (senderName == null ? void 0 : senderName.toLowerCase().includes(searchInput.toLowerCase())) || (modelName == null ? void 0 : modelName.toLowerCase().includes(searchInput.toLowerCase()));
    });
  }, [deviceTransits, searchInput, getCachedUserById]);
  const columnData = useMemo(
    () => [
      {
        header: () => "Device",
        accessorFn: (row) => row,
        id: "modelName",
        enableSorting: false,
        cell: (info) => {
          var _a, _b, _c;
          const deviceTransit = info.getValue();
          return ((_a = deviceTransit.device) == null ? void 0 : _a.modelName) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
            deviceTransit && getModelImage((_b = deviceTransit == null ? void 0 : deviceTransit.device) == null ? void 0 : _b.type, (_c = deviceTransit == null ? void 0 : deviceTransit.device) == null ? void 0 : _c.modelName, {
              width: "30px",
              height: "auto"
            }),
            deviceTransit.device.modelName
          ] }) : /* @__PURE__ */ jsx(IconEmptyCell, {});
        }
      },
      {
        header: () => "Serial number",
        accessorFn: (row) => row,
        id: "serialNumber",
        enableSorting: false,
        cell: (info) => {
          var _a;
          const deviceTransit = info.getValue();
          return ((_a = deviceTransit == null ? void 0 : deviceTransit.device) == null ? void 0 : _a.serialNumber) ? /* @__PURE__ */ jsx(Box, { children: deviceTransit.device.serialNumber }) : /* @__PURE__ */ jsx(IconEmptyCell, {});
        }
      },
      {
        header: () => "Receiver",
        accessorFn: (row) => row,
        id: "receiverId",
        enableSorting: false,
        cell: (info) => {
          const deviceTransit = info.getValue();
          const ownedBy = getDeviceOwnerAsSuperadminByDevicePossession(deviceTransit.receiver, userNames, sites);
          return /* @__PURE__ */ jsx(Box, { children: ownedBy });
        }
      },
      {
        header: () => "Receiver address",
        accessorFn: (row) => row,
        id: "receiver",
        enableSorting: false,
        cell: (info) => {
          var _a;
          const deviceTransit = info.getValue();
          return ((_a = deviceTransit.receiver) == null ? void 0 : _a.deliveryAddress) ? /* @__PURE__ */ jsx(Box, { children: deviceTransit.receiver.deliveryAddress }) : /* @__PURE__ */ jsx(IconEmptyCell, {});
        }
      },
      {
        header: () => "Sender",
        accessorFn: (row) => row,
        id: "senderId",
        enableSorting: false,
        cell: (info) => {
          const deviceTransit = info.getValue();
          const ownedBy = getDeviceOwnerAsSuperadminByDevicePossession(deviceTransit.sender, userNames, sites);
          return /* @__PURE__ */ jsx(Box, { children: ownedBy });
        }
      },
      {
        header: () => "Sender address",
        accessorFn: (row) => row,
        id: "sender",
        enableSorting: false,
        cell: (info) => {
          const deviceTransit = info.getValue();
          return deviceTransit.senderAddress ? /* @__PURE__ */ jsx(Box, { children: deviceTransit.senderAddress }) : /* @__PURE__ */ jsx(IconEmptyCell, {});
        }
      },
      {
        header: () => "Status",
        accessorFn: (row) => row,
        id: "status",
        enableSorting: false,
        cell: (info) => {
          const deviceTransit = info.getValue();
          return /* @__PURE__ */ jsx(Fragment, { children: getTransitStatus(deviceTransit.status) });
        }
      },
      {
        header: () => "Notes",
        accessorFn: (row) => row,
        id: "notes",
        enableSorting: false,
        cell: (info) => {
          const deviceTransit = info.getValue();
          return deviceTransit.notes ? /* @__PURE__ */ jsx(Box, { children: deviceTransit.notes }) : /* @__PURE__ */ jsx(IconEmptyCell, {});
        }
      }
    ],
    [sites, userNames]
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    deviceTransits.length > 0 && /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ display: "flex", justifyContent: "flex-start", width: "100%" }, spacing.mb20), children: /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => {
      var _a, _b;
      return setSearchInput((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
    } }) }),
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: filteredDevices,
        columnData,
        rowClick: (row) => {
          setSelectedDeviceTransit(row.original);
          setIsDrawerOpen(true);
        }
      }
    ),
    selectedDeviceTransit && /* @__PURE__ */ jsx(
      SuperAdminDeviceTransitDrawer,
      {
        isOpen: isDrawerOpen,
        setIsOpen: setIsDrawerOpen,
        deviceTransit: selectedDeviceTransit,
        setDeviceTransit: setSelectedDeviceTransit,
        companyNames: companiesNames,
        userNames,
        refresh,
        sites
      }
    )
  ] });
};
