"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Box, FormControl } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { isValid } from "date-fns";
import dayjs from "dayjs";
import { fieldSx as defaultFieldSx } from "./styles.layout";
import { useProfileFields } from "@/hooks/profile-fields.hook";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { TypeableDateComponent } from "@/v2/components/forms/typeable-date.component";
import { CustomProfileFieldType } from "@/v2/feature/custom-fields/custom-profile-fields.dto";
import { sortCustomFields, sortSelectOptions } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const ShowCustomFieldComponent = ({
  field,
  setCustomUpdates,
  autoFocus,
  fieldSx = defaultFieldSx,
  disabled
}) => {
  const { polyglot } = usePolyglot();
  const [localValue, setLocalValue] = useState(field.value);
  const [fieldError, setFieldError] = useState();
  useEffect(() => {
    setLocalValue(field.value);
  }, [field.value]);
  const getFieldByType = useCallback(
    (customField) => {
      var _a, _b;
      const update = (value) => {
        setCustomUpdates((updates) => [
          ...updates.filter((u) => u.field.fieldId !== customField.field.fieldId),
          __spreadProps(__spreadValues({}, customField), { value })
        ]);
      };
      switch (customField.field.fieldType) {
        case CustomProfileFieldType.Input:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: customField.field.fieldName,
              required: customField.field.isRequired,
              value: localValue,
              onChange: (e) => {
                setLocalValue(e.target.value);
                update(e.target.value);
              },
              autoFocus,
              disabled
            }
          ) });
        case CustomProfileFieldType.DatePicker:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
            DatePickerComponent,
            {
              inputFormat: "DD/MM/YYYY",
              required: customField.field.isRequired,
              value: localValue != null ? localValue : null,
              onChange: (value) => {
                if (dayjs(value).isValid()) {
                  setLocalValue(value);
                  update(value);
                }
              },
              name: customField.field.fieldName,
              label: customField.field.fieldName,
              disabled
            }
          ) }) });
        case CustomProfileFieldType.Select:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            SelectComponent,
            {
              required: customField.field.isRequired,
              label: customField.field.fieldName,
              name: customField.field.fieldName,
              options: sortSelectOptions((_b = (_a = customField.field.options) == null ? void 0 : _a.allOptions) != null ? _b : []),
              value: localValue,
              onChange: (e) => {
                setLocalValue(e.target.value);
                update(e.target.value);
              },
              disabled
            }
          ) });
        case CustomProfileFieldType.Number:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              required: customField.field.isRequired,
              type: "number",
              label: customField.field.fieldName,
              value: localValue,
              onChange: (e) => {
                setLocalValue(e.target.value);
                update(e.target.value);
              },
              autoFocus,
              disabled
            }
          ) });
        case CustomProfileFieldType.DateInput:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TypeableDateComponent,
            {
              name: customField.field.fieldName,
              label: customField.field.fieldName,
              required: customField.field.isRequired,
              value: localValue,
              onChange: (value) => {
                if (!isValid(new Date(value))) {
                  setFieldError(polyglot.t("ShowCustomFieldComponent.errorMessages.invalidDate"));
                } else {
                  setLocalValue(value);
                  update(value);
                  setFieldError(void 0);
                }
              },
              autoFocus,
              error: !!fieldError,
              helperText: fieldError,
              disabled
            }
          ) });
        default:
          return null;
      }
    },
    [autoFocus, disabled, fieldError, fieldSx, localValue, polyglot, setCustomUpdates]
  );
  return getFieldByType(field);
};
export const ProfileField = ({ fieldStub, sx = defaultFieldSx, children }) => {
  const { getDefaultField, loading } = useProfileFields();
  const field = getDefaultField(fieldStub);
  if (loading || field && field.isHidden) return null;
  return /* @__PURE__ */ jsx(Box, { sx, children });
};
export const CustomFieldComponents = ({
  values,
  onChange,
  disabled,
  formName,
  rowModalMode,
  fieldSx
}) => {
  const [customUpdates, setCustomUpdates] = useState(values);
  const sortedCustomFields = useMemo(() => sortCustomFields(customUpdates), [customUpdates]);
  const { getCustomFieldsForForm } = useProfileFields();
  useLayoutEffect(() => {
    if (!formName || !rowModalMode) return;
    if (rowModalMode !== "add") return;
    setCustomUpdates(
      getCustomFieldsForForm(formName).map((field) => ({
        fieldId: field.fieldId,
        formId: field.formId,
        field,
        value: ""
      }))
    );
  }, [getCustomFieldsForForm, rowModalMode, formName]);
  useEffect(() => {
    if (customUpdates !== values) {
      onChange(customUpdates);
    }
  }, [customUpdates, onChange, values]);
  return sortedCustomFields.length > 0 ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: sortedCustomFields.map((f) => /* @__PURE__ */ jsx(
    ShowCustomFieldComponent,
    {
      field: f,
      setCustomUpdates,
      disabled,
      fieldSx
    },
    f.field.fieldId
  )) }) : null;
};
