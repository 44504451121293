"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { UserAPI } from "@/v2/feature/user/user.api";
import { nestErrorMessage } from "@/lib/errors";
export var AccountStatus = /* @__PURE__ */ ((AccountStatus2) => {
  AccountStatus2["Created"] = "Created";
  AccountStatus2["Invited"] = "Invited";
  AccountStatus2["Active"] = "Active";
  AccountStatus2["Deactivated"] = "Deactivated";
  AccountStatus2["InvitedToOnboard"] = "Invited to Onboard";
  return AccountStatus2;
})(AccountStatus || {});
export function inviteUser(userId, showMessage, polyglot, mode = "invite") {
  return __async(this, null, function* () {
    try {
      const result = yield UserAPI.inviteUser(userId, mode);
      if (result.success && result.emailAddresses)
        showMessage(
          polyglot.t("UserInvite.successMessages.sendInviteWithEmail", {
            emailAddress: result.emailAddresses.join(", ")
          }),
          "success"
        );
      if (result.success)
        showMessage(polyglot.t("UserInvite.successMessages.sendInvite", { message: result.message }), "success");
      else showMessage(result.message, "error");
    } catch (e) {
      console.error(`Failed to send email: ${nestErrorMessage(e)}`);
      throw e;
    }
  });
}
export function getInviteLink(userId, showMessage, polyglot, mode = "invite") {
  return __async(this, null, function* () {
    try {
      const result = yield UserAPI.getInviteLink(userId, mode);
      if (!result) {
        showMessage(polyglot.t("UserInvite.errorMessages.notFound"), "error");
        console.error("Invite link not found");
        return void 0;
      }
      return result;
    } catch (error) {
      console.error(`Failed to get invite link. ${nestErrorMessage(error)}`);
      throw error;
    }
  });
}
export function copyInviteLink(inviteLink, showMessage, polyglot) {
  return __async(this, null, function* () {
    try {
      yield navigator.clipboard.writeText(inviteLink);
      showMessage(polyglot.t("UserInvite.successMessages.copyInvite"), "info");
    } catch (error) {
      console.error(`Failed to copy invite link: ${nestErrorMessage(error)}`);
      throw error;
    }
  });
}
