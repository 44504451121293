"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
const UserFieldsContext = createContext({
  forms: [],
  getCachedFieldsByFormName: () => void 0,
  getCachedDefaultFieldsByFormName: () => void 0,
  getCachedCustomFieldsByFormName: () => void 0,
  getCachedCustomForms: () => [],
  getCachedDefaultForms: () => [],
  visibleFormNames: /* @__PURE__ */ new Set()
});
export const UserFieldsProvider = ({ children }) => {
  var _a;
  const [cache, setCache] = useState({
    loaded: false,
    onboardingForms: [],
    fieldsByFormName: /* @__PURE__ */ new Map(),
    visibleFormNames: /* @__PURE__ */ new Set()
  });
  const refreshProfileFields = useCallback(() => __async(void 0, null, function* () {
    try {
      const onboardingFormsRes = yield OnboardingAPI.onboardingForms();
      const fieldsByFormName = new Map(
        onboardingFormsRes.map((o) => [o.formName, o])
      );
      const visibleFormNames = new Set(onboardingFormsRes.map((o) => o.formName));
      setCache({
        onboardingForms: onboardingFormsRes,
        fieldsByFormName,
        visibleFormNames,
        loaded: true
      });
    } catch (error) {
      console.error("Failed to fetch onboarding forms:", error);
      setCache({
        onboardingForms: [],
        fieldsByFormName: /* @__PURE__ */ new Map(),
        visibleFormNames: /* @__PURE__ */ new Set(),
        loaded: false
      });
    }
  }), []);
  const getCachedFieldsByFormName = useCallback(
    (formName) => cache.fieldsByFormName.get(formName),
    [cache]
  );
  const getCachedDefaultFieldsByFormName = useCallback(
    (formName) => {
      var _a2, _b;
      return (_b = (_a2 = cache.fieldsByFormName.get(formName)) == null ? void 0 : _a2.fields) == null ? void 0 : _b.filter((f) => f.isDefault);
    },
    [cache]
  );
  const getCachedCustomFieldsByFormName = useCallback(
    (formName) => {
      var _a2, _b;
      return (_b = (_a2 = cache.fieldsByFormName.get(formName)) == null ? void 0 : _a2.fields) == null ? void 0 : _b.filter((f) => !f.isDefault);
    },
    [cache]
  );
  const getCachedCustomForms = useCallback(() => {
    var _a2;
    return (_a2 = cache.onboardingForms) == null ? void 0 : _a2.filter((f) => !f.isDefault);
  }, [cache]);
  const getCachedDefaultForms = useCallback(() => {
    var _a2;
    return (_a2 = cache.onboardingForms) == null ? void 0 : _a2.filter((f) => f.isDefault);
  }, [cache]);
  useEffect(() => {
    refreshProfileFields();
  }, [refreshProfileFields]);
  return /* @__PURE__ */ jsx(
    UserFieldsContext.Provider,
    {
      value: {
        forms: (_a = cache.onboardingForms) != null ? _a : [],
        getCachedFieldsByFormName,
        getCachedDefaultFieldsByFormName,
        getCachedCustomFieldsByFormName,
        getCachedCustomForms,
        getCachedDefaultForms,
        visibleFormNames: cache.visibleFormNames
      },
      children
    }
  );
};
export const useUserFieldsContext = () => {
  const context = useContext(UserFieldsContext);
  if (!context) {
    throw new Error("useUserFieldsContext must be used within an OnboardingProvider");
  }
  return context;
};
