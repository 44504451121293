"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { todaysDateShortISOString } from "@v2/util/date-format.util";
import { EventsAPI } from "@/api-client/events.api";
import { ScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Hazard } from "@/images/side-bar-icons/Hazard.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DateLabelComponent, getDateString } from "@/v2/components/forms/date-label.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useProfileFormUpdate } from "@/v2/feature/user/context/user-profile.context";
import { UserLifecycleStatuses } from "@/v2/feature/user/features/user-forms/user-lifecycle/user-lifecycle.interface";
import { FieldStructure } from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import { MissingInformationLabel } from "@/v2/feature/user/features/user-profile/details/components/missing-information-label.component";
import { ShowHideButton } from "@/v2/feature/user/features/user-profile/details/components/show-hide-button.component";
import {
  cardHeaderSx,
  cardMaxWidth,
  definitionListSx,
  definitionValueSx,
  hazardSize,
  showTableSx,
  tablecardSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserProfileLifecycleForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-lifecycle-form.component";
import { UserAPI } from "@/v2/feature/user/user.api";
import {
  translategetAllChangeTypeOptions,
  translateUserProfileLifecycleCard
} from "@/v2/infrastructure/i18n/translate.util";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { hazardMissingField, tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { sleep } from "@/v2/util/time.util";
const defaultBlankModalValues = {
  id: 0,
  userId: 0,
  companyId: 0,
  createdBy: 0,
  updatedBy: 0,
  effectiveDate: todaysDateShortISOString(),
  status: void 0,
  changeReason: "",
  note: null,
  effectiveEndDate: null
};
const SCOPE = "user.lifecycle:all";
export const UserProfileLifecycleCard = ({
  userId,
  handleSubmit = () => {
  },
  missingFields
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [userLifeCycle, setUserLifeCycle] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowModalMode, setRowModalMode] = useState("add");
  const [showMessage] = useMessage();
  const [modalInitialValues, setModalInitialValues] = useState(defaultBlankModalValues);
  const [showDetails, setShowDetails] = useState(false);
  const [formState, setFormState] = useState(null);
  const setFormCurrentlyEditing = useProfileFormUpdate();
  const { getCachedUserById } = useCachedUsers();
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const hasLifecycleScope = hasScopes(["user.lifecycle:read", "user.lifecycle:manager"], scopesContext);
  const loadInitialData = useCallback(() => __async(void 0, null, function* () {
    if (!userId) return;
    setLoading(true);
    try {
      const [userEvents, userLifecycle] = yield Promise.all([
        EventsAPI.getAllUserEvents(userId),
        hasLifecycleScope ? UserAPI.getUserLifecycleInfo(userId) : void 0
      ]);
      setData(userEvents);
      setUserLifeCycle(userLifecycle);
    } catch (error) {
      showMessage(`${polyglot.t("UserProfileLifecycleCard.errorMessages.error")}. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [polyglot, hasLifecycleScope, showMessage, userId]);
  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);
  const editLifeCycleRow = useCallback((rowData) => {
    setModalInitialValues(__spreadValues({}, rowData));
    setRowModalMode("edit");
    setFormState(null);
    setIsModalOpen(true);
  }, []);
  const getCurrentEmployedEvent = () => {
    const allEntries = data == null ? void 0 : data.entries;
    return allEntries == null ? void 0 : allEntries.find((eachEntry) => eachEntry.status === UserLifecycleStatuses.Employed);
  };
  const addLeaveForUser = () => {
    setModalInitialValues(__spreadProps(__spreadValues({}, defaultBlankModalValues), { status: UserLifecycleStatuses.Leave }));
    setFormState("Leave");
    setRowModalMode("add");
    setIsModalOpen(true);
  };
  const rehireUser = () => {
    const currentEmployedEvent = getCurrentEmployedEvent();
    setModalInitialValues(__spreadProps(__spreadValues({}, currentEmployedEvent != null ? currentEmployedEvent : defaultBlankModalValues), {
      status: UserLifecycleStatuses.Employed,
      changeReason: "rehired"
    }));
    setFormState("Rehire");
    setRowModalMode("edit");
    setIsModalOpen(true);
  };
  const getMenuItemsBasedOnCurrentlyEffectiveStatus = () => {
    var _a2, _b2;
    const ADD_LEAVE_MENU_OPTION = /* @__PURE__ */ jsx(Button, { onClick: () => addLeaveForUser(), sx: secondaryCTABtn, children: polyglot.t("UserProfileLifecycleCard.newLeave") }, "add-leave");
    const REHIRE_MENU_OPTION = /* @__PURE__ */ jsx(Button, { onClick: () => rehireUser(), sx: secondaryCTABtn, children: polyglot.t("UserProfileLifecycleCard.rehire") }, "rehire");
    if (((_a2 = data == null ? void 0 : data.effectiveRecord) == null ? void 0 : _a2.status) === UserLifecycleStatuses.Employed) {
      return [ADD_LEAVE_MENU_OPTION];
    }
    if (((_b2 = data == null ? void 0 : data.effectiveRecord) == null ? void 0 : _b2.status) === UserLifecycleStatuses.Terminated) {
      return [REHIRE_MENU_OPTION];
    }
    return [];
  };
  const refreshLifecycle = () => __async(void 0, null, function* () {
    if (hasLifecycleScope) {
      yield sleep(5e3);
      setUserLifeCycle(yield UserAPI.getUserLifecycleInfo(userId));
    }
  });
  const columnData = useMemo(
    () => [
      {
        header: () => polyglot.t("UserProfileLifecycleCard.status"),
        accessorFn: (row) => row,
        id: "status",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: translateUserProfileLifecycleCard(original.status ? original.status : "", polyglot) })
      },
      {
        header: () => polyglot.t("UserProfileLifecycleCard.eventStartDate"),
        accessorFn: (row) => row,
        id: "eventStartDate",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx("div", { children: original.effectiveDate ? getDateString(original.effectiveDate) : "" });
        }
      },
      {
        header: () => polyglot.t("UserProfileLifecycleCard.changeType"),
        accessorFn: (row) => row,
        id: "changeReason",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { style: { textTransform: "capitalize" }, children: translategetAllChangeTypeOptions(original.changeReason ? original.changeReason : "", polyglot) })
      },
      {
        header: () => polyglot.t("UserProfileLifecycleCard.changeBy"),
        accessorFn: (row) => row,
        id: "changeBy",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a2;
          return /* @__PURE__ */ jsx("div", { children: original.updatedBy ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(UserAvatar, { userId: original.updatedBy, size: "xxsmall" }),
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { marginLeft: spacing.m10, color: themeColors.DarkGrey }), children: (_a2 = getCachedUserById(original.updatedBy)) == null ? void 0 : _a2.displayName })
          ] }) : "" });
        }
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { style: { display: "flex", justifyContent: "flex-end" }, children: original.status !== UserLifecycleStatuses.Hired && /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            title: polyglot.t("UserProfileLifecycleCard.edit"),
            onClick: () => {
              editLifeCycleRow(original);
            },
            sx: tableIconButtonSx,
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) }) })
      }
    ],
    [polyglot, scopesContext, editLifeCycleRow, getCachedUserById]
  );
  return /* @__PURE__ */ jsxs(Box, { sx: tablecardSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, cardMaxWidth), children: [
      /* @__PURE__ */ jsxs(Box, { component: "header", sx: cardHeaderSx, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "h3", sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("UserProfileLifecycleCard.lifecycle") }),
          /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user:all"], context: scopesContext, children: (missingFields == null ? void 0 : missingFields.find((rec) => rec.cardId === CustomProfileFormType.Lifecycle)) && /* @__PURE__ */ jsx(Box, { sx: hazardMissingField, children: /* @__PURE__ */ jsx(Hazard, __spreadValues({}, hazardSize)) }) })
        ] }),
        /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: getMenuItemsBasedOnCurrentlyEffectiveStatus() }) })
      ] }),
      /* @__PURE__ */ jsx(Box, { component: "section", children: !(data == null ? void 0 : data.effectiveRecord) ? /* @__PURE__ */ jsx(MissingInformationLabel, {}) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
        ((_a = data == null ? void 0 : data.effectiveRecord) == null ? void 0 : _a.status) && /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("UserProfileLifecycleCard.status"),
            fieldValue: translateUserProfileLifecycleCard((_c = (_b = data == null ? void 0 : data.effectiveRecord) == null ? void 0 : _b.status) != null ? _c : "", polyglot)
          }
        ),
        (userLifeCycle == null ? void 0 : userLifeCycle.startDate) && /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("UserProfileLifecycleCard.startDate"),
            fieldValue: /* @__PURE__ */ jsx(DateLabelComponent, { date: userLifeCycle == null ? void 0 : userLifeCycle.startDate, titleSx: definitionValueSx })
          }
        ),
        (userLifeCycle == null ? void 0 : userLifeCycle.leaveDate) && /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("UserProfileLifecycleCard.endDate"),
            fieldValue: /* @__PURE__ */ jsx(DateLabelComponent, { date: userLifeCycle == null ? void 0 : userLifeCycle.leaveDate, titleSx: definitionValueSx })
          }
        )
      ] }) }) })
    ] }),
    (data == null ? void 0 : data.effectiveRecord) && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(ShowHideButton, { showDetails, setShowDetails }) }),
      showDetails && data && /* @__PURE__ */ jsx(Box, { sx: showTableSx, children: /* @__PURE__ */ jsx(BasicTable, { rowData: [...data.entries], columnData }) })
    ] }),
    /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(DrawerModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, children: /* @__PURE__ */ jsx(
      UserProfileLifecycleForm,
      {
        initialValues: modalInitialValues,
        allEntries: data == null ? void 0 : data.entries,
        userId,
        loadInitialData,
        rowModalMode,
        handleSubmit,
        setFormCurrentlyEditing,
        setIsModalOpen,
        setLoading,
        loading,
        onClose: () => {
          setIsModalOpen(false);
        },
        refreshLifecycle,
        formState
      }
    ) }) })
  ] });
};
