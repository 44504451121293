"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import { forwardRef, useCallback, useRef, useState } from "react";
import { Box, IconButton, InputAdornment } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Chose } from "@/images/side-bar-icons/Chose.svg";
import { ReactComponent as Copy } from "@/images/side-bar-icons/Copy.svg";
import { ReactComponent as Eye } from "@/images/side-bar-icons/Eye.svg";
import { ReactComponent as EyeSlash } from "@/images/side-bar-icons/EyeSlash.svg";
import { ReactComponent as MistakeIcon } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as QuestionIcon } from "@/images/side-bar-icons/Question.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ClearIconButton } from "@/v2/components/theme-components/clear-icon-button.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { StyledTextfield } from "@/v2/styles/textfield.styles";
const iconSize = { width: "14px", height: "14px" };
const defaultTo = (value, defaultValue) => typeof value === "undefined" ? defaultValue : value;
export const TextfieldComponent = forwardRef(
  (_a, ref) => {
    var _b = _a, {
      id,
      value,
      type,
      onBlur,
      onChange,
      error,
      clearText,
      disabled,
      endAdornment = "clear-text",
      autoComplete,
      autoFocus,
      multiline,
      minValue,
      maxValue,
      spellCheck,
      maxLength,
      tooltip,
      InputProps = {},
      fullWidth = true,
      autoTrim = true,
      validateNumber = false,
      fieldBackgroundColor = "Background",
      fieldBackgroundHoverColor = "GreyHover",
      valueFont = "title4",
      fieldPadding = "10px",
      borderRadius = "10px",
      inputRef
    } = _b, textFieldProps = __objRest(_b, [
      "id",
      "value",
      "type",
      "onBlur",
      "onChange",
      "error",
      "clearText",
      "disabled",
      "endAdornment",
      "autoComplete",
      "autoFocus",
      "multiline",
      "minValue",
      "maxValue",
      "spellCheck",
      "maxLength",
      "tooltip",
      "InputProps",
      "fullWidth",
      "autoTrim",
      "validateNumber",
      "fieldBackgroundColor",
      "fieldBackgroundHoverColor",
      "valueFont",
      "fieldPadding",
      "borderRadius",
      "inputRef"
    ]);
    const [showPassword, setShowPassword] = useState(false);
    const [copied, setCopied] = useState(false);
    const textFieldType = type === "password" && showPassword ? "text" : type;
    const initialValue = useRef(value).current;
    const [showMessage] = useMessage();
    const checkNumberValue = useCallback(
      (e) => {
        const { min, max, value: value2 } = e.target;
        let parsedValue;
        try {
          parsedValue = parseInt(value2);
        } catch (e2) {
          showMessage(`Something went wrong. ${nestErrorMessage(e2)}`, "error");
          return;
        }
        if (!parsedValue) {
          return;
        }
        let trimmedValue = parsedValue;
        if (min !== void 0 && parsedValue <= parseInt(min)) {
          trimmedValue = parseInt(min);
        }
        if (max !== void 0 && parsedValue >= parseInt(max)) {
          trimmedValue = parseInt(max);
        }
        if (trimmedValue !== parsedValue) {
          e.target.value = trimmedValue.toString();
          onChange == null ? void 0 : onChange(e);
        }
      },
      [onChange, showMessage]
    );
    const checkAutoTrim = useCallback(
      (e) => {
        if (!autoTrim) return;
        const trimmedValue = e.target.value.trim();
        if (trimmedValue === e.target.value) return;
        e.target.value = trimmedValue;
        onChange == null ? void 0 : onChange(e);
      },
      [autoTrim, onChange]
    );
    const handleCopy = useCallback(() => {
      setCopied(true);
      navigator.clipboard.writeText(String(value));
      setTimeout(() => setCopied(false), 3e3);
    }, [value]);
    return /* @__PURE__ */ jsx(
      StyledTextfield,
      __spreadProps(__spreadValues({}, textFieldProps), {
        fieldBackgroundColor,
        fieldBackgroundHoverColor,
        valueFont,
        fieldPadding,
        fullWidth,
        variant: "standard",
        placeholder: textFieldProps.placeholder,
        helperText: textFieldProps.helperText,
        inputRef: inputRef || ref,
        type: textFieldType,
        size: "small",
        value: value != null ? value : "",
        error,
        borderRadius,
        multiline,
        disabled,
        onBlur: (e) => {
          if (textFieldType === "number" && validateNumber) {
            checkNumberValue(e);
          } else {
            checkAutoTrim(e);
          }
          onBlur == null ? void 0 : onBlur(e);
        },
        onChange,
        inputProps: {
          autoComplete,
          autoFocus,
          spellCheck: (
            // normally, spellCheck on inputs is on by default, but for most of our
            // editable text fields, it doesn't make sense to spell check the data
            defaultTo(spellCheck, false)
          ),
          maxLength,
          max: maxValue,
          min: minValue
        },
        InputLabelProps: { shrink: true },
        InputProps: __spreadProps(__spreadValues({}, InputProps), {
          id,
          endAdornment: /* @__PURE__ */ jsx(InputAdornment, {
            position: "end",
            //value !== initialValue &&
            //!error &&
            children: value && !multiline && endAdornment === "clear-text" && clearText && type !== "password" && !disabled ? /* @__PURE__ */ jsx(ClearIconButton, { onClick: clearText }) : value && value !== initialValue && !!error && endAdornment === "clear-text" && type !== "password" ? /* @__PURE__ */ jsx(MistakeIcon, __spreadValues({}, iconSize)) : value && type === "password" ? /* @__PURE__ */ jsx(
              IconButton,
              {
                "aria-label": "toggle password visibility",
                onClick: () => setShowPassword((value2) => !value2),
                children: showPassword ? /* @__PURE__ */ jsx(Eye, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(EyeSlash, __spreadValues({}, iconSize))
              }
            ) : !value && tooltip ? /* @__PURE__ */ jsx(StyledTooltip, { title: tooltip, placement: "top", children: /* @__PURE__ */ jsx(QuestionIcon, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Grey })) }) : value && !multiline && endAdornment === "copy" ? /* @__PURE__ */ jsx(Box, { sx: { cursor: "pointer" }, children: copied ? /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(Copy, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Grey }, onClick: handleCopy })) }) : typeof endAdornment !== "string" ? endAdornment : null
          })
        })
      })
    );
  }
);
