"use strict";
import styled from "@emotion/styled";
export const FlagIcon = styled("div")(({ height, width }) => ({
  display: "inline-flex",
  flexShrink: 0,
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  overflow: "hidden",
  width,
  height,
  position: "relative",
  "& > svg, & > img": {
    position: "absolute",
    inset: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transform: "scale(1.5)"
  }
}));
