"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, Stack } from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE } from "@/lib/routes";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { ReviewTemplateAPI, ReviewTemplateEndpoints } from "@/v2/feature/growth/reviews/api-client/review-template.api";
import { UpsertReviewTemplateModal } from "@/v2/feature/growth/reviews/features/review-template/review-template-list/components/upsert-review-template.component-modal";
import { PayrollLogoZelt } from "@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const ReviewTemplateListPage = () => {
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [deleteDrawerOpen, setDeleteDrawerOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const {
    data: templates,
    mutate: refreshTemplates,
    isLoading: templatesLoading
  } = useApiClient(ReviewTemplateEndpoints.getReviewTemplatesCompanyId(searchInput), { suspense: false });
  const deleteAction = useCallback(() => __async(void 0, null, function* () {
    if (!selectedTemplateId) return;
    try {
      setDeleteLoading(true);
      yield ReviewTemplateAPI.deleteTemplatebyId(selectedTemplateId);
      showMessage(polyglot.t("ReviewTemplateDeleteDrawer.successMessages.delete"), "success");
      refreshTemplates == null ? void 0 : refreshTemplates();
      setDeleteDrawerOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t("ReviewTemplateDeleteDrawer.errorMessages.delete", { nestError: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setDeleteLoading(false);
    }
  }), [selectedTemplateId, refreshTemplates, showMessage, polyglot]);
  const getTemplateActionsOptions = useCallback(
    (original) => {
      const menuOptions = [
        {
          icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
          handler: () => {
            routerHistory.push(
              generatePath(SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE, {
                templateId: original == null ? void 0 : original.id
              })
            );
          },
          label: "Edit item",
          disabled: false
        },
        {
          icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
          handler: () => {
            setSelectedTemplateId(original.id);
            setDeleteDrawerOpen(true);
          },
          label: "Delete item",
          disabled: false
        }
      ];
      return menuOptions;
    },
    [routerHistory]
  );
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
    /* @__PURE__ */ jsx(
      SettingsSectionContent,
      {
        title: "Templates",
        contentWidth: "100%",
        headerWidth: "100%",
        buttons: [
          /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsOpen(true), children: "New template" })
        ],
        children: /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            loadingAll: templatesLoading,
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      TableSearch,
                      {
                        query: searchInput,
                        handleChange: (e) => {
                          setSearchInput(e.target.value);
                        }
                      }
                    )
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: (templates || []).map((item) => {
                      const avatar = item.companyId === null ? /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g8 }, children: [
                        /* @__PURE__ */ jsx(PayrollLogoZelt, { height: "0.9em", width: "0.9em", padding: ".25em" }),
                        "Zelt"
                      ] }) : /* @__PURE__ */ jsx(UserCell, { userId: item.createdBy });
                      return /* @__PURE__ */ jsx(
                        SettingsItemCard,
                        {
                          title: item.name,
                          boxSx: responsiveCardStyle,
                          contentItemsSets: [
                            {
                              type: ContentItemType.component,
                              contentComponent: /* @__PURE__ */ jsx(
                                ChipComponent,
                                {
                                  name: avatar,
                                  backgroundColor: "white",
                                  textColor: "DarkGrey",
                                  textVariant: "caption",
                                  border: "middle"
                                }
                              )
                            },
                            {
                              name: `${new LocalDate(item.createdAt).getDate().toLocaleDateString(void 0, {
                                day: "numeric",
                                month: "short",
                                year: "numeric"
                              })}`,
                              type: ContentItemType.chip,
                              textColor: "DarkGrey",
                              backgroundColor: "white",
                              border: "middle"
                            }
                          ],
                          boxAction: () => {
                            routerHistory.push(
                              generatePath(SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE, {
                                templateId: item == null ? void 0 : item.id
                              })
                            );
                          },
                          advanceActionHidden: !item.companyId,
                          advanceActionOptions: getTemplateActionsOptions(item)
                        },
                        item.name
                      );
                    }) })
                  }
                ]
              }
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("ReviewTemplateDeleteDrawer.title"),
        description: polyglot.t("ReviewTemplateDeleteDrawer.description"),
        isOpen: deleteDrawerOpen,
        setIsOpen: setDeleteDrawerOpen,
        loading: deleteLoading,
        action: deleteAction
      }
    ),
    /* @__PURE__ */ jsx(
      UpsertReviewTemplateModal,
      {
        setIsOpen,
        isOpen,
        onClose: () => {
          setIsOpen(false);
        },
        template: void 0
      }
    )
  ] });
};
