"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { sortString } from "@v2/components/table/table-sorting.util";
import { Typography } from "@v2/components/typography/typography.component";
import { AttendanceSettingsUpsertMultiplierDrawer } from "@v2/feature/attendance/settings/components/attendance-settings-upsert-multiplier-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
export const AttendanceMultipliersTable = ({ attendancePayMultipliers, refreshMultipliers }) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  const [multiplierToBeEdited, setMultiplierToBeEdited] = useState(null);
  useEffect(() => {
    attendancePayMultipliers.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  }, [attendancePayMultipliers]);
  const columns = useMemo(
    () => [
      {
        id: "name",
        header: () => polyglot.t("General.name"),
        accessorFn: (row) => row.name,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item == null ? void 0 : item.name),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.name }) })
      },
      {
        id: "value",
        header: () => polyglot.t("AbsenceModule.payMultiplier"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item == null ? void 0 : item.name),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          "x",
          original.value
        ] }) })
      }
    ],
    [polyglot]
  );
  const handleRowClick = useCallback((row) => {
    setMultiplierToBeEdited(row.original);
    setIsOpen(true);
  }, []);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: attendancePayMultipliers,
        columnData: columns,
        rowClick: handleRowClick
      }
    ),
    /* @__PURE__ */ jsx(
      AttendanceSettingsUpsertMultiplierDrawer,
      {
        isOpen,
        setIsOpen,
        multiplier: multiplierToBeEdited,
        refresh: refreshMultipliers,
        onClose: () => {
          setIsOpen(false);
        },
        afterClose: () => {
          setMultiplierToBeEdited(null);
        }
      }
    )
  ] });
};
