"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DocumentSettingsNewTypeForm } from "@/v2/feature/documents/components/document-settings-new-type-drawer.component";
import { DocumentTypeAPI } from "@/v2/feature/documents/document-type.api";
import { iconSize } from "@/v2/feature/onboarding/onboarding-template-edit.page";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { toTitleCase } from "@/v2/util/string.util";
export const DocumentsSettingsPage = () => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [newTypeDrawerIsOpen, setNewTypeDrawerIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [drawerMode, setDrawerMode] = useState("create");
  const [currentDocumentType, setCurrentDocumentType] = useState();
  const [allDocumentTypes, setAllDocumentTypes] = useState([]);
  const [deleteDrawerOpen, setDeleteDrawerOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedDocTypeId, setSelectedDocTypeId] = useState();
  const filteredDocumentTypes = useMemo(() => {
    if ((searchInput == null ? void 0 : searchInput.length) === 0) {
      return allDocumentTypes != null ? allDocumentTypes : [];
    } else {
      return allDocumentTypes == null ? void 0 : allDocumentTypes.filter(
        (d) => {
          var _a, _b, _c;
          return ((_b = (_a = d.documentTypeName) == null ? void 0 : _a.toLowerCase()) == null ? void 0 : _b.includes(searchInput == null ? void 0 : searchInput.toLowerCase())) || ((_c = d.availableTo) == null ? void 0 : _c.includes(searchInput == null ? void 0 : searchInput.toLowerCase()));
        }
      );
    }
  }, [searchInput, allDocumentTypes]);
  const refreshDocumentTypes = useCallback(() => __async(void 0, null, function* () {
    setLoading(true);
    try {
      const data = yield DocumentTypeAPI.getAllDocumentTypesForSettings();
      const documentTypesForTable = data.map((d) => {
        const documentTypeName = d.label;
        const status = d.default ? "Default" : "Custom";
        return {
          id: d.id,
          documentTypeName,
          value: d.value,
          availableTo: d.availableTo === "company" ? "company" : "personal",
          status,
          visible: d.visible,
          default: d.default,
          createdAt: d.createdAt
        };
      });
      setAllDocumentTypes(documentTypesForTable);
    } catch (error) {
      showMessage(
        polyglot.t("DocumentsSettingsPage.errorMessages.fetch", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  }), [polyglot, showMessage]);
  useEffect(() => {
    refreshDocumentTypes();
  }, [refreshDocumentTypes]);
  const newCustomDocumentTypeHandler = () => {
    setDrawerMode("create");
    setCurrentDocumentType(void 0);
    setNewTypeDrawerIsOpen(true);
  };
  const editCustomDocumentTypeHandler = (documentType) => {
    setDrawerMode("edit");
    setCurrentDocumentType(__spreadValues({}, documentType));
    setNewTypeDrawerIsOpen(true);
  };
  const deleteCustomDocumentTypeHandler = useCallback(() => __async(void 0, null, function* () {
    if (!selectedDocTypeId) return;
    try {
      setDeleteLoading(true);
      yield DocumentTypeAPI.deleteCustomDocumentType(selectedDocTypeId);
      showMessage(polyglot.t("DocumentsSettingsDeleteDrawer.successMessages.delete"), "success");
      refreshDocumentTypes();
      setDeleteDrawerOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t("DocumentsSettingsDeleteDrawer.errorMessages.delete", { nestError: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setDeleteLoading(false);
    }
  }), [selectedDocTypeId, refreshDocumentTypes, showMessage, polyglot]);
  const getActionsForDocumentType = useCallback(
    (documentTypeObject) => {
      const actionOptions = [];
      const hideCustomDocumentTypeHandler = (documentType) => __async(void 0, null, function* () {
        try {
          yield DocumentTypeAPI.hideCustomDocumentType(documentType);
          refreshDocumentTypes();
        } catch (error) {
          showMessage(
            polyglot.t("DocumentsSettingsPage.errorMessages.hide", { errorMessage: nestErrorMessage(error) }),
            "error"
          );
        }
      });
      const showCustomDocumentTypeHandler = (documentType) => __async(void 0, null, function* () {
        try {
          yield DocumentTypeAPI.showCustomDocumentType(documentType);
          refreshDocumentTypes();
        } catch (error) {
          showMessage(
            polyglot.t("DocumentsSettingsPage.errorMessages.unhide", { errorMessage: nestErrorMessage(error) }),
            "error"
          );
        }
      });
      if (!documentTypeObject.default)
        actionOptions.push(
          {
            icon: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, iconSize)),
            label: polyglot.t("General.edit"),
            handler: () => editCustomDocumentTypeHandler(documentTypeObject)
          },
          {
            icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
            label: polyglot.t("General.delete"),
            handler: () => {
              setSelectedDocTypeId(documentTypeObject == null ? void 0 : documentTypeObject.id);
              setDeleteDrawerOpen(true);
            }
          }
        );
      if (documentTypeObject.visible)
        actionOptions.push({
          label: polyglot.t("DocumentsSettingsPage.hide"),
          handler: () => hideCustomDocumentTypeHandler(documentTypeObject)
        });
      if (!documentTypeObject.visible)
        actionOptions.push({
          label: polyglot.t("DocumentsSettingsPage.unhide"),
          handler: () => showCustomDocumentTypeHandler(documentTypeObject)
        });
      return actionOptions;
    },
    [polyglot, refreshDocumentTypes, showMessage]
  );
  const columns = useMemo(
    () => [
      {
        header: () => polyglot.t("DocumentsSettingsPage.documentType"),
        enableSorting: false,
        accessorFn: (row) => row,
        id: "documentType",
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: original == null ? void 0 : original.documentTypeName });
        }
      },
      {
        header: () => polyglot.t("DocumentsSettingsPage.availableTo"),
        enableSorting: false,
        accessorFn: (row) => row,
        id: "availableTo",
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: (original == null ? void 0 : original.availableTo) ? toTitleCase(original == null ? void 0 : original.availableTo) : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => polyglot.t("DocumentsSettingsPage.type"),
        enableSorting: false,
        accessorFn: (row) => row,
        id: "status",
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: original == null ? void 0 : original.status });
        }
      },
      {
        header: () => polyglot.t("DocumentsSettingsPage.visible"),
        enableSorting: false,
        accessorFn: (row) => row,
        id: "visible",
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: (original == null ? void 0 : original.visible) ? polyglot.t("DocumentsSettingsPage.inUse") : polyglot.t("DocumentsSettingsPage.hidden") });
        }
      },
      {
        id: "action",
        header: () => "",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Stack, { flexDirection: "row", justifyContent: "flex-end", gap: "5px", whiteSpace: "nowrap", children: /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: getActionsForDocumentType(original),
              actionButtonDetails: {
                type: "iconButton",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: "actions",
                icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
              }
            }
          ) });
        }
      }
    ],
    [polyglot, getActionsForDocumentType]
  );
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: polyglot.t("DocumentsSettingsPage.types") }),
        actions: /* @__PURE__ */ jsx(ButtonComponent, { onClick: newCustomDocumentTypeHandler, colorVariant: "primary", sizeVariant: "small", children: polyglot.t("DocumentsSettingsPage.newType") }),
        showAction: true
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, sx: __spreadValues({}, spacing.pt20), children: [
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: spacing.mb30
          },
          children: /* @__PURE__ */ jsx(
            TableSearch,
            {
              query: searchInput,
              handleChange: (e) => {
                setSearchInput(e.target.value);
              }
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(BasicTable, { rowData: [...filteredDocumentTypes], columnData: columns, loading, hidePagination: true }) }),
      /* @__PURE__ */ jsx(DrawerModal, { isOpen: newTypeDrawerIsOpen, setIsOpen: setNewTypeDrawerIsOpen, children: /* @__PURE__ */ jsx(
        DocumentSettingsNewTypeForm,
        {
          initialValues: currentDocumentType,
          onClose: () => {
            setNewTypeDrawerIsOpen(false);
          },
          refreshDocumentTypes,
          mode: drawerMode
        }
      ) }),
      /* @__PURE__ */ jsx(
        DeleteDrawer,
        {
          title: polyglot.t("DocumentsSettingsDeleteDrawer.title"),
          description: polyglot.t("DocumentsSettingsDeleteDrawer.description"),
          isOpen: deleteDrawerOpen,
          setIsOpen: setDeleteDrawerOpen,
          loading: deleteLoading,
          action: deleteCustomDocumentTypeHandler
        }
      )
    ] })
  ] });
};
