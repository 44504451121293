"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box/Box";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { lowerCase } from "lodash";
import * as yup from "yup";
import { UploadInput } from "@/component/forms/UploadInput";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as UploadedDocIcon } from "@/images/documents/UploadedDoc.svg";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as InfoGrey } from "@/images/side-bar-icons/InfoGrey.svg";
import { nestErrorMessage } from "@/lib/errors";
import { checkScopes } from "@/lib/scopes";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserSelect } from "@/v2/components/user-select-type/user-select.component";
import { UserSelectFiltersOptions } from "@/v2/components/user-select-type/user-select.interface";
import { DocumentNewAPI } from "@/v2/feature/document-new/api-client/document-new.api";
import { DocumentTypeEndpoints } from "@/v2/feature/document-new/api-client/document-type.api";
import { getFileName, newDocViewTypeOptions } from "@/v2/feature/document-new/document-new.util";
import {
  AdminDocumentView,
  FormTypeEnum
} from "@/v2/feature/document-new/interfaces/document-new.interface";
import { DocumentSettingsNewTypeForm } from "@/v2/feature/documents/components/document-settings-new-type-drawer.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { ReachType } from "@/v2/interfaces/general.interface";
import { iconSize } from "@/v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const MemoizedUserSelect = React.memo(UserSelect, (before, after) => {
  return before.ruleString === after.ruleString;
});
export const NewDocumentFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  formType,
  reach,
  documentTypes,
  userId,
  document,
  refresh,
  afterClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(
    NewDocumentFormContent,
    {
      onClose,
      refresh,
      documentTypes,
      document,
      formType,
      userId,
      reach
    }
  ) });
};
const NewDocumentFormContent = ({
  onClose,
  formType,
  reach,
  documentTypes: initialDocumentTypes,
  userId,
  document,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q;
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { getCachedUserById } = useCachedUsers();
  const hasDocumentsAllScope = checkScopes(user, ["documents:all"], { userId: user.userId });
  const [documentTypes, setDocumentTypes] = useState(initialDocumentTypes != null ? initialDocumentTypes : []);
  const currentUserId = !!userId ? Number(userId) : reach === ReachType.Me ? user.userId : void 0;
  const [newTypeDrawerIsOpen, setNewTypeDrawerIsOpen] = useState(false);
  const { data: fetchedDocumentTypes, mutate: refreshAllTypes } = useApiClient(
    DocumentTypeEndpoints.getTypeCompanyId(),
    {
      suspense: false
    },
    !initialDocumentTypes || initialDocumentTypes.length === 0
  );
  useEffect(() => {
    if (fetchedDocumentTypes) {
      setDocumentTypes(fetchedDocumentTypes);
    }
  }, [fetchedDocumentTypes, initialDocumentTypes]);
  const hasDocumentsManagerScope = useMemo(() => {
    if (currentUserId) return checkScopes(globalState.user, ["documents:manager"], { userId: currentUserId });
    if (document && document.userId) {
      checkScopes(globalState.user, ["documents:manager"], { userId: document.userId });
    }
    return false;
  }, [currentUserId, document, globalState.user]);
  const managerOrAdmin = useMemo(() => {
    return hasDocumentsAllScope || hasDocumentsManagerScope;
  }, [hasDocumentsAllScope, hasDocumentsManagerScope]);
  const formik = useFormik({
    initialValues: {
      fileUuid: (_a = document == null ? void 0 : document.fileUuid) != null ? _a : null,
      contractId: (_b = document == null ? void 0 : document.contractId) != null ? _b : null,
      isExternalResource: (_c = document == null ? void 0 : document.isExternalResource) != null ? _c : false,
      programmaticRecordType: (_d = document == null ? void 0 : document.programmaticRecordType) != null ? _d : null,
      programmaticRecordId: (_e = document == null ? void 0 : document.programmaticRecordId) != null ? _e : null,
      userId: (_g = (_f = document == null ? void 0 : document.userId) != null ? _f : currentUserId) != null ? _g : null,
      typeId: (_h = document == null ? void 0 : document.typeId) != null ? _h : -1,
      name: (_i = document == null ? void 0 : document.name) != null ? _i : "",
      note: (_j = document == null ? void 0 : document.note) != null ? _j : null,
      viewType: Boolean(formType === FormTypeEnum.request || currentUserId || !hasDocumentsAllScope) ? AdminDocumentView.Personal : document ? (document == null ? void 0 : document.membersRule) === UserSelectFiltersOptions.Everyone || (document == null ? void 0 : document.membersRule) === UserSelectFiltersOptions.CustomRule ? AdminDocumentView.Company : AdminDocumentView.Personal : AdminDocumentView.Company,
      membersRule: (_k = document == null ? void 0 : document.membersRule) != null ? _k : formType === FormTypeEnum.request || currentUserId || !hasDocumentsAllScope ? UserSelectFiltersOptions.SelectSpecific : UserSelectFiltersOptions.Everyone,
      customRule: (_l = document == null ? void 0 : document.customRule) != null ? _l : null,
      documentSettings: (_m = document == null ? void 0 : document.documentSettings) != null ? _m : {
        isPinned: false,
        expiryDate: null,
        private: false,
        hideFromEmployee: false
      }
    },
    validationSchema: yup.object({
      userId: yup.number().nullable().notRequired(),
      typeId: yup.number().required(),
      viewType: yup.string().required(),
      name: yup.string().required(),
      fileUuid: formType === FormTypeEnum.upload ? yup.string().nullable().required(polyglot.t("DocumentFormModal.validationMessages.attachment")) : yup.string().nullable().notRequired(),
      membersRule: yup.string().required(),
      customRule: yup.string().nullable().notRequired(),
      note: yup.string().nullable().notRequired()
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        if (document) {
          yield DocumentNewAPI.updateDocument(values, document.id);
        } else {
          yield DocumentNewAPI.createDocument(values);
        }
        showMessage(polyglot.t("DocumentFormModal.successMessages.upload"), "success");
        yield refresh == null ? void 0 : refresh();
        onClose();
      } catch (error) {
        showMessage(
          polyglot.t("DocumentFormModal.errorMessages.upload", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setSubmitting(false);
      }
    })
  });
  const filteredTypes = useMemo(() => {
    if (!documentTypes) return [];
    return documentTypes.filter((d) => d.availableTo === lowerCase(formik.values.viewType) && d.visible).map((type) => {
      return { label: type.label, value: type.id };
    });
  }, [formik.values.viewType, documentTypes]);
  const getTitle = useCallback(
    (formType2, document2) => {
      const type = formType2 === FormTypeEnum.upload ? polyglot.t("DocumentFormModal.document") : formType2;
      const operation = document2 ? polyglot.t("General.edit") : polyglot.t("General.new");
      return `${operation} ${type}`;
    },
    [polyglot]
  );
  useEffect(() => {
    if (formik.values.viewType === AdminDocumentView.Personal && formik.values.documentSettings.isPinned) {
      formik.setFieldValue("documentSettings.isPinned", false);
    }
    if (managerOrAdmin && formik.values.viewType === AdminDocumentView.Company && formik.values.documentSettings.hideFromEmployee) {
      formik.setFieldValue("documentSettings.hideFromEmployee", false);
    }
    if (formik.values.viewType === AdminDocumentView.Company && formik.values.documentSettings.private) {
      formik.setFieldValue("documentSettings.private", false);
    }
  }, [
    formik.values.viewType,
    formik.values.documentSettings.isPinned,
    formik.values.documentSettings.hideFromEmployee,
    formik.values.documentSettings.private,
    managerOrAdmin,
    formik
  ]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: getTitle(formType, document) }),
    formType === FormTypeEnum.upload && !Boolean(document || currentUserId) && hasDocumentsAllScope && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "viewType",
        label: polyglot.t("DocumentFormModal.documentFor"),
        options: newDocViewTypeOptions,
        value: formik.values.viewType,
        onChange: formik.handleChange,
        compareValue: formik.values.viewType,
        error: !!formik.errors.viewType && formik.touched.viewType,
        helperText: formik.touched.viewType && formik.errors.viewType,
        disabled: Boolean(document || currentUserId)
      }
    ),
    !currentUserId ? /* @__PURE__ */ jsx(Box, { children: formik.values.viewType === AdminDocumentView.Company && hasDocumentsAllScope ? /* @__PURE__ */ jsx(
      MemoizedUserSelect,
      {
        label: polyglot.t("DocumentFormModal.belongs"),
        value: [],
        onChange: (_, filterValue, customRule) => {
          formik.setFieldValue("membersRule", filterValue != null ? filterValue : UserSelectFiltersOptions.None);
          formik.setFieldValue("customRule", customRule != null ? customRule : null);
          formik.setFieldValue("userId", null);
        },
        excludeSpecific: true,
        initialFilterValue: (_n = formik.values.membersRule) != null ? _n : UserSelectFiltersOptions.None,
        ruleString: formik.values.customRule,
        reach
      }
    ) : /* @__PURE__ */ jsx(
      SingleUserSelect,
      {
        name: "userId",
        options: reach,
        onChange: (_, x) => {
          formik.setFieldValue("userId", (x == null ? void 0 : x.value) ? Number(x.value) : null);
          formik.setFieldValue("membersRule", UserSelectFiltersOptions.SelectSpecific);
          formik.setFieldValue("customRule", null);
        },
        value: formik.values.userId,
        label: polyglot.t("DocumentFormModal.belongs"),
        error: formik.touched.userId && Boolean(formik.errors.userId),
        helperText: formik.touched.userId && formik.errors.userId
      }
    ) }) : user.userId !== currentUserId ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: polyglot.t("DocumentFormModal.belongs") }),
      /* @__PURE__ */ jsx(UserCell, { userId: currentUserId })
    ] }) : /* @__PURE__ */ jsx(Fragment, {}),
    /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "typeId",
        label: polyglot.t("General.type"),
        options: filteredTypes,
        value: (filteredTypes == null ? void 0 : filteredTypes.find(({ value }) => value === formik.values.typeId)) || null,
        compareValue: (_o = formik.values.typeId) != null ? _o : "",
        onChange: (_, x) => {
          var _a2, _b2;
          formik.setFieldValue("typeId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          if ((x == null ? void 0 : x.value) && formik.values.userId && getCachedUserById) {
            const type = filteredTypes == null ? void 0 : filteredTypes.find(({ value }) => value === (x == null ? void 0 : x.value));
            const user2 = getCachedUserById(formik.values.userId);
            const placeholderName = `${type == null ? void 0 : type.label} - ${(_b2 = user2 == null ? void 0 : user2.displayName) != null ? _b2 : user2 == null ? void 0 : user2.firstName}`;
            formik.setFieldValue("name", placeholderName);
          }
        },
        error: !!formik.errors.typeId && formik.touched.typeId,
        helperText: formik.touched.typeId && formik.errors.typeId,
        editList: {
          handler: () => setNewTypeDrawerIsOpen(true),
          isHidden: !hasDocumentsAllScope
        }
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("General.name"),
        multiline: true,
        value: formik.values.name,
        onChange: formik.handleChange,
        error: formik.touched.name && !!formik.errors.name,
        helperText: (_p = formik.touched.name && formik.errors.name) != null ? _p : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "note",
        label: polyglot.t("General.note"),
        multiline: true,
        value: formik.values.note,
        onChange: formik.handleChange,
        error: formik.touched.note && !!formik.errors.note,
        helperText: (_q = formik.touched.note && formik.errors.note) != null ? _q : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.sm }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("DocumentFormModal.advancedSettings") }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("DocumentFormModal.advancedSettingsHelperText") })
    ] }),
    formik.values.viewType === AdminDocumentView.Company && /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "documentSettings.isPinned",
        label: polyglot.t("DocumentFormModal.isPinned"),
        checked: formik.values.documentSettings.isPinned,
        onChange: () => {
          formik.setFieldValue("documentSettings.isPinned", !formik.values.documentSettings.isPinned);
        }
      }
    ),
    managerOrAdmin && formik.values.viewType !== AdminDocumentView.Company && /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "documentSettings.hideFromEmployee",
        label: polyglot.t("DocumentFormModal.visibilitySettings"),
        checked: formik.values.documentSettings.hideFromEmployee,
        onChange: () => {
          formik.setFieldValue(
            "documentSettings.hideFromEmployee",
            !formik.values.documentSettings.hideFromEmployee
          );
        }
      }
    ),
    formik.values.viewType !== AdminDocumentView.Company && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "documentSettings.private",
          label: polyglot.t("DocumentFormModal.privateDocument"),
          checked: formik.values.documentSettings.private,
          onChange: () => {
            formik.setFieldValue("documentSettings.private", !formik.values.documentSettings.private);
          }
        }
      ),
      /* @__PURE__ */ jsx(StyledTooltip, { title: polyglot.t("DocumentFormModal.privateHelperText"), children: /* @__PURE__ */ jsx(InfoGrey, __spreadProps(__spreadValues({}, iconSize), { "aria-label": polyglot.t("DocumentFormModal.privateHelperText") })) })
    ] }),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "documentSettings.expiryDate",
        label: polyglot.t("DocumentFormModal.expirySettings"),
        checked: Boolean(formik.values.documentSettings.expiryDate),
        onChange: (e) => {
          const target = e.target;
          if (target.checked) formik.setFieldValue("documentSettings.expiryDate", dayjs());
          else formik.setFieldValue("documentSettings.expiryDate", null);
        }
      }
    ),
    Boolean(formik.values.documentSettings.expiryDate) && /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        label: polyglot.t("DocumentFormModal.expiryDate"),
        name: "documentSettings.expiryDate",
        value: formik.values.documentSettings.expiryDate,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("documentSettings.expiryDate", value);
          }
        },
        error: void 0,
        helperText: void 0
      }
    ),
    formType === FormTypeEnum.upload && /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: "20px"
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.sm }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "title4", children: polyglot.t("DocumentFormModal.upload") }),
            /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: polyglot.t("DocumentFormModal.uploadDescription") })
          ] }),
          formik.values.fileUuid ? /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                alignItems: "center",
                gap: spacing.s2,
                justifyContent: "space-between",
                width: "100%"
              },
              children: [
                /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1, maxWidth: "70%" }, children: [
                  /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(UploadedDocIcon, __spreadValues({}, iconSize)) }),
                  /* @__PURE__ */ jsx(
                    Typography,
                    {
                      variant: "caption",
                      sx: {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%"
                      },
                      children: document ? getFileName(document) : polyglot.t("DocumentFormModal.uploaded")
                    }
                  )
                ] }),
                (!document || !document.contractId) && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1, maxWidth: "30%" }, children: /* @__PURE__ */ jsx(
                  IconButton,
                  {
                    sizeVariant: "small",
                    colorVariant: "secondary",
                    onClick: () => __async(void 0, null, function* () {
                      try {
                        if (document) {
                          yield DocumentNewAPI.deleteFileByDocumentId(document.id);
                        } else if (formik.values.fileUuid) {
                          yield DocumentNewAPI.deleteFileByUuid(formik.values.fileUuid);
                        }
                        formik.setFieldValue("fileUuid", null);
                      } catch (error) {
                        showMessage(nestErrorMessage(error), "error");
                      }
                    }),
                    title: polyglot.t("General.remove"),
                    children: /* @__PURE__ */ jsx(TrashIcon, {})
                  }
                ) })
              ]
            }
          ) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
            /* @__PURE__ */ jsx(
              UploadInput,
              {
                onChange: (response) => {
                  formik.setFieldValue("fileUuid", response == null ? void 0 : response.uuid);
                }
              }
            ),
            Boolean(formik.touched.fileUuid && !!formik.errors.fileUuid) && /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "RedDark", children: formik.errors.fileUuid })
          ] })
        ]
      }
    ),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: newTypeDrawerIsOpen, setIsOpen: setNewTypeDrawerIsOpen, children: /* @__PURE__ */ jsx(
      DocumentSettingsNewTypeForm,
      {
        initialValues: void 0,
        onClose: () => {
          setNewTypeDrawerIsOpen(false);
        },
        refreshDocumentTypes: () => refreshAllTypes == null ? void 0 : refreshAllTypes(),
        mode: "create"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxDrawerSx), { pt: spacing.s2 }), children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
