"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { USER_OFFBOARDING_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { BasicServerTable, DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { sortDate, sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { OffboardingDrawer } from "@/v2/feature/offboarding/components/offboarding-drawer.component";
import { OffboardingAPI } from "@/v2/feature/offboarding/offboarding.api";
import {
  ProgressStatus,
  TimeSegment
} from "@/v2/feature/offboarding/offboarding.interface";
import { getStatusToIcon } from "@/v2/feature/offboarding/offboarding.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useJune } from "@/v2/infrastructure/june/june.hook";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const OffboardingPage = () => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const { trackPage } = useJune();
  const routerHistory = useHistory();
  const [sorting, setSorting] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState(TimeSegment.All);
  const [userLoading, setUserLoading] = useState(false);
  const [offboardingModalOpen, setOffboardingModalOpen] = useState(false);
  const [users, setUsers] = useState(void 0);
  const [showMessage] = useMessage();
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [totalPages, setTotalpages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    trackPage("Offboarding admin page");
  }, []);
  const getOffboardingUsers = useCallback(() => __async(void 0, null, function* () {
    setUserLoading(true);
    try {
      const { users: users2, pagination: paginationInfo } = yield OffboardingAPI.getOffboardingListing({
        page: pageIndex.toString(),
        pageSize: pageSize.toString(),
        searchQuery: searchInput,
        filter: filterString
      });
      const { totalPages: totalPages2, totalCount } = paginationInfo;
      setTotalpages(totalPages2);
      setTotalItems(totalCount);
      setUsers(users2);
    } catch (error) {
      showMessage(
        polyglot.t("OffboardingPage.errorMessages.fetch", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setUserLoading(false);
    }
  }), [polyglot, pageIndex, pageSize, searchInput, filterString, showMessage]);
  useEffect(() => {
    getOffboardingUsers();
  }, [getOffboardingUsers]);
  const currentUserColumn = useMemo(
    () => [
      {
        header: () => polyglot.t("OffboardingPage.name"),
        accessorFn: (row) => row,
        id: "firstName",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => original.userId ? /* @__PURE__ */ jsx(UserCell, { userId: original.userId }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => polyglot.t("OffboardingPage.offboardedBy"),
        accessorFn: (row) => row,
        id: "offboardedBy",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return item.offboardedBy ? (_a = getCachedUserById(item.offboardedBy)) == null ? void 0 : _a.displayName : "";
        }),
        cell: ({ row: { original } }) => original.offboardedBy ? /* @__PURE__ */ jsx(UserCell, { userId: original.offboardedBy }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => polyglot.t("OffboardingPage.terminationDate"),
        accessorFn: (row) => row,
        id: "terminationDate",
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => new Date(item == null ? void 0 : item.terminationDate)),
        cell: ({ row: { original } }) => original.terminationDate ? /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: getDateString(original.terminationDate) }) }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => polyglot.t("OffboardingPage.deactivationDate"),
        accessorFn: (row) => row,
        id: "deactivationDate",
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => (item == null ? void 0 : item.deactivationDate) && new Date(item.deactivationDate)),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.deactivationDate ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: getDateString(original.deactivationDate) }) : getStatusToIcon(ProgressStatus.Missing) })
      }
    ],
    [polyglot, getCachedUserById]
  );
  const handleRowClick = useCallback(
    (row) => {
      routerHistory.push({ pathname: generatePath(USER_OFFBOARDING_ROUTE, { userId: row.original.userId }) });
    },
    [routerHistory]
  );
  const debouncedFilter = useDebouncedCallback((filterString2) => {
    try {
      setFilterString(filterString2);
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (_error) {
      showMessage(polyglot.t("OffboardingPage.errorMessages.filter"), "error");
    }
  }, 500);
  const handleFilter = useCallback((value) => debouncedFilter.callback(value), [debouncedFilter]);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: polyglot.t("OffboardingPage.offboarding"),
        actions: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => setOffboardingModalOpen(true), sizeVariant: "small", colorVariant: "primary", children: polyglot.t("OffboardingPage.offboard") }),
        showAction: true
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, sx: { overflow: "hidden" }, children: [
      /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(
            TabFilterButtons,
            {
              filters: [
                { name: TimeSegment.All, value: TimeSegment.All },
                { name: TimeSegment.Upcoming, value: TimeSegment.Upcoming },
                { name: TimeSegment.Past, value: TimeSegment.Past }
              ],
              filterValue: filterString,
              setFilterValue: handleFilter
            }
          ),
          /* @__PURE__ */ jsx(
            TableSearch,
            {
              query: searchInput,
              handleChange: (e) => {
                var _a, _b;
                setSearchInput((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
                setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
              }
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
          BasicServerTable,
          {
            rowData: users ? [...users] : [],
            columnData: currentUserColumn,
            loading: userLoading,
            rowClick: handleRowClick,
            sorting,
            setSorting,
            pagination,
            setPagination,
            totalPages,
            totalItems,
            stickyHeader: true
          }
        ) })
      ] }),
      /* @__PURE__ */ jsx(OffboardingDrawer, { userId: void 0, isOpen: offboardingModalOpen, setIsOpen: setOffboardingModalOpen })
    ] })
  ] });
};
