"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { ManageEmployeeMembershipDrawer } from "@v2/feature/benefits/subfeature/pension/components/manage-employee-membership-drawer.component";
import { PensionAPI } from "@v2/feature/benefits/subfeature/pension/pension.api";
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel
} from "@v2/feature/benefits/subfeature/pension/pension.util";
import { PayrollAPI } from "@v2/feature/payroll/payroll.api";
import { useUserProfileConfig } from "@v2/feature/user/features/user-profile/user-profile.hook";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { dateAPItoDisplay } from "@v2/util/date-format.util";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { CurrencyCell } from "@/v2/components/table/currency-cell.component";
import { themeColors } from "@/v2/styles/colors.styles";
const PastContributionsList = ({ payruns }) => {
  const currentUserColumn = useMemo(
    () => [
      {
        header: () => "Past contributions",
        id: "paymentDate",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsx("div", { children: (_b = dateAPItoDisplay((_a = original == null ? void 0 : original.metadata) == null ? void 0 : _a.paymentDate)) != null ? _b : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Tax year",
        id: "taxYear",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsx("div", { children: (_b = (_a = original == null ? void 0 : original.metadata) == null ? void 0 : _a.taxYear) != null ? _b : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Period",
        id: "period",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsx("div", { children: (_b = (_a = original == null ? void 0 : original.metadata) == null ? void 0 : _a.period) != null ? _b : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Gross",
        id: "gross",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(CurrencyCell, { value: (_a = original == null ? void 0 : original.metadata) == null ? void 0 : _a.gross });
        }
      },
      {
        header: () => "Employee",
        id: "employee",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(CurrencyCell, { value: (_a = original == null ? void 0 : original.metadata) == null ? void 0 : _a.employeePensionContribution });
        }
      },
      {
        header: () => "Employer",
        id: "employer",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(CurrencyCell, { value: (_a = original == null ? void 0 : original.metadata) == null ? void 0 : _a.employerPensionContribution });
        }
      },
      {
        header: () => "Total Contributions",
        id: "contributions",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsx(
            CurrencyCell,
            {
              value: ((_a = original == null ? void 0 : original.metadata) == null ? void 0 : _a.employeePensionContribution) + ((_b = original == null ? void 0 : original.metadata) == null ? void 0 : _b.employerPensionContribution)
            }
          );
        }
      }
    ],
    []
  );
  return /* @__PURE__ */ jsx(BasicTable, { rowData: [...payruns], columnData: currentUserColumn });
};
export const PensionMe = ({
  userId,
  employeePension,
  onEmployeeUpdate,
  isEnrolDrawerOpen,
  setIsEnrolDrawerOpen,
  loading
}) => {
  var _a, _b, _c;
  const [payruns, setPayruns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { hasScopes, getScopesContext } = useScopes();
  const [state] = useContext(GlobalContext);
  const hasPensionAllScope = hasScopes(["pension:all"], getScopesContext(state.user));
  const hasPensionWriteScope = hasScopes(["pension"], getScopesContext(state.user));
  const [employeeMembership, setEmployeeMembership] = useState(null);
  const [pensionSchemes, setPensionSchemes] = useState([]);
  const { userOverviewConfig } = useUserProfileConfig(userId);
  const [showMessage] = useMessage();
  const refresh = useCallback(() => __async(void 0, null, function* () {
    setIsLoading(true);
    try {
      const membership = yield PensionAPI.getEmployeePension(userId);
      setEmployeeMembership(membership != null ? membership : null);
      if (!userOverviewConfig.companyInPension || !userOverviewConfig.inPension) {
        return;
      }
      const payruns2 = yield PayrollAPI.getUserPayruns(userId);
      setPayruns(payruns2);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsLoading(false);
    }
  }), [userId, userOverviewConfig.companyInPension, userOverviewConfig.inPension, showMessage]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        if (hasPensionAllScope) {
          const pensionSchemes2 = yield PensionAPI.getCompanyPensionSchemes();
          setPensionSchemes(pensionSchemes2);
        }
      } catch (error) {
        showMessage(`Could not get pension schemes. ${nestErrorMessage(error)}`, "error");
      }
    }))();
  }, [hasPensionAllScope, showMessage]);
  const payrunsAvailable = payruns && Boolean(payruns.length);
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: isLoading || loading, children: [
    isEnrolDrawerOpen && employeeMembership && /* @__PURE__ */ jsx(
      ManageEmployeeMembershipDrawer,
      {
        isOpen: isEnrolDrawerOpen,
        setIsOpen: setIsEnrolDrawerOpen,
        userPension: employeeMembership,
        setUserPension: setEmployeeMembership,
        pensionSchemes,
        refresh: () => __async(void 0, null, function* () {
          yield Promise.all([refresh(), onEmployeeUpdate()]);
        })
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap40 }, children: [
      userOverviewConfig.inPension && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap5, mr: spacing.mr20 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Pension Scheme" }),
        (employeePension == null ? void 0 : employeePension.pensionProviderName) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap5, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(Box, { children: getPensionLogoByProviderName(employeePension.pensionProviderName, 30) }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { whiteSpace: "nowrap", color: themeColors.DarkGrey }), children: (_a = PensionProvidersValueToLabel[employeePension.pensionProviderName]) != null ? _a : employeePension.pensionProviderName })
        ] }) : /* @__PURE__ */ jsx(EmptyCell, {})
      ] }),
      userOverviewConfig.inPension && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap5, mx: spacing.mx20 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Employee contribution" }),
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
          (_b = employeePension == null ? void 0 : employeePension.employeeContribution) != null ? _b : "??",
          "%"
        ] })
      ] }),
      userOverviewConfig.inPension && hasPensionWriteScope && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap5, ml: spacing.ml20 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.caption), { color: themeColors.DarkGrey }), children: "Employer contribution" }),
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: [
          (_c = employeePension == null ? void 0 : employeePension.employerContribution) != null ? _c : "??",
          "%"
        ] })
      ] })
    ] }),
    userOverviewConfig.companyInPension && payrunsAvailable && /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 }, children: /* @__PURE__ */ jsx(PastContributionsList, { payruns }) })
  ] });
};
