"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { Box, IconButton } from "@mui/material";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";
import * as RNEA from "fp-ts/ReadonlyNonEmptyArray";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as UploadDoc } from "@/images/documents/UploadedDoc.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as CheckCircle } from "@/images/side-bar-icons/CheckCircle.svg";
import { ReactComponent as ErrorIcon } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { IconTextCell } from "@/v2/components/table/icon-text-cell.component";
import { sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { MultiUserAvatars } from "@/v2/feature/documents/components/document-table.component";
import { DocumentUploadStatus } from "@/v2/feature/documents/documents.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableSecondaryIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize, selectColumnSize } from "@/v2/styles/table.styles";
import { toTitleCase } from "@/v2/util/string.util";
const HIDE_ACTION_STATUSES = [DocumentUploadStatus.UPLOADED, DocumentUploadStatus.PROCESSING];
const customRowStyle = (row) => {
  var _a, _b;
  const hasFailed = ((_b = (_a = row.original) == null ? void 0 : _a.dzProps) == null ? void 0 : _b.state) === DocumentUploadStatus.FAILED;
  if (hasFailed) return { color: themeColors.RedDark, backgroundColor: themeColors.RedSuperLight };
  return {};
};
export const DocumentBulkUploadResultTable = ({
  documents,
  documentTypes,
  onEditClick,
  onDelete,
  uploadResult,
  setSelectionModel,
  selectionModel
}) => {
  const [state] = useContext(GlobalContext);
  const mapZeltDocumentToDZFile = (document) => {
    var _a, _b;
    if (!document.dzProps) return;
    if (((_a = document == null ? void 0 : document.dzProps) == null ? void 0 : _a.meta) && ((_b = document == null ? void 0 : document.dzProps) == null ? void 0 : _b.file) && document.dzProps.remove)
      return {
        meta: document.dzProps.meta,
        file: document.dzProps.file,
        cancel: () => {
        },
        restart: () => {
        },
        remove: document.dzProps.remove
      };
  };
  const columnData = useMemo(() => {
    const errorForDocument = (row) => {
      var _a, _b, _c;
      return (_c = (_b = (_a = uploadResult == null ? void 0 : uploadResult.errorDetails) == null ? void 0 : _a.find((error) => error.documentId === row.fileUuid)) == null ? void 0 : _b.errorMessage) != null ? _c : "";
    };
    const belongsToColumnLogic = (row) => {
      var _a;
      return pipe(
        (_a = row.belongsTo) != null ? _a : [],
        RNEA.fromReadonlyArray,
        O.fold(
          () => row.canAllEmployeesSee ? /* @__PURE__ */ jsx("span", { children: "Everyone" }) : /* @__PURE__ */ jsx("span", { children: "Unassigned" }),
          (users) => users.length === 1 ? /* @__PURE__ */ jsx(UserCell, { userId: users[0].User.userId }) : /* @__PURE__ */ jsx(MultiUserAvatars, { currentUser: state.user, users, size: "xxsmall" })
        )
      );
    };
    return [
      {
        id: "select",
        enableSorting: false,
        minSize: selectColumnSize,
        size: selectColumnSize,
        header: () => {
          const notUploadedDocs = documents.filter(
            (document) => {
              var _a;
              return ((_a = document == null ? void 0 : document.dzProps) == null ? void 0 : _a.state) !== DocumentUploadStatus.UPLOADED;
            }
          );
          const allSelected = selectionModel.length > 0 && selectionModel.length === notUploadedDocs.length;
          return /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: void 0,
              name: "allSelected",
              checked: allSelected,
              value: "allSelected",
              onChange: () => {
                if (!allSelected) {
                  const selectedValues = notUploadedDocs.map((document) => {
                    var _a, _b;
                    return (_b = (_a = document == null ? void 0 : document.dzProps) == null ? void 0 : _a.meta) == null ? void 0 : _b.id;
                  });
                  setSelectionModel(selectedValues);
                } else setSelectionModel([]);
              }
            }
          );
        },
        cell: ({ row: { original } }) => {
          var _a, _b, _c, _d, _e, _f, _g;
          const isChecked = ((_b = (_a = original == null ? void 0 : original.dzProps) == null ? void 0 : _a.meta) == null ? void 0 : _b.id) ? selectionModel.includes(original.dzProps.meta.id.toString()) : false;
          return ((_c = original == null ? void 0 : original.dzProps) == null ? void 0 : _c.state) !== DocumentUploadStatus.UPLOADED ? /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: void 0,
              name: (_d = original.name) != null ? _d : "",
              checked: isChecked,
              value: (_g = (_f = (_e = original == null ? void 0 : original.dzProps) == null ? void 0 : _e.meta) == null ? void 0 : _f.id.toString()) != null ? _g : "",
              onChange: () => {
                var _a2, _b2;
                const documentId = (_b2 = (_a2 = original == null ? void 0 : original.dzProps) == null ? void 0 : _a2.meta) == null ? void 0 : _b2.id;
                const finalArray = documentId && isChecked ? selectionModel.filter((sm) => sm !== documentId) : [...selectionModel, documentId];
                setSelectionModel(finalArray);
              }
            }
          ) : null;
        }
      },
      {
        header: () => "Document name",
        accessorFn: (row) => row,
        id: "name",
        enableSorting: false,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        maxSize: 350,
        minSize: 200,
        cell: (info) => {
          const rowValue = info.getValue();
          return /* @__PURE__ */ jsx(
            IconTextCell,
            {
              icon: /* @__PURE__ */ jsx(UploadDoc, { width: 24, height: 30 }),
              text: rowValue.name,
              textSx: themeFonts.caption
            }
          );
        }
      },
      {
        header: () => "Type",
        accessorFn: (row) => row,
        id: "type",
        enableSorting: false,
        cell: (info) => {
          const rowValue = info.getValue();
          return /* @__PURE__ */ jsx("div", { children: rowValue.type });
        }
      },
      {
        header: () => "Belongs To",
        accessorFn: (row) => row,
        id: "avatar",
        enableSorting: false,
        cell: (info) => {
          const row = info.getValue();
          return row.belongsTo ? /* @__PURE__ */ jsx(Fragment, { children: belongsToColumnLogic(row) }) : /* @__PURE__ */ jsx(Fragment, {});
        }
      },
      {
        header: () => "Status",
        accessorFn: (row) => row,
        id: "documentStatus",
        enableSorting: false,
        cell: (info) => {
          var _a, _b, _c, _d, _e;
          const row = info.getValue();
          return /* @__PURE__ */ jsx(Fragment, { children: ((_a = row.dzProps) == null ? void 0 : _a.state) === DocumentUploadStatus.FAILED ? /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(ErrorIcon, __spreadValues({}, iconSize)),
            "\xA0Failed - ",
            errorForDocument(row)
          ] }) : ((_b = row.dzProps) == null ? void 0 : _b.state) === DocumentUploadStatus.PROCESSING ? /* @__PURE__ */ jsx(Fragment, { children: "Processing" }) : ((_c = row.dzProps) == null ? void 0 : _c.state) && ![
            DocumentUploadStatus.PROCESSING,
            DocumentUploadStatus.FAILED,
            DocumentUploadStatus.UPLOADED,
            DocumentUploadStatus.MAPPED
          ].includes(row == null ? void 0 : row.dzProps.state) ? toTitleCase(row.dzProps.state) : ((_d = row == null ? void 0 : row.dzProps) == null ? void 0 : _d.state) === DocumentUploadStatus.UPLOADED ? /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(CheckCircle, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
            "\xA0",
            toTitleCase(row.dzProps.state)
          ] }) : ((_e = row == null ? void 0 : row.dzProps) == null ? void 0 : _e.state) === DocumentUploadStatus.MAPPED ? "Pending" : "" });
        }
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        cell: (info) => {
          var _a, _b, _c, _d, _e, _f, _g, _h;
          return /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
                gap: spacing.g10
              },
              children: [
                ((_b = (_a = info == null ? void 0 : info.getValue()) == null ? void 0 : _a.dzProps) == null ? void 0 : _b.state) && !HIDE_ACTION_STATUSES.includes((_d = (_c = info.getValue()) == null ? void 0 : _c.dzProps) == null ? void 0 : _d.state) && /* @__PURE__ */ jsx(
                  IconButton,
                  {
                    sx: tableSecondaryIconButtonSx,
                    onClick: () => {
                      onDelete(mapZeltDocumentToDZFile(info.getValue()));
                    },
                    children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
                  }
                ),
                ((_f = (_e = info == null ? void 0 : info.getValue()) == null ? void 0 : _e.dzProps) == null ? void 0 : _f.state) && !HIDE_ACTION_STATUSES.includes((_h = (_g = info.getValue()) == null ? void 0 : _g.dzProps) == null ? void 0 : _h.state) && /* @__PURE__ */ jsx(
                  IconButton,
                  {
                    sx: tableSecondaryIconButtonSx,
                    onClick: () => {
                      onEditClick(info.getValue());
                    },
                    children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
                  }
                )
              ]
            }
          );
        }
      }
    ];
  }, [documents, onDelete, onEditClick, selectionModel, setSelectionModel, state.user, uploadResult == null ? void 0 : uploadResult.errorDetails]);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    BasicTable,
    {
      rowData: [...documents],
      columnData,
      loading: false,
      customRowStyle,
      fixedSecondColumn: true
    }
  ) });
};
