"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SurveyQuestionBankEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-question-bank.api";
import { SurveyQuestionBankModal } from "@/v2/feature/growth/surveys/features/survey-question-bank/components/survey-question-bank-modal.component";
import { SurveyQuestionBankViewModal } from "@/v2/feature/growth/surveys/features/survey-question-bank/components/survey-question-bank-view-modal.component";
import { PayrollLogoZelt } from "@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { replaceParamsInQuestionText, stripHtml } from "@/v2/util/string.util";
export const SurveyQuestionBankPage = () => {
  var _a, _b;
  const [question, setQuestion] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const company_name = (_b = (_a = user == null ? void 0 : user.company) == null ? void 0 : _a.name) != null ? _b : void 0;
  const {
    data: allQuestions,
    mutate: refreshQuestions,
    isLoading: questionsLoading
  } = useApiClient(SurveyQuestionBankEndpoints.getSurveyQuestionFromBank(searchInput), { suspense: false });
  const cleanedQuestions = useMemo(() => {
    return allQuestions ? allQuestions.map((eachQuestion) => {
      var _a2;
      return __spreadProps(__spreadValues({}, eachQuestion), {
        questionText: (_a2 = replaceParamsInQuestionText(stripHtml(eachQuestion.questionText), { company_name })) != null ? _a2 : ""
      });
    }) : [];
  }, [allQuestions, company_name]);
  const tableColumns = useMemo(
    () => [
      {
        header: () => "Question text",
        accessorFn: (row) => stripHtml(row.questionText),
        id: "questionText",
        enableSorting: false,
        cell: ({
          row: {
            original: { questionText }
          }
        }) => questionText ? /* @__PURE__ */ jsxs("div", { children: [
          stripHtml(questionText),
          " "
        ] }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        minSize: 120
      },
      // {
      //   header: () => 'Type',
      //   accessorFn: (row) => row,
      //   id: 'type',
      //   enableSorting: false,
      //   cell: ({
      //     row: {
      //       original: { type },
      //     },
      //   }) => (type ? <div>{type}</div> : <EmptyCell />),
      // },
      {
        header: () => "Factors",
        accessorFn: (row) => row.factor,
        id: "factor",
        enableSorting: false,
        cell: ({
          row: {
            original: { factor }
          }
        }) => factor ? /* @__PURE__ */ jsx("div", { children: factor }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      // {
      //   header: () => 'Surveyer select',
      //   accessorFn: (row) => row,
      //   id: 'reviewerSelect',
      //   enableSorting: false,
      //   cell: ({
      //     row: {
      //       original: { reviewerSelect },
      //     },
      //   }) => (reviewerSelect ? <div>{reviewerSelect.toString()}</div> : <EmptyCell />),
      // },
      {
        header: () => "Created by",
        accessorFn: (row) => row,
        id: "createdby",
        enableSorting: false,
        cell: ({
          row: {
            original: { createdBy, companyId }
          }
        }) => /* @__PURE__ */ jsx("div", { children: companyId === null ? /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g8 }, children: [
          /* @__PURE__ */ jsx(PayrollLogoZelt, { height: "1.2em", width: "1.2em", padding: ".25em" }),
          "Zelt"
        ] }) : createdBy ? /* @__PURE__ */ jsx(UserCell, { userId: createdBy }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }) => original.companyId && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            colorVariant: "light",
            sizeVariant: "small",
            onClick: () => {
              setQuestion(original);
              setIsEditOpen(true);
            },
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) })
      }
    ],
    []
  );
  const handleRowClick = useCallback((row) => {
    setIsViewOpen(true);
    setQuestion(row.original);
  }, []);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Question library" }),
        showAction: true,
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsEditOpen(true), children: "New question" })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
      /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.m16 }, children: /* @__PURE__ */ jsx(
        TableSearch,
        {
          query: searchInput,
          handleChange: (e) => {
            setSearchInput(e.target.value);
          }
        }
      ) }),
      /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: cleanedQuestions != null ? cleanedQuestions : [],
          columnData: tableColumns,
          loading: questionsLoading,
          rowClick: handleRowClick
        }
      ),
      /* @__PURE__ */ jsx(
        SurveyQuestionBankViewModal,
        {
          setIsOpen: setIsViewOpen,
          isOpen: isViewOpen,
          setIsEditOpen,
          question,
          onClose: () => {
            setIsViewOpen(false);
            setQuestion(null);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        SurveyQuestionBankModal,
        {
          setIsOpen: setIsEditOpen,
          isOpen: isEditOpen,
          question,
          onClose: () => {
            setIsViewOpen(false);
            setIsEditOpen(false);
            setTimeout(() => {
              setQuestion(null);
            }, 500);
          },
          refresh: () => __async(void 0, null, function* () {
            yield refreshQuestions == null ? void 0 : refreshQuestions();
          })
        }
      )
    ] })
  ] });
};
