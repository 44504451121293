"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { BenefitsSettingsPageSection } from "@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component";
import { ManageWorkerGroupDrawer } from "@v2/feature/benefits/subfeature/pension/components/manage-pension-worker-group-drawer.component";
import { PensionEndpoints } from "@v2/feature/benefits/subfeature/pension/pension.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
export const PensionSettingsWorkerGroupsPage = ({ pensionScheme, refreshPension }) => {
  var _a;
  const { data: providerEmployees, isLoading } = useApiClient(
    PensionEndpoints.fetchPensionSchemeEmployeesPension(pensionScheme.id),
    { suspense: false }
  );
  const { polyglot } = usePolyglot();
  const [isWorkerGroupDrawerOpen, setIsWorkerGroupDrawerOpen] = useState(false);
  const [selectedWorkerGroup, setSelectedWorkerGroup] = useState(null);
  const workerGroupColumns = useMemo(
    () => [
      {
        header: () => polyglot.t("General.name"),
        id: "name",
        enableSorting: false,
        accessorFn: (row) => row.name,
        cell: ({ row: { original } }) => {
          var _a2;
          return /* @__PURE__ */ jsx("div", { children: (_a2 = original.name) != null ? _a2 : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => polyglot.t("PensionModule.employeesEnrolled"),
        id: "display",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a2;
          return /* @__PURE__ */ jsx("div", { children: (_a2 = providerEmployees == null ? void 0 : providerEmployees.filter((employee) => employee.workerGroupId === original.externalId).length) != null ? _a2 : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => polyglot.t("BenefitModule.employerContribution"),
        id: "employerContribution",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => original.employerContribution ? /* @__PURE__ */ jsxs("div", { children: [
          original.employerContribution,
          "%"
        ] }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => polyglot.t("BenefitModule.employeeContribution"),
        id: "employeeContribution",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => original.employeeContribution ? /* @__PURE__ */ jsxs("div", { children: [
          original.employeeContribution,
          "%"
        ] }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "",
        id: "action",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "end" }, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            "aria-label": "settings",
            onClick: () => {
              setSelectedWorkerGroup(original);
              setIsWorkerGroupDrawerOpen(true);
            },
            sx: tableIconButtonSx,
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) })
      }
    ],
    [polyglot, providerEmployees]
  );
  return /* @__PURE__ */ jsxs(
    BenefitsSettingsPageSection,
    {
      title: polyglot.t("PensionModule.workerGroups"),
      buttons: [
        /* @__PURE__ */ jsx(
          IconButton,
          {
            onClick: () => {
              setSelectedWorkerGroup(null);
              setIsWorkerGroupDrawerOpen(true);
            },
            title: polyglot.t("General.add"),
            sx: tableIconButtonSx,
            children: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize))
          }
        )
      ],
      children: [
        /* @__PURE__ */ jsx(BasicTable, { rowData: (_a = pensionScheme.workerGroups) != null ? _a : [], columnData: workerGroupColumns, loading: isLoading }),
        /* @__PURE__ */ jsx(
          ManageWorkerGroupDrawer,
          {
            isOpen: isWorkerGroupDrawerOpen,
            setIsOpen: setIsWorkerGroupDrawerOpen,
            refresh: refreshPension,
            workerGroup: selectedWorkerGroup,
            pensionSchemeId: pensionScheme.id
          }
        )
      ]
    }
  );
};
