"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo } from "react";
import { Redirect, Route, Switch, generatePath, useHistory, useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import {
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_HMRC_ROUTE,
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ACCOUNTING_ROUTE,
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_PAYCODES_ROUTE,
  SETTINGS_MONEY_SALARY_ROUTE,
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_GENERAL_ROUTE,
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_INTEGRATIONS_ROUTE,
  DASHBOARD_ROUTE
} from "@/lib/routes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { GlobalPayrollPayCodes } from "@/v2/feature/payroll/features/payroll-global/global-payroll-paycodes/global-payroll-paycodes.page";
import { PayrollCompanySettingsAccounting } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-settings-accounting.page";
import { PayrollCompanySettingsGeneral } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-settings-general.page";
import { PayrollCompanySettingsHMRC } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-settings-hmrc.page";
import { PayrollCompanySettingsIntegrations } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-settings-integrations.page";
import { PayrollCompanySettingsPayCodes } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-settings-paycodes.page";
import { PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
const PageConfigUKPayroll = (payrollId) => [
  {
    title: "",
    stub: "",
    isHidden: false,
    hasChildren: true,
    subItems: [
      {
        title: "General",
        stub: "general",
        path: generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_GENERAL_ROUTE, { payrollId }),
        hasChildren: false,
        isHidden: false
      },
      {
        title: "HMRC",
        stub: "allowance",
        path: generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_HMRC_ROUTE, { payrollId }),
        hasChildren: false,
        isHidden: false
      },
      {
        title: "Accounting",
        stub: "accounting",
        path: generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ACCOUNTING_ROUTE, { payrollId }),
        hasChildren: false,
        isHidden: false
      },
      {
        title: "Pay codes",
        stub: "pay-codes",
        path: generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_PAYCODES_ROUTE, { payrollId }),
        hasChildren: false,
        isHidden: false
      }
    ]
  }
];
const PageConfigGlobalPayroll = (payrollId) => [
  {
    title: "",
    stub: "",
    isHidden: false,
    hasChildren: true,
    subItems: [
      {
        title: "General",
        stub: "general",
        path: generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_GENERAL_ROUTE, { payrollId }),
        hasChildren: false,
        isHidden: false
      },
      {
        title: "Pay codes",
        stub: "pay-codes",
        path: generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_PAYCODES_ROUTE, { payrollId }),
        hasChildren: false,
        isHidden: false
      },
      {
        title: "Integrations",
        stub: "integrations",
        path: generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_INTEGRATIONS_ROUTE, { payrollId }),
        hasChildren: false,
        isHidden: false
      }
    ]
  }
];
export const PayrollCompanySettingsRouter = () => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const payrollId = Number(useParams().payrollId);
  const { data: payrolls, mutate: refreshPayrolls } = useApiClient(PayrollEndpoints.getCompanyPayrolls(), {
    suspense: false
  });
  const payroll = useMemo(() => payrolls == null ? void 0 : payrolls.items.find((p) => p.id === payrollId), [payrollId, payrolls == null ? void 0 : payrolls.items]);
  const refreshPayroll = useCallback(() => __async(void 0, null, function* () {
    yield refreshPayrolls == null ? void 0 : refreshPayrolls();
  }), [refreshPayrolls]);
  useEffect(() => {
    if (!payroll && (payrolls == null ? void 0 : payrolls.items)) {
      showMessage(polyglot.t("CommonErrorMessages.noPageAccess"), "error");
      routerHistory.push(DASHBOARD_ROUTE);
      return;
    }
  }, [payroll, payrolls, showMessage, polyglot, routerHistory]);
  if (!payroll) {
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        title: payroll.displayName,
        subtitle: payroll.employer ? polyglot.t("CompanyPayroll.ukPayroll") : polyglot.t("CompanyPayroll.globalPayroll"),
        pageConfig: payroll.employer ? PageConfigUKPayroll(payroll.id) : PageConfigGlobalPayroll(payroll.id),
        backPath: SETTINGS_MONEY_SALARY_ROUTE,
        showBack: true,
        type: "Custom"
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { children: /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(Route, { path: SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_GENERAL_ROUTE, exact: true, children: /* @__PURE__ */ jsx(PayrollCompanySettingsGeneral, { payroll, refreshPayroll }) }),
      /* @__PURE__ */ jsx(Route, { path: SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_HMRC_ROUTE, exact: true, children: /* @__PURE__ */ jsx(PayrollCompanySettingsHMRC, { payroll, refreshPayroll }) }),
      /* @__PURE__ */ jsx(Route, { path: SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ACCOUNTING_ROUTE, exact: true, children: /* @__PURE__ */ jsx(PayrollCompanySettingsAccounting, { payroll }) }),
      /* @__PURE__ */ jsxs(Route, { path: SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_PAYCODES_ROUTE, exact: true, children: [
        payroll.employer && /* @__PURE__ */ jsx(PayrollCompanySettingsPayCodes, { payroll }),
        !payroll.employer && /* @__PURE__ */ jsx(GlobalPayrollPayCodes, { payroll })
      ] }),
      /* @__PURE__ */ jsx(Route, { path: SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_INTEGRATIONS_ROUTE, exact: true, children: /* @__PURE__ */ jsx(PayrollCompanySettingsIntegrations, {}) }),
      /* @__PURE__ */ jsx(Redirect, { to: SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_GENERAL_ROUTE })
    ] }) })
  ] });
};
