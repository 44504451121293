"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortDate } from "@/v2/components/table/table-sorting.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const DeviceHistoryTable = ({ deviceHistory }) => {
  var _a;
  const deviceHistoryColumns = useMemo(() => {
    return [
      {
        header: () => "Event",
        accessorFn: (row) => row,
        id: "event",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx("div", { children: info.getValue().event });
        }
      },
      {
        header: () => "Event date",
        accessorFn: (row) => row,
        id: "eventDate",
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.date),
        cell: (info) => {
          return new Date(info.getValue().date).toUTCString();
        }
      },
      {
        header: () => "Details",
        accessorFn: (row) => row,
        id: "details",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx("div", { children: info.getValue().details });
        }
      }
    ];
  }, []);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        pt: spacing.p25,
        flex: 1,
        overflow: "auto"
      },
      children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: (_a = deviceHistory == null ? void 0 : deviceHistory.events) != null ? _a : [],
          columnData: deviceHistoryColumns,
          loading: false,
          initialSort: [{ id: "eventDate", desc: true }],
          stickyHeader: true
        }
      )
    }
  ) });
};
