"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import { styled } from "@mui/material/styles";
import truncate from "html-truncate";
import { themeColors } from "@/v2/styles/colors.styles";
const TypographyComponent = styled("p")`
  margin: 0;
  padding: 0;
`;
export const Typography = forwardRef(
  ({
    id,
    variant,
    sx = {},
    className = "",
    color,
    children,
    onClick,
    dangerouslySetInnerHTML,
    truncateHtml,
    title,
    draggable,
    onDragStart
  }, ref) => {
    return /* @__PURE__ */ jsx(
      TypographyComponent,
      {
        ref,
        onClick,
        className: `${className} ${variant}`,
        sx: __spreadProps(__spreadValues({
          color: color && themeColors[color]
        }, sx), {
          "& > p": {
            padding: "0px",
            margin: "0px"
          }
        }),
        id,
        title,
        dangerouslySetInnerHTML: truncateHtml && dangerouslySetInnerHTML ? { __html: truncate(dangerouslySetInnerHTML.__html, 1, { ellipsis: "...", truncateLastWord: false }) } : dangerouslySetInnerHTML,
        draggable,
        onDragStart,
        children: dangerouslySetInnerHTML ? null : children
      }
    );
  }
);
