"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { PermissionCategoryListingTable } from "@/v2/feature/permission-group/components/permission-category-listing-table.component";
import { PermissionGroupEditDrawerPage } from "@/v2/feature/permission-group/components/permission-group-edit-drawer.component";
import {
  PERMISSION_GROUP_EDIT_DRAWER_MODES
} from "@/v2/feature/permission-group/permission-group.interface";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const EditPermissionGroupPermissionsPage = ({
  permissionGroup,
  refresh,
  additionalScopes,
  permissionCategories,
  entitiesForPermission
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [isLoading, setIsLoading] = useState(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const [permissionGroupDrawerMode, setPermissionGroupDrawerMode] = useState(
    PERMISSION_GROUP_EDIT_DRAWER_MODES.member
  );
  const [selectedPermissionCategory, setSelectedPermissionCategory] = useState(null);
  const openDrawerInEditScopesMode = (permissionCategory) => {
    setSelectedPermissionCategory(permissionCategory);
    setPermissionGroupDrawerMode(PERMISSION_GROUP_EDIT_DRAWER_MODES.scope);
    setEditDrawerOpen(true);
  };
  const openDrawerInViewScopesMode = (permissionCategoryRow) => {
    setSelectedPermissionCategory(permissionCategoryRow.original);
    setPermissionGroupDrawerMode(PERMISSION_GROUP_EDIT_DRAWER_MODES.view);
    setEditDrawerOpen(true);
  };
  return /* @__PURE__ */ jsxs(SettingsSectionContent, { title: polyglot.t("PermissionGroups.router.permissions"), children: [
    /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            items: [
              {
                type: SectionItemType.Component,
                value: /* @__PURE__ */ jsx(
                  PermissionCategoryListingTable,
                  {
                    permissionCategories,
                    defaultPermission: permissionGroup ? permissionGroup.isDefault : false,
                    loading: isLoading,
                    openDrawerInEditMode: openDrawerInEditScopesMode,
                    openDrawerInViewMode: openDrawerInViewScopesMode,
                    permissionGroupName: (_a = permissionGroup == null ? void 0 : permissionGroup.name) != null ? _a : ""
                  }
                )
              }
            ]
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: editDrawerOpen, setIsOpen: setEditDrawerOpen, children: /* @__PURE__ */ jsx(
      PermissionGroupEditDrawerPage,
      {
        refreshPermissionGroup: refresh,
        selectedPermission: permissionGroup,
        selectedPermissionCategory,
        permissionGroupMembers: (_b = permissionGroup == null ? void 0 : permissionGroup.members) != null ? _b : [],
        closePage: () => __async(void 0, null, function* () {
          setEditDrawerOpen(false);
          yield refresh();
        }),
        mode: permissionGroupDrawerMode,
        setLoading: setIsLoading,
        permissionGroupName: (_c = permissionGroup == null ? void 0 : permissionGroup.name) != null ? _c : "",
        additionalScopesToEdit: additionalScopes && (selectedPermissionCategory == null ? void 0 : selectedPermissionCategory.name) ? additionalScopes[selectedPermissionCategory.name] : void 0,
        entitiesForPermission
      }
    ) })
  ] });
};
