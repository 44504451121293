"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class AttendanceAPI {
  static findAllAttendancePerUserId(page, pageSize, searchInput, filterString) {
    return __async(this, null, function* () {
      var _a;
      const queryString = [
        filterString ? filterString : void 0,
        searchInput ? `search=${searchInput}` : void 0,
        page ? `page=${page}` : void 0,
        pageSize ? `pageSize=${pageSize}` : void 0
      ].filter(Boolean).join("&");
      return (_a = yield axios.get(`/apiv2/attendance-entries/company/count-per-userId?${queryString}`)) == null ? void 0 : _a.data;
    });
  }
  static findTeamAttendancePerUserId(page, pageSize, searchInput, filterString) {
    return __async(this, null, function* () {
      var _a;
      const queryString = [
        filterString ? filterString : void 0,
        searchInput ? `search=${searchInput}` : void 0,
        page ? `page=${page}` : void 0,
        pageSize ? `pageSize=${pageSize}` : void 0
      ].filter(Boolean).join("&");
      return (_a = yield axios.get(`/apiv2/attendance-entries/team/count-per-userId?${queryString}`)) == null ? void 0 : _a.data;
    });
  }
  static createAttendanceSchedule(createBody) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/attendance-schedules", createBody)).data;
    });
  }
  static addEmptyWeekToAttendanceSchedule(scheduleId, startDateOfFirstWeek) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/attendance-schedules/${scheduleId}/add-empty-week`, {
        startDateOfFirstWeek
      });
    });
  }
  static clearWeekFromAttendanceScheduleByWeekIndex(scheduleId, weekIndex) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/attendance-schedules/${scheduleId}/week/${weekIndex}/clear`);
    });
  }
  static duplicateWeekFromAttendanceScheduleByWeekIndex(scheduleId, weekIndex, startDateOfFirstWeek) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/attendance-schedules/${scheduleId}/week/${weekIndex}/duplicate`, { startDateOfFirstWeek });
    });
  }
  static deleteWeekFromAttendanceScheduleByWeekIndex(scheduleId, weekIndex) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/attendance-schedules/${scheduleId}/week/${weekIndex}`);
    });
  }
  static createAttendanceJob(createBody) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/attendance-types", createBody);
    });
  }
  static updateAttendanceJob(jobId, updateBody) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-types/${jobId}`, updateBody);
    });
  }
  static deleteAttendanceJob(jobId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/attendance-types/${jobId}`);
    });
  }
  static createAttendancePayMultiplier(createBody) {
    return __async(this, null, function* () {
      yield axios.post("/apiv2/attendance-pay-multipliers", createBody);
    });
  }
  static updateAttendancePayMultiplier(multiplierId, updateBody) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-pay-multipliers/${multiplierId}`, updateBody);
    });
  }
  static deleteAttendanceMultiplier(multiplierId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/attendance-pay-multipliers/${multiplierId}`);
    });
  }
  static updateAttendanceScheduleGeneral(scheduleId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-schedules/${scheduleId}/general`, update);
    });
  }
  static updateAttendanceScheduleTrackingSettings(scheduleId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-schedules/${scheduleId}/tracking-settings`, update);
    });
  }
  static updateAttendanceSchedulePayroll(scheduleId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-schedules/${scheduleId}/payroll`, update);
    });
  }
  static updateAttendanceScheduleSettings(scheduleId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-schedules/${scheduleId}/schedule-settings`, update);
    });
  }
  static updateAttendanceScheduleApproval(scheduleId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-schedules/${scheduleId}/approval`, update);
    });
  }
  static deleteAttendanceScheduleById(id) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/attendance-schedules/${id}`);
    });
  }
  static createAttendanceEntriesForDate(userId, logDate, attendanceEntries) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/attendance-entries/users/${userId}/date/${logDate}`, attendanceEntries);
    });
  }
  static getPersonalAttendanceEntriesByWeekNo(userId, weekNo, year) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/attendance-entries/${userId}/weekly/${weekNo}/${year}`)).data;
    });
  }
  static getAttendanceTableByUserId(userId, filterString) {
    return __async(this, null, function* () {
      const queryString = [filterString ? filterString : void 0].filter(Boolean).join("&");
      return (yield axios.get(`/apiv2/attendance-entries/users/${userId}/table/by-user?${queryString}`)).data;
    });
  }
  static getWeekListByUserId(userId, year, searchInput) {
    return __async(this, null, function* () {
      var _a;
      const queryString = [`year=${year}`, searchInput ? `search=${searchInput}` : void 0].filter(Boolean).join("&");
      return (_a = yield axios.get(`/apiv2/attendance-entries/${userId}/week-list?${queryString}`)) == null ? void 0 : _a.data;
    });
  }
  static approveUserAttendanceEntriesByLogDate(userId, logDate, status) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-entries/users/${userId}/date/${logDate}/single-approval`, { status });
    });
  }
  static approveUserAttendanceByWeek(userId, year, weekNo) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-entries/approve/week/${weekNo}/year/${year}/users/${userId}`);
    });
  }
  static rejectUserAttendanceByWeek(userId, year, weekNo) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-entries/reject/week/${weekNo}/year/${year}/users/${userId}`);
    });
  }
  static approveUsersRequestByWeekOrMonth(userId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-entries/bulk-approve/users/${userId}`, update);
    });
  }
  static rejectUsersRequestByWeekOrMonth(userId, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/attendance-entries/bulk-reject/users/${userId}`, update);
    });
  }
  static deleteUserAttendanceEntriesByLogDate(userId, logDate) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/attendance-entries/users/${userId}/date/${logDate}`);
    });
  }
  static deleteUserAttendancesByWeek(userId, year, weekNo) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/attendance-entries/week/${weekNo}/year/${year}/users/${userId}`);
    });
  }
  static autopopulateUserAttendancesByWeek(userId, weekNo, year, monday) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/attendance-entries/autopopulate/week/${weekNo}/year/${year}/users/${userId}`, { monday });
    });
  }
  static copyUserAttendancesFromLastWeek(userId, weekNo, year) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/attendance-entries/copy-last-week/week/${weekNo}/year/${year}/users/${userId}`);
    });
  }
  static updateAttendanceSettings(updateData) {
    return __async(this, null, function* () {
      yield axios.put("/apiv2/attendance-settings", updateData);
    });
  }
  static addAttendanceJobWorkEntryByDayAndWeekIndex(scheduleId, weekIndex, day, updateBody) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/attendance-schedules/${scheduleId}/work-entries/day/${day}/week/${weekIndex}`, updateBody);
    });
  }
  static updateAttendanceJobWorkEntryByDayAndWeekIndex(scheduleId, entryId, weekIndex, day, updateBody) {
    return __async(this, null, function* () {
      yield axios.put(
        `/apiv2/attendance-schedules/${scheduleId}/work-entries/${entryId}/day/${day}/week/${weekIndex}`,
        updateBody
      );
    });
  }
  static deleteAttendanceJobWorkEntryByDayAndWeekIndex(scheduleId, entryId, weekIndex, day) {
    return __async(this, null, function* () {
      yield axios.delete(
        `/apiv2/attendance-schedules/${scheduleId}/work-entries/${entryId}/day/${day}/week/${weekIndex}`
      );
    });
  }
}
export class AttendanceEndpoints {
  static getAttendanceSchedules() {
    return {
      url: "/apiv2/attendance-schedules"
    };
  }
  static getTeamAttendanceSchedules() {
    return {
      url: "/apiv2/attendance-schedules/team"
    };
  }
  static getAttendanceScheduleById(id) {
    return {
      url: `/apiv2/attendance-schedules/${id}`
    };
  }
  static getUserAttendanceSchedule(userId) {
    return {
      url: `/apiv2/attendance-schedules/users/${userId}`
    };
  }
  static getUserAttendanceScheduleByLogDate(userId, logDate) {
    return {
      url: `/apiv2/attendance-schedules/users/${userId}/effective-date/${logDate}`
    };
  }
  static getCompanyAttendanceTypes() {
    return {
      url: "/apiv2/attendance-types"
    };
  }
  static getCompanyAttendancePayMultipliers() {
    return {
      url: "/apiv2/attendance-pay-multipliers"
    };
  }
  static getTeamAttendanceTypes() {
    return {
      url: "/apiv2/attendance-types/team"
    };
  }
  // TODO: @approval is this protected by approval rule + is it needed
  static canApproveRejectSchedule(userId) {
    return {
      url: `/apiv2/attendance-schedules/users/${userId}/can-approve-and-reject`
    };
  }
  static getUserAttendanceRequests(userId) {
    return {
      url: `/apiv2/attendance-entries/users/${userId}`
    };
  }
  static getScheduleMembers(scheduleId) {
    return {
      url: `/apiv2/attendance-schedules/${scheduleId}/members`
    };
  }
  static getCompanyAttendanceSettings() {
    return {
      url: "/apiv2/attendance-settings"
    };
  }
  static getUserAttendanceEntriesByLogDate(userId, logDate) {
    return {
      url: `/apiv2/attendance-entries/users/${userId}/date/${logDate}`
    };
  }
}
