"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { CustomProfileFormAPI } from "@/v2/feature/custom-fields/custom-profile-fields.api";
import { spacing } from "@/v2/styles/spacing.styles";
import { actionIconSize } from "@/v2/styles/table.styles";
export const CustomFormDrawer = ({
  isOpened,
  setIsOpened,
  formTab,
  form,
  formNames,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isDeleteOpened, setIsDeleteOpened] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const close = useCallback(
    (form2, change) => __async(void 0, null, function* () {
      yield refresh({ form: form2, change });
      setIsOpened(false);
    }),
    [refresh, setIsOpened]
  );
  const deleteSection = useCallback(() => __async(void 0, null, function* () {
    if (!form) return;
    try {
      setDeleteLoading(true);
      yield CustomProfileFormAPI.deleteForm(form.formId);
      showMessage(polyglot.t("CustomFormDeleteDrawer.successMessages.delete", { formName: form.formName }), "success");
      yield close(form, "deleted");
      setIsDeleteOpened(false);
    } catch (error) {
      showMessage(
        polyglot.t("CustomFormDeleteDrawer.errorMessages.delete", { nestError: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setDeleteLoading(false);
    }
  }), [close, showMessage, form, polyglot]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isOpened, setIsOpen: setIsOpened, children: /* @__PURE__ */ jsx(
      CustomFormDrawerContent,
      {
        formTab,
        form,
        formNames,
        close,
        openDeleteDrawer: () => setIsDeleteOpened(true)
      }
    ) }),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("CustomFormDeleteDrawer.title"),
        description: polyglot.t("CustomFormDeleteDrawer.confirmDeletion", {
          formName: (form == null ? void 0 : form.formName) || ""
        }),
        isOpen: isDeleteOpened,
        setIsOpen: setIsDeleteOpened,
        loading: deleteLoading,
        action: deleteSection
      }
    )
  ] });
};
export const CustomFormDrawerContent = ({
  form,
  formNames,
  formTab,
  close,
  openDeleteDrawer
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const createSection = useCallback(
    (formTab2, formName) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        const newForm = yield CustomProfileFormAPI.createForm({
          formTab: formTab2,
          formName
        });
        showMessage(polyglot.t("CustomFormDrawer.successMessages.add"), "success");
        yield close(newForm, "created");
      } catch (error) {
        showMessage(polyglot.t("CustomFormDrawer.errorMessages.add", { nestError: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [close, showMessage, polyglot]
  );
  const updateSection = useCallback(
    (form2, formName) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        const newForm = yield CustomProfileFormAPI.updateForm(form2.formId, {
          formName
        });
        showMessage(polyglot.t("CustomFormDrawer.successMessages.update"), "success");
        yield close(newForm, "updated");
      } catch (error) {
        showMessage(
          polyglot.t("CustomFormDrawer.errorMessages.update", { nestError: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [close, showMessage, polyglot]
  );
  const formik = useFormik({
    initialValues: {
      formName: (_a = form == null ? void 0 : form.formName) != null ? _a : ""
    },
    validationSchema: yup.object({
      formName: yup.string().required(polyglot.t("validation.requiredField")).test("isDuplicateFormName", "Duplicate section name", (formName) => {
        const lowerFormName = formName == null ? void 0 : formName.toLocaleLowerCase();
        if (!formNames.find((n) => n.toLocaleLowerCase() === lowerFormName)) return true;
        return lowerFormName === (form == null ? void 0 : form.formName.toLocaleLowerCase());
      })
    }),
    onSubmit(values) {
      return __async(this, null, function* () {
        if (form) {
          yield updateSection(form, values.formName);
        } else {
          yield createSection(formTab, values.formName);
        }
      });
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "title2", children: polyglot.t(form ? "CustomFormDrawer.edit" : "CustomFormDrawer.new") }),
      form && /* @__PURE__ */ jsx(
        IconButton,
        {
          sizeVariant: "small",
          colorVariant: "secondary",
          title: polyglot.t("CustomFormDrawer.delete"),
          onClick: openDeleteDrawer,
          children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize))
        },
        "delete"
      )
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "formName",
        value: formik.values.formName,
        label: polyglot.t("CustomFormDrawer.sectionName"),
        onChange: formik.handleChange,
        disabled: loading,
        maxLength: 50,
        error: !!formik.submitCount && !!formik.errors.formName,
        helperText: !!formik.submitCount && formik.errors.formName,
        autoFocus: true
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
