"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { EmptyStateBox } from "@v2/components/empty-state-box.component";
import { generatePath, useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import useMessage from "@/hooks/notification.hook";
import { REVIEWS_ME_RESULT_DETAIL_ROUTE } from "@/lib/routes";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ReviewEndpoints } from "@/v2/feature/growth/reviews/api-client/review.api";
import { getStatus } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const ReviewPersonalResultListPage = ({ userId }) => {
  const [showMessage] = useMessage();
  const [searchInput, setSearchInput] = useState("");
  const { data: resultList, isValidating: validatingResultList, isLoading: loadingResultList } = useApiClient(
    ReviewEndpoints.getMeResultList(userId, searchInput),
    {
      suspense: false
    }
  );
  const routerHistory = useHistory();
  const tableColumns = useMemo(
    () => [
      {
        header: () => "Cycle name",
        id: "name",
        accessorFn: (row) => row.name,
        enableSorting: false,
        cell: ({
          row: {
            original: { name }
          }
        }) => name ? /* @__PURE__ */ jsx("div", { children: name }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "Cycle status",
        accessorFn: (row) => row,
        id: "state",
        enableSorting: false,
        cell: ({
          row: {
            original: { state }
          }
        }) => state ? getStatus()[state] : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "Start date",
        id: "startDate",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({
          row: {
            original: { startDate }
          }
        }) => /* @__PURE__ */ jsx("div", { children: new LocalDate(startDate).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" }) })
      }
    ],
    []
  );
  const handleRowClick = useCallback(
    (row) => {
      if (row.original.id)
        routerHistory.push(
          generatePath(REVIEWS_ME_RESULT_DETAIL_ROUTE, { cycleId: row.original.id, revieweeId: userId })
        );
    },
    [routerHistory, userId]
  );
  const debouncedSearch = useDebouncedCallback((event) => __async(void 0, null, function* () {
    try {
      setSearchInput(event.target.value);
    } catch (error) {
      showMessage("Failed to handle search", "error");
    }
  }), 300);
  const handleSearch = useCallback((event) => debouncedSearch.callback(event), [
    debouncedSearch
  ]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: "Results" }) }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, children: !loadingResultList && resultList && resultList.length === 0 && !searchInput ? /* @__PURE__ */ jsx(
      EmptyStateBox,
      {
        title: "Your results",
        subtitle: "Once completed and shared, your performance results will be published here. There are no results for you, yet."
      }
    ) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
      /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: handleSearch }),
      /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: resultList ? [...resultList] : [],
          columnData: tableColumns,
          loading: validatingResultList,
          hidePagination: true,
          rowClick: handleRowClick
        }
      )
    ] }) })
  ] });
};
