"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box, FormControlLabel, RadioGroup } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as yup from "yup";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_GENERAL_ROUTE,
  SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE
} from "@/lib/routes";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserSelectFiltersOptions } from "@/v2/components/user-select-type/user-select.interface";
import { AppDetailsEndpoints } from "@/v2/feature/app-integration/features/app-details/app-details.api";
import {
  PaymentCategoryEnum,
  PayrolledOptionsEnum,
  SyncToExternalOptionList
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface";
import {
  PaymentTypeSettingsAPI,
  PaymentTypeSettingsEndpoints
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { caseInsensitiveSort } from "@/v2/util/array.util";
export const PaymentSettingsNewTypeDrawer = ({
  isOpen,
  setIsOpen,
  title,
  codeConfigIndex,
  refreshIndividualSetting,
  refreshAllSettings,
  typeForEdit,
  editMode,
  typeCategory = PaymentCategoryEnum.EXPENSE,
  redirectToSettings = false
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    PaymentSettingsNewTypeDrawerContent,
    {
      refreshIndividualSetting,
      refreshAllSettings,
      setIsOpen,
      title,
      typeForEdit,
      editMode,
      typeCategory,
      redirectToSettings,
      codeConfigIndex
    }
  ) });
};
export const PaymentSettingsNewTypeDrawerContent = ({
  refreshAllSettings,
  refreshIndividualSetting,
  setIsOpen,
  title,
  codeConfigIndex,
  typeForEdit,
  editMode,
  typeCategory = PaymentCategoryEnum.EXPENSE,
  redirectToSettings = false
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const { polyglot } = usePolyglot();
  const { getScopesContext, hasScopes } = useScopes();
  const history = useHistory();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const scopesContext = getScopesContext({ userId: user.userId });
  const hasAppsScope = hasScopes(["apps"], scopesContext);
  const { data: accountingAppConfigured } = useApiClient(PaymentTypeSettingsEndpoints.accountingAppConfigured(), {
    suspense: false
  });
  const accountingDefaultApp = "xero";
  const isExpense = typeCategory === PaymentCategoryEnum.EXPENSE;
  const { data: accountingApp } = useApiClient(
    hasAppsScope ? AppDetailsEndpoints.getAppDetails(accountingDefaultApp) : { url: void 0 },
    {
      suspense: false
    }
  );
  const { data: accountingCodes } = useApiClient(PaymentTypeSettingsEndpoints.getAccountingCodesFromProvider(), {
    suspense: false
  });
  const accountingCodeOptions = useMemo(() => {
    return accountingCodes && accountingCodes.length > 0 ? accountingCodes.map((ac) => ({
      label: `${ac.code} (${ac.name})`,
      value: +ac.code
    })) : [];
  }, [accountingCodes]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [showMessage] = useMessage();
  const editingGeneral = typeForEdit && editMode && editMode === "name";
  const editingPayment = typeForEdit && editMode && editMode === "payment";
  const editingAccounting = typeForEdit && editMode && editMode === "accounting";
  const editingSync = typeForEdit && editMode && editMode === "sync";
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2;
      try {
        if (!values.name || !values.type) return;
        setLoading(true);
        if (!(typeForEdit == null ? void 0 : typeForEdit.id)) {
          const payload = __spreadProps(__spreadValues({}, values), {
            syncToExternal: (_a2 = values.syncToExternal) != null ? _a2 : "manual",
            paycode: values.payrolled ? values.paycode : null,
            accountingCode: !values.payrolled && values.accountingCode ? +values.accountingCode : null,
            accountingCodeDescription: !values.payrolled && values.accountingCodeDescription ? values.accountingCodeDescription : "",
            provider: accountingAppConfigured && (accountingApp == null ? void 0 : accountingApp.active) && (accountingApp == null ? void 0 : accountingApp.authorised) ? accountingDefaultApp : null
          });
          const newTypeId = yield PaymentTypeSettingsAPI.create(payload);
          showMessage(polyglot.t("PaymentSettingsNewTypeDrawer.successMessages.addNewType"), "success");
          if (redirectToSettings) {
            history.push(
              generatePath(
                isExpense ? SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_GENERAL_ROUTE : SETTINGS_MONEY_INVOICE_TYPES_DETAILS_GENERAL_ROUTE,
                {
                  id: newTypeId
                }
              )
            );
          }
        } else {
          if (editingAccounting) {
            const originalValues = __spreadProps(__spreadValues({}, typeForEdit), {
              syncToExternal: (_b2 = values == null ? void 0 : values.syncToExternal) != null ? _b2 : "manual",
              accountingCodeConfig: typeForEdit.accountingCodeConfig,
              provider: accountingAppConfigured && (accountingApp == null ? void 0 : accountingApp.active) && (accountingApp == null ? void 0 : accountingApp.authorised) ? accountingDefaultApp : null
            });
            if (codeConfigIndex !== null && codeConfigIndex !== void 0 && codeConfigIndex >= 0 && originalValues.accountingCodeConfig) {
              const updatedAccountingCodeConfig = [...originalValues.accountingCodeConfig];
              updatedAccountingCodeConfig[codeConfigIndex] = {
                accountingCode: (_c2 = values.accountingCode) != null ? _c2 : 0,
                accountingCodeDescription: (_d2 = values.accountingCodeDescription) != null ? _d2 : ""
              };
              originalValues.accountingCodeConfig = updatedAccountingCodeConfig;
            } else if (codeConfigIndex === null || codeConfigIndex === void 0) {
              if (!originalValues.accountingCodeConfig) {
                originalValues.accountingCodeConfig = [];
              }
              originalValues.accountingCodeConfig.push({
                accountingCode: (_e2 = values.accountingCode) != null ? _e2 : 0,
                accountingCodeDescription: (_f2 = values.accountingCodeDescription) != null ? _f2 : ""
              });
            }
            yield PaymentTypeSettingsAPI.updateAccounting(typeForEdit.id, originalValues);
          } else if (editingSync) {
            const originalValues = __spreadProps(__spreadValues({}, typeForEdit), {
              syncToExternal: (_g2 = values == null ? void 0 : values.syncToExternal) != null ? _g2 : "manual",
              externalSyncOrganisation: (_h2 = values == null ? void 0 : values.externalSyncOrganisation) != null ? _h2 : null,
              provider: accountingAppConfigured && (accountingApp == null ? void 0 : accountingApp.active) && (accountingApp == null ? void 0 : accountingApp.authorised) ? accountingDefaultApp : null
            });
            yield PaymentTypeSettingsAPI.updateSyncSetting(typeForEdit.id, originalValues);
          } else if (editingGeneral) {
            yield PaymentTypeSettingsAPI.updateGeneral(typeForEdit.id, __spreadValues({
              name: values.name,
              requireAttachment: values.requireAttachment
            }, !(typeForEdit == null ? void 0 : typeForEdit.payrolled) && values.payrolled ? { nullifyAccountingConfig: true } : {}));
          } else {
            yield PaymentTypeSettingsAPI.update(typeForEdit.id, __spreadValues(__spreadProps(__spreadValues({}, values), {
              paycode: values.payrolled ? values.paycode : null,
              approvalRuleId: values.approvalRuleId
            }), !(typeForEdit == null ? void 0 : typeForEdit.payrolled) && values.payrolled ? { nullifyAccountingConfig: true } : {}));
          }
          showMessage(polyglot.t("PaymentSettingsNewTypeDrawer.successMessages.updateType"), "success");
        }
        setIsOpen(false);
        refreshAllSettings == null ? void 0 : refreshAllSettings();
        refreshIndividualSetting == null ? void 0 : refreshIndividualSetting();
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t("PaymentSettingsNewTypeDrawer.errorMessages.updateType", {
              errorMessage: nestErrorMessage(error)
            }),
            "error"
          );
        }
      } finally {
        setLoading(false);
      }
    }),
    [
      typeForEdit,
      setIsOpen,
      refreshAllSettings,
      refreshIndividualSetting,
      accountingAppConfigured,
      accountingApp == null ? void 0 : accountingApp.active,
      accountingApp == null ? void 0 : accountingApp.authorised,
      showMessage,
      polyglot,
      redirectToSettings,
      history,
      isExpense,
      editingAccounting,
      editingSync,
      editingGeneral,
      codeConfigIndex
    ]
  );
  const getAccountingCodeValue = (typeForEdit2, codeConfigIndex2) => {
    if (codeConfigIndex2 !== null && codeConfigIndex2 !== void 0 && codeConfigIndex2 >= 0 && (typeForEdit2 == null ? void 0 : typeForEdit2.accountingCodeConfig) && typeForEdit2.accountingCodeConfig[codeConfigIndex2].accountingCode !== void 0) {
      return typeForEdit2.accountingCodeConfig[codeConfigIndex2].accountingCode;
    }
    return null;
  };
  const getAccountingCodeDescription = (typeForEdit2, codeConfigIndex2) => {
    if (codeConfigIndex2 !== null && codeConfigIndex2 !== void 0 && codeConfigIndex2 >= 0 && (typeForEdit2 == null ? void 0 : typeForEdit2.accountingCodeConfig) && typeForEdit2.accountingCodeConfig[codeConfigIndex2].accountingCodeDescription !== void 0) {
      return typeForEdit2.accountingCodeConfig[codeConfigIndex2].accountingCodeDescription;
    }
    return "";
  };
  const formik = useFormik({
    initialValues: {
      name: (_a = typeForEdit == null ? void 0 : typeForEdit.name) != null ? _a : "",
      accountingCode: getAccountingCodeValue(typeForEdit, codeConfigIndex),
      accountingCodeDescription: getAccountingCodeDescription(typeForEdit, codeConfigIndex),
      accountingCodeConfig: (_b = typeForEdit == null ? void 0 : typeForEdit.accountingCodeConfig) != null ? _b : [],
      type: (_c = typeForEdit == null ? void 0 : typeForEdit.type) != null ? _c : typeCategory,
      provider: null,
      payrolled: !!(typeForEdit == null ? void 0 : typeForEdit.payrolled),
      paycode: (_d = typeForEdit == null ? void 0 : typeForEdit.paycode) != null ? _d : null,
      syncToExternal: (_e = typeForEdit == null ? void 0 : typeForEdit.syncToExternal) != null ? _e : "manual",
      approvalRuleId: (_f = typeForEdit == null ? void 0 : typeForEdit.approvalRuleId) != null ? _f : null,
      membersRule: (_g = typeForEdit == null ? void 0 : typeForEdit.membersRule) != null ? _g : UserSelectFiltersOptions.None,
      customRule: null,
      externalSyncOrganisation: (_h = typeForEdit == null ? void 0 : typeForEdit.externalSyncOrganisation) != null ? _h : null,
      requireAttachment: (_i = typeForEdit == null ? void 0 : typeForEdit.requireAttachment) != null ? _i : false
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: typeForEdit ? yup.string() : yup.string().required(polyglot.t("PolicyGeneralEditDrawer.fieldIsRequired"))
    }),
    onSubmit
  });
  const confirmDelete = () => __async(void 0, null, function* () {
    if (typeForEdit == null ? void 0 : typeForEdit.id) {
      try {
        yield PaymentTypeSettingsAPI.delete(typeForEdit.id);
        showMessage(polyglot.t("PaymentSettingsNewTypeDrawer.successMessages.deleteType"), "success");
        refreshAllSettings == null ? void 0 : refreshAllSettings();
      } catch (error) {
        showMessage(`Could not deleted the benefit. ${nestErrorMessage(error)}`, "error");
      } finally {
        setAnchorEl(null);
        setIsOpen(false);
        setIsDelete(false);
      }
    }
  });
  const tenantOptions = useMemo(() => {
    var _a2;
    if (!accountingApp || !(accountingApp == null ? void 0 : accountingApp.tenants) || ((_a2 = accountingApp == null ? void 0 : accountingApp.tenants) == null ? void 0 : _a2.length) === 0) return null;
    return [...accountingApp.tenants].sort((a, b) => caseInsensitiveSort(a, b, (item) => item.tenantName)).map((e) => ({
      label: e.tenantName,
      value: e.tenantId
    }));
  }, [accountingApp]);
  const titleForDrawer = useMemo(() => {
    if (title) return title;
    if ((codeConfigIndex === void 0 || codeConfigIndex === null) && typeForEdit && editingAccounting) {
      return polyglot.t("PaymentSettingsNewTypeDrawer.newAccountingCode");
    }
    if (editingSync) return polyglot.t("PaymentSettingsNewTypeDrawer.editSyncSettings");
    return polyglot.t(typeForEdit ? "PaymentSettingsNewTypeDrawer.editTitle" : "PaymentSettingsNewTypeDrawer.title");
  }, [title, codeConfigIndex, editingAccounting, editingSync, polyglot, typeForEdit]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title2", children: titleForDrawer }),
    (editingGeneral || !typeForEdit) && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("PaymentSettingsPage.tableColumns.name"),
        value: formik.values.name,
        onChange: formik.handleChange,
        error: formik.touched.name && !!formik.errors.name,
        helperText: formik.touched.name && formik.errors.name,
        endAdornment: "none",
        fullWidth: true
      }
    ),
    (editingGeneral || !typeForEdit) && formik.values.type === PaymentCategoryEnum.EXPENSE && /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "requiresAttachment",
        label: polyglot.t("NewTimePolicyGeneralStep.requiresAttachment"),
        checked: formik.values.requireAttachment,
        onChange: (event, checked) => {
          formik.setFieldValue("requireAttachment", checked);
        }
      }
    ),
    editingSync && !loading && accountingAppConfigured && tenantOptions && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.sm }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", children: polyglot.t("PaymentSettingsNewTypeDrawer.syncingOrganisation") }),
      /* @__PURE__ */ jsx(
        RadioGroup,
        {
          name: "external-sync-organisation-group",
          onChange: (event) => {
            var _a2;
            if (!event.target.value) return;
            const matchingTenant = ((_a2 = accountingApp == null ? void 0 : accountingApp.tenants) != null ? _a2 : []).find((t) => t.tenantId === event.target.value);
            if (!matchingTenant) return;
            const { tenantId, tenantName } = matchingTenant;
            formik.setFieldValue("externalSyncOrganisation", {
              id: tenantId,
              name: tenantName
            });
          },
          children: tenantOptions.map((option) => {
            var _a2;
            return /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "end",
                value: option.value,
                checked: formik.values.externalSyncOrganisation ? ((_a2 = formik.values.externalSyncOrganisation) == null ? void 0 : _a2.id) === option.value : false,
                control: /* @__PURE__ */ jsx(StyledRadio, {}),
                label: /* @__PURE__ */ jsx(Typography, { variant: "title4", children: option.label }),
                sx: { mb: spacing.m5 }
              },
              option.value
            );
          })
        }
      )
    ] }),
    editingAccounting && accountingAppConfigured ? /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "accountingCode",
        label: polyglot.t("PaymentSettingsPage.tableColumns.accountingCode"),
        options: accountingCodeOptions,
        value: accountingCodeOptions.find(
          ({ value }) => value === formik.values.accountingCode
        ) || null,
        compareValue: formik.values.accountingCode,
        onChange: (_, x) => {
          if (!x) return;
          const matchingCode = accountingCodes == null ? void 0 : accountingCodes.find((ac) => Number(ac.code) === Number(x.value));
          formik.setFieldValue("accountingCode", +x.value);
          formik.setFieldValue("accountingCodeDescription", matchingCode == null ? void 0 : matchingCode.name);
        },
        fullWidth: true
      }
    ) : editingAccounting ? /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "accountingCode",
        label: polyglot.t("PaymentSettingsPage.tableColumns.accountingCode"),
        value: formik.values.accountingCode,
        onChange: formik.handleChange,
        error: formik.touched.accountingCode && !!formik.errors.accountingCode,
        helperText: formik.touched.accountingCode && formik.errors.accountingCode,
        endAdornment: "none",
        type: "number",
        fullWidth: true
      }
    ) : null,
    editingAccounting && !accountingAppConfigured && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "accountingCodeDescription",
        label: polyglot.t("PaymentSettingsPage.tableColumns.accountingCodeDescription"),
        value: formik.values.accountingCodeDescription,
        onChange: formik.handleChange,
        error: formik.touched.accountingCodeDescription && !!formik.errors.accountingCodeDescription,
        helperText: formik.touched.accountingCodeDescription && formik.errors.accountingCodeDescription,
        endAdornment: "none",
        fullWidth: true
      }
    ),
    editingSync && accountingAppConfigured && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "syncToExternal",
        label: polyglot.t("ContractorInvoiceModal.syncMethod"),
        options: SyncToExternalOptionList,
        value: formik.values.syncToExternal,
        onChange: (event) => {
          if (!event.target.value) return;
          formik.setFieldValue("syncToExternal", event.target.value);
        }
      }
    ),
    formik.values.type === PaymentCategoryEnum.EXPENSE && editingPayment && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(Box, { sx: { mb: spacing.s2 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "Grey", children: polyglot.t("General.name") }),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: typeForEdit.name })
      ] }),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "payrolled",
          label: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: PayrolledOptionsEnum.PayViaPayroll }),
          checked: !!formik.values.payrolled,
          onChange: formik.handleChange
        }
      )
    ] }),
    formik.values.payrolled && editingPayment && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "paycode",
        label: polyglot.t("PayItemModule.payCode"),
        value: formik.values.paycode,
        onChange: formik.handleChange,
        error: formik.touched.paycode && !!formik.errors.paycode,
        helperText: (_j = formik.touched.paycode && formik.errors.paycode) != null ? _j : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true
      }
    ) }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isDelete,
        onClose: () => setIsDelete(false),
        anchorEl,
        takeAction: confirmDelete,
        message: "Are you sure you want to delete this payment setting type?",
        callToAction: "Yes"
      }
    ),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: (isOpen) => setUpgradeModalOpen(isOpen),
        planName: PlanNames.MONEY_PRO,
        messageSuffix: "proGeneric"
      }
    )
  ] }) });
};
