"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { FiltersDrawer } from "@v2/components/table/filters-drawer.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { Typography } from "@v2/components/typography/typography.component";
import {
  convertMinutesToClockHours,
  getPeriodFromAbsence,
  hasOverlap,
  isHourlyPolicy
} from "@v2/feature/absence/absence.util";
import { AbsenceTableActions } from "@v2/feature/absence/sections/absence-table-actions.component";
import { canApproveOrRejectRequest } from "@v2/feature/approval-rule/approval-rule.util";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { FiltersEndpoints } from "@v2/feature/filters/filters.api";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { translateAbsenceStatuses } from "@v2/infrastructure/i18n/translate.util";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize, selectColumnSize } from "@v2/styles/table.styles";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as InfoIcon } from "@/images/side-bar-icons/InfoGrey.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { ReactComponent as WaitingEmpty } from "@/images/side-bar-icons/WaitingEmpty.svg";
import { ReactComponent as WaitingEmptyRed } from "@/images/side-bar-icons/WaitingEmptyRed.svg";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { BasicServerTable } from "@/v2/components/table/server-side-table.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { AbsenceStatus } from "@/v2/feature/absence/absence.interface";
import { themeColors } from "@/v2/styles/colors.styles";
export function AbsenceTable(_a) {
  var _b = _a, {
    rows,
    showUserColumn = false,
    loading,
    onRowClick,
    newRequestButton,
    setSelectionModel,
    selectionModel,
    pagination,
    setPagination,
    totalPages,
    searchInput,
    setDebouncedSearchInput,
    filterString,
    setFilterString,
    view,
    totalItems,
    userId,
    hideTimePolicyFilter,
    hidePolicyColumn,
    stickyHeader
  } = _b, handlers = __objRest(_b, [
    "rows",
    "showUserColumn",
    "loading",
    "onRowClick",
    "newRequestButton",
    "setSelectionModel",
    "selectionModel",
    "pagination",
    "setPagination",
    "totalPages",
    "searchInput",
    "setDebouncedSearchInput",
    "filterString",
    "setFilterString",
    "view",
    "totalItems",
    "userId",
    "hideTimePolicyFilter",
    "hidePolicyColumn",
    "stickyHeader"
  ]);
  const { polyglot } = usePolyglot();
  const { data: filters } = useApiClient(FiltersEndpoints.getTimeFiltersConfig(view, userId), {
    suspense: false
  });
  const [state] = useContext(GlobalContext);
  const timeFilters = useMemo(() => {
    var _a2;
    const filterOptions = __spreadValues({}, (_a2 = filters == null ? void 0 : filters.timeFilters) != null ? _a2 : {});
    if (hideTimePolicyFilter) delete filterOptions["Time policy"];
    return filterOptions;
  }, [filters, hideTimePolicyFilter]);
  const { getCachedUserById } = useCachedUsers();
  const [sorting, setSorting] = useState([]);
  const pendingRequests = useMemo(() => {
    return rows.filter(
      (absence) => absence.status === AbsenceStatus.Pending && canApproveOrRejectRequest(absence, state.user.userId)
    );
  }, [rows, state.user]);
  const hiddenTableColumns = useMemo(() => {
    const hasNonHourly = rows && rows.some((a) => !isHourlyPolicy(a.policy));
    return hasNonHourly ? [] : ["workdayCount"];
  }, [rows]);
  const columns = useMemo(
    () => [
      ...setSelectionModel && selectionModel && pendingRequests.length > 0 ? [
        {
          id: "select",
          enableSorting: false,
          minSize: selectColumnSize,
          size: selectColumnSize,
          header: () => {
            const allSelected = selectionModel.length > 0 && selectionModel.length === pendingRequests.length;
            return /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: "allSelected",
                checked: allSelected,
                value: "allSelected",
                onChange: () => {
                  var _a2;
                  if (!allSelected)
                    setSelectionModel(
                      (_a2 = rows.filter(
                        (absence) => absence.status === AbsenceStatus.Pending && canApproveOrRejectRequest(absence, state.user.userId)
                      )) == null ? void 0 : _a2.map((a) => a.absenceId)
                    );
                  else setSelectionModel([]);
                }
              }
            );
          },
          cell: ({ row: { original } }) => {
            var _a2, _b2, _c, _d;
            return original.status === AbsenceStatus.Pending && canApproveOrRejectRequest(original, state.user.userId) ? /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: (_b2 = (_a2 = original.userId) == null ? void 0 : _a2.toString()) != null ? _b2 : "",
                checked: selectionModel.includes(original.absenceId),
                value: (_d = (_c = original.userId) == null ? void 0 : _c.toString()) != null ? _d : "",
                onChange: () => {
                  let finalArray;
                  if (selectionModel == null ? void 0 : selectionModel.includes(original.absenceId)) {
                    finalArray = selectionModel.filter((sm) => sm !== original.absenceId);
                  } else finalArray = [...selectionModel, original.absenceId];
                  setSelectionModel(finalArray);
                }
              }
            ) : null;
          }
        }
      ] : [],
      ...showUserColumn ? [
        {
          header: () => polyglot.t("AbsenceTable.name"),
          accessorFn: (row) => row,
          id: "displayName",
          enableSorting: true,
          sortingFn: (a, b) => sortString(a, b, (item) => {
            var _a2, _b2;
            return item.user ? `${(_a2 = item.user) == null ? void 0 : _a2.firstName} ${(_b2 = item.user) == null ? void 0 : _b2.lastName}` : "";
          }),
          cell: ({ row: { original } }) => {
            return /* @__PURE__ */ jsx(UserCell, { userId: original.userId });
          }
        }
      ] : [],
      {
        header: () => polyglot.t("AbsenceTable.start"),
        accessorFn: (row) => row,
        id: "start",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a2;
          return (_a2 = item == null ? void 0 : item.start) != null ? _a2 : "";
        }),
        cell: ({ row }) => {
          const period = getPeriodFromAbsence(row.original, false);
          return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, whiteSpace: "pre", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx("time", { children: period }),
            hasOverlap(row.original.start, row.original.end, row.original.policy) && /* @__PURE__ */ jsx(
              StyledTooltip,
              {
                title: /* @__PURE__ */ jsx(Typography, { variant: "captionSmall", color: "white", children: polyglot.t("AbsenceTable.startTooltip") }),
                children: /* @__PURE__ */ jsx(Box, { sx: { cursor: "pointer" }, children: /* @__PURE__ */ jsx(InfoIcon, __spreadValues({}, iconSize)) })
              }
            )
          ] });
        }
      },
      {
        header: () => polyglot.t("AbsenceTable.length"),
        id: "totalLength",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a2;
          return (_a2 = item.totalLength) != null ? _a2 : 0;
        }),
        accessorFn: (row) => row,
        cell: ({ row }) => {
          var _a2;
          const isHourly = isHourlyPolicy(row.original.policy);
          const totalLength = (_a2 = row.original.totalLength) != null ? _a2 : 0;
          if (isHourly) {
            const startHour = row.original.startHourTimestamp ? new Date(row.original.startHourTimestamp).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit"
            }).toUpperCase() : void 0;
            const endHour = row.original.endHourTimestamp ? new Date(row.original.endHourTimestamp).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit"
            }).toUpperCase() : void 0;
            return startHour && endHour ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: `${convertMinutesToClockHours(
              totalLength,
              polyglot
            )} (${startHour}-${endHour})` }) : /* @__PURE__ */ jsx(Typography, { variant: "caption", children: convertMinutesToClockHours(totalLength, polyglot) });
          }
          return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: convertMinutesToClockHours(totalLength, polyglot) });
        }
      },
      {
        header: () => polyglot.t("AbsenceTable.workdayCount"),
        id: "workdayCount",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a2;
          return (_a2 = item.workdayCount) != null ? _a2 : 0;
        }),
        accessorFn: (row) => row,
        cell: ({ row }) => {
          const isHourly = isHourlyPolicy(row.original.policy);
          if (isHourly) return /* @__PURE__ */ jsx(EmptyCell, {});
          return /* @__PURE__ */ jsx(Typography, { variant: "caption", children: polyglot.t("General.noOfDays", { smart_count: row.original.workdayCount }) });
        }
      },
      ...hidePolicyColumn ? [] : [
        {
          header: () => polyglot.t("AbsenceTable.policyId"),
          accessorFn: (row) => {
            var _a2;
            return (_a2 = row.policy) == null ? void 0 : _a2.name;
          },
          id: "policyId",
          enableSorting: true,
          sortingFn: (a, b) => sortString(a, b, (item) => {
            var _a2, _b2;
            return (_b2 = (_a2 = item.policy) == null ? void 0 : _a2.name) != null ? _b2 : "";
          }),
          cell: ({ row }) => {
            var _a2, _b2;
            return /* @__PURE__ */ jsx(Box, { children: (_b2 = (_a2 = row.original.policy) == null ? void 0 : _a2.name) != null ? _b2 : /* @__PURE__ */ jsx(EmptyCell, {}) });
          }
        }
      ],
      {
        header: () => polyglot.t("AbsenceTable.status"),
        accessorFn: (row) => row,
        id: "status",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a2;
          return (_a2 = item.status) != null ? _a2 : "";
        }),
        cell: ({ row }) => /* @__PURE__ */ jsx(Box, { children: getStatusCell({ status: row.original.status, absence: row.original }, polyglot) })
      },
      ...handlers.onApprove || handlers.onDelete || handlers.onEdit || handlers.onReject || handlers.onRequestCancellation ? [
        {
          header: () => "",
          id: "action",
          enableSorting: false,
          cell: ({ row: { original } }) => {
            return !original.isPublicHoliday && original.absenceId ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end", cursor: "default" }, children: /* @__PURE__ */ jsx(
              Suspense,
              {
                fallback: /* @__PURE__ */ jsx(
                  SkeletonLoader,
                  {
                    variant: "rectangular",
                    width: "80%",
                    height: "40%",
                    sx: { borderRadius: 2, backgroundColor: themeColors.Background }
                  }
                ),
                children: /* @__PURE__ */ jsx(AbsenceTableActions, __spreadValues({ absence: original }, handlers))
              }
            ) }) : /* @__PURE__ */ jsx(Fragment, {});
          }
        }
      ] : []
    ],
    [
      hidePolicyColumn,
      showUserColumn,
      rows,
      handlers,
      polyglot,
      selectionModel,
      setSelectionModel,
      pendingRequests,
      state.user
    ]
  );
  const handleRowClick = useCallback(
    (row) => {
      onRowClick && onRowClick(row.original);
    },
    [onRowClick]
  );
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          overflow: "hidden"
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(
              FiltersDrawer,
              {
                name: polyglot.t("AbsenceTable.filters"),
                filtersOptions: [{ filters: timeFilters }],
                selectedFilters: filterString,
                setSelectedFilters: setFilterString
              }
            ),
            /* @__PURE__ */ jsx(
              TableSearch,
              {
                query: searchInput,
                handleChange: (e) => {
                  setDebouncedSearchInput(e.target.value);
                }
              }
            )
          ] }),
          newRequestButton && /* @__PURE__ */ jsx(Box, { children: newRequestButton })
        ]
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
      BasicServerTable,
      {
        rowData: [...rows == null ? void 0 : rows.map((row) => __spreadProps(__spreadValues({}, row), { user: getCachedUserById(row.userId) }))],
        columnData: columns,
        loading,
        rowClick: handleRowClick,
        pagination,
        setPagination,
        sorting,
        setSorting,
        totalPages,
        totalItems,
        hiddenColumns: hiddenTableColumns,
        stickyHeader,
        fixedSecondColumn: !!columns.find((c) => c.id === "select")
      }
    ) })
  ] });
}
export const getInitialFilterString = (filters, availablePolicies) => {
  const [upToTypeFilters, fromTypeFiltersOnwards] = filters.split("Policy=");
  if (!fromTypeFiltersOnwards) return filters;
  const [typeFilters, afterTypeFilters] = fromTypeFiltersOnwards.split("&");
  const updatedListOfFilters = typeFilters.split(",").filter(
    (stringId) => availablePolicies.some((policy) => String(policy.id) === stringId || stringId === "publicHoliday")
  );
  if (updatedListOfFilters.length === 0)
    return upToTypeFilters && afterTypeFilters ? `${upToTypeFilters}&${afterTypeFilters != null ? afterTypeFilters : ""}` : upToTypeFilters ? upToTypeFilters != null ? upToTypeFilters : "" : afterTypeFilters != null ? afterTypeFilters : "";
  const updatedStringListOfFilters = updatedListOfFilters.join(",");
  if (typeFilters === updatedStringListOfFilters) return filters;
  return filters.replace(`Policy=${typeFilters}`, `Policy=${updatedStringListOfFilters}`);
};
const getStatusCell = ({ status, absence }, polyglot) => {
  if (absence.isPublicHoliday) {
    if (status === AbsenceStatus.Pending)
      return /* @__PURE__ */ jsxs(Box, { sx: { gap: spacing.sm, display: "flex", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(WaitingEmpty, __spreadValues({}, iconSize)),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: translateAbsenceStatuses("deletionrequested", polyglot) })
      ] });
    if (status === AbsenceStatus.Rejected)
      return /* @__PURE__ */ jsxs(Box, { sx: { gap: spacing.sm, display: "flex", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: translateAbsenceStatuses("deleted", polyglot) })
      ] });
    return /* @__PURE__ */ jsxs(Box, { sx: { gap: spacing.sm, display: "flex", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: translateAbsenceStatuses("autoapproved", polyglot) })
    ] });
  }
  switch (status) {
    case AbsenceStatus.Pending:
      return /* @__PURE__ */ jsxs(Box, { sx: { gap: spacing.sm, display: "flex", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(WaitingEmpty, __spreadValues({}, iconSize)),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", children: translateAbsenceStatuses(absence.status, polyglot) })
      ] });
    case AbsenceStatus.Approved:
      return absence.cancellationRequested ? /* @__PURE__ */ jsxs(Box, { sx: { gap: spacing.sm, display: "flex", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(WaitingEmptyRed, __spreadValues({}, iconSize)),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Red", children: polyglot.t("AbsenceViewDrawerContent.pendingCancellation") })
      ] }) : /* @__PURE__ */ jsxs(Box, { sx: { gap: spacing.sm, display: "flex", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: translateAbsenceStatuses(absence.status, polyglot) })
      ] });
    case AbsenceStatus.Rejected:
      return /* @__PURE__ */ jsxs(Box, { sx: { gap: spacing.sm, display: "flex", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
        /* @__PURE__ */ jsx(Typography, { variant: "caption", children: translateAbsenceStatuses(absence.status, polyglot) })
      ] });
    default:
      return /* @__PURE__ */ jsx(Fragment, {});
  }
};
