"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { ReactComponent as MistakeIcon } from "@/images/side-bar-icons/MistakeGray.svg";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
const iconSize = { width: 14, height: 14 };
export const EmptyCell = ({ textAlign = "left" }) => {
  return /* @__PURE__ */ jsx(
    Typography,
    {
      sx: __spreadProps(__spreadValues({}, themeFonts.caption), {
        textAlign,
        color: themeColors.Grey
      }),
      children: "\u2014"
    }
  );
};
export const IconEmptyCell = () => {
  return /* @__PURE__ */ jsx(MistakeIcon, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.middleGrey } }));
};
