"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { generatePath, Redirect, Route, Switch } from "react-router-dom";
import { GlobalContext, GlobalStateActions, GlobalStateProvider } from "@/GlobalState";
import {
  LOGIN_ROUTE,
  USER_ONBOARDING_ABOUT,
  USER_ONBOARDING_DOCUMENTS,
  USER_ONBOARDING_PAYROLL,
  USER_ONBOARDING_SUMMARY_ROUTE,
  USER_ONBOARDING_TASKS
} from "@/lib/routes";
import { Loader } from "@/v2/components/loader.component";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { UserFieldsProvider } from "@/v2/feature/user/context/user-fields.context";
import { UserOnboardingByUserProvider } from "@/v2/feature/user-onboarding/onboarding-by-user/onboarding-by-user.context";
import { OnboardingBasicFlow } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-basic/onboarding-basic-flow.page";
import { OnboardingDocumentsPage } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-documents/onboarding-documents.page";
import { OnboardingPayrollPage } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/onboarding-payroll.page";
import { OnboardingTasksPage } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-tasks/onboarding-tasks.page";
const LayoutRootStyle = styled("div")({
  display: "flex",
  height: "100%",
  width: "100%",
  overflow: "hidden"
});
const RedirectToOnboardingSummary = () => {
  const [path, setPath] = useState();
  useEffect(() => {
    AuthAPI.getAuthMe().then(
      ({ user }) => setPath(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId: user.userId })),
      () => setPath(LOGIN_ROUTE)
    );
  }, []);
  return path ? /* @__PURE__ */ jsx(Redirect, { to: path }) : /* @__PURE__ */ jsx(Fragment, {});
};
export const OnboardingUserRouter = () => {
  const [globalState, dispatch] = useContext(GlobalContext);
  const [user, setUser] = useState(void 0);
  const [publicURL, setPublicURL] = useState(void 0);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    AuthAPI.getAuthMe().then((response) => {
      const { user: user2, publicURL: publicURL2 } = response;
      const authUser = user2 != null ? user2 : null;
      setUser(authUser);
      setPublicURL(publicURL2);
      dispatch({ type: GlobalStateActions.UPDATE_USER, payload: authUser });
      dispatch({ type: GlobalStateActions.UPDATE_PUBLIC_URL, payload: publicURL2 });
    }).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch]);
  return /* @__PURE__ */ jsx(LayoutRootStyle, { children: /* @__PURE__ */ jsx(UserOnboardingByUserProvider, { children: !isLoading && user ? /* @__PURE__ */ jsx(GlobalStateProvider, { initialState: __spreadProps(__spreadValues({}, globalState), { user, publicURL: publicURL || null }), children: /* @__PURE__ */ jsx(UserFieldsProvider, { children: /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { exact: true, path: USER_ONBOARDING_ABOUT, children: /* @__PURE__ */ jsx(OnboardingBasicFlow, {}) }),
    /* @__PURE__ */ jsx(Route, { exact: true, path: USER_ONBOARDING_DOCUMENTS, children: /* @__PURE__ */ jsx(OnboardingDocumentsPage, {}) }),
    /* @__PURE__ */ jsx(Route, { exact: true, path: USER_ONBOARDING_PAYROLL, children: /* @__PURE__ */ jsx(OnboardingPayrollPage, { mode: "onboarding" }) }),
    /* @__PURE__ */ jsx(Route, { exact: true, path: USER_ONBOARDING_TASKS, children: /* @__PURE__ */ jsx(OnboardingTasksPage, {}) }),
    /* @__PURE__ */ jsx(Route, { component: RedirectToOnboardingSummary })
  ] }) }) }) : /* @__PURE__ */ jsx(Loader, { loading: !!isLoading, hideFooter: true, children: /* @__PURE__ */ jsx(Fragment, { children: "Loading" }) }) }) });
};
