"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { UserContractSchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { EditIdVerify } from "@/v2/feature/onboarding/onboarding-items/id-verify/edit-id-verify.component";
import { RightToWorkAPI } from "@/v2/feature/user/features/user-forms/user-right-work/user-right-work.api";
import {
  CardStructure,
  FieldStructure
} from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import { ViewResultsForIdChecksModal } from "@/v2/feature/user/features/user-profile/details/components/id-checks/view-results-for-id-checks.component";
import {
  calculateSkeletonHeight,
  cardSx,
  definitionListSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { RightToWorkForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-right-to-work-form.component";
import {
  ID_CHECK_COMPLETED_STATES,
  MANUAL_RTW_CHECK
} from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { OnboardingAPI, OnboardingEndpoints } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { toTitleCase } from "@/v2/util/string.util";
const skeletonHeight = (polyglot) => calculateSkeletonHeight(UserContractSchema(polyglot));
const RTW_SCOPE = "user.rightWork";
const SCOPE = ["user.onboard:all", "user.rightWork:all"];
export const IdentityChecksCard = ({ userId }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const currentUserContext = getScopesContext(state.user);
  const scopesContext = getScopesContext({ userId });
  const isAdminUser = hasScopes(["user.onboard:all"], currentUserContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [viewResultsOpen, setViewResultsOpen] = useState(false);
  const [loadingRTW, setLoadingRTW] = useState(false);
  const [rtwData, setRTWData] = useState();
  const [documents, setDocuments] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [manualCheckOpen, setManualCheckOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [currentIdCheck, setCurrentIdCheck] = useState(null);
  const [idCheckState, setIdCheckState] = useState();
  const { data: idCheckResult, isValidating: loadingIdChecks, mutate: refreshIdChecks } = useApiClient(
    OnboardingEndpoints.getIDCheckResult(userId),
    {
      suspense: false
    }
  );
  const idCheckDocumentToDisplay = useCallback(
    (requestId) => {
      var _a2, _b2;
      return documents && Array.isArray(documents) && documents.length > 0 ? (_b2 = (_a2 = documents.find(
        (d) => d.type === "identityCheck" && d.programmaticRecordId && d.programmaticRecordId.includes(requestId)
      )) == null ? void 0 : _a2.label) != null ? _b2 : void 0 : void 0;
    },
    [documents]
  );
  const documentNameBasedOnIdChecks = useCallback(
    (row) => {
      const idCheckDocument = idCheckDocumentToDisplay(row.requestId);
      return row.status && ID_CHECK_COMPLETED_STATES.has(row.status) && idCheckDocument ? idCheckDocument : void 0;
    },
    [idCheckDocumentToDisplay]
  );
  const fetchDocuments = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoadingRTW(true);
      if (!userId) return;
      const userRTWDocuments = yield RightToWorkAPI.getDocumentsByUserId(userId);
      setDocuments((userRTWDocuments == null ? void 0 : userRTWDocuments.length) > 0 ? userRTWDocuments : []);
    } catch (error) {
      showMessage("Identity check could not be loaded. Please try again.", "error");
    } finally {
      setLoadingRTW(false);
    }
  }), [userId, showMessage]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      const typeData = yield DocumentAPI.listDocumentTypes();
      const newData = typeData == null ? void 0 : typeData.filter(({ category }) => category === "Personal Documents");
      setDocumentTypes(newData);
    }))();
  }, []);
  useEffect(() => {
    setIdCheckState(idCheckResult);
  }, [idCheckResult]);
  const userHasIdCheck = useMemo(() => {
    return idCheckState && (idCheckState == null ? void 0 : idCheckState.length) > 0 ? true : false;
  }, [idCheckState]);
  const newIdCheck = () => {
    setDrawerOpen(true);
  };
  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);
  const initateIdentityChecks = useCallback(
    (packageForIdCheck, checkCountry) => __async(void 0, null, function* () {
      try {
        if (packageForIdCheck) {
          yield OnboardingAPI.initiateIdentityChecksFromUserProfile(userId, packageForIdCheck, checkCountry);
          showMessage("The user will receive an invite to start checks shortly", "success");
        }
      } catch (error) {
        showMessage(
          `Encountered an error while trying to initiate an identity check: ${nestErrorMessage(error)}`,
          "error"
        );
      }
    }),
    [showMessage, userId]
  );
  const getEmptyStateMessage = () => {
    return isAdminUser ? "You can verify documents and references for this employee" : "Any ongoing / completed identity check will appear here";
  };
  const canInitiateNewIdCheck = isAdminUser;
  const documentForManualRTWCheckEntry = useCallback(
    (check) => {
      return (check == null ? void 0 : check.requestId) === MANUAL_RTW_CHECK && (documents == null ? void 0 : documents.length) > 0 ? documents.find((d) => d.documentId === check.reportId) : void 0;
    },
    [documents]
  );
  const idCheckButtonForState = useMemo(() => {
    if (canInitiateNewIdCheck) return newIdCheck;
    else return void 0;
  }, [canInitiateNewIdCheck]);
  const IdCheckActionsMenuStructure = [
    {
      label: polyglot.t("UserProfileIdentityChecks.newAutomatedCheck"),
      handler: () => {
        newIdCheck();
      },
      hidden: !canInitiateNewIdCheck,
      scopes: SCOPE,
      context: scopesContext
    },
    {
      label: polyglot.t("UserProfileIdentityChecks.addManually"),
      handler: () => {
        setDrawerOpen(false);
        setCurrentIdCheck(null);
        setManualCheckOpen(true);
      },
      scopes: SCOPE,
      context: scopesContext
    }
  ];
  const combinedListOfIdChecksAndRtwData = useMemo(() => {
    var _a2, _b2;
    const checksData = [];
    if ((idCheckState == null ? void 0 : idCheckState.length) && idCheckState.length > 0) {
      for (const check of idCheckState) {
        if (check.requestId === MANUAL_RTW_CHECK) {
          checksData.push(__spreadProps(__spreadValues({}, check), {
            reportUrl: (_b2 = (_a2 = documentForManualRTWCheckEntry(check)) == null ? void 0 : _a2.documentUuid) != null ? _b2 : ""
          }));
        } else {
          checksData.push(check);
        }
      }
    }
    return checksData;
  }, [documentForManualRTWCheckEntry, idCheckState]);
  const getDateForIdCheckEntry = (check) => {
    var _a2;
    return (check == null ? void 0 : check.requestId) === MANUAL_RTW_CHECK && check.completedAt ? getDateString(check.completedAt) : (_a2 = getDateString(check.createdAt)) != null ? _a2 : "";
  };
  const cardFieldDetails = useMemo(() => {
    const latestCheck = combinedListOfIdChecksAndRtwData.sort((a, b) => {
      const aIsManualRTWCheck = a.requestId === MANUAL_RTW_CHECK;
      const bIsManualRTWCheck = b.requestId === MANUAL_RTW_CHECK;
      return new Date(bIsManualRTWCheck ? b.completedAt : b.createdAt).getTime() - new Date(aIsManualRTWCheck ? a.completedAt : a.createdAt).getTime();
    })[0];
    if (combinedListOfIdChecksAndRtwData && (combinedListOfIdChecksAndRtwData == null ? void 0 : combinedListOfIdChecksAndRtwData.length) > 0 && latestCheck) {
      const isManualRTWCheck = latestCheck.requestId === MANUAL_RTW_CHECK;
      const idCheckDocumentName = isManualRTWCheck ? void 0 : documentNameBasedOnIdChecks(latestCheck);
      const docForManualRTWCheck = isManualRTWCheck ? documentForManualRTWCheckEntry(latestCheck) : void 0;
      const documentNameForLatestCheck = isManualRTWCheck ? docForManualRTWCheck == null ? void 0 : docForManualRTWCheck.label : idCheckDocumentName;
      return /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("IdCheck.latestCheckDate"),
            fieldValue: latestCheck ? getDateForIdCheckEntry(latestCheck) : ""
          }
        ),
        latestCheck && latestCheck.status && /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("IdCheck.status"),
            fieldValue: latestCheck && latestCheck.status ? toTitleCase(latestCheck.status) : void 0
          }
        ),
        latestCheck && latestCheck.result && /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: polyglot.t("IdCheck.result"),
            fieldValue: latestCheck.result ? toTitleCase(latestCheck.result) : void 0
          }
        ),
        documentNameForLatestCheck && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: polyglot.t("IdCheck.document"), fieldValue: documentNameForLatestCheck != null ? documentNameForLatestCheck : "" })
      ] });
    }
    return /* @__PURE__ */ jsx(Fragment, {});
  }, [combinedListOfIdChecksAndRtwData, documentForManualRTWCheckEntry, documentNameBasedOnIdChecks, polyglot]);
  const columnData = useMemo(
    () => [
      // {
      //   accessorFn: (row) => row,
      //   id: 'requestId',
      //   enableSorting: false,
      //   cell: ({ row: { original } }) => <div>{original?.requestId ? original.requestId : ''}</div>,
      // },
      {
        header: () => polyglot.t("IdCheck.dateOfCheck"),
        accessorFn: (row) => row,
        id: "dateOfCheck",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: getDateForIdCheckEntry(original) })
      },
      {
        header: () => polyglot.t("IdCheck.status"),
        accessorFn: (row) => row,
        id: "status",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { style: { textTransform: "capitalize" }, children: original.status ? original.status : "" })
      },
      {
        header: () => polyglot.t("IdCheck.resultZinc"),
        accessorFn: (row) => row,
        id: "result",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { style: { textTransform: "capitalize" }, children: original.result ? original.result : "" })
      },
      {
        header: () => polyglot.t("IdCheck.documentName"),
        accessorFn: (row) => {
          var _a2;
          const isManualRTWCheck = row.requestId === MANUAL_RTW_CHECK;
          const idCheckDocumentName = isManualRTWCheck ? void 0 : documentNameBasedOnIdChecks(row);
          const docForManualRTWCheck = isManualRTWCheck ? documentForManualRTWCheckEntry(row) : void 0;
          return ((_a2 = row == null ? void 0 : row.requestId) == null ? void 0 : _a2.length) > 0 && idCheckDocumentName ? idCheckDocumentName : docForManualRTWCheck ? docForManualRTWCheck.label : "";
        },
        id: "result",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a2;
          const isManualRTWCheck = original.requestId === MANUAL_RTW_CHECK;
          const idCheckDocumentName = isManualRTWCheck ? void 0 : documentNameBasedOnIdChecks(original);
          const docForManualRTWCheck = isManualRTWCheck ? documentForManualRTWCheckEntry(original) : void 0;
          return /* @__PURE__ */ jsx("div", { children: ((_a2 = original == null ? void 0 : original.requestId) == null ? void 0 : _a2.length) > 0 && idCheckDocumentName ? idCheckDocumentName : docForManualRTWCheck ? docForManualRTWCheck.label : "" });
        }
      }
    ],
    [documentForManualRTWCheckEntry, documentNameBasedOnIdChecks, polyglot]
  );
  const handleRowClick = useCallback(({ original }) => {
    if (original.requestId === MANUAL_RTW_CHECK) {
      setManualCheckOpen(true);
    } else {
      setViewResultsOpen(true);
    }
    setCurrentIdCheck(original);
  }, []);
  const currentIdCheckIsExternal = useMemo(() => {
    if (currentIdCheck) {
      return currentIdCheck.requestId !== MANUAL_RTW_CHECK;
    }
    return false;
  }, [currentIdCheck]);
  const refreshIdCheckAndRtwData = useCallback(() => {
    refreshIdChecks();
    fetchDocuments();
  }, [refreshIdChecks, fetchDocuments]);
  return loadingIdChecks || loadingRTW ? /* @__PURE__ */ jsx(
    SkeletonLoader,
    {
      variant: "rectangular",
      height: skeletonHeight(polyglot),
      sx: [cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]
    }
  ) : /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      CardStructure,
      {
        cardTitle: "Identity check",
        cardScope: SCOPE,
        showHistoryButton: (combinedListOfIdChecksAndRtwData == null ? void 0 : combinedListOfIdChecksAndRtwData.length) > 0,
        showEditButton: false,
        showDetails,
        setShowDetails,
        userId,
        showMissingInfo: !userHasIdCheck,
        addActionMenuOptions: IdCheckActionsMenuStructure,
        addAction: idCheckButtonForState,
        addButtonTitle: "New check",
        newAction: isAdminUser && !userHasIdCheck ? newIdCheck : void 0,
        newButtonTitle: "New check",
        emptyText: !userHasIdCheck ? getEmptyStateMessage() : void 0,
        cardFieldCustomUpdates: rtwData == null ? void 0 : rtwData.customUpdates,
        cardFieldStubs: ["user.rightToWorkCheckDate", "user.rightToWorkDocuments"],
        cardFieldDetails,
        drawerDetails: /* @__PURE__ */ jsx(DrawerModal, { isOpen: drawerOpen, setIsOpen: setDrawerOpen, children: /* @__PURE__ */ jsx(
          EditIdVerify,
          {
            onSave: (checkPackage, checkCountry) => __async(void 0, null, function* () {
              yield initateIdentityChecks(checkPackage, checkCountry);
              refreshIdCheckAndRtwData();
              setDrawerOpen(false);
            }),
            mode: "profile"
          }
        ) }),
        tableRowData: combinedListOfIdChecksAndRtwData,
        tableColumn: columnData,
        tableRowClickHandler: (row) => handleRowClick(row)
      }
    ),
    currentIdCheck && currentIdCheckIsExternal && /* @__PURE__ */ jsx(
      ViewResultsForIdChecksModal,
      {
        isOpen: viewResultsOpen,
        setIsOpen: setViewResultsOpen,
        idCheckState: currentIdCheck,
        idCheckDocumentToDisplay: currentIdCheckIsExternal ? documentNameBasedOnIdChecks(currentIdCheck) : (_b = (_a = documentForManualRTWCheckEntry(currentIdCheck)) == null ? void 0 : _a.label) != null ? _b : ""
      }
    ),
    /* @__PURE__ */ jsx(ScopesControl, { scopes: [RTW_SCOPE], context: scopesContext, children: userId && /* @__PURE__ */ jsx(DrawerModal, { isOpen: manualCheckOpen, setIsOpen: setManualCheckOpen, children: /* @__PURE__ */ jsx(
      RightToWorkForm,
      {
        userId,
        initialValues: {
          id: currentIdCheck == null ? void 0 : currentIdCheck.id,
          rightToWorkCheckDate: (currentIdCheck == null ? void 0 : currentIdCheck.completedAt) ? getDateString(currentIdCheck == null ? void 0 : currentIdCheck.completedAt, false, "yyyy-MM-dd") : "",
          rightToWorkDocuments: (currentIdCheck == null ? void 0 : currentIdCheck.reportId) ? currentIdCheck == null ? void 0 : currentIdCheck.reportId.toString() : "",
          rightToWorkChecked: true,
          rightToWorkExpiryDate: null,
          rightToWorkIsLimited: null
        },
        documents,
        fetchDocuments,
        onSubmit: (values) => {
          setRTWData(values);
          setManualCheckOpen(false);
        },
        onClose: () => setManualCheckOpen(false),
        documentTypes,
        fetchData: () => __async(void 0, null, function* () {
          return refreshIdCheckAndRtwData();
        })
      }
    ) }) })
  ] });
};
