"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { PayrollCompanyRouter } from "@v2/feature/payroll/payroll-company.router";
import { PayrollMeRouter } from "@v2/feature/payroll/payroll-me.router";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { PAYROLL_COMPANY_ROUTE, PAYROLL_ME_ROUTE, PAYROLL_ME_SUMMARY_ROUTE, PAYROLL_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { useUserProfileConfig } from "@/v2/feature/user/features/user-profile/user-profile.hook";
export const PayrollRouter = () => {
  var _a, _b, _c;
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const userId = user.userId;
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const { userOverviewConfig, loading: loadingUser } = useUserProfileConfig(userId);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: [PAYROLL_ME_ROUTE, PAYROLL_COMPANY_ROUTE], children: /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          scopes: ["payroll:read:all", "payroll.selectedEntity:all"],
          path: PAYROLL_COMPANY_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(PayrollCompanyRouter, { loading: loadingUser })
        }
      ),
      ((_a = globalState.user) == null ? void 0 : _a.contractType) === "Employee" && /* @__PURE__ */ jsx(RouteScopesControl, { path: PAYROLL_ME_ROUTE, scopes: ["payroll:read"], context: scopesContext, children: /* @__PURE__ */ jsx(PayrollMeRouter, { userOverviewConfig }) })
    ] }) }),
    checkScopes(globalState.user, ["payroll:all", "payroll.selectedEntity:all"]) && /* @__PURE__ */ jsx(
      RouteScopesControl,
      {
        path: PAYROLL_ROUTE,
        scopes: ["payroll:all", "payroll.selectedEntity:all"],
        context: scopesContext,
        children: /* @__PURE__ */ jsx(Redirect, { to: PAYROLL_COMPANY_ROUTE })
      }
    ),
    Boolean(user.contractType === "Employee") && !Boolean((_c = (_b = user == null ? void 0 : user.navigation) == null ? void 0 : _b.salary) == null ? void 0 : _c.isHidden) && /* @__PURE__ */ jsx(Redirect, { from: PAYROLL_ROUTE, to: PAYROLL_ME_SUMMARY_ROUTE })
  ] });
};
