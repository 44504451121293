"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as EyeSlash } from "@/images/new-theme-icon/HiddenEye.svg";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { GrowthScaleArchiveModal } from "@/v2/feature/growth/growth-scale/components/growth-scale-archive-modal.component";
import { GrowthScaleFormModal } from "@/v2/feature/growth/growth-scale/components/growth-scale-form-modal.component";
import { GrowthScaleViewModal } from "@/v2/feature/growth/growth-scale/components/growth-scale-view-modal.component";
import { GrowthScaleEndpoints } from "@/v2/feature/growth/growth-scale/growth-scale.api";
import { PayrollLogoZelt } from "@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const GrowthScalePage = () => {
  const { data: allScales, isLoading, mutate: refreshScales } = useApiClient(
    GrowthScaleEndpoints.getGrowthScalesByCompanyId(),
    {
      suspense: false
    }
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isArchiveOpen, setIsArchiveOpen] = useState(false);
  const [selectedScale, setSelectedScale] = useState(void 0);
  const [searchInput, setSearchInput] = useState("");
  const filteredScales = useMemo(() => {
    if (!searchInput || !allScales) return allScales;
    const search = searchInput.trim().toLowerCase();
    return allScales.filter((scale) => {
      return scale.type.toLowerCase().includes(search);
    });
  }, [allScales, searchInput]);
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
    /* @__PURE__ */ jsx(
      SettingsSectionContent,
      {
        title: "Scales",
        contentWidth: "100%",
        headerWidth: "100%",
        buttons: [
          /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "primary", sizeVariant: "small", onClick: () => setIsOpen(true), children: "New scale" })
        ],
        children: /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            loadingAll: isLoading,
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsxs(Box, { children: [
                      /* @__PURE__ */ jsx(
                        TableSearch,
                        {
                          query: searchInput,
                          handleChange: (e) => {
                            var _a, _b;
                            return setSearchInput((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
                          },
                          style: { marginBottom: "16px" }
                        }
                      ),
                      /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: (filteredScales || []).map((item) => {
                        const avatar = item.companyId === null ? /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g8 }, children: [
                          /* @__PURE__ */ jsx(PayrollLogoZelt, { height: "0.9em", width: "0.9em", padding: ".25em" }),
                          "Zelt"
                        ] }) : /* @__PURE__ */ jsx(UserCell, { userId: item.createdBy });
                        return /* @__PURE__ */ jsx(
                          SettingsItemCard,
                          {
                            title: item.type,
                            boxSx: responsiveCardStyle,
                            contentItemsSets: [
                              {
                                type: ContentItemType.component,
                                contentComponent: /* @__PURE__ */ jsx(
                                  ChipComponent,
                                  {
                                    name: avatar,
                                    backgroundColor: "white",
                                    textColor: "DarkGrey",
                                    textVariant: "caption",
                                    border: "middle"
                                  }
                                )
                              },
                              {
                                name: `${new LocalDate(item.createdAt).getDate().toLocaleDateString(void 0, {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric"
                                })}`,
                                type: ContentItemType.chip,
                                textColor: "DarkGrey",
                                backgroundColor: "white",
                                border: "middle"
                              }
                            ],
                            boxAction: () => {
                              setSelectedScale(item);
                              setIsViewOpen(true);
                            },
                            advanceActionHidden: !item.companyId,
                            advanceActionOptions: [
                              {
                                icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
                                handler: () => {
                                  setSelectedScale(item);
                                  setIsOpen(true);
                                },
                                label: "Edit item",
                                disabled: false
                              },
                              {
                                icon: /* @__PURE__ */ jsx(EyeSlash, __spreadValues({}, iconSize)),
                                handler: () => __async(void 0, null, function* () {
                                  setSelectedScale(item);
                                  setIsArchiveOpen(true);
                                }),
                                label: "Archive item",
                                disabled: false
                              }
                            ]
                          },
                          item.type
                        );
                      }) })
                    ] })
                  }
                ]
              }
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      GrowthScaleFormModal,
      {
        isOpen,
        setIsOpen,
        onClose: () => {
          setIsOpen(false);
          setTimeout(() => setSelectedScale(void 0), 300);
        },
        afterClose: () => setSelectedScale(void 0),
        growthScale: selectedScale,
        refresh: () => __async(void 0, null, function* () {
          yield refreshScales == null ? void 0 : refreshScales();
        })
      }
    ),
    /* @__PURE__ */ jsx(
      GrowthScaleViewModal,
      {
        isOpen: isViewOpen,
        setIsOpen: setIsViewOpen,
        onClose: () => {
          setIsViewOpen(false);
          setTimeout(() => setSelectedScale(void 0), 300);
        },
        afterClose: () => setSelectedScale(void 0),
        growthScale: selectedScale
      }
    ),
    /* @__PURE__ */ jsx(
      GrowthScaleArchiveModal,
      {
        isOpen: isArchiveOpen,
        setIsOpen: setIsArchiveOpen,
        onClose: () => {
          setIsArchiveOpen(false);
          setTimeout(() => setSelectedScale(void 0), 300);
        },
        afterClose: () => setSelectedScale(void 0),
        selectedScale,
        refresh: refreshScales
      }
    )
  ] });
};
