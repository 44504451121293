"use strict";
import * as yup from "yup";
export const canApproveOrRejectRequest = (request, currentUserId) => {
  var _a;
  const firstIncompleteStep = request.approverSteps.find((step) => !step.isComplete);
  if (!firstIncompleteStep) return false;
  if (!((_a = firstIncompleteStep.approversIds) == null ? void 0 : _a.includes(currentUserId))) return false;
  if (!request.approvedByIds || request.approvedByIds.includes(currentUserId)) return false;
  if (!request.rejectedByIds || request.rejectedByIds.includes(currentUserId)) return false;
  return true;
};
export const getApprovalStepSchema = (polyglot) => yup.object({
  adminApproval: yup.boolean().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
  managerApproval: yup.boolean().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
  managerManagerApproval: yup.boolean().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
  specificApprovers: yup.array().of(yup.number().integer()).typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
  minApprovers: yup.number().min(1, polyglot.t("ValidationMessages.validValue")).typeError(polyglot.t("ValidationMessages.validValue")).integer(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField"))
});
function isApprovalStepAutoApprove(approvalStep) {
  if (!approvalStep) return false;
  const isSpecificUserApproval = approvalStep.specificApprovers && approvalStep.specificApprovers.length > 0;
  return !approvalStep.adminApproval && !approvalStep.managerApproval && !approvalStep.managerManagerApproval && !isSpecificUserApproval;
}
export function isApprovalRuleAutoApprove(approvalRule) {
  if (!approvalRule) return false;
  return approvalRule.approvalSteps.every((approvalStep) => isApprovalStepAutoApprove(approvalStep));
}
export const getStepDescription = (step, getCachedUserById, polyglot, showItems = -1) => {
  const rules = [
    step.adminApproval ? polyglot.t("ApproverSelectComponent.Admin") : null,
    step.managerApproval ? polyglot.t("ApproverSelectComponent.Manager") : null,
    step.managerManagerApproval ? polyglot.t("ApproverSelectComponent.ManagersManager") : null,
    ...step.specificApprovers.length > 0 ? step.specificApprovers.map((userId) => {
      var _a, _b, _c;
      const user = getCachedUserById(userId);
      return user ? polyglot.t((_c = user.displayName) != null ? _c : `${(_a = user.firstName) != null ? _a : ""} ${(_b = user.lastName) != null ? _b : ""}`) : null;
    }) : []
  ].filter(Boolean);
  if (rules.length === 1)
    return polyglot.t("ApprovalRuleModule.xApprovalsNeededFrom", {
      smart_count: step.minApprovers,
      approversList: rules[0]
    });
  if (showItems > 0 && rules.length > showItems) {
    const shortArray = rules.slice(0, showItems);
    const xOthers = polyglot.t("ApprovalRuleModule.andXOthers", { smart_count: rules.length - showItems });
    const approversList2 = [shortArray.join(", "), polyglot.t("General.and"), xOthers].join(" ");
    return polyglot.t("ApprovalRuleModule.xApprovalsNeededFrom", {
      smart_count: step.minApprovers,
      approversList: approversList2
    });
  }
  const lastItem = rules.pop();
  const approversList = rules.length > 0 ? [rules.join(", "), polyglot.t("General.and"), lastItem].join(" ") : [lastItem];
  return polyglot.t("ApprovalRuleModule.xApprovalsNeededFrom", {
    smart_count: step.minApprovers,
    approversList
  });
};
