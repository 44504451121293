"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/system";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsPageSection } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/settings-page-section.component";
import { DeveloperHubPermissionsEditDrawer } from "@/v2/feature/security/security-settings/features/developer-hub/components/developer-hub-permissions-edit-drawer.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const DeveloperHubPermissionsSettingsPage = ({ oauthApp, onUpdate }) => {
  const { polyglot } = usePolyglot();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const columns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("DeveloperHub.scope"),
        accessorFn: (row) => row,
        id: "scope",
        cell: ({ row: { original } }) => original.scope ? /* @__PURE__ */ jsx(Typography, { variant: "caption", sx: { fontWeight: "bold" }, children: original.scope }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => polyglot.t("DeveloperHub.reasonFor"),
        accessorFn: (row) => row.reason,
        maxWidth: 400,
        // Adjusted width to accommodate longer reasons
        id: "reason",
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.reason ? /* @__PURE__ */ jsx(Typography, { variant: "caption", children: original.reason }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      }
    ];
  }, [polyglot]);
  const scopeReasonData = oauthApp.scopeReasons ? Object.entries(oauthApp.scopeReasons).map(([scope, reason]) => ({
    scope,
    reason
  })) : [];
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(
    SettingsPageSection,
    {
      title: polyglot.t("DeveloperHub.permissions"),
      onEdit: () => {
        setIsEditOpen(true);
      },
      children: [
        /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
          polyglot.t("DeveloperHub.permissionsPageExplainer"),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "text",
              onClick: () => {
                setIsEditOpen(true);
              },
              style: {
                margin: 0,
                padding: 0,
                color: themeColors.hoverBlue,
                display: "inline",
                textDecoration: "underline"
              },
              children: "documentation"
            }
          ),
          "."
        ] }),
        (scopeReasonData == null ? void 0 : scopeReasonData.length) > 0 ? /* @__PURE__ */ jsx(
          BasicTable,
          {
            rowData: scopeReasonData,
            columnData: columns,
            loading: false,
            hidePagination: true,
            externalTdAndThPadding: "0 0px",
            style: { width: "900px" }
          }
        ) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "caption", color: "Grey", sx: { mt: "8px" }, children: polyglot.t("DeveloperHub.noPermissions") }) }),
        isEditOpen && /* @__PURE__ */ jsx(
          DeveloperHubPermissionsEditDrawer,
          {
            isOpen: isEditOpen,
            setIsOpen: setIsEditOpen,
            oauthApp,
            onClose: function() {
              setIsEditOpen(false);
            },
            onUpdate
          }
        )
      ]
    }
  ) });
};
