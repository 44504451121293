"use strict";
import { jsx } from "react/jsx-runtime";
import { FlagIcon } from "@v2/styles/flag-icon.styles";
import { hasFlag } from "country-flag-icons";
import * as Flags from "country-flag-icons/react/3x2";
import { PayrollLogoZelt } from "@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component";
export const CountryFlag = ({
  countryCode,
  height = "1.2em",
  width = "1.2em",
  zeltIconPadding = ".25em"
}) => {
  const countryCodeUpperCase = countryCode == null ? void 0 : countryCode.toUpperCase();
  const normalizedCountryCode = countryCodeUpperCase === "UK" ? "GB" : countryCodeUpperCase;
  if (normalizedCountryCode && hasFlag(normalizedCountryCode)) {
    const FlagComponent = Flags[normalizedCountryCode];
    return /* @__PURE__ */ jsx(FlagIcon, { height, width, children: /* @__PURE__ */ jsx(FlagComponent, { title: normalizedCountryCode }) });
  }
  return /* @__PURE__ */ jsx(PayrollLogoZelt, { height, width, padding: zeltIconPadding });
};
