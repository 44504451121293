"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { HiddenColumnSelector } from "@v2/components/table/hidden-column-selector.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import {
  UserInsuranceDependants
} from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import { UserEndpoints } from "@v2/feature/user/user.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import CsvDownloader from "react-csv-downloader";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { CurrencyCell } from "@/v2/components/table/currency-cell.component";
const getDependantsToLabel = (dependants, polyglot) => {
  if (dependants === UserInsuranceDependants.Children) return polyglot.t("UserInsuranceDependants.Children");
  if (dependants === UserInsuranceDependants.Family) return polyglot.t("UserInsuranceDependants.Family");
  if (dependants === UserInsuranceDependants.SpousePartner) return polyglot.t("UserInsuranceDependants.SpousePartner");
  return "";
};
export const BenefitsCompanyPeoplePage = () => {
  const { polyglot } = usePolyglot();
  const { data: tableData, isValidating } = useApiClient(UserEndpoints.getUserBenefitsPeopleData(), {
    suspense: false
  });
  const [state] = useContext(GlobalContext);
  const [searchInput, setSearchInput] = useState("");
  const [showMessage] = useMessage();
  const [hiddenColumns, setHiddenColumns] = useState([]);
  useEffect(() => {
    var _a, _b, _c, _d;
    setHiddenColumns((_d = (_c = (_b = (_a = state.user.features) == null ? void 0 : _a.benefits) == null ? void 0 : _b.peopleTable) == null ? void 0 : _c.hiddenColumns) != null ? _d : []);
  }, [state]);
  const allColumnsOptions = useMemo(() => {
    var _a, _b;
    const options = [{ name: "Name", value: "userName" }];
    const pensionColumns = ((_a = tableData == null ? void 0 : tableData.tableStructure.pensionSchemes) != null ? _a : []).flatMap((p) => {
      var _a2;
      return [
        {
          name: (_a2 = p.displayName) != null ? _a2 : "",
          value: `pensionScheme${p.id}`
        },
        {
          name: polyglot.t("BenefitsCompanyPeoplePage.employerContribution"),
          value: `pensionEmployerContrib${p.id}`
        },
        {
          name: polyglot.t("BenefitsCompanyPeoplePage.employeeContribution"),
          value: `pensionEmployeeContrib${p.id}`
        }
      ];
    });
    options.push(...pensionColumns);
    const insuranceColumns = (tableData == null ? void 0 : tableData.tableStructure.insurance) ? [
      { name: tableData.tableStructure.insurance.providerName, value: "insurance" },
      { name: polyglot.t("BenefitsCompanyPeoplePage.monthlyPremium"), value: "insuranceMonthlyPremium" },
      { name: polyglot.t("BenefitsCompanyPeoplePage.monthlyContribution"), value: "insuranceMonthlyContribution" },
      { name: polyglot.t("BenefitsCompanyPeoplePage.dependants"), value: "insuranceDependants" }
    ] : [];
    options.push(...insuranceColumns);
    const benefitsColumns = ((_b = tableData == null ? void 0 : tableData.tableStructure.customBenefits) != null ? _b : []).flatMap((cB) => {
      var _a2;
      return [
        { name: (_a2 = cB.name) != null ? _a2 : "Benefit", value: `otherBenefit${cB.id}` },
        { name: polyglot.t("BenefitsCompanyPeoplePage.memberPremium"), value: `benefitPremium${cB.id}` },
        { name: polyglot.t("BenefitsCompanyPeoplePage.memberContribution"), value: `benefitContrib${cB.id}` },
        { name: polyglot.t("BenefitsCompanyPeoplePage.dependants"), value: `benefitDependants${cB.id}` }
      ];
    });
    options.push(...benefitsColumns);
    options.push({ name: "Entity", value: "entityId" });
    return options;
  }, [tableData, polyglot]);
  const tableColumns = useMemo(() => {
    var _a, _b;
    const pensionColumns = (_a = tableData == null ? void 0 : tableData.tableStructure.pensionSchemes.flatMap((p) => [
      {
        id: `pensionScheme${p.id}`,
        header: () => {
          var _a2;
          return (_a2 = p.displayName) != null ? _a2 : "";
        },
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a2;
          return ((_a2 = original.pension) == null ? void 0 : _a2.id) && original.pension.id === p.externalId && original.pension.state ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: original.pension.state }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: `pensionEmployerContrib${p.id}`,
        header: () => polyglot.t("BenefitsCompanyPeoplePage.employerContribution"),
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a2;
          return ((_a2 = original.pension) == null ? void 0 : _a2.id) && original.pension.id === p.externalId && original.pension.employerContribution !== null ? /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.caption, children: [
            original.pension.employerContribution,
            "%"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: `pensionEmployeeContrib${p.id}`,
        header: () => polyglot.t("BenefitsCompanyPeoplePage.employeeContribution"),
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a2;
          return ((_a2 = original.pension) == null ? void 0 : _a2.id) && original.pension.id === p.externalId && original.pension.employeeContribution !== null ? /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.caption, children: [
            original.pension.employeeContribution,
            "%"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      }
    ])) != null ? _a : [];
    const insurance = tableData == null ? void 0 : tableData.tableStructure.insurance;
    const insuranceColumns = insurance ? [
      {
        id: "insurance",
        header: () => insurance.providerName,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => original.insurance.status ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: original.insurance.status }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        id: "insuranceMonthlyPremium",
        header: () => polyglot.t("BenefitsCompanyPeoplePage.monthlyPremium"),
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(CurrencyCell, { value: original.insurance.monthlyPremium, currency: original.insurance.currency })
      },
      {
        id: "insuranceMonthlyContribution",
        header: () => polyglot.t("BenefitsCompanyPeoplePage.monthlyContribution"),
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(CurrencyCell, { value: original.insurance.monthlyContribution, currency: original.insurance.currency })
      },
      {
        id: "insuranceDependants",
        header: () => polyglot.t("BenefitsCompanyPeoplePage.dependants"),
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => original.insurance.dependants ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: getDependantsToLabel(original.insurance.dependants, polyglot) }) : /* @__PURE__ */ jsx(EmptyCell, {})
      }
    ] : [];
    const customBenefitsColumns = (_b = tableData == null ? void 0 : tableData.tableStructure.customBenefits.flatMap((cB) => [
      {
        id: `otherBenefit${cB.id}`,
        header: () => {
          var _a2;
          return (_a2 = cB.name) != null ? _a2 : "";
        },
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const benefit = original.customBenefits.find((b) => b.id && b.id === cB.id);
          return benefit ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: polyglot.t("BenefitsCompanyPeoplePage.member") }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: `benefitPremium${cB.id}`,
        header: () => polyglot.t("BenefitsCompanyPeoplePage.memberPremium"),
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const benefit = original.customBenefits.find((b) => b.id && b.id === cB.id);
          return /* @__PURE__ */ jsx(CurrencyCell, { value: benefit == null ? void 0 : benefit.memberPremium, currency: benefit == null ? void 0 : benefit.currency });
        }
      },
      {
        id: `benefitContrib${cB.id}`,
        header: () => polyglot.t("BenefitsCompanyPeoplePage.memberContribution"),
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const benefit = original.customBenefits.find((b) => b.id && b.id === cB.id);
          return /* @__PURE__ */ jsx(CurrencyCell, { value: benefit == null ? void 0 : benefit.memberContribution, currency: benefit == null ? void 0 : benefit.currency });
        }
      },
      {
        id: `benefitDependants${cB.id}`,
        header: () => polyglot.t("BenefitsCompanyPeoplePage.dependants"),
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const benefit = original.customBenefits.find((b) => b.id && b.id === cB.id);
          return (benefit == null ? void 0 : benefit.dependants) ? /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: getDependantsToLabel(benefit.dependants, polyglot) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      }
    ])) != null ? _b : [];
    return [
      {
        id: "userName",
        header: () => polyglot.t("BenefitsCompanyPeoplePage.name"),
        enableSorting: false,
        accessorFn: (row) => row.name,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: polyglot.t(original.name) })
      },
      ...pensionColumns,
      ...insuranceColumns,
      ...customBenefitsColumns,
      {
        id: "entityId",
        header: () => polyglot.t("BenefitsCompanyPeoplePage.entity"),
        enableSorting: false,
        accessorFn: (row) => row.entityName,
        cell: ({ row: { original } }) => {
          var _a2;
          return /* @__PURE__ */ jsx(Typography, { sx: themeFonts.caption, children: polyglot.t((_a2 = original.entityName) != null ? _a2 : "") });
        }
      }
    ];
  }, [tableData, polyglot]);
  const filteredColumns = useMemo(() => {
    return tableColumns.filter((column) => !hiddenColumns.includes(column.id));
  }, [hiddenColumns, tableColumns]);
  const tableRows = useMemo(() => {
    if (!tableData) return [];
    const data = tableData.usersData.map((u) => {
      var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t;
      const pensionScheme = !((_a = u.pension) == null ? void 0 : _a.inPension) || !u.pension.pensionProviderId ? null : tableData.tableStructure.pensionSchemes.find((p) => {
        var _a2;
        return p.externalId === ((_a2 = u.pension) == null ? void 0 : _a2.pensionProviderId);
      });
      const pension = u.pension && pensionScheme ? {
        id: pensionScheme.externalId,
        displayName: (_b = pensionScheme.displayName) != null ? _b : null,
        state: u.pension.state,
        employerContribution: u.pension.employerContribution,
        employeeContribution: u.pension.employeeContribution
      } : null;
      const insurance = {
        providerName: (_d = (_c = tableData.tableStructure.insurance) == null ? void 0 : _c.providerName) != null ? _d : null,
        status: (_f = (_e = u.insurance) == null ? void 0 : _e.status) != null ? _f : null,
        monthlyPremium: (_h = (_g = u.insurance) == null ? void 0 : _g.monthlyPremium) != null ? _h : null,
        monthlyContribution: (_j = (_i = u.insurance) == null ? void 0 : _i.monthlyContribution) != null ? _j : null,
        dependants: (_l = (_k = u.insurance) == null ? void 0 : _k.dependants) != null ? _l : null,
        dependantsMonthlyPremium: (_n = (_m = u.insurance) == null ? void 0 : _m.dependantsMonthlyPremium) != null ? _n : null,
        dependantsMonthlyContribution: (_p = (_o = u.insurance) == null ? void 0 : _o.dependantsMonthlyContribution) != null ? _p : null,
        dependantsStatus: (_r = (_q = u.insurance) == null ? void 0 : _q.dependantsStatus) != null ? _r : null,
        currency: (_t = (_s = u.insurance) == null ? void 0 : _s.currency) != null ? _t : "GBP"
      };
      const customBenefits = u.customBenefits.reduce(
        (result, uB) => {
          const benefit = tableData.tableStructure.customBenefits.find((cB) => cB.id === uB.customBenefitId);
          if (benefit)
            result.push({
              id: benefit.id,
              name: benefit.name,
              memberPremium: uB.employerContribution,
              memberContribution: uB.employeeContribution,
              dependants: uB.dependants,
              currency: uB.currency
            });
          return result;
        },
        []
      );
      return {
        userId: u.userId,
        name: u.name,
        pension,
        insurance,
        customBenefits,
        entityName: u.entityName
      };
    });
    if (!searchInput) return data;
    const search = searchInput.toLowerCase();
    return data.filter((d) => d.name.toLowerCase().includes(search));
  }, [tableData, searchInput]);
  const getExportData = useCallback(() => {
    var _a, _b, _c, _d;
    if (!tableData) return [];
    try {
      const header = [polyglot.t("BenefitsCompanyPeoplePage.userId"), "Name"];
      for (const pS of (_a = tableData.tableStructure.pensionSchemes) != null ? _a : [])
        if (pS.displayName)
          header.push(
            pS.displayName,
            polyglot.t("BenefitsCompanyPeoplePage.startDate"),
            polyglot.t("BenefitsCompanyPeoplePage.employerContribution"),
            polyglot.t("BenefitsCompanyPeoplePage.employeeContribution")
          );
      if ((_b = tableData.tableStructure.insurance) == null ? void 0 : _b.providerName)
        header.push(
          tableData.tableStructure.insurance.providerName,
          polyglot.t("BenefitsCompanyPeoplePage.startDate"),
          polyglot.t("BenefitsCompanyPeoplePage.endDate"),
          polyglot.t("BenefitsCompanyPeoplePage.monthlyPremium"),
          polyglot.t("BenefitsCompanyPeoplePage.monthlyContribution"),
          polyglot.t("BenefitsCompanyPeoplePage.dependants")
        );
      for (const cB of (_c = tableData.tableStructure.customBenefits) != null ? _c : [])
        if (cB.name)
          header.push(
            cB.name,
            // polyglot.t('BenefitsCompanyPeoplePage.startDate'),
            // polyglot.t('BenefitsCompanyPeoplePage.endDate'),
            polyglot.t("BenefitsCompanyPeoplePage.memberPremium"),
            polyglot.t("BenefitsCompanyPeoplePage.memberContribution"),
            polyglot.t("BenefitsCompanyPeoplePage.dependants")
          );
      header.push("Entity");
      const userRows = (_d = tableData.usersData.map((userData) => {
        var _a2, _b2, _c2, _d2, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s;
        const csvRow = [String(userData.userId), userData.name];
        for (const pS of (_a2 = tableData == null ? void 0 : tableData.tableStructure.pensionSchemes) != null ? _a2 : []) {
          if (!pS.displayName) continue;
          const isUserMemberOfThis = ((_b2 = userData.pension) == null ? void 0 : _b2.pensionProviderId) && pS.externalId === userData.pension.pensionProviderId;
          csvRow.push(
            isUserMemberOfThis ? (_d2 = (_c2 = userData.pension) == null ? void 0 : _c2.state) != null ? _d2 : polyglot.t("BenefitsCompanyPeoplePage.member") : "",
            (_f = (_e = userData.pension) == null ? void 0 : _e.startDate) != null ? _f : "",
            ((_g = userData.pension) == null ? void 0 : _g.employerContribution) ? `${userData.pension.employerContribution}%` : "",
            ((_h = userData.pension) == null ? void 0 : _h.employeeContribution) ? `${userData.pension.employeeContribution}%` : ""
          );
        }
        if ((_i = tableData.tableStructure.insurance) == null ? void 0 : _i.providerName)
          csvRow.push(
            ((_j = userData.insurance) == null ? void 0 : _j.status) ? userData.insurance.status : "",
            (_l = (_k = userData.insurance) == null ? void 0 : _k.startDate) != null ? _l : "",
            (_n = (_m = userData.insurance) == null ? void 0 : _m.endDate) != null ? _n : "",
            ((_o = userData.insurance) == null ? void 0 : _o.monthlyPremium) ? `\xA3${userData.insurance.monthlyPremium}` : "",
            ((_p = userData.insurance) == null ? void 0 : _p.monthlyContribution) ? `\xA3${userData.insurance.monthlyContribution}` : "",
            ((_q = userData.insurance) == null ? void 0 : _q.dependants) ? getDependantsToLabel(userData.insurance.dependants, polyglot) : ""
          );
        for (const cB of (_r = tableData.tableStructure.customBenefits) != null ? _r : []) {
          if (!cB.name) continue;
          const isUserMemberOfThis = userData == null ? void 0 : userData.customBenefits.find((uB) => uB.customBenefitId === cB.id);
          csvRow.push(
            isUserMemberOfThis ? polyglot.t("BenefitsCompanyPeoplePage.member") : "",
            (isUserMemberOfThis == null ? void 0 : isUserMemberOfThis.employerContribution) ? `${isUserMemberOfThis.employerContribution}` : "",
            (isUserMemberOfThis == null ? void 0 : isUserMemberOfThis.employeeContribution) ? `${isUserMemberOfThis.employeeContribution}` : "",
            (isUserMemberOfThis == null ? void 0 : isUserMemberOfThis.dependants) ? getDependantsToLabel(isUserMemberOfThis.dependants, polyglot) : ""
          );
        }
        csvRow.push((_s = userData.entityName) != null ? _s : "");
        return csvRow;
      })) != null ? _d : [];
      return [header, ...userRows];
    } catch (error) {
      showMessage(polyglot.t("BenefitsCompanyPeoplePage.error", { nestError: nestErrorMessage(error) }), "error");
      return [];
    }
  }, [tableData, showMessage, polyglot]);
  return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsxs(ContentWrapper, { loading: isValidating, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(
          HiddenColumnSelector,
          {
            options: allColumnsOptions,
            hiddenColumns,
            setHiddenColumns,
            columnAction: { domain: "benefits", subDomain: "peopleTable", feature: "hiddenColumns" }
          }
        ),
        /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput,
            handleChange: (e) => {
              setSearchInput(e.target.value);
            }
          }
        )
      ] }),
      /* @__PURE__ */ jsx(CsvDownloader, { filename: "people-benefits", separator: ",", datas: getExportData, children: /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "secondary", sizeVariant: "small", children: polyglot.t("General.export") }) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt20 }, children: !isValidating && tableData && /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: tableRows,
        columnData: filteredColumns,
        fixedLastColumn: false,
        hiddenColumns
      }
    ) })
  ] }) });
};
