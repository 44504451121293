"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { COLOR_OPTIONS } from "@/v2/feature/absence/absence.util";
import { GrowthFactorAPI } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const FactorSchema = () => Yup.object().shape({
  name: Yup.string().required(),
  color: Yup.string().required()
});
export const GrowthFactorFormContent = ({
  onClose,
  growthFactor,
  refresh
}) => {
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const formik = useFormik({
    initialValues: {
      name: growthFactor ? growthFactor.name : "",
      color: growthFactor ? growthFactor == null ? void 0 : growthFactor.color : ""
    },
    enableReinitialize: true,
    validationSchema: FactorSchema(),
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        if (growthFactor) {
          const updateObject = __spreadValues({ id: growthFactor.id }, values);
          yield GrowthFactorAPI.updateFactor(updateObject);
          showMessage("Factor updated successfully", "success");
        } else {
          yield GrowthFactorAPI.createFactor(values);
          showMessage("Factor created successfully", "success");
        }
        refresh();
        onClose();
      } catch (error) {
        showMessage("Couldn't create the section", "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.g20,
        height: "100%",
        boxSizing: "border-box",
        px: spacing.p16,
        pb: spacing.p24
      },
      children: [
        /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m16 }, children: /* @__PURE__ */ jsx(Typography, { variant: "title2", children: growthFactor ? "Edit factor" : "New factor" }) }),
        /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { display: "flex", gap: spacing.g16, alignItems: "center", mt: spacing.m8 }), children: [
          /* @__PURE__ */ jsx(Box, { sx: { width: "20%" }, children: /* @__PURE__ */ jsx(
            SelectComponent,
            {
              name: "color",
              label: "Color",
              options: COLOR_OPTIONS,
              value: formik.values.color,
              onChange: formik.handleChange,
              compareValue: formik.values.color,
              error: !!formik.errors.color && formik.touched.color,
              helperText: formik.touched.color && formik.errors.color
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: { width: "80%" }, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: "name",
              label: "Name",
              value: formik.values.name,
              onChange: formik.handleChange,
              error: formik.touched.name && !!formik.errors.name,
              helperText: formik.touched.name && formik.errors.name,
              endAdornment: "none",
              fullWidth: true
            }
          ) })
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: polyglot.t("General.save"),
            loading,
            fullWidth: true,
            sizeVariant: "medium",
            colorVariant: "primary"
          }
        ) })
      ]
    }
  ) }) });
};
