"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Stack } from "@mui/material";
import { pick } from "lodash";
import { CustomProfileFormEndpoints } from "@/v2/feature/custom-fields/custom-profile-fields.api";
import { CustomSectionForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-form.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { spacing } from "@/v2/styles/spacing.styles";
export const CustomFieldValueAttributes = [
  "formId",
  "fieldId",
  "fieldType",
  "fieldName",
  "isRequired",
  "isHidden",
  "options",
  "paycode"
];
export const UserCustomSection = ({ onNext, sx, userId, form }) => {
  const { data: customValues } = useApiClient(CustomProfileFormEndpoints.getByFormId(userId, form.formId), {
    suspense: false
  });
  const formValues = useMemo(() => {
    var _a, _b;
    const valuesByFieldId = new Map((customValues != null ? customValues : []).map((v) => [v.fieldId, v]));
    return (_b = (_a = form == null ? void 0 : form.fields) != null ? _a : []) == null ? void 0 : _b.map((field) => {
      var _a2, _b2;
      return {
        formId: field.formId,
        fieldId: field.fieldId,
        field: pick(field, CustomFieldValueAttributes),
        value: (_b2 = (_a2 = valuesByFieldId.get(field.fieldId)) == null ? void 0 : _a2.value) != null ? _b2 : ""
      };
    });
  }, [customValues, form == null ? void 0 : form.fields]);
  return /* @__PURE__ */ jsx(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: /* @__PURE__ */ jsx(
    CustomSectionForm,
    {
      userId,
      form,
      fields: formValues != null ? formValues : [],
      onSubmit: () => {
        onNext();
      },
      usedForOnboarding: true
    }
  ) });
};
