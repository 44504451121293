"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Badge,
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { ViewQueueToCleanUpSummaryDrawer } from "@v2/feature/monitoring/component/view-queue-to-clean-up-summary-drawer.component";
import { Route, Switch } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import cover from "@/images/illustration_devices.png";
import { ReactComponent as Delete } from "@/images/side-bar-icons/Delete.svg";
import { SUPER_ADMIN_MONITORING_ROUTE } from "@/lib/routes";
import { EmptyStateComponent } from "@/v2/components/empty-state.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { MonitoringAPI } from "@/v2/feature/monitoring/monitoring.api";
import { getStartingTimeForJob } from "@/v2/feature/monitoring/monitoring.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
import { timeAgo } from "@/v2/util/date-format.util";
export const QUEUE_TABS = [
  { value: "Delayed" },
  { value: "Active" },
  { value: "Completed" },
  { value: "Failed" },
  { value: "Waiting" }
];
export var MonitoringQueue = /* @__PURE__ */ ((MonitoringQueue2) => {
  MonitoringQueue2["GoogleCalendar"] = "google-calendar";
  MonitoringQueue2["OutlookCalendar"] = "outlook-calendar";
  MonitoringQueue2["AppActions"] = "app-actions";
  MonitoringQueue2["AppEmails"] = "app-emails";
  MonitoringQueue2["PayrunEntries"] = "payrun-entries";
  MonitoringQueue2["Billing"] = "billing";
  MonitoringQueue2["BillPayment"] = "bill-payment";
  MonitoringQueue2["Payroll"] = "payroll";
  MonitoringQueue2["ReviewCycle"] = "review_cycle";
  MonitoringQueue2["SurveyCycle"] = "survey_cycle";
  MonitoringQueue2["DeviceMDM"] = "device-mdm";
  MonitoringQueue2["DeviceNotifications"] = "device-notifications";
  MonitoringQueue2["DeviceMDMSync"] = "mdm-device-sync";
  MonitoringQueue2["ImportAttendances"] = "attendance-import";
  MonitoringQueue2["ImportUsers"] = "user-import";
  MonitoringQueue2["ImportAbsences"] = "absence-import";
  return MonitoringQueue2;
})(MonitoringQueue || {});
const QUEUES = [
  { value: "google-calendar" /* GoogleCalendar */ },
  { value: "outlook-calendar" /* OutlookCalendar */ },
  { value: "app-actions" /* AppActions */ },
  { value: "app-emails" /* AppEmails */ },
  { value: "payrun-entries" /* PayrunEntries */ },
  { value: "billing" /* Billing */ },
  { value: "bill-payment" /* BillPayment */ },
  { value: "payroll" /* Payroll */ },
  { value: "review_cycle" /* ReviewCycle */ },
  { value: "survey_cycle" /* SurveyCycle */ },
  { value: "device-mdm" /* DeviceMDM */ },
  { value: "device-notifications" /* DeviceNotifications */ },
  { value: "mdm-device-sync" /* DeviceMDMSync */ },
  { value: "attendance-import" /* ImportAttendances */ },
  { value: "user-import" /* ImportUsers */ },
  { value: "absence-import" /* ImportAbsences */ }
];
const QUEUES_SUPPORTING_DELETION = ["google-calendar", "outlook-calendar"];
export const MonitoringPage = () => {
  const [showMessage] = useMessage();
  const [currentQueue, setCurrentQueue] = useState(void 0);
  const [currentTab, setCurrentTab] = useState("Active");
  const { getCachedUserById } = useCachedUsers();
  const [isViewQueueToCleanUpSummaryDrawerOpen, setIsViewQueueToCleanUpSummaryDrawerOpen] = useState(false);
  const [queuesToCleanUp, setQueuesToCleanUp] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [jobToRemove, setJobToRemove] = useState(null);
  const [loading, setLoading] = useState(true);
  const [localRefreshing, setLocalRefreshing] = useState(false);
  const [currentQueueData, setCurrentQueueData] = useState(void 0);
  const fetchQueueData = useCallback(
    (disableLoaders = false) => __async(void 0, null, function* () {
      const queueApiMap = {
        ["google-calendar" /* GoogleCalendar */]: MonitoringAPI.getGoogleCalendarQueueDetails,
        ["absence-import" /* ImportAbsences */]: MonitoringAPI.getAbsenceImportQueueDetails,
        ["outlook-calendar" /* OutlookCalendar */]: MonitoringAPI.getOutlookCalendarQueueDetails,
        ["payrun-entries" /* PayrunEntries */]: MonitoringAPI.getPayrunEntriesQueueDetails,
        ["app-actions" /* AppActions */]: MonitoringAPI.getAppActionsQueueDetails,
        ["app-emails" /* AppEmails */]: MonitoringAPI.getAppEmailsQueueDetails,
        ["billing" /* Billing */]: MonitoringAPI.getBillingQueueDetails,
        ["bill-payment" /* BillPayment */]: MonitoringAPI.getBillPaymentQueueDetails,
        ["payroll" /* Payroll */]: MonitoringAPI.getPayrollQueueDetails,
        ["review_cycle" /* ReviewCycle */]: MonitoringAPI.getReviewQueueDetails,
        ["survey_cycle" /* SurveyCycle */]: MonitoringAPI.getSurveyQueueDetails,
        ["device-mdm" /* DeviceMDM */]: MonitoringAPI.getDevicesMDMQueueDetails,
        ["device-notifications" /* DeviceNotifications */]: MonitoringAPI.getDevicesNotificationsQueueDetails,
        ["mdm-device-sync" /* DeviceMDMSync */]: MonitoringAPI.getInHouseMdmDeviceSyncQueueDetails,
        ["attendance-import" /* ImportAttendances */]: MonitoringAPI.getAttendanceImportQueueDetails,
        ["user-import" /* ImportUsers */]: MonitoringAPI.getUsersImportQueueDetails
      };
      try {
        if (!disableLoaders) setLoading(true);
        else setLocalRefreshing(true);
        if (!currentQueue) return;
        const apiFunction = queueApiMap[currentQueue];
        if (apiFunction) {
          const selectedQueueData = yield apiFunction();
          setCurrentQueueData(selectedQueueData);
        } else {
          setCurrentQueueData(void 0);
        }
      } catch (error) {
        showMessage("Could not load queue data", "error");
      } finally {
        if (!disableLoaders) setLoading(false);
        else setLocalRefreshing(false);
      }
    }),
    [currentQueue, showMessage]
  );
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      fetchQueueData();
    }))();
  }, [fetchQueueData]);
  const getCompanyForRow = useCallback(
    (row) => {
      var _a, _b, _c, _d, _e, _f, _g, _h, _i;
      if (currentQueue === "payrun-entries") {
        return (_b = (_a = row == null ? void 0 : row.data) == null ? void 0 : _a.p45Payloads[0]) == null ? void 0 : _b.companyId;
      } else if ((_c = row == null ? void 0 : row.data) == null ? void 0 : _c.companyId) {
        return (_d = row == null ? void 0 : row.data) == null ? void 0 : _d.companyId;
      } else if ((_e = row == null ? void 0 : row.data) == null ? void 0 : _e.company) {
        return `${(_g = (_f = row == null ? void 0 : row.data) == null ? void 0 : _f.company) == null ? void 0 : _g.name}(id: ${(_i = (_h = row == null ? void 0 : row.data) == null ? void 0 : _h.company) == null ? void 0 : _i.companyId})`;
      } else return "";
    },
    [currentQueue]
  );
  const getNameForUser = useCallback(
    (row) => {
      var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
      if (currentQueue === "payrun-entries" && ((_a = row == null ? void 0 : row.data) == null ? void 0 : _a.p45Payloads.length) > 0) {
        return `${(_d = getCachedUserById((_c = (_b = row == null ? void 0 : row.data) == null ? void 0 : _b.p45Payloads[0]) == null ? void 0 : _c.userId)) == null ? void 0 : _d.displayName} (${(_f = (_e = row == null ? void 0 : row.data) == null ? void 0 : _e.p45Payloads[0]) == null ? void 0 : _f.userId})`;
      } else if (currentQueue !== "payrun-entries" && (row == null ? void 0 : row.data.appUserId)) {
        return (_g = row == null ? void 0 : row.data) == null ? void 0 : _g.appUserId;
      } else if (currentQueue !== "payrun-entries" && ((_h = row == null ? void 0 : row.data) == null ? void 0 : _h.userId)) {
        return ((_j = getCachedUserById((_i = row == null ? void 0 : row.data) == null ? void 0 : _i.userId)) == null ? void 0 : _j.displayName) + ` (${(_k = row == null ? void 0 : row.data) == null ? void 0 : _k.userId})`;
      } else return "";
    },
    [currentQueue, getCachedUserById]
  );
  const confirmTriggerDeleteJobFromQueue = (event, job) => {
    setJobToRemove(job);
    setAnchorEl(event.currentTarget);
    setIsRemovalModalOpen(true);
  };
  const deleteJobFromQueue = () => __async(void 0, null, function* () {
    if (jobToRemove && currentQueue) {
      setLoading(true);
      const updatedQueueStatus = yield MonitoringAPI.deleteJobFromCalendarQueue(currentQueue, jobToRemove.id);
      setCurrentQueueData(updatedQueueStatus);
      setLoading(false);
    }
    setJobToRemove(null);
    setIsRemovalModalOpen(false);
    setAnchorEl(null);
  });
  const tableColumns = useMemo(
    () => [
      {
        header: () => "jobId",
        id: "id",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original.id) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Job Name",
        id: "name",
        enableSorting: false,
        accessorFn: (row) => row.name,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original.name) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Company",
        id: "company",
        enableSorting: false,
        accessorFn: (row) => getCompanyForRow(row),
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = getCompanyForRow(original)) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "User",
        id: "user",
        enableSorting: false,
        accessorFn: (row) => getNameForUser(row),
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = getNameForUser(original)) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => `${currentTab !== "Delayed" ? "Started" : "Starting on"}`,
        id: "timeAgo",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.processedOn ? timeAgo(new Date(original.processedOn).toISOString()) : original.opts.delay && original.opts.delay > 0 ? `${getStartingTimeForJob(original)} UTC` : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => "Time taken",
        id: "duration",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.finishedOn && original.processedOn ? `${((original.finishedOn - original.processedOn) / 1e3).toFixed(2)} seconds` : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => "attempts",
        id: "attemptsMade",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: original.attemptsMade === 0 ? 1 : (_a = original.attemptsMade) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "returnValue",
        id: "returnValue",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
          Tooltip,
          {
            title: original.returnvalue ? JSON.stringify(original.returnvalue, null, 2) : JSON.stringify(original.data, null, 2),
            placement: "bottom-start",
            children: /* @__PURE__ */ jsx(Box, { children: original.returnvalue ? JSON.stringify(original.returnvalue) : JSON.stringify(original.data) })
          }
        )
      },
      {
        header: () => "failedReason",
        id: "failedReason",
        enableSorting: false,
        accessorFn: (row) => JSON.stringify(row.failedReason),
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = JSON.stringify(original.failedReason)) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "action",
        id: "action",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
          IconButton,
          {
            color: "error",
            sx: { display: currentQueue && QUEUES_SUPPORTING_DELETION.includes(currentQueue) ? "" : "none" },
            onClick: (event) => {
              confirmTriggerDeleteJobFromQueue(event, original);
            },
            children: /* @__PURE__ */ jsx(Delete, {})
          }
        )
      }
    ],
    [currentQueue, currentTab, getCompanyForRow, getNameForUser]
  );
  const currentTabData = useMemo(() => {
    switch (currentTab) {
      case "Active":
        return currentQueueData == null ? void 0 : currentQueueData.active;
      case "Completed":
        return currentQueueData == null ? void 0 : currentQueueData.completed;
      case "Failed":
        return currentQueueData == null ? void 0 : currentQueueData.failed;
      case "Waiting":
        return currentQueueData == null ? void 0 : currentQueueData.waiting;
      case "Delayed":
        return currentQueueData == null ? void 0 : currentQueueData.delayed;
      default:
        return [];
    }
  }, [currentQueueData, currentTab]);
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_MONITORING_ROUTE, children: /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.title2), { color: themeColors.DarkGrey }), children: "Monitoring Dashboard" })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "row" }, children: [
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              alignItems: "left"
            },
            children: /* @__PURE__ */ jsx(FormControl, { disabled: loading, children: /* @__PURE__ */ jsx(
              TextField,
              {
                select: true,
                label: currentQueue ? "Selected queue" : "Please select a queue",
                size: "small",
                sx: { minWidth: "250px" },
                value: currentQueue,
                onChange: (event) => setCurrentQueue(event.target.value),
                children: QUEUES.map((queue) => /* @__PURE__ */ jsx(MenuItem, { value: queue.value, children: queue.value }, queue.value))
              }
            ) })
          }
        ),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              width: "50%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
              mr: 4
            },
            children: [
              /* @__PURE__ */ jsx(IconButton, { color: "secondary", onClick: () => fetchQueueData(true), children: /* @__PURE__ */ jsx(RefreshIcon, {}) }),
              /* @__PURE__ */ jsx(
                IconButton,
                {
                  color: "secondary",
                  onClick: () => __async(void 0, null, function* () {
                    const map = yield MonitoringAPI.getNumberOfJobsToCleanUp();
                    setQueuesToCleanUp(map);
                    setIsViewQueueToCleanUpSummaryDrawerOpen(true);
                  }),
                  children: /* @__PURE__ */ jsx(DeleteForeverIcon, {})
                }
              )
            ]
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { borderBottom: 1, borderColor: "divider" }, children: /* @__PURE__ */ jsx(
        Tabs,
        {
          value: currentTab,
          scrollButtons: "auto",
          variant: "scrollable",
          allowScrollButtonsMobile: true,
          onChange: (e, value) => setCurrentTab(value),
          children: QUEUE_TABS.map((tab) => /* @__PURE__ */ jsx(
            Tab,
            {
              disableRipple: true,
              label: /* @__PURE__ */ jsxs(
                "span",
                {
                  style: {
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    paddingRight: (
                      // @ts-ignore
                      currentQueueData && currentQueueData[tab.value.toLowerCase()].length > 0 ? 10 : 0
                    )
                  },
                  children: [
                    tab.value,
                    currentQueueData && currentQueueData[tab.value.toLowerCase()].length > 0 && /* @__PURE__ */ jsx(
                      Badge,
                      {
                        badgeContent: (
                          // @ts-ignore
                          currentQueueData && currentQueueData[tab.value.toLowerCase()].length > 0 ? (
                            // @ts-ignore
                            currentQueueData[tab.value.toLowerCase()].length
                          ) : ""
                        ),
                        color: "primary",
                        sx: { ml: 2 }
                      }
                    )
                  ]
                }
              ),
              value: tab.value
            },
            tab.value
          ))
        }
      ) }),
      currentQueueData && currentTab && /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: currentTabData != null ? currentTabData : [],
          columnData: tableColumns,
          loading: localRefreshing ? localRefreshing : loading
        }
      ),
      !currentQueueData && /* @__PURE__ */ jsx(
        EmptyStateComponent,
        {
          header: !currentQueue ? "No queue selected" : "No queue data",
          subheader: !currentQueue ? "Please select a queue from above in order to see job data" : "This queue / state does not have any job data",
          cover
        }
      ),
      /* @__PURE__ */ jsx(
        NotificationModal,
        {
          isOpen: isRemovalModalOpen,
          onClose: () => setIsRemovalModalOpen(false),
          anchorEl,
          takeAction: () => __async(void 0, null, function* () {
            deleteJobFromQueue();
          }),
          message: `Are you sure you want to delete this job from the queue?`,
          callToAction: "Yes"
        }
      ),
      /* @__PURE__ */ jsx(
        ViewQueueToCleanUpSummaryDrawer,
        {
          isOpen: isViewQueueToCleanUpSummaryDrawerOpen,
          setIsOpen: setIsViewQueueToCleanUpSummaryDrawerOpen,
          onClose: () => __async(void 0, null, function* () {
            setIsViewQueueToCleanUpSummaryDrawerOpen(false);
          }),
          queues: queuesToCleanUp
        }
      )
    ] })
  ] }) }) });
};
