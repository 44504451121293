"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import {
  isPaycodeForAdditions,
  isPaycodeForDeductions,
  isPaycodeForOtherEmployerPayments
} from "@v2/feature/payroll/features/payroll-uk/payrun-flow/payrun-flow.util";
import { ReactComponent as DownloadIcon } from "@/images/icons/download-icon.svg";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { UserHeader } from "@/v2/components/user-header.component";
import { DocPreviewer } from "@/v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component";
import { payslipDownloadUrl } from "@/v2/feature/payroll/features/payroll-uk/user-payroll/components/personal-payroll.component";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
const SummaryRow = ({
  description,
  amount,
  subtract,
  hideIfZero,
  sx,
  textVariant = "caption",
  textColor = "DarkGrey"
}) => {
  if (hideIfZero && !amount) {
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  const numericAmount = (amount != null ? amount : 0) * (subtract ? -1 : 1);
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flexFlow: "row", justifyContent: "space-between", gap: spacing.g10 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: textVariant, color: textColor, children: description }),
    /* @__PURE__ */ jsx(Typography, { variant: textVariant, color: textColor, children: formatCurrency(numericAmount, { noCurrencySymbol: true }) })
  ] });
};
const classifyAdditionsDeductions = (paylines, paycodes) => {
  const result = { additions: [], deductions: [], otherEmployerPayments: [] };
  paylines.forEach((payline) => {
    const paycode = paycodes.find((code) => code.code === payline.code);
    if (paycode) {
      const { id, description, amount, code, calculated, isProrated, hoursWorked, hourlyRate } = payline;
      const paylineEntry = { id, description, amount, code, calculated, isProrated, hoursWorked, hourlyRate };
      if (isPaycodeForAdditions(paycode)) {
        result.additions.push(paylineEntry);
      } else if (isPaycodeForDeductions(paycode)) {
        result.deductions.push(paylineEntry);
      } else if (isPaycodeForOtherEmployerPayments(paycode)) {
        result.otherEmployerPayments.push(paylineEntry);
      }
    }
  });
  return result;
};
const calculateTotalAmount = (classifiedPaylines) => {
  return classifiedPaylines.reduce((total, payline) => total + payline.amount, 0);
};
export const GlobalPayrollSalarySummaryPage = ({
  user,
  payrunEntry,
  globalPaycodes,
  payslip,
  payrun,
  handleEditAction
}) => {
  var _a;
  const classifiedPaylines = useMemo(() => classifyAdditionsDeductions(payrunEntry.paylines, globalPaycodes), [
    globalPaycodes,
    payrunEntry.paylines
  ]);
  const [viewingPayslip, setViewingPayslip] = useState(null);
  const handleDocumentButtonClick = useCallback(
    (user2, payslip2, payrun2) => __async(void 0, null, function* () {
      var _a2, _b, _c, _d, _e;
      if (!(payslip2 == null ? void 0 : payslip2.payrollId) && !(payrun2 == null ? void 0 : payrun2.payrollId)) return;
      const payrollId = (_a2 = payslip2 == null ? void 0 : payslip2.payrollId) != null ? _a2 : payrun2 == null ? void 0 : payrun2.payrollId;
      const taxYear = (_b = payslip2 == null ? void 0 : payslip2.taxYear) != null ? _b : payrun2 == null ? void 0 : payrun2.taxYear;
      const payPeriod = (_c = payslip2 == null ? void 0 : payslip2.payPeriod) != null ? _c : payrun2 == null ? void 0 : payrun2.payPeriod;
      const period = (_d = payslip2 == null ? void 0 : payslip2.period) != null ? _d : payrun2 == null ? void 0 : payrun2.period;
      const userId = (_e = payslip2 == null ? void 0 : payslip2.userId) != null ? _e : user2 == null ? void 0 : user2.userId;
      const url = `/apiv2/company/salary/payroll/${payrollId}/global/global-payroll/${taxYear}/${payPeriod}/${period}/${userId}/payslip-pdf/download`;
      const payslipUrl = payslipDownloadUrl(url);
      if (payslipUrl) setViewingPayslip(payslipUrl);
    }),
    []
  );
  const generateDescription = (cpl) => {
    const { isProrated, hourlyRate, hoursWorked, description } = cpl;
    if (isProrated) {
      return `${cpl.description} (${hoursWorked}h * ${hourlyRate})`;
    }
    return description;
  };
  const payrunComplete = (payrun == null ? void 0 : payrun.state) === "COMPLETED";
  return /* @__PURE__ */ jsxs(Stack, { children: [
    /* @__PURE__ */ jsxs(Stack, { flexDirection: "row", justifyContent: "space-between", children: [
      /* @__PURE__ */ jsx(UserHeader, { user }),
      payslip || payrun && payrunComplete && user ? /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: tableIconButtonSx,
          onClick: () => handleDocumentButtonClick(user, payslip, payrun),
          title: "View/Download",
          children: /* @__PURE__ */ jsx(DownloadIcon, __spreadValues({}, iconSize))
        }
      ) : null,
      handleEditAction && payrunEntry ? /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => handleEditAction(payrunEntry), title: "Edit", children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, iconSize)) }) : null
    ] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g40, mt: spacing.mt40 }, children: [
      /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g15 }, children: [
        /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title3", color: "black", children: "Payments" }),
          /* @__PURE__ */ jsx(Typography, { variant: "title3", color: "black", children: "Amount" })
        ] }),
        classifiedPaylines.additions.map((cpl) => {
          var _a2;
          return /* @__PURE__ */ jsx(SummaryRow, { description: generateDescription(cpl), amount: (_a2 = cpl.amount) != null ? _a2 : 0 }, cpl.code);
        }),
        /* @__PURE__ */ jsx(
          SummaryRow,
          {
            description: "Total Payments",
            amount: calculateTotalAmount(classifiedPaylines.additions),
            textVariant: "title4"
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g15 }, children: [
        /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "title3", color: "black", children: "Deductions" }),
          /* @__PURE__ */ jsx(Typography, { variant: "title3", color: "black", children: "Amount" })
        ] }),
        classifiedPaylines.deductions.map((cpl) => {
          var _a2;
          return /* @__PURE__ */ jsx(SummaryRow, { subtract: true, description: cpl.description, amount: (_a2 = cpl.amount) != null ? _a2 : 0 }, cpl.code);
        }),
        /* @__PURE__ */ jsx(
          SummaryRow,
          {
            description: "Total Deductions",
            amount: calculateTotalAmount(classifiedPaylines.deductions),
            textVariant: "title4",
            subtract: true
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Stack, { sx: { gap: spacing.g15 }, children: /* @__PURE__ */ jsx(
        SummaryRow,
        {
          description: "Take Home pay",
          amount: calculateTotalAmount(classifiedPaylines.additions) - calculateTotalAmount(classifiedPaylines.deductions),
          textVariant: "title4"
        }
      ) }),
      ((_a = classifiedPaylines.otherEmployerPayments) == null ? void 0 : _a.length) > 0 && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g15, mt: spacing.mt30 }, children: [
        /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", justifyContent: "space-between" }, children: /* @__PURE__ */ jsx(Typography, { variant: "title4", color: "black", children: "Other employer payments" }) }),
        classifiedPaylines.otherEmployerPayments.map((cpl) => {
          var _a2;
          return /* @__PURE__ */ jsx(SummaryRow, { description: cpl.description, amount: (_a2 = cpl.amount) != null ? _a2 : 0 });
        })
      ] })
    ] }),
    viewingPayslip && /* @__PURE__ */ jsx(DocPreviewer, { docData: viewingPayslip, visible: true, onClose: () => setViewingPayslip(null), title: "Payslip" })
  ] });
};
