"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class CompanyDepartmentAPI {
  static getCompanyDepartments(includeUserCount = false) {
    return __async(this, null, function* () {
      const query = `includeUserCount=${includeUserCount ? "true" : "false"}`;
      return (yield axios.get(`/apiv2/companies/departments?${query}`)).data;
    });
  }
  static getCompanyDepartmentById(departmentId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/companies/departments/${departmentId}`)).data;
    });
  }
  static addCompanyDepartment(department) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/companies/departments", department)).data;
    });
  }
  static deleteCompanyDepartment(departmentId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/companies/departments/${departmentId}`);
    });
  }
  static updateCompanyDepartment(departmentId, department) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/companies/departments/${departmentId}`, department);
    });
  }
}
export class DepartmentEndpoints {
  static getCompanyDepartments(includeUserCount = false) {
    const query = `includeUserCount=${includeUserCount ? "true" : "false"}`;
    return {
      url: `/apiv2/companies/departments?${query}`
    };
  }
  static getCompanyDepartmentsOptions() {
    return {
      url: `/apiv2/companies/departments-options`
    };
  }
  static getCompanyDepartmentsCount() {
    return {
      url: `/apiv2/companies/departments-count`
    };
  }
}
