"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import { UserCell } from "@v2/components/table/user-cell.component";
import { Typography } from "@v2/components/typography/typography.component";
import { UpdateUserCustomBenefitDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/components/update-user-custom-benefit-drawer.component";
import { CustomBenefitEndpoints } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import {
  getOpeningBalanceColumns,
  getYearlyAllowanceNextRenewal,
  isAllowanceBenefit,
  isLoanBenefit,
  isRecurringBenefit,
  isYearlyAllowanceBenefit
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { ViewUserCustomBenefitDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/view-user-custom-benefit-drawer.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { FieldStructure } from "@v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import { ShowHideButton } from "@v2/feature/user/features/user-profile/details/components/show-hide-button.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { translateUserInsuranceDependants } from "@v2/infrastructure/i18n/translate.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { LocalDate } from "@v2/util/local-date";
import useScopes from "@/hooks/scopes.hook";
import { CurrencyCell } from "@/v2/components/table/currency-cell.component";
export const UserCustomBenefitListTableComponent = ({ benefitId, userBenefit, userId }) => {
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();
  const hasInsuranceAll = hasScopes(["insurance:all"]);
  const [showDetails, setShowDetails] = useState(false);
  const {
    data: userCustomBenefits,
    mutate: refreshUserCustomBenefits,
    isLoading
  } = useApiClient(CustomBenefitEndpoints.getAllUserCustomBenefitsByBenefitId(userId, benefitId), { suspense: false });
  const [isOpen, setIsOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const isLoan = useMemo(() => {
    var _a;
    return isLoanBenefit((_a = userBenefit.customBenefit) == null ? void 0 : _a.type);
  }, [userBenefit]);
  const isAllowance = useMemo(() => {
    var _a;
    return isAllowanceBenefit((_a = userBenefit.customBenefit) == null ? void 0 : _a.type);
  }, [userBenefit]);
  const isRecurring = useMemo(() => {
    var _a;
    return isRecurringBenefit((_a = userBenefit.customBenefit) == null ? void 0 : _a.type);
  }, [userBenefit]);
  const isYearlyAllowance = useMemo(() => {
    var _a;
    return isYearlyAllowanceBenefit((_a = userBenefit.customBenefit) == null ? void 0 : _a.allowanceType);
  }, [
    userBenefit
  ]);
  const yearlyAllowanceNextRenewal = useMemo(
    () => userBenefit.customBenefit ? getYearlyAllowanceNextRenewal(userBenefit.customBenefit) : "",
    [userBenefit]
  );
  const refresh = useCallback(() => __async(void 0, null, function* () {
    if (refreshUserCustomBenefits) yield refreshUserCustomBenefits();
  }), [refreshUserCustomBenefits]);
  const columns = useMemo(() => {
    if (!userCustomBenefits) return [];
    const openingBalanceColumns = [];
    if (isLoan || isAllowance) openingBalanceColumns.push(...getOpeningBalanceColumns(isLoan, polyglot));
    return [
      {
        header: () => polyglot.t("General.effectiveDate"),
        accessorFn: (row) => row,
        id: "effectiveDate",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item.effectiveDate) != null ? _a : "";
        }),
        cell: ({ row }) => row.original.effectiveDate ? /* @__PURE__ */ jsx(Box, { children: row.original.effectiveDate }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => polyglot.t("General.effectiveEndDate"),
        accessorFn: (row) => row,
        id: "effectiveEndDate",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item.effectiveEndDate) != null ? _a : "";
        }),
        cell: ({ row }) => row.original.effectiveEndDate ? /* @__PURE__ */ jsx(Box, { children: row.original.effectiveEndDate }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      ...openingBalanceColumns,
      ...isRecurring ? [
        {
          header: () => polyglot.t("BenefitModule.employerContribution"),
          accessorFn: (row) => row,
          id: "employerContribution",
          enableSorting: true,
          sortingFn: (a, b) => sortNumeric(a, b, (item) => {
            var _a;
            return (_a = item.employerContribution) != null ? _a : 0;
          }),
          cell: ({ row }) => /* @__PURE__ */ jsx(CurrencyCell, { value: row.original.employerContribution, formatOptions: { noCurrencySymbol: true } })
        },
        {
          header: () => polyglot.t("BenefitModule.employeeContribution"),
          accessorFn: (row) => row,
          id: "employeeContribution",
          enableSorting: true,
          sortingFn: (a, b) => sortNumeric(a, b, (item) => {
            var _a;
            return (_a = item.employeeContribution) != null ? _a : 0;
          }),
          cell: ({ row }) => /* @__PURE__ */ jsx(CurrencyCell, { value: row.original.employeeContribution, formatOptions: { noCurrencySymbol: true } })
        },
        {
          header: () => polyglot.t("BenefitModule.dependant"),
          accessorFn: (row) => row,
          id: "dependants",
          enableSorting: true,
          sortingFn: (a, b) => sortString(a, b, (item) => {
            var _a;
            return (_a = item.dependants) != null ? _a : "";
          }),
          cell: ({ row }) => row.original.dependants ? /* @__PURE__ */ jsx(Box, { children: translateUserInsuranceDependants(row.original.dependants, polyglot) }) : /* @__PURE__ */ jsx(EmptyCell, {})
        }
      ] : []
    ];
  }, [userCustomBenefits, isLoan, isAllowance, polyglot, isRecurring]);
  const handleRowClick = useCallback(
    (row) => {
      setSelectedRow(row.original);
      if (hasInsuranceAll) setIsOpen(true);
      else setIsViewOpen(true);
    },
    [hasInsuranceAll]
  );
  const effectiveRecord = useMemo(() => {
    if (!userCustomBenefits) return void 0;
    if (userCustomBenefits.length === 0) return void 0;
    if (userCustomBenefits.length === 1) return userCustomBenefits[0];
    const sortedUserCustomBenefits = [...userCustomBenefits].sort((a, b) => {
      if (a.effectiveDate === b.effectiveDate)
        return new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? 1 : -1;
      return a.effectiveDate > b.effectiveDate ? 1 : -1;
    });
    const today = new LocalDate().toDateString();
    for (let index = 0; index < sortedUserCustomBenefits.length; index += 1) {
      const current = sortedUserCustomBenefits[index];
      const next = sortedUserCustomBenefits[index + 1];
      if (!next) return current;
      if (current.effectiveDate <= today && (!current.effectiveEndDate || current.effectiveEndDate > today) && next.effectiveDate > today)
        return current;
    }
    return void 0;
  }, [userCustomBenefits]);
  return /* @__PURE__ */ jsxs(Box, { sx: { width: "600px" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "title3", sx: { mb: "10px" }, children: "Enrollment details" }),
    isRecurring && effectiveRecord && effectiveRecord.employerContribution !== null && /* @__PURE__ */ jsx(
      FieldStructure,
      {
        fieldTitle: polyglot.t("BenefitModule.employerContribution"),
        fieldValue: formatMoney({
          amount: effectiveRecord.employerContribution,
          asDecimal: true
        })
      }
    ),
    isRecurring && effectiveRecord && effectiveRecord.employeeContribution !== null && /* @__PURE__ */ jsx(
      FieldStructure,
      {
        fieldTitle: polyglot.t("BenefitModule.employeeContribution"),
        fieldValue: formatMoney({
          amount: effectiveRecord.employeeContribution,
          asDecimal: true
        })
      }
    ),
    isRecurring && effectiveRecord && effectiveRecord.dependants !== null && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: polyglot.t("BenefitModule.dependants"), fieldValue: effectiveRecord.dependants }),
    (isLoan || isAllowance) && effectiveRecord && effectiveRecord.openingBalance !== null && /* @__PURE__ */ jsx(
      FieldStructure,
      {
        fieldTitle: isLoan ? polyglot.t("BenefitModule.borrowed") : polyglot.t("BenefitModule.allowance"),
        fieldValue: effectiveRecord.openingBalance ? formatMoney({
          amount: effectiveRecord.openingBalance,
          asDecimal: true
        }) : "\u2013"
      }
    ),
    isLoan && effectiveRecord && effectiveRecord.numberOfInstallments !== null && /* @__PURE__ */ jsx(
      FieldStructure,
      {
        fieldTitle: polyglot.t("BenefitModule.numberOfInstallments"),
        fieldValue: effectiveRecord.numberOfInstallments
      }
    ),
    effectiveRecord && /* @__PURE__ */ jsx(
      FieldStructure,
      {
        fieldTitle: polyglot.t("General.effectiveDate"),
        fieldValue: new LocalDate(effectiveRecord.effectiveDate).toDayMonthYearString()
      }
    ),
    isAllowance && isYearlyAllowance && /* @__PURE__ */ jsx(
      FieldStructure,
      {
        fieldTitle: polyglot.t("BenefitModule.allowanceRenewal"),
        fieldValue: new LocalDate(yearlyAllowanceNextRenewal).toDayMonthYearString()
      }
    ),
    (effectiveRecord == null ? void 0 : effectiveRecord.effectiveEndDate) && /* @__PURE__ */ jsx(
      FieldStructure,
      {
        fieldTitle: polyglot.t("General.effectiveEndDate"),
        fieldValue: effectiveRecord.effectiveEndDate
      }
    ),
    (effectiveRecord == null ? void 0 : effectiveRecord.createdBy) && /* @__PURE__ */ jsx(
      FieldStructure,
      {
        fieldTitle: polyglot.t("BenefitModule.addedBy"),
        fieldValue: /* @__PURE__ */ jsx(UserCell, { userId: effectiveRecord.createdBy, nameSx: themeFonts.title4 })
      }
    ),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(ShowHideButton, { showDetails, setShowDetails }) }),
    showDetails && /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: userCustomBenefits != null ? userCustomBenefits : [],
        columnData: columns,
        rowClick: handleRowClick,
        loading: isLoading
      }
    ),
    isOpen && selectedRow && /* @__PURE__ */ jsx(
      UpdateUserCustomBenefitDrawer,
      {
        isOpen,
        setIsOpen,
        userBenefit: selectedRow,
        onClose: () => {
          setSelectedRow(null);
          setIsOpen(false);
        },
        refresh
      }
    ),
    isViewOpen && selectedRow && /* @__PURE__ */ jsx(
      ViewUserCustomBenefitDrawer,
      {
        isOpen: isViewOpen,
        setIsOpen: setIsViewOpen,
        userBenefit: __spreadProps(__spreadValues({}, selectedRow), { included: true }),
        onClose: () => {
          setSelectedRow(null);
          setIsViewOpen(false);
        },
        refresh
      }
    )
  ] });
};
